import React from 'react';
import { useTranslation } from 'react-i18next';
import BriefcaseIcon from 'assets/images/BriefcaseIcon';
import useAgentBusinessProfiles from 'hooks/useAgentBusinessProfiles';
import CardSwitcher from './index';

export default function CardSwitcherAgentProfile({
  iconColor,
  iconSize,
  size,
}) {
  const { t } = useTranslation('menu');
  const { currentAgentProfile } = useAgentBusinessProfiles();

  if (!currentAgentProfile) {
    return null;
  }

  return (
    <CardSwitcher
      iconColor={iconColor}
      iconSize={iconSize}
      size={size}
      label={currentAgentProfile?.name}
      description={t('menu:new.contbank_agent', 'Agente Contbank')}
      icon={<BriefcaseIcon />}
    />
  );
}
