import styled from 'styled-components';
import GlobalConfigs from 'styles/variables';
import * as MenuConfigs from 'styles/menuVariables';

export const CardSwitcher = styled.div.attrs((props) => ({
  className: `card-switcher ${props.className || ''}`,
}))`
  display: flex;
  color: ${GlobalConfigs.newColors.s800};
  font-family: 'Manrope', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  width: 100%;
  letter-spacing: 0px;

  div {
    justify-content: start;
    align-items: center;
    display: flex;
    flex-grow: 1;

    div.info {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: start;
      p,
      span {
        width: 100%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
      }

      p {
        margin-bottom: 0.25rem;
        font-size: ${(props) => {
          switch (props.size) {
            case 'medium':
              return '1.125rem';

            default:
              return '0.75rem';
          }
        }};
      }

      span {
        font-weight: 500;
        font-size: ${(props) => {
          switch (props.size) {
            case 'medium':
              return '0.875rem';

            default:
              return '0.75rem';
          }
        }};
      }
    }

    span.icon {
      background-color: ${(props) =>
        !props.iconColor ? GlobalConfigs.newColors.t200 : props.iconColor};
      align-items: center;
      justify-content: center;
      display: flex;
      padding: 0.325rem;
      border-radius: 100%;
      height: ${(props) => (!props.iconSize ? '2.5rem' : props.iconSize)};
      width: ${(props) => (!props.iconSize ? '2.5rem' : props.iconSize)};
      margin-right: 0.5rem;
      svg {
        shape-rendering: geometricPrecision;
        fill: ${GlobalConfigs.newColors.n900};
      }
    }
  }

  button {
    cursor: pointer;
    text-transform: none;
    font-family: inherit;
    line-height: inherit;
    margin: 0;
    background-color: transparent;
    border: none;
    color: ${GlobalConfigs.newColors.s800};
    padding: 0;
    justify-content: space-between;
    align-items: center;
    flex: none;
    height: ${MenuConfigs.HEADER_HEIGHT};
    -webkit-tap-highlight-color: transparent;

    svg {
      width: auto;
      height: 1.5rem;
      display: block;
      vertical-align: middle;
      shape-rendering: geometricPrecision;
    }
  }
`;
