import UserAuthorizationContext from 'contexts/authorization';
import { useContext, useMemo } from 'react';
import useToken from '../hooks/useToken';
import api from './api';

const useNotifications = (token) => {
  const { getBankAccount } = useContext(UserAuthorizationContext);
  const tokenFromHook = useToken();
  const serviceToken = token || tokenFromHook;

  const BASE_URL = Object.freeze('notifications');

  const DEFAULT_HEADERS = useMemo(
    () => ({
      headers: {
        Authorization: `Bearer ${serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    }),
    [getBankAccount, serviceToken]
  );

  const getNotificationsModal = async (accountId) =>
    await api.get(`${BASE_URL}/modals/${accountId}`, DEFAULT_HEADERS);

  const postNotificationsModal = async (data) => {
    await api.post(`${BASE_URL}/modals`, data, DEFAULT_HEADERS);
  };

  return {
    getNotificationsModal,
    postNotificationsModal,
  };
};

export default useNotifications;
