import useMaskedInput from 'hooks/useMaskedInput';
import React, { forwardRef, useEffect, useState } from 'react';
import { onlyNumbers, toMask } from 'tools';
import { Field } from '../styles';

const mask = (data) => {
  const tempMask = [/\d/];

  if (data) {
    const clean = onlyNumbers(data);

    if (clean.length > 3) {
      const loop = clean.length - 3;

      for (let it = 0; it < loop; it++) {
        if ((loop - it) % 3 === 0) {
          tempMask.push('.');
        }
        tempMask.push(/\d/);
      }
    }
  }
  tempMask.push(',', /\d/, /\d/);
  return tempMask;
};

let MoneyInput = ({ className, inputId, ...props }, input) => {
  let onChange = useMaskedInput({
    input,
    guide: false,
    mask,
    onChange: (e) => {
      if (e.target.value) {
        if (e.target.value.length > 4) {
          e.target.value = e.target.value.replace(/^0/g, '');
        } else {
          e.target.value = `${onlyNumbers(e.target.value) * 1}`
            .padStart(3, '0')
            .replace(/(.+)(\d\d)$/, '$1,$2');
        }
      }
      if (props.onChange) props.onChange(e);
    },
  });

  const [count, setCount] = useState(0);
  const [dirt, setDirt] = useState(false);

  useEffect(() => {
    let mounted = true;
    const el = input.current;

    if (
      mounted &&
      count === 0 &&
      !dirt &&
      el &&
      props.defaultValue &&
      props.defaultValue !== 'undefined'
    ) {
      // console.log(count, 'money input');
      const value = onlyNumbers(props.defaultValue);

      // el.value = props.defaultValue;
      el.value = toMask(value, mask(value)).conformedValue;
      setCount(count + 1);
    }
    return () => (mounted = false);
  }, [input, props.defaultValue, count, dirt]);

  const handleOnChange = (value) => {
    if (onChange) {
      onChange(value);
    }
    setDirt(true);
  };

  return (
    <Field
      {...props}
      className={className}
      id={inputId}
      ref={input}
      type="text"
      pattern="[0-9]*"
      defaultValue={props.defaultValue}
      onChange={handleOnChange}
    />
  );
};

export default forwardRef(MoneyInput);
