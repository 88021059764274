import styled from 'styled-components';
import Configs from 'styles/variables';

export const ProgressBarBox = styled.div`
  height: 0px;
  background: ${Configs.progressBar.gray};
  width: 100%;
  z-index: 1027;
  /* z-index: 9997; */
`;

export const ProgressBarInner = styled.div`
  height: 100%;
  background: ${Configs.progressBar.yellow};
  border-radius: 0 16px 16px 0;
  transition: width 0.25s ease, border 0.25s ease;
  &.done {
    border-radius: 0;
  }
`;
