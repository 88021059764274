import React, { useRef, useEffect, useContext } from 'react';
import SearchIcon from 'assets/images/SearchIcon';
import { Container, SearchGroup, SearchField, SearchButton } from './styles';
import { MenuSearchResults } from '../SearchResults';
import { useMediaQuery } from 'react-responsive';
import Configs from 'styles/variables';
import { useTranslation } from 'react-i18next';
import SearchMenuContext from 'contexts/search-menu';

export const MenuSearchField = () => {
  const field = useRef(null);
  const { t } = useTranslation('common');

  const { initSearch } = useContext(SearchMenuContext);
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${Configs.maxTabletWidth}px)`,
  });

  useEffect(() => {
    let mounted = true;

    if (mounted && field.current && !isTabletOrMobile) {
      field.current.focus();
    }
    return () => (mounted = false);
  }, [field, isTabletOrMobile]);

  const handleInputChange = (event) => {
    initSearch(event.target.value);
  };

  return (
    <Container>
      <SearchGroup>
        <SearchField
          ref={field}
          onChange={handleInputChange}
          placeholder={t('menu.search_placeholder', 'O que você procura?')}
        />
        <SearchButton>
          <SearchIcon />
        </SearchButton>
      </SearchGroup>
      <MenuSearchResults />
    </Container>
  );
};
