import React, { createContext, useContext, useEffect, useState } from 'react';
import { default as hotjar } from '@hotjar/browser';
import RegistrationsContext from './registrations';

const HotjarContext = createContext({
  OnStart: () => {},
  initHotjar: () => {},
  identifyUser: () => {},
  sendEventToHotjar: () => {},
  stateChange: () => {},
  isReady: () => false,
});

export const HotjarProvider = ({
  hotjarId,
  hotjarVersion,
  defaultStart = true,
  children,
}) => {
  const [isHotjarInitialized, setIsHotjarInitialized] = useState(false);
  const [start, setStart] = useState(defaultStart);
  const { registrations } = useContext(RegistrationsContext);
  const { isReady } = hotjar;

  const initHotjar = () => {
    if (!isHotjarInitialized && hotjarVersion && hotjarId) {
      hotjar.init(hotjarId, hotjarVersion);
      setIsHotjarInitialized(true);
    }
  };

  const remove = () => {
    delete window.hj;
    delete window._hjSettings;
    setIsHotjarInitialized(false);
  };

  const OnStart = () => {
    if (!start) setStart(true);
  };

  const sendEventToHotjar = (event) => {
    if (isReady() && !!event) hotjar.event(event);
  };

  const identifyUser = (userId, properties = {}) => {
    if (isReady() && !!userId) hotjar.identify(userId, properties);
  };

  const stateChange = (path) => {
    if (isReady() && !!path) hotjar.stateChange(path);
  };

  useEffect(() => {
    let mounted = true;

    if (mounted && start) {
      initHotjar();
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start]);

  useEffect(() => {
    let mounted = true;
    const userId = registrations?.user_id ?? null;

    if (mounted && !!userId) {
      identifyUser(userId);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrations?.user_id]);

  const value = {
    identifyUser,
    sendEventToHotjar,
    stateChange,
    isReady,
    remove,
    initHotjar,
    OnStart,
  };

  return (
    <HotjarContext.Provider value={value}>{children}</HotjarContext.Provider>
  );
};

export const useHotjar = () => useContext(HotjarContext);
