import useMaskedInput from 'hooks/useMaskedInput';
import React, { forwardRef, useEffect, useState } from 'react';
import { onlyNumbers, toMask } from 'tools';
import { Field } from '../styles';

const mask = [/\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/, ' ', /\d/];

let TokenInput = ({ className, inputId, ...props }, input) => {
  const [count, setCount] = useState(0);
  const [dirt, setDirt] = useState(false);

  let onChange = useMaskedInput({
    input,
    mask: mask,
    onChange: props.onChange,
  });

  const handleOnChange = (value) => {
    onChange(value);
    setDirt(true);
  };

  useEffect(() => {
    let mounted = true;
    const el = input.current;

    if (
      mounted &&
      count === 0 &&
      !dirt &&
      el &&
      props.defaultValue &&
      props.defaultValue !== 'undefined'
    ) {
      const value = onlyNumbers(props.defaultValue);

      el.value = toMask(value, mask).conformedValue;
      setCount(count + 1);
    }
    return () => (mounted = false);
  }, [input, props.defaultValue, count, dirt]);
  return (
    <Field
      {...props}
      className={className}
      id={inputId}
      ref={input}
      type="text"
      pattern="[0-9]*"
      autocomplete="one-time-code"
      defaultValue={props.defaultValue}
      onChange={handleOnChange}
    />
  );
};

export default forwardRef(TokenInput);
