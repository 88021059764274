import { useContext } from 'react';
import UserAuthorizationContext from 'contexts/authorization';
import api from './api';
import useToken from 'hooks/useToken';

const usePasswordService = (token) => {
  const { getBankAccount } = useContext(UserAuthorizationContext);
  const tokenFromHook = useToken();
  const serviceToken = token || tokenFromHook;

  const savePassword = async (password, localToken) =>
    await api.post(
      '/passwords',
      { password: password },
      {
        headers: {
          Authorization: `Bearer ${serviceToken || localToken}`,
          'X-Current-Identity': `${getBankAccount()}`,
        },
      }
    );

  const hasPassword = async (localToken) =>
    await api.get('/passwords', {
      headers: {
        Authorization: `Bearer ${serviceToken || localToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });

  const updateNewPassword = async (password, localToken) =>
    await api.patch(
      '/passwords',
      { password: password },
      {
        headers: {
          Authorization: `Bearer ${serviceToken || localToken}`,
          'X-Current-Identity': `${getBankAccount()}`,
        },
      }
    );

  const validatePasswordNew = async (password, localToken) =>
    await api.post(
      '/passwords/validate',
      { password: password },
      {
        headers: {
          Authorization: `Bearer ${serviceToken || localToken}`,
          'X-Current-Identity': `${getBankAccount()}`,
        },
      }
    );

  return {
    savePassword,
    hasPassword,
    validatePasswordNew,
    updateNewPassword,
  };
};

export default usePasswordService;
