import React from 'react';
import useNewMenuContext, { MENU_TYPE } from 'contexts/new-menu';
import CardSwitcherBankAccount from 'components/CardSwitcher/CardSwitcherBankAccount';
import CardSwitcherAgentProfile from 'components/CardSwitcher/CardSwitcherAgentProfile';

export default function MenuHeaderCardSwitcher() {
  const { menuView } = useNewMenuContext();
  const agentMenuOn = menuView === MENU_TYPE.BUSINESS_AGENT;

  if (agentMenuOn) {
    return <CardSwitcherAgentProfile />;
  }

  return <CardSwitcherBankAccount />;
}
