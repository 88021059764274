import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

export const initialize = () => {
  // TODO: Adicionar UA na config
  ReactGA.initialize('UA-199113509-2');
};

export const usePageViewAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // TODO: Bloquear o GA em localhost
    // const host = window && window.location && window.location.hostname;
    // console.log(host === 'localhost');

    if (location) {
      pageview(location.pathname + location.search);
    }
  }, [location]);
};

export const event = ({
  category = '',
  action = '',
  label = '',
  value = 0,
  nonInteraction = false,
}) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
    nonInteraction,
  });
};

export const logEvent = (description = '', context = '') => {
  ReactGA.event({
    category: 'LOG',
    action: description,
    label: context,
  });
};

export const errorEvent = (description = '', context = '') => {
  ReactGA.event({
    category: 'ERROR',
    action: description,
    label: context,
  });
};

export const exception = ({ description = '', fatal = false }) => {
  ReactGA.exception({
    description,
    fatal,
  });
};

export const modalview = (modalName = '') => {
  ReactGA.modalview(modalName);
};

export const pageview = (path = '', title = '', trackerNames = []) => {
  if (process.env.REACT_APP_ENV === 'PROD') {
    ReactGA.pageview(path, trackerNames, title);
  }
};
