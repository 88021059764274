import useToken from 'hooks/useToken';
import React, { useState, useEffect } from 'react';
import products from 'services/products';

const PricesContext = React.createContext({ list: [] });

export const PricesProvider = ({ children }) => {
  const [prices, setPrices] = useState(null);
  const [loading, setLoading] = useState(true);
  const [canLoad, setCanLoad] = useState(false);
  const token = useToken();

  useEffect(() => {
    const fetchPrices = async () => {
      setLoading(true);
      try {
        const prices = await products.getList(token);

        if (prices.data.products) {
          setPrices(prices.data.products);
        }
        setLoading(false);
      } catch (e) {
        if (e) {
          console.error(e.message, 'Try updating the API key in App.js');
        }
        setLoading(false);
      }
    };

    if (token && canLoad) fetchPrices();
  }, [token, canLoad]);

  const loadPrices = () => {
    setCanLoad(true);
  };

  return (
    <PricesContext.Provider value={{ prices, setPrices, loadPrices, loading }}>
      {children}
    </PricesContext.Provider>
  );
};

export default PricesContext;
