import { EXTERNAL_LINK_PARAM } from 'config/constants';

export const parseURL = (url = '') => {
  if (url && url.includes('?')) {
    return ` ${url}&${EXTERNAL_LINK_PARAM}`;
  }

  if (url) {
    return ` ${url}?${EXTERNAL_LINK_PARAM}`;
  }

  return url;
};
