import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PATHS } from 'Routes/constants';
import NotepadPersonIcon from 'assets/images/NotepadPersonIcon';
import Configs from 'styles/variables';
import useCan from 'hooks/useCan';
import PersonIcon from 'assets/images/PersonIcon';
import { clearURL } from 'tools';
import { isMobile } from 'react-device-detect';
import ToolboxBankIcon from 'assets/images/ToolboxBankIcon';
import SettingsIcon from 'assets/images/SettingsIcon';
import DashboardIcon from 'assets/images/DashboardIcon';
import CreditIcon from 'assets/images/CreditIcon';
import FiscalSystemIcon from 'assets/images/FiscalSystemIcon';
import RegistrationsContext from 'contexts/registrations';
import { useFeatureFlag } from 'configcat-react';
import NAVIGATOR_CONFIG_CAT_FLAG from 'pages/Navigator/shared/constants/configcat-const';
import NavigatorIcon from 'assets/images/NavigatorIcon';
// import PersonProfileManagementIcon from 'assets/images/PersonProfileManagementIcon';

const useBusinessAgentMenu = () => {
  const { t } = useTranslation('menu');
  const { canDo } = useCan();
  const { registrations } = useContext(RegistrationsContext);
  const user = {
    custom: {
      userID: registrations?.user_id || '',
    },
  };
  const { value: navigatorFlag, loading: loadingCat } = useFeatureFlag(
    NAVIGATOR_CONFIG_CAT_FLAG.EXIBIR_NAVIGATOR,
    false,
    user
  );

  const enableShowNavigator = useMemo(
    () => navigatorFlag && !loadingCat,
    [loadingCat, navigatorFlag]
  );

  const menuOptions = useMemo(
    () =>
      [
        {
          id: 'b0',
          location: clearURL(PATHS.AGENT.DASHBOARD),
          label: t('menu:new.dashboard', 'Dashboard'),
          icon: <DashboardIcon fill={Configs.newColors.n200} />,
        },
        {
          id: 'b1',
          location: PATHS.EXTERNAL.CREDIT,
          label: t('menu:new.credit', 'Crédito'),
          icon: <CreditIcon fill={Configs.newColors.n200} />,
          external: true,
        },

        ...[
          !!enableShowNavigator && {
            id: 'b7',
            location: PATHS.NAVIGATOR.INDICATORS,
            label: t('menu:new.navigator', 'Navigator'),
            icon: <NavigatorIcon fill={Configs.newColors.n200} />,
          },
        ],

        ...[
          isMobile && {
            id: 'b2',
            location: PATHS.AGENT.INVITE,
            label: t('menu:new.invite', 'Convidar Novo Cliente'),
            icon: <NotepadPersonIcon fill={Configs.newColors.n200} />,
          },
        ],

        ...[
          canDo('read:technologies', 'business') === true && {
            id: 'b3',
            location: clearURL(PATHS.TECHNOLOGIES.LIST),
            label: t(
              'menu:submenus.profile.cont_systems',
              'Sistemas Contábeis'
            ),
            icon: <ToolboxBankIcon fill={Configs.newColors.n200} />,
          },
        ],
        ...[
          canDo('read:technologies', 'business') === true && {
            id: 'b4',
            location: clearURL(PATHS.TECHNOLOGIES.LIST_NF),
            label: t('menu:submenus.profile.invoices', 'Sistemas de NF/ERP’s'),
            icon: <FiscalSystemIcon fill={Configs.newColors.n200} />,
          },
        ],
        ...[
          canDo('read:backups', 'business') === true && {
            id: 'b5',
            location: PATHS.PREFERENCES,
            label: t('menu:new.contbox_credentials', 'Credenciais do Contbox'),
            icon: <SettingsIcon fill={Configs.newColors.n200} />,
          },
        ],
        {
          id: 'b6',
          location: PATHS.AGENT.PROFILE,
          label: t('menu:new.my_profile', 'Meu Perfil'),
          icon: <PersonIcon fill={Configs.newColors.n200} />,
        },
        // {
        //   id: 'b7',
        //   location: clearURL(PATHS.PERMISSIONS.CREATE),
        //   label: t('menu:new.profile_management', 'Gestão de Perfis'),
        //   icon: <PersonProfileManagementIcon fill={Configs.newColors.n200} />,
        // },

        // ...[
        //   canDo('read:customers', 'business') === true && {
        //     id: 'b2',
        //     location: PATHS.CUSTOMERS,
        //     label: t('menu:new.customers_management', 'Gestão de Clientes'),
        //     icon: <NotepadPersonIcon fill={Configs.newColors.n200} />,
        //   },
        // ],
        // {
        //   id: 'b2',
        //   location: PATHS.CONFIGURATIONS,
        //   label: t('menu:new.contbox', 'Contbox'),
        //   icon: <ToolboxBankIcon fill={Configs.newColors.n200} />,
        // },
        // {
        //   id: 'b1',
        //   location: PATHS.DASHBOARD,
        //   label: t('menu:dashboard', 'Dashboard'),
        // },
        // ...[
        //   canDo('read:customers', 'business') === true && {
        //     id: 'b2',
        //     location: PATHS.CUSTOMERS,
        //     label: t('menu:customers', 'Clientes'),
        //   },
        // ],
        // ...[
        //   canDo('read:technologies', 'business') === true && {
        //     id: 'b4',
        //     location: clearURL(PATHS.TECHNOLOGIES.LIST),
        //     label: t(
        //       'menu:submenus.profile.cont_systems',
        //       'Sistemas Contábeis'
        //     ),
        //   },
        // ],
        // ...[
        //   canDo('read:technologies', 'business') === true && {
        //     id: 'b5',
        //     location: clearURL(PATHS.TECHNOLOGIES.LIST_NF),
        //     label: t('menu:submenus.profile.invoices', 'Sistemas de NF'),
        //   },
        // ],
        // ...[
        //   canDo('read:backups', 'business') === true && {
        //     id: 'b6',
        //     location: PATHS.PREFERENCES,
        //     label: t('menu:preferences', 'Preferências'),
        //   },
        // ],
      ].filter((item) => typeof item === 'object'),
    [t, canDo, enableShowNavigator]
  );

  return {
    menuOptions,
  };
};

export default useBusinessAgentMenu;
