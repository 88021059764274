import React from 'react';

import { PATHS } from 'Routes/constants';

import useNewMenuContext from 'contexts/new-menu';
import { Redirect } from 'react-router-dom';
import useConstants from 'services/contants';

const Dashboard = ({ t, location }) => {
  const { menuView } = useNewMenuContext();
  const { MENU_TYPES } = useConstants();

  if (menuView === MENU_TYPES.BUSINESS_AGENT) {
    return <Redirect to={PATHS.AGENT.DASHBOARD} />;
  } else {
    return <Redirect to={PATHS.BANK.DASHBOARD} />;
  }
};

export default Dashboard;
