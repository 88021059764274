import { useEffect, useState, useContext, useMemo, useCallback } from 'react';
// import useToken from 'hooks/useToken';
import UserAuthorizationContext from 'contexts/authorization';
import useToken from 'hooks/useToken';
// import useAuthorization from 'hooks/useAuthorization';

const useCan = () => {
  const token = useToken();
  const [permissions, setPermissions] = useState({});
  const [loadingBusiness, setLoadingBusiness] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isBusinessAgent, setIsBusinessAgent] = useState(false);
  const [businessPermissions, setBusinessPermissions] = useState();
  const {
    userAuthorization,
    isAdmin,
    loading: loadingAuth,
    error,
    getCurrentID,
    getAgentData,
  } = useContext(UserAuthorizationContext);
  const currId = useMemo(() => getCurrentID(), [getCurrentID]);

  const loading = loadingAuth || loadingBusiness;

  useEffect(() => {
    let mounted = true;

    if (mounted && currId && currId !== refresh) {
      setRefresh(currId);
    }
    return () => (mounted = false);
  }, [currId, refresh]);

  useEffect(() => {
    let mounted = true;
    const loadBusinessPermissions = async () => {
      setLoadingBusiness(true);
      if (
        userAuthorization &&
        userAuthorization.business_agents &&
        userAuthorization.business_agents.length > 0
      ) {
        setIsBusinessAgent(true);
        const userData = await getAgentData(currId);

        setBusinessPermissions(userData.permissions);
      } else {
        setBusinessPermissions(null);
      }
      setLoadingBusiness(false);
    };

    if (mounted && userAuthorization && !error) {
      loadBusinessPermissions();
    }
    if (mounted && refresh && refresh !== currId) {
      setRefresh(false);
      loadBusinessPermissions();
    }
    return () => (mounted = false);
  }, [userAuthorization, error, getAgentData, refresh, setRefresh, currId]);

  useEffect(() => {
    let mounted = true;
    const parseJwt = (token) => {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );

      return JSON.parse(jsonPayload);
    };
    const setPermissionsFromToken = () => {
      try {
        setPermissions({ ...parseJwt(token) }.permissions);
      } catch (e) {
        console.error(e);
      }
    };

    if (mounted && token) {
      setPermissionsFromToken();
    }
    return () => (mounted = false);
  }, [token]);

  const validatePermission = useCallback(
    (what) =>
      permissions && permissions.length > 0
        ? permissions.indexOf(what) > -1
        : true,
    [permissions]
  );

  const validateBusinessAgentPermission = useCallback(
    (what) =>
      isAdmin() ||
      (businessPermissions && businessPermissions.indexOf(what) > -1),
    [businessPermissions, isAdmin]
  );

  const validateBankPermission = useCallback((what) => true, []);

  const canDo = useCallback(
    (what, userType) => {
      if (userType === 'business') {
        return isBusinessAgent ? validateBusinessAgentPermission(what) : false;
      }

      if (userType === 'bank') {
        return validateBankPermission(what);
      }

      return validatePermission(what);
    },
    [
      validateBusinessAgentPermission,
      validateBankPermission,
      validatePermission,
      isBusinessAgent,
    ]
  );

  return {
    permissions,
    businessPermissions,
    loading,
    canDo,
  };
};

export default useCan;
