import React, { useRef, useContext } from 'react';
import useOutsideClick from 'hooks/useOutsideClick';
import { MenuVisibilityContext } from 'contexts/menuVisibility';
import {
  MENU_UNLOADED_CLASS,
  NAV_MENU_CLOSE_CLASS,
  NAV_SWITCHER_CLOSE_CLASS,
} from './constants';
import MenuContainer from './components/MenuContainer';

function NewMenu() {
  const menuRef = useRef(null);
  const {
    menuState,
    setMenuState,
    menuIsOpen,
    handleCloseMenu,
    switcherIsOpen,
    isVisible,
  } = useContext(MenuVisibilityContext);

  const callbackOutsideClick = () => {
    if (menuIsOpen) {
      setMenuState(NAV_MENU_CLOSE_CLASS);
      return;
    }

    if (switcherIsOpen) {
      setMenuState(NAV_SWITCHER_CLOSE_CLASS);
      return;
    }

    setMenuState(MENU_UNLOADED_CLASS);
  };

  useOutsideClick(menuRef, callbackOutsideClick, false);

  if (!isVisible) {
    return null;
  }

  return (
    <MenuContainer
      ref={menuRef}
      handleCloseMenu={handleCloseMenu}
      menuState={menuState}
      onClickOverlay={callbackOutsideClick}
    />
  );
}

export default NewMenu;
