import styled from 'styled-components';
import Configs from 'styles/variables';

export const ButtonBox = styled.label`
  display: flex;
  height: ${({ noTextOverflow, big }) =>
    noTextOverflow ? '44px' : big ? '48px' : `${Configs.buttons.height}px`};
  justify-content: flex-start;
  align-items: center;
  font-size: 24px;
  padding: 0 ${Configs.buttons.paddingX}px;
  transition: all 0.25s ease;
  background: none;
  position: relative;
  cursor: pointer;
  padding-left: 54px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: ${({ big }) => (big ? 42 : 50)}%;
    margin-top: -${Configs.buttons.height / 2}px;
    width: ${({ big }) => (big ? 48 : Configs.buttons.height)}px;
    height: ${({ big }) => (big ? 48 : Configs.buttons.height)}px;
    border-radius: ${({ big }) =>
      big ? '50%' : `${Configs.buttons.height / 2}px`};
    margin-right: 14px;
    border: solid 1px ${Configs.lightBlue};
    background-color: ${Configs.lightGray};
    transition: all 0.25s ease;
  }
  &:after {
    content: '';
    width: ${({ big }) => (big ? 17 : 8)}px;
    height: ${({ big }) => (big ? 17 : 8)}px;
    border-radius: ${({ big }) => (big ? 32 : 24)}px;
    position: absolute;
    left: ${({ big }) => (big ? 17 : Configs.buttons.height / 2 - 2)}px;
    top: ${({ big }) => (big ? 34 : 50)}%;
    margin-top: ${({ big }) => (big ? 0 : -3)}px;
    background-color: ${Configs.yellow};
    transform: scale(3);
    opacity: 0;
    transition: all 0.15s 0.1s ease;
  }
`;

export const Label = styled.span`
  font-size: 14px;
  line-height: 2.29;
  letter-spacing: -0.28px;
  display: block;
  text-align: center;
  white-space: nowrap;
`;

export const ButtonEl = styled.input.attrs((props) => ({
  activeBg:
    props.theme === 'dark' ? Configs.buttons.active.background : Configs.yellow,
  bgMarker:
    props.theme === 'dark' ? Configs.yellow : Configs.buttons.active.background,
}))`
  display: none;
  &:checked + label {
    &:before {
      background: ${(props) => props.activeBg};
      color: ${Configs.buttons.active.color};
      border-color: transparent;
    }
    &:after {
      background-color: ${(props) => props.bgMarker};
      transform: scale(1);
      opacity: 1;
    }
  }
`;
