import { useMemo } from 'react';

const useConstants = () => {
  const FETCH_STATES = useMemo(
    () => ({
      LOADING: 'LOADING',
      SUCCESS: 'SUCCESS',
      ERROR: 'ERROR',
      FETCH: 'FETCH',
      INITIAL: 'INITIAL',
      NONE: null,
    }),
    []
  );

  const PAGE_STATES = useMemo(
    () => ({
      INITIAL: 'INITIAL',
      LOADING: 'LOADING',
      SUCCESS: 'SUCCESS',
      ERROR: 'ERROR',
      REDIRECT: 'REDIRECT',
    }),
    []
  );

  const MODAL_STATES = useMemo(
    () => ({
      OPENED: 'OPENED',
      CLOSED: 'CLOSED',
    }),
    []
  );

  const MODAL_BTN_TYPES = useMemo(
    () => ({
      INTERNAL_LINK: 'INTERNAL_LINK',
      EXTERNAL_LINK: 'EXTERNAL_LINK',
      ENDPOINT: 'ENDPOINT',
      CLOSE: 'CLOSE',
    }),
    []
  );

  const STORAGE_LABELS = useMemo(
    () => ({
      SMN: 'sMN',
      CAC: 'cAc',
      CBA: 'cBa',
      MV: 'mV',
    }),
    []
  );

  const ACCOUNT_TYPES = useMemo(
    () => ({
      PERSONAL: 'PERSONAL',
      BUSINESS: 'BUSINESS',
    }),
    []
  );
  const MENU_TYPES = useMemo(
    () => ({
      BANK: '1',
      BUSINESS_AGENT: '2',
    }),
    []
  );

  const BUSINESS_TYPE = useMemo(
    () => ({
      SA: 'SA',
      LTDA: 'LTDA',
    }),
    []
  );

  const INPUT_STATES = useMemo(
    () => ({
      ERROR: 'error',
      SUCCESS: 'success',
      DEFAULT: 'default',
    }),
    []
  );

  const INPUT_DEFAULT_ERRORS = useMemo(
    () => ({
      REQUIRED: 'REQUIRED',
      INVALID: 'INVALID',
    }),
    []
  );

  const INPUT_NUMBER_ERRORS = useMemo(
    () => ({
      MIN: 'MIN',
      MAX: 'MAX',
      REQUIRED: 'REQUIRED',
      INVALID: 'INVALID',
    }),
    []
  );

  const INPUT_EMAIL_ERRORS = useMemo(
    () => ({
      ...INPUT_DEFAULT_ERRORS,
    }),
    [INPUT_DEFAULT_ERRORS]
  );
  const INPUT_PHONE_ERRORS = useMemo(
    () => ({
      ...INPUT_DEFAULT_ERRORS,
    }),
    [INPUT_DEFAULT_ERRORS]
  );

  const VALIDATION_STATE = useMemo(
    () => ({
      IDLE: 'IDLE',
      VALIDATED: 'VALIDATED',
    }),
    []
  );

  return {
    FETCH_STATES,
    MODAL_STATES,
    MODAL_BTN_TYPES,
    STORAGE_LABELS,
    PAGE_STATES,
    ACCOUNT_TYPES,
    MENU_TYPES,
    BUSINESS_TYPE,
    INPUT_STATES,
    INPUT_DEFAULT_ERRORS,
    INPUT_NUMBER_ERRORS,
    INPUT_EMAIL_ERRORS,
    INPUT_PHONE_ERRORS,
    VALIDATION_STATE,
  };
};

export default useConstants;
