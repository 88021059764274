import { useContext } from 'react';
import api from './api';
import UserAuthorizationContext from 'contexts/authorization';
import useAccountsService from './accounts';
import useToken from 'hooks/useToken';

const useBankPixService = (token) => {
  const serviceToken = useToken();
  const { getBankAccount } = useContext(UserAuthorizationContext);
  const { getAccountRegistry } = useAccountsService();

  const getAccountDetails = async (localToken) => {
    return await getAccountRegistry(token || serviceToken || localToken, true);
  };

  const getKeys = async (accountNumber, localToken) => {
    return await api.get(`/pix/keys?account_number=${accountNumber}`, {
      headers: {
        Authorization: `Bearer ${token || serviceToken || localToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const getKeyClaim = async (key, localToken) => {
    return await api.get(`/pix/keys/${key}/claim`, {
      headers: {
        Authorization: `Bearer ${token || serviceToken || localToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const postKey = async (data, account_id, transactionalToken, localToken) => {
    return await api.post(`/pix/keys`, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken || localToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
        'X-Transaction-Token': `${transactionalToken}`,
        'X-Account-ID': `${account_id}`,
      },
    });
  };

  const postKeyClaim = async (
    data,
    account_id,
    transactionalToken,
    localToken
  ) => {
    return await api.post(`/pix/keys/claim`, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken || localToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
        'X-Account-ID': `${account_id}`,
        'X-Transaction-Token': `${transactionalToken}`,
      },
    });
  };

  const getKey = async (key, localToken) => {
    return await api.get(`/pix/keys/${key}`, {
      headers: {
        Authorization: `Bearer ${token || serviceToken || localToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const getKeyClaimStatus = async (account_id, localToken) => {
    return await api.get(
      `/pix/keys/claim?claim_status=WAITING_RESOLUTION&per_page=1&page=1`,
      {
        headers: {
          Authorization: `Bearer ${token || serviceToken || localToken}`,
          'X-Current-Identity': `${getBankAccount()}`,
          'X-Account-ID': `${account_id}`,
        },
      }
    );
  };

  const cancelClaim = async (account_id, claimID, localToken) => {
    return await api.delete(`/pix/keys/claim/${claimID}`, {
      headers: {
        Authorization: `Bearer ${token || serviceToken || localToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
        'X-Account-ID': `${account_id}`,
      },
    });
  };

  const confirmClaim = async (account_id, claimID, localToken) => {
    return await api.patch(`/pix/keys/claim/${claimID}/confirm`, {
      headers: {
        Authorization: `Bearer ${token || serviceToken || localToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
        'X-Account-ID': `${account_id}`,
      },
    });
  };

  const deleteKey = async (account, key, transactionalToken, localToken) => {
    return await api.delete(`/pix/accounts/${account.number}/keys/${key}`, {
      headers: {
        Authorization: `Bearer ${token || serviceToken || localToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
        'X-Transaction-Token': `${transactionalToken}`,
        'X-Account-ID': `${account.account_id}`,
      },
    });
  };

  const getPixTransaction = async (id, localToken) => {
    return await api.get(`/pix/transfer/${id}`, {
      headers: {
        Authorization: `Bearer ${token || serviceToken || localToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const sendTransfer = async (data, transactionalToken, localToken) => {
    return await api.post(`/pix/transfer`, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken || localToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
        'X-Transaction-Token': `${transactionalToken}`,
      },
    });
  };

  const decodeQRCode = async (data, localToken) => {
    return await api.post(`/pix/qrcode/decode`, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken || localToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const getPixTransactionWithError = async (id, localToken) => {
    return await api
      .get(`/pix/transfer/${id}`, {
        headers: {
          Authorization: `Bearer ${token || serviceToken || localToken}`,
          'X-Current-Identity': `${getBankAccount()}`,
        },
      })
      .then((res) => {
        if (res?.data?.errors) {
          return Promise.reject(res.data.errors);
        }
        return Promise.resolve(res);
      })
      .catch((e) => Promise.reject(e));
  };

  return {
    getKey,
    getKeys,
    postKeyClaim,
    getKeyClaim,
    postKey,
    deleteKey,
    getAccountDetails,
    sendTransfer,
    decodeQRCode,
    getPixTransaction,
    getPixTransactionWithError,
    getKeyClaimStatus,
    cancelClaim,
    confirmClaim,
  };
};

export default useBankPixService;
