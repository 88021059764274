import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useTokenClaims = (catchError, ignoreCache) => {
  const [tokenClaim, setTokenClaim] = useState(null);
  const [tokenError, setTokenError] = useState(null);
  const { getIdTokenClaims, isLoading } = useAuth0();

  useEffect(() => {
    let mounted = true;
    const fetchToken = async () => {
      try {
        const claim = await getIdTokenClaims({
          audience: process.env.REACT_APP_AUDIENCE,
        });

        setTokenClaim(claim);
        setTokenError(null);
      } catch (e) {
        console.error(e);
        setTokenError(e.message);
      }
    };

    if (mounted && (!tokenClaim || ignoreCache) && !isLoading) {
      fetchToken();
    }
    return () => (mounted = false);
  }, [isLoading, ignoreCache, tokenClaim, getIdTokenClaims]);

  return tokenClaim
    ? tokenClaim
    : catchError && tokenError
    ? { error: tokenError }
    : null;
};

export default useTokenClaims;
