import React from 'react';

import { TitleTag } from './styles';

const TitleLegend = ({ className, children, ...props }) => (
  <TitleTag {...props} className={className}>
    {children}
  </TitleTag>
);

export default TitleLegend;
