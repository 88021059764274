import styled from 'styled-components';
import FreeCardContainer from 'components/FreeCardContainer';
import {
  WizardAlert,
  WizardInput,
  WizardStep,
} from 'components/WizardBasicContainer/styles';
import media from 'styles/media';
import WizardBackButton from 'components/WizardBackButton';
import RoundedButtonArrow from 'components/RoundedButtonArrow';
import Configs from 'styles/variables';
import RoundedButtonLink from 'components/RoundedButtonLink';
import RoundedButton from 'components/RoundedButton';

export const Step = styled(WizardStep)``;

export const Alert = styled(WizardAlert)`
  flex-wrap: wrap;
  flex-direction: column;
`;

export const Input = styled(WizardInput)``;

export const UserBox = styled.div`
  font-family: 'Manrope-Light';
  font-size: 16px;
  font-weight: 300;
  line-height: 1.33;
  letter-spacing: 0.12px;
  color: #03071f;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  font-size: 12px;
  font-family: 'Manrope';
  font-weight: bold;
`;

export const Value = styled.div``;

export const Result = styled(FreeCardContainer)`
  margin-bottom: 32px;
  flex-direction: row;
  ${media.mobile`
    flex-direction: column;
  `}
`;

export const Column = styled.div`
  margin-right: 40px;
  &:last-child {
    margin-right: 0;
  }
`;

export const ButtonBack = styled(WizardBackButton)``;

export const ChooseSystemButton = styled(RoundedButtonArrow)`
  margin: 0 16px 16px 0;
`;

export const List = styled.div`
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 75%;
  overflow-y: auto;
  align-content: baseline;
  ${media.mobile`
    flex: 1;
  `}
`;

export const Description = styled.div`
  font-size: 24px;
  padding: 24px 0;
  color: ${Configs.white};
  font-family: 'Manrope-Light';
  width: 51%;
  text-align: center;
  line-height: 180%;
  ${media.mobile`
    display: none;
  `}
`;
export const DescriptionMobile = styled(Description)`
  display: none;
  width: 100%;
  padding: 0 42px;
  line-height: 120%;
  text-align: left;
  color: ${Configs.lightGray};
  button {
    display: block;
    margin-top: 32px;
  }
  ${media.mobile`
    display: block;
  `};
`;

export const RoundedLink = styled(RoundedButtonLink)`
  display: inline-flex;
`;

export const RoundedButtonLine = styled(RoundedButton)`
  display: inline-flex;
`;

export const Credentials = styled(FreeCardContainer)`
  flex-direction: column;
  div:nth-child(1) {
    margin-bottom: 30px;
  }
  span {
    padding: 8px;
    display: block;
    cursor: pointer;
    color: ${Configs.darkGray};
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-weight: normal;
    color: ${Configs.darkGray};
    font-size: 16px;
    padding: 8px;
  }
  ${media.mobile`
    display: none;
  `}
`;
