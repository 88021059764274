import styled from 'styled-components';
import media from 'styles/media';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  & > div {
    display: flex;
    flex: 1;
    ${media.mobile`
        flex: initial;
        padding-top: 0;
      `}
    &:nth-child(1) {
      max-width: 45%;
      ${media.mobile`
        max-width: 100vw;
      `}
    }
  }
  ${media.mobile`
    flex-direction: column;
    ${({ mAlignFlexStart }) =>
      mAlignFlexStart &&
      `
      align-items: flex-start;
    `}
  `}
`;
