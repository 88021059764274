import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Configs from 'styles/variables';
import BriefcaseIcon from 'assets/images/BriefcaseIcon';
import PersonIcon from 'assets/images/PersonIcon';
import CompanyIcon from 'assets/images/CompanyIcon';
import * as S from '../style';
import useAgentBusinessProfiles from 'hooks/useAgentBusinessProfiles';
import useAccounts from 'hooks/useAccounts';
import { forceStringCapitalize, truncate } from 'tools/string';
import MenuList from './MenuList';
import MenuListItem from './MenuListItem';
import { ACCOUNT_TYPE } from 'config/constants';
import useNewMenuContext, { MENU_TYPE } from 'contexts/new-menu';
import { MenuVisibilityContext } from 'contexts/menuVisibility';
import AlertProgress from 'components/AlertProgress';
import { useDispatch } from 'react-redux';
import { setAccountTypeState } from 'store/features/authorization/authorization-slice';

export default function MenuNavSwitcherProfile() {
  const dispatch = useDispatch();
  const {
    accountsPF,
    accountsPJ,
    currentAccountPF,
    currentAccountPJ,
    accountsPFWithoutCurrent,
    accountsPJWithoutCurrent,
    setAccount,
  } = useAccounts();
  const { handleCloseSwitcher } = useContext(MenuVisibilityContext);
  const { menuView } = useNewMenuContext();
  const agentMenuOn = menuView === MENU_TYPE.BUSINESS_AGENT;
  const bankMenuOn = menuView === MENU_TYPE.BANK;
  const [alertProgressAgentOpen, setAlertProgressAgentOpen] = useState(false);
  const [alertProgressAccountOpen, setAlertProgressAccountOpen] =
    useState(false);
  const [accountIdState, setAccountIdState] = useState(null);
  const [agentIdState, setAgentIdState] = useState(null);
  const [nameToProgress, setNameToProgress] = useState('');

  const {
    currentAgentProfile,
    agentProfilesWithoutCurrent,
    agentProfiles,
    setAgentProfile,
  } = useAgentBusinessProfiles();

  const switchAccount = (id, name) => {
    setAlertProgressAccountOpen(true);
    handleCloseSwitcher();
    setAccountIdState(id);
    setNameToProgress(name);
  };

  const alertProgressAccountCallback = () => {
    setAlertProgressAccountOpen(false);
    setAccount(accountIdState);
  };

  const switchAgentProfile = (id, name) => {
    setAlertProgressAgentOpen(true);
    handleCloseSwitcher();
    setAgentIdState(id);
    setNameToProgress(name);
  };

  const alertProgressAgentCallback = () => {
    setAlertProgressAgentOpen(false);
    setAgentProfile(agentIdState);
  };

  React.useEffect(() => {
    dispatch(setAccountTypeState(menuView));
  }, [dispatch, menuView]);

  return (
    <>
      {agentMenuOn && (
        <AgentMenu
          currentAgentProfile={currentAgentProfile}
          agentProfilesWithoutCurrent={agentProfilesWithoutCurrent}
          switchAgentProfile={switchAgentProfile}
          agentProfiles={agentProfiles}
          alertProgressAgentOpen={alertProgressAgentOpen}
          alertProgressAgentCallback={alertProgressAgentCallback}
          nameToProgress={nameToProgress}
        />
      )}

      {bankMenuOn && (
        <>
          <BankMenu
            currentAccountPF={currentAccountPF}
            currentAccountPJ={currentAccountPJ}
            accountsPJWithoutCurrent={accountsPJWithoutCurrent}
            accountsPFWithoutCurrent={accountsPFWithoutCurrent}
            switchAccount={switchAccount}
            accountsPJ={accountsPJ}
            accountsPF={accountsPF}
            alertProgressAccountOpen={alertProgressAccountOpen}
            alertProgressAccountCallback={alertProgressAccountCallback}
            nameToProgress={nameToProgress}
          />
        </>
      )}
    </>
  );
}

function AgentMenu({
  currentAgentProfile,
  agentProfilesWithoutCurrent,
  switchAgentProfile,
  agentProfiles,
  alertProgressAgentOpen,
  alertProgressAgentCallback,
  nameToProgress,
}) {
  const { t } = useTranslation('menu');

  if (!agentProfiles?.length || agentProfiles?.length < 1) {
    return null;
  }

  return (
    <>
      {agentProfiles?.length === 1 && (
        <S.MennuTitle>{t('menu:new.my_profile', 'Meu Perfil')}</S.MennuTitle>
      )}
      {agentProfiles?.length > 1 && (
        <S.MennuTitle>{t('menu:new.my_profiles', 'Meus Perfis')}</S.MennuTitle>
      )}

      <MenuList>
        {currentAgentProfile && (
          <MenuListItem
            icon={<BriefcaseIcon fill={Configs.newColors.n100} />}
            label={forceStringCapitalize(
              truncate(currentAgentProfile.name, 56)
            )}
            description={t('menu:new.contbank_agent', 'Agente Contbank')}
            className="active"
          />
        )}

        {agentProfilesWithoutCurrent?.length > 0 &&
          agentProfilesWithoutCurrent.map((agent, index) => (
            <MenuListItem
              key={index}
              icon={<BriefcaseIcon fill={Configs.newColors.n100} />}
              onClick={() =>
                switchAgentProfile(
                  agent.current_identity,
                  forceStringCapitalize(truncate(agent.name, 32))
                )
              }
              description={t('menu:new.contbank_agent', 'Agente Contbank')}
              label={forceStringCapitalize(truncate(agent.name, 56))}
            />
          ))}
      </MenuList>
      <AlertProgress
        isOpen={alertProgressAgentOpen}
        callback={alertProgressAgentCallback}
      >
        <p>
          {t('menu:new.progress_bar_agent', 'Entrando no Perfil de Agente')}
        </p>
        <p>
          <strong>{nameToProgress}</strong>
        </p>
      </AlertProgress>
    </>
  );
}

function BankMenu({
  accountsPF,
  accountsPJ,
  currentAccountPF,
  currentAccountPJ,
  accountsPJWithoutCurrent,
  accountsPFWithoutCurrent,
  switchAccount,
  alertProgressAccountOpen,
  alertProgressAccountCallback,
  nameToProgress,
}) {
  const { t } = useTranslation('menu');

  if (
    (!accountsPF?.length || accountsPF?.length < 1) &&
    (!accountsPJ?.length || accountsPJ?.length < 1)
  ) {
    return null;
  }

  return (
    <>
      {accountsPF?.length > 0 && (
        <>
          {accountsPF?.length === 1 && (
            <S.MennuTitle>
              {t('menu:new.my_account', 'Minha Conta')}
            </S.MennuTitle>
          )}
          {accountsPF?.length > 1 && (
            <S.MennuTitle>
              {t('menu:new.my_accounts', 'Minhas Contas')}
            </S.MennuTitle>
          )}

          <MenuList>
            {currentAccountPF && (
              <MenuListItem
                icon={
                  currentAccountPF?.profile_type === ACCOUNT_TYPE.PERSONAL ? (
                    <PersonIcon fill={Configs.newColors.n100} />
                  ) : (
                    <CompanyIcon fill={Configs.newColors.n100} />
                  )
                }
                description={
                  currentAccountPF?.profile_type === ACCOUNT_TYPE.PERSONAL
                    ? t('menu:new.personal_account', 'Conta Pessoa Física')
                    : t('menu:new.business_account', 'Conta Pessoa Jurídica')
                }
                label={forceStringCapitalize(
                  truncate(currentAccountPF.name, 56)
                )}
                className="active"
              />
            )}
            {accountsPFWithoutCurrent?.length > 0 &&
              accountsPFWithoutCurrent.map((account, index) => (
                <MenuListItem
                  key={index}
                  icon={
                    account?.profile_type === ACCOUNT_TYPE.PERSONAL ? (
                      <PersonIcon fill={Configs.newColors.n100} />
                    ) : (
                      <CompanyIcon fill={Configs.newColors.n100} />
                    )
                  }
                  description={
                    account?.profile_type === ACCOUNT_TYPE.PERSONAL
                      ? t('menu:new.personal_account', 'Conta Pessoa Física')
                      : t('menu:new.business_account', 'Conta Pessoa Jurídica')
                  }
                  label={forceStringCapitalize(truncate(account.name, 56))}
                  onClick={() =>
                    switchAccount(
                      account.current_identity,
                      forceStringCapitalize(truncate(account.name, 32))
                    )
                  }
                />
              ))}
          </MenuList>
        </>
      )}

      {accountsPJ?.length > 0 && (
        <>
          {accountsPJ?.length === 1 && (
            <S.MennuTitle>
              {t('menu:new.my_company', 'Minha Empresa')}
            </S.MennuTitle>
          )}
          {accountsPJ?.length > 1 && (
            <S.MennuTitle>
              {t('menu:new.my_companies', 'Minhas Empresas')}
            </S.MennuTitle>
          )}

          <MenuList>
            {currentAccountPJ && (
              <MenuListItem
                icon={
                  currentAccountPJ?.profile_type === ACCOUNT_TYPE.PERSONAL ? (
                    <PersonIcon fill={Configs.newColors.n100} />
                  ) : (
                    <CompanyIcon fill={Configs.newColors.n100} />
                  )
                }
                description={
                  currentAccountPJ?.profile_type === ACCOUNT_TYPE.PERSONAL
                    ? t('menu:new.personal_account', 'Conta Pessoa Física')
                    : t('menu:new.business_account', 'Conta Pessoa Jurídica')
                }
                label={forceStringCapitalize(
                  truncate(currentAccountPJ.name, 56)
                )}
                className="active"
              />
            )}
            {accountsPJWithoutCurrent?.length > 0 &&
              accountsPJWithoutCurrent.map((account, index) => (
                <MenuListItem
                  key={index}
                  icon={
                    account?.profile_type === ACCOUNT_TYPE.PERSONAL ? (
                      <PersonIcon fill={Configs.newColors.n100} />
                    ) : (
                      <CompanyIcon fill={Configs.newColors.n100} />
                    )
                  }
                  description={
                    account?.profile_type === ACCOUNT_TYPE.PERSONAL
                      ? t('menu:new.personal_account', 'Conta Pessoa Física')
                      : t('menu:new.business_account', 'Conta Pessoa Jurídica')
                  }
                  label={forceStringCapitalize(truncate(account.name, 56))}
                  onClick={() =>
                    switchAccount(
                      account.current_identity,
                      forceStringCapitalize(truncate(account.name, 32))
                    )
                  }
                />
              ))}
          </MenuList>
        </>
      )}
      <AlertProgress
        isOpen={alertProgressAccountOpen}
        callback={alertProgressAccountCallback}
      >
        <p>{t('menu:new.progress_bar_account', 'Entrando na Conta de')}</p>
        <p>
          <strong>{nameToProgress}</strong>
        </p>
      </AlertProgress>
    </>
  );
}
