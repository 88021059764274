import BackButton from 'components/BackButton';
import { CloseBox } from 'components/CloseBox';
import InputText from 'components/InputText';
import RoundedButtonArrow from 'components/RoundedButtonArrow';
import styled from 'styled-components';
import media from 'styles/media';
import Configs from 'styles/variables';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
  &.request-card {
    ${media.tablet`
      margin-top: 0px;
    `}
    .close-button-container {
      margin-top: 65px;
      ${media.tablet`
        margin-top: 3px;
      `}
      ${media.mobile`
        margin-top: 5px;
      `}
    }
  }
  ${media.tablet`
    padding-bottom: 64px;
  `}
  ${media.mobile`
    margin-top: 0;
  `}
`;

export const WizardButton = styled(RoundedButtonArrow)`
  &.request-card {
    font-family: 'Manrope';
  }
  margin-top: 20px;
  ${media.tablet`
    font-family: "Manrope";
    position: fixed;
    bottom: 0;
    min-height: 64px;
    border-radius: 0;
    width: 105vw;
    margin-bottom: 0;
    ${({ mb }) =>
      mb &&
      `
      margin-bottom: ${mb}px;
    `}
    svg {
      fill: ${Configs.black}
    }
  `}
`;

export const Close = styled(CloseBox)`
  background: ${Configs.pageColor};
  z-index: 300;
  svg {
    fill: ${Configs.darkGray};
  }
  ${media.tablet`
    top: 30px;
    right: 30px;
    border-radius: 50px;
  `}
  ${media.mobile`
    right: 15px;
  `}
`;

export const WizardButtonBack = styled(BackButton)`
  opacity: 0;
  position: absolute;
  top: 40%;
  left: 120px;
  transition: opacity 0.25s ease;
  z-index: -1;
  &.visible {
    opacity: 1;
    z-index: 9;
  }
  ${media.tablet`
    top: 32px;
    left: 32px;
    width: 32px;
    height: 32px;
    background-color: transparent;
  `}
  svg {
    ${media.tablet`
      margin-top: 0;
      width: 39px;
      height: 26px;
    `}
  }
`;

export const WizardStep = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  height: calc(100vh - 60px);
  width: 80%;
  position: absolute;
  top: 0;
  left: 10%;
  transition: all 0.25s ease;
  transform: translateX(100%);
  max-height: 100%;
  overflow-x: none;
  overflow-y: none;
  ${media.tablet`
    width: 100%;
    left: 0;
    padding-top: 140px;
    justify-content: start;
    height: 100vh;
  `}

  ${media.mobile`
    height: 100%;
  `};
  opacity: 0;
  &.active {
    transform: translateX(0);
    opacity: 1;
    height: 100vh;
  }
  &.active + div:not(.alert):not(.wizard-back-button) {
    transform: translateX(97%);
    opacity: 0.1;
    z-index: -1;
  }
  &.old {
    transform: translateX(-100%);
    opacity: 0;
  }

  &.no-padding-top {
    padding: 0;
  }

  &.d-space-top {
    padding-top: 60px;

    ${media.tablet`
      padding-top: 0;
    `}
  }

  &.space-top {
    padding-top: 60px;
    &.spate-top-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ${media.tablet`
      padding-top: 140px;
    `};
  }
`;

export const WizardAlert = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 0.25s ease;
  transform: translateY(100%) !important;
  opacity: 0;
  background-color: ${Configs.black};
  position: fixed;
  z-index: 1028;
  &.active {
    transform: translateY(0) !important;
    opacity: 1;
    & ~ .wizard-back-button.visible {
      background: #fff753;
    }
  }
  small {
    font-size: 24px;
  }
  a {
    &.yellow {
      color: #fff753;
    }
  }
`;

export const WizardInput = styled(InputText)`
  margin-bottom: 45px;
`;

export const ScrollableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  ${({ dPadding }) =>
    dPadding &&
    `
    padding-top: 60px;
  `};

  ${({ alignCenter }) =>
    alignCenter &&
    `
    align-items: center;
  `};

  ${media.tablet`
    ${({ tPadding }) =>
      tPadding &&
      `
      padding-top: 88px;
    `};
    margin-bottom: ${({ tNoMarginBottom }) =>
      tNoMarginBottom ? '0px' : '65px'};
  `};

  ${({ noHorizontalScroll }) =>
    noHorizontalScroll &&
    `
      overflow-x: hidden;
    `}

  ${({ noVerticalScroll }) =>
    noVerticalScroll &&
    `
      overflow-y: hidden;
    `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ noSidePadding }) => (noSidePadding ? '0;' : '0 24px;')};
  height: ${({ dHeightAuto }) => (dHeightAuto ? 'auto' : '100%;')};
  width: 100%;

  justify-content: center;
  align-items: center;

  ${media.tablet`
    padding-top: 140px;
    margin-bottom: 65px;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    
    ${({ tFlexEnd }) => tFlexEnd && `justify-content: flex-end;`};
    ${({ tFlexStart }) => tFlexStart && `justify-content: flex-start;`};
    ${({ marginBottom }) => marginBottom && `margin-bottom: 65px;`};
    ${({ paddingBottom }) => paddingBottom && 'padding-bottom: 10%;'}
  `};

  ${media.mobile`
    padding-top: 88px;
    margin-bottom: 65px;
    height: 100%;

    overflow-x: hidden;
    overflow-y: auto;

    ${({ mFlexEnd }) => mFlexEnd && `justify-content: flex-end;`};
    ${({ mFlexStart }) => mFlexStart && `justify-content: flex-start;`};
    ${({ marginBottom }) => marginBottom && `margin-bottom: 65px;`};
    ${({ mNoMarginBottom }) => mNoMarginBottom && `margin-bottom: 0;`};
    ${({ paddingBottom }) => paddingBottom && 'padding-bottom: 10%;'}
  `};
`;

export const ContentScrollable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${({ dOverflowY }) =>
    dOverflowY &&
    `
    overflow-y: auto;
  `};

  &.no-overflow-x {
    overflow-x: hidden;
  }

  &.justify-center {
    justify-content: center;
  }
  &.justify-flex-end {
    justify-content: flex-end;
    ${media.tablet`
      justify-content: center;
    `}
    ${media.mobile`
      justify-content: flex-end;
    `}
  }

  ${media.tablet`
    height: 100%;
    overflow: auto;
    padding-bottom: 60px;
    margin-bottom: 70px;

    ${({ tNoOverflowX }) =>
      tNoOverflowX &&
      `
      overflow-x: hidden;
    `};
    padding-bottom: ${(props) => props.pb}px;
  `}

  ${media.mobile`
    height: 100%;
    overflow: auto;
    padding-bottom: 60px;
    margin-bottom: 70px;

    ${({ mNoOverflowX }) =>
      mNoOverflowX &&
      `
      overflow-x: hidden;
    `};
      padding-bottom: ${(props) => props.pb}px;
  `}
`;
