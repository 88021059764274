import React, { useEffect, useState } from 'react';
import WizardBasicContainer from 'components/WizardBasicContainer';

import { useHistory } from 'react-router-dom';
import { PATHS } from 'Routes/constants';

import SavedStep from './Steps/savedStep';
import { ProductCategoriesProvider } from 'contexts/categories';
import ProductCategoryStep from './Steps/productCategoryStep';
import ProductSubCategoryStep from './Steps/productSubCategoryStep';
import CheckDataStep from './Steps/checkDataStep';
import useRouter from 'hooks/useRouter';
import useToken from 'hooks/useToken';
import useCatalogsService from 'services/catalogs';
import useProductsService from 'services/products';
import { WizardButtonBack } from 'components/WizardBasicContainer/styles';

const AssociateProduct = () => {
  const [priceItem, setPriceItem] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [saved, setSaved] = useState(false);
  const history = useHistory();
  const token = useToken();
  const { getProductById } = useProductsService();
  const { postCatalog } = useCatalogsService();

  const router = useRouter();
  const { id } = router.query;

  useEffect(() => {
    (async () => {
      if (id && token && !priceItem.product_name) {
        const prod = await getProductById(id, token);

        if (prod.data && prod.data.name) {
          let pi = priceItem;

          pi.product_name = prod.data.name;
          pi.product_id = prod.data.id;
          setPriceItem(pi);
        }
      }
    })();
  }, [id, token, priceItem, getProductById]);

  const closeAction = () => {
    history.push(PATHS.PRICELIST.LIST);
  };
  const addCategoryAction = () => {
    history.push(PATHS.PRICELIST.ASSOCIATE.replace(':id', id));
  };

  const save = async () => {
    setButtonDisabled(true);
    const sendData = {
      product_id: priceItem.product_id,
      category_id: priceItem.category_id,
    };

    await postCatalog(sendData, token).then((result) => {
      setSaved(true);
    });
    setButtonDisabled(false);
  };

  return (
    <WizardBasicContainer closeAction={closeAction}>
      <WizardButtonBack />
      <ProductCategoriesProvider>
        <ProductCategoryStep
          priceItem={priceItem}
          setPriceItem={setPriceItem}
          step={1}
        />
        <ProductSubCategoryStep
          priceItem={priceItem}
          setPriceItem={setPriceItem}
          step={2}
        />
      </ProductCategoriesProvider>
      <CheckDataStep
        priceItem={priceItem}
        save={save}
        buttonDisabled={buttonDisabled}
      />
      <SavedStep
        register={priceItem}
        saved={saved}
        addCategoryAction={addCategoryAction}
        listAction={closeAction}
      />
    </WizardBasicContainer>
  );
};

export default AssociateProduct;
