import { ONBOARDING_DOCUMENT_TYPE } from 'pages/Onboarding/shared/onboarding-constants';

const onboardingRegisterPF = {
  identifier: '',
  name: '',
  socialName: '',
  politicallyExposed: 'NONE',
  email: '',
  password: '',
  'document-type': ONBOARDING_DOCUMENT_TYPE.CNH,
};

export { onboardingRegisterPF };
