import styled from 'styled-components';

const transitionSpeed = 300;

export const TransitionContainer = styled.div`
  height: 100%;

  .transition-group {
    position: relative;
    height: 100%;
  }

  .route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .wizard-enter {
    opacity: 0.01;
  }

  .wizard-enter .section-wizard .main-box {
    transform: translateY(100%);
  }

  .wizard-enter.wizard-enter-active {
    opacity: 1;
    transition: opacity ${transitionSpeed}ms ease-in;
  }

  .wizard-enter.wizard-enter-active .section-wizard .main-box {
    transform: translateY(0);
    transition: transform ${transitionSpeed}ms ${transitionSpeed * 0.5}ms
      ease-in;
  }

  .wizard-exit .main-box {
    opacity: 1;
  }

  .wizard-exit.wizard-exit-active .main-box {
    opacity: 0;
    transition: opacity ${transitionSpeed}ms ease-in;
  }
`;
