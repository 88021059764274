import React from 'react';

const ArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="24"
      viewBox="0 0 60 24"
    >
      <g>
        <g>
          <g>
            <path
              d="M138.879 181.414L140.293 180 151.293 191 152 191.707 151.293 192.414 140.293 203.414 138.879 202 148.172 192.707 92 192.707 92 190.707 148.172 190.707z"
              transform="translate(-736 -564) translate(644 384)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowIcon;
