import useMaskedInput from 'hooks/useMaskedInput';
import React, { forwardRef, useEffect, useState } from 'react';
import { onlyNumbers, toMask } from 'tools';
import { FieldMulti } from '../styles';

const mask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

let BarcodeInput = ({ className, inputId, ...props }, input) => {
  let onChange = useMaskedInput({
    input,
    guide: false,
    mask,
    onChange: props.onChange,
  });

  const [count, setCount] = useState(0);
  const [dirt, setDirt] = useState(false);

  useEffect(() => {
    let mounted = true;
    const el = input.current;

    if (
      mounted &&
      count === 0 &&
      !dirt &&
      el &&
      props.defaultValue &&
      props.defaultValue !== 'undefined'
    ) {
      const value = onlyNumbers(props.defaultValue);

      el.value = toMask(value, mask).conformedValue;
      setCount(count + 1);
    }
    return () => (mounted = false);
  }, [input, props.defaultValue, count, dirt]);

  const handleOnChange = (value) => {
    onChange(value);
    setDirt(true);
  };

  return (
    <FieldMulti
      {...props}
      className={className}
      id={inputId}
      ref={input}
      type="text"
      pattern="[0-9]*"
      defaultValue={props.defaultValue}
      onChange={handleOnChange}
      onBlur={handleOnChange}
      rows="4"
      cols="16"
    />
  );
};

export default forwardRef(BarcodeInput);
