import styled, { keyframes } from 'styled-components';
import { css } from 'styled-components';
import media from 'styles/media';
import Configs from 'styles/variables';

const closebutton = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
`;

const animatedCss = css`
  animation: ${closebutton} 0.25s 0.5s ease forwards;
`;

const withoutAnimationCss = css`
  opacity: 1;
`;

const backgroundColor = ({ yellow, gray }) => {
  if (yellow) return Configs.yellow;
  if (gray) return Configs.newColors.n100;
  return Configs.white;
};

export const Container = styled.div`
  position: ${({ relative }) => (relative ? 'relative' : 'fixed')};
  right: 65px;
  top: 0;
  background: ${({ yellow }) => (yellow ? Configs.yellow : '#24262e')};
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11002;
  opacity: 0;
  transition: all 0.15s ease;
  transform: translateY(-100%);
  cursor: pointer;

  ${({ rounded }) => rounded && `border-radius: 50%`};

  ${media.tablet`
    top: unset;
    bottom: 40px;
    right: 40px;
    position: ${({ relative }) => (relative ? 'relative' : 'fixed')};
    background: transparent;
    transform: translateY(200%);
  `}
  &.open {
    ${({ animated }) => (animated ? animatedCss : withoutAnimationCss)};
    background: ${backgroundColor};
  }
  &:hover {
    background: ${backgroundColor};
    svg {
      fill: ${Configs.black};
    }
  }

  svg {
    transition: all 0.15s ease;
    fill: ${({ yellow }) => (yellow ? Configs.black : '#fff')};
    width: 24px;
    height: 24px;
  }
`;
