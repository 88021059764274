import React from 'react';
import PrivateLayouted from 'Routes/components/PrivateLayouted';
import { PATHS } from 'Routes/constants';
import PrivateWizardSingle from 'Routes/components/PrivateWizardSingle';

const AgentDashboard = React.lazy(() => import('pages/AgentDashboard'));
const Invite = React.lazy(() => import('pages/Invite'));
const CreateAgentPF = React.lazy(() =>
  import('pages/AgentDashboard/AddClientCPF')
);

const AddClientCNPJ = React.lazy(() =>
  import('pages/AgentDashboard/AddClientCNPJ')
);

export const AgentRoutes = () => {
  return [
    <PrivateLayouted
      component={AgentDashboard}
      exact
      path={PATHS.AGENT.DASHBOARD}
      key={PATHS.AGENT.DASHBOARD}
    />,
    <PrivateWizardSingle
      component={CreateAgentPF}
      exact
      path={PATHS.AGENT.CLIENT.ADD_PF}
      key={PATHS.AGENT.CLIENT.ADD_PF}
    />,
    <PrivateWizardSingle
      component={AddClientCNPJ}
      exact
      path={PATHS.AGENT.CLIENT.ADD_CNPJ}
      key={PATHS.AGENT.CLIENT.ADD_CNPJ}
    />,
    <PrivateLayouted
      component={Invite}
      exact
      path={PATHS.AGENT.INVITE}
      key={PATHS.AGENT.INVITE}
    />,
  ];
};
