import useMaskedInput from 'hooks/useMaskedInput';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { onlyNumbers, toMask } from 'tools';
import { Field } from '../styles';

const cellphoneMask = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const phoneMask = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const PhoneInput = ({ className, inputId, ...props }, input) => {
  const mask = useCallback(
    (data) =>
      data && onlyNumbers(data).length > 10 ? cellphoneMask : phoneMask,
    []
  );

  let onChange = useMaskedInput({
    input,
    mask,
    onChange: props.onChange,
  });

  let [count, setCount] = useState(0);

  useEffect(() => {
    let mounted = true;
    const el = input.current;

    if (
      mounted &&
      count === 0 &&
      el &&
      props.defaultValue &&
      props.defaultValue !== 'undefined'
    ) {
      // console.log(count, 'phone input');
      const value = onlyNumbers(props.defaultValue);

      // el.value = props.defaultValue;
      el.value = toMask(value, mask(value)).conformedValue;
      setCount(count + 1);
    }
    return () => (mounted = false);
  }, [input, props.defaultValue, count, mask]);

  return (
    <Field
      {...props}
      className={className}
      id={inputId}
      type="text"
      pattern="[0-9]*"
      ref={input}
      defaultValue={props.defaultValue}
      onChange={onChange}
    />
  );
};

export default forwardRef(PhoneInput);
