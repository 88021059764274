import styled from 'styled-components';
import Configs from 'styles/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
export const SearchGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: solid 1px rgba(151, 151, 151, 0.2);
  height: 94px;
`;
export const SearchField = styled.input`
  display: flex;
  flex: 1;
  background: transparent;
  font-size: 40px;
  border: none;
  letter-spacing: -0.8px;
  color: #ffffff;
  padding: 30px 10px;
`;
export const SearchButton = styled.button`
  display: flex;
  width: 56px;
  height: 56px;
  border: solid 2px #d7d7d7;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 56px;
  transition: all 0.25s ease;
  svg {
    fill: #d7d7d7;
    width: 18px;
    height: 18px;
    display: flex;
  }
  &:hover {
    background: ${Configs.yellow};
    border-color: ${Configs.black};
    svg {
      fill: ${Configs.black};
    }
  }
`;
