import React from 'react';
import PublicWizard from 'Routes/components/PublicWizard';
import { PATHS } from 'Routes/constants';

const OperatorOnBoarding = React.lazy(() =>
  import('pages/Onboarding/Operator')
);

export const OperatorRoutes = () => [
  <PublicWizard
    component={OperatorOnBoarding}
    exact
    path={PATHS.ONBOARDING.OPERATOR}
    key={PATHS.ONBOARDING.OPERATOR}
  />,
];
