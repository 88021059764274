import React, { useCallback, useEffect, useState, useContext } from 'react';
import useToken from 'hooks/useToken';
import useAccountsService from 'services/accounts';
import useAccountRegistry from './account-registry';

const AgentConflicContext = React.createContext({
  conflicts: null,
  message: null,
  success: false,
  error: false,
  loading: false,
  canShowConflict: false,
  setCanShowConflict: (value = true || false) => {},
  loadAgentConflict: () => {},
});

export const AgentConflicProvider = ({ children }) => {
  const token = useToken();
  const { fetchConflict } = useAccountsService(token);
  const { registry: account } = useAccountRegistry();
  const [conflicts, setConflicts] = useState();
  const [currentAccount, setCurrentAccount] = useState();
  const [fetchState, setFetchState] = useState('');
  const [message, setMessage] = useState();
  const [canShowConflict, setCanShowConflict] = useState(true);
  const loading = fetchState === 'loading';
  const error = fetchState === 'error';
  const success = fetchState === 'success';
  const initial = fetchState === 'initial';

  const handleError = useCallback((e) => {
    console.error(e.message, '.Error trying to fetch account registry');
    if (
      e.response &&
      e.response.data &&
      e.response.data.code >= 400 &&
      e.response.data.code < 500
    ) {
      setMessage('Ocorreu um erro ao buscar os dados.');
    }
    setConflicts(null);
    setFetchState('error');
  }, []);

  const handleResponse = useCallback((response, actions) => {
    const { data } = response;

    setConflicts(data ? data : null);
    setFetchState('success');
  }, []);

  /*
   * When change account should put canShowConflit to true, to allow show conflict on view if user doesnt have business_agent_id
   * if currentAccount doesnt exist add account to currentAccount
   */
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (!currentAccount && account) {
        setCurrentAccount(account);
      }

      if (currentAccount && account && currentAccount?.id !== account?.id) {
        setCanShowConflict(true);
        setCurrentAccount(account);
      }
    }
    return () => (mounted = false);
  }, [account, currentAccount]);

  useEffect(() => {
    let mounted = true;

    const getAccountConflict = async () => {
      setFetchState('loading');
      try {
        handleResponse(await fetchConflict(account.id));
      } catch (e) {
        handleError(e);
      }
    };

    if (mounted && token && initial && account?.id) {
      getAccountConflict();
    }
    return () => (mounted = false);
  }, [token, initial, handleError, handleResponse, fetchConflict, account]);

  /*
   * Load agent conflict data
   */
  const loadAgentConflict = useCallback(() => {
    setFetchState('initial');
  }, []);

  const defaultValues = {
    conflicts,
    message,
    success,
    error,
    loading,
    canShowConflict,
    setCanShowConflict,
    loadAgentConflict,
  };

  return (
    <AgentConflicContext.Provider value={defaultValues}>
      {children}
    </AgentConflicContext.Provider>
  );
};
const useAgentConflict = () => useContext(AgentConflicContext);

export default useAgentConflict;
