import React, { useContext, useEffect, useState } from 'react';

import useRouter from 'hooks/useRouter';
import useToken from 'hooks/useToken';
import {
  Container,
  ContentBox,
  MainTitleBox,
  TitleBox,
  ValueBox,
  DataText,
  RemoveButton,
  ListLabel,
  ListItem,
} from './styles';
import { money } from 'tools';
import ProductCategoriesContext from 'contexts/categories';
import useCatalogsService from 'services/catalogs';
import useProductsService from 'services/products';

const ProductDetails = () => {
  const { productCategories, loading, loadProductCategories } = useContext(
    ProductCategoriesContext
  );
  const { getProductById } = useProductsService();
  const { getCatalogByProductId } = useCatalogsService();

  useEffect(() => {
    if (!productCategories) {
      loadProductCategories();
    }
  }, [productCategories, loadProductCategories]);

  const [product, setProduct] = useState(null);
  const [catalogs, setCatalogs] = useState(null);
  const token = useToken();

  const router = useRouter();
  const { id } = router.query;

  useEffect(() => {
    (async () => {
      if (id && token && !product) {
        const prod = await getProductById(id, token);

        if (prod.data) {
          setProduct(prod.data);
        }
        const catalogReq = await getCatalogByProductId(id, token);

        if (catalogReq.data) {
          setCatalogs(catalogReq.data.catalogs);
        }
      }
    })();
  }, [id, token, setProduct, product, getCatalogByProductId, getProductById]);

  const getCategory = (catId) => {
    return productCategories
      ? [...productCategories.filter((cat) => cat.id === catId)].pop()
      : {};
  };

  return product ? (
    <Container>
      <ContentBox>
        <MainTitleBox>{product.name}</MainTitleBox>
        <TitleBox>{product.description}</TitleBox>
        <ValueBox>
          Preço:
          <DataText>{money(product.price)}</DataText>
        </ValueBox>
      </ContentBox>
      {!loading && catalogs && (
        <ContentBox>
          <MainTitleBox>Categorias Associadas</MainTitleBox>
          <TitleBox>
            {catalogs.map((catalog) => (
              <ListItem key={catalog.category_id}>
                <ListLabel>{getCategory(catalog.category_id).name}</ListLabel>
                <RemoveButton>Remover</RemoveButton>
              </ListItem>
            ))}
          </TitleBox>
        </ContentBox>
      )}
    </Container>
  ) : null;
};

export default ProductDetails;
