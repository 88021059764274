import {
  ONBOARDING_ACCOUNT_TYPES,
  ONBOARDING_STEPS,
} from 'pages/Onboarding/shared/onboarding-constants';

const STEPS = ONBOARDING_STEPS;

export const CORPORATION_PF_STEPS = [
  {
    id: STEPS.CORPORATION_PERSONAL_NAME,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_PERSONAL_SOCIAL_NAME,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_PERSONAL_SELECT_PEP,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_PERSONAL_EMAIL,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_PERSONAL_PHONE,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_PERSONAL_DATE_OF_BIRTH,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_PERSONAL_MOTHER_NAME,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_PERSONAL_OCUPATION,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_PERSONAL_INCOME,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_PERSONAL_PERCENT_PARTICIPATION_COMPANY,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_PERSONAL_ADDRESS,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_PERSONAL_CONFIRM,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_PERSONAL_LOADING_SENDING,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_PERSONAL_ERROR_PARTNER_UPDATE,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_PERSONAL_SUCCESS_SAVED_PARTNER,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
];

export const CORPORATION_PJ_STEPS = [
  {
    id: STEPS.CORPORATION_IDENTIFIER,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.LOADING_WAIT_BUSINESS_VERIFY,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.ERROR_INSTABILITY_BUSINESS_VERIFY,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.ERROR_INVALID_COMPANIES_STATUS,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.ERROR_LEGAL_NATURE_NOT_ALLOWED,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.ERROR_ONBOARDING_NOT_ALLOWED,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.ERROR_COMPANIES_NOT_FOUND,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.CORPORATION_BUSINESS_NAME,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.CORPORATION_TRADING_NAME,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.CORPORATION_BUSINESS_EMAIL,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.CORPORATION_BUSINESS_PHONE,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.CORPORATION_BUSINESS_MONTHLY_CURRENCY,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  // {
  //   id: STEPS.CORPORATION_BUSINESS_ANNUAL_CURRENCY,
  //   skip: false,
  //   visible: true,
  //   type: ONBOARDING_ACCOUNT_TYPES.PJ,
  // },
  {
    id: STEPS.CORPORATION_BUSINESS_PERCENT_PARTICIPATION_COMPANY,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.CORPORATION_BUSINESS_ADDRESS,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.CORPORATION_BUSINESS_CONFIRM,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.CORPORATION_BUSINESS_LOADING_SENDING,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_BUSINESS_ERROR_PARTNER_UPDATE,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_BUSINESS_SUCCESS_SAVED_PARTNER,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
];

export const CORPORATION_DOCS_STEPS = [
  {
    id: STEPS.CORPORATION_PARTNER_PERCENT_PARTICIPATION_COMPANY,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.SELFIE_TIPS,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.AUTHORIZATION_CAMERA,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.ERROR_SELFIE,
    skip: true,
    visible: true,
  },
  {
    id: STEPS.DOCUMENT_TYPE,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.ERROR_DOCUMENTS,
    skip: true,
    visible: true,
  },
  {
    id: STEPS.DOCUMENT_FRONT,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.DOCUMENT_BACK,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.CORPORATION_WAIT_UPDATE,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_ERROR_UPDATE,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CORPORATION_SUCCESS_SENDED_DOCUMENTS,
    skip: false,
    visible: true,
  },
];

export const ROOT = [
  {
    id: STEPS.LOADING,
    skip: true,
    visible: true,
  },
  {
    id: STEPS.MANAGE_PARTNERS,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.UPLOAD_PJ_DOCUMENT_ESTATUTO,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.UPLOAD_PJ_DOCUMENT_ATA_ELEICAO,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.UPLOAD_PJ_DOCUMENT_CONTRATO_SOCIAL,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.UPLOAD_PJ_DOCUMENT_ALTERACAO_CONTRATUAL,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.UPLOAD_PJ_DOCUMENT_CERTIDAO_BREVE_RELATO,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.UPLOAD_PJ_DOCUMENT_PROCURACAO,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.LOADING_WAIT_ACCOUNT_CREATION,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.CORPORATION_ERROR_ACCEPT_TERM,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.ANALYSING_ACCOUNT,
    skip: false,
    visible: true,
  },
];
