import { useContext, useEffect } from 'react';
import UserAuthorizationContext from 'contexts/authorization';
import useToken from 'hooks/useToken';

const useAuthorization = () => {
  const token = useToken(true);
  const {
    loading,
    error,
    success,
    userAuthorization,
    loadUserAuthorization,
    getCurrentID,
    getAgentData,
    setCurrentID,
    getAccountData,
    getBankAccount,
    setBankAccount,
    loadAgentConflict,
    setLoadAgentConflict,
  } = useContext(UserAuthorizationContext);

  useEffect(() => {
    let mounted = true;

    if (
      mounted &&
      token &&
      !loading &&
      !userAuthorization &&
      loadUserAuthorization
    ) {
      loadUserAuthorization();
    }
    return () => (mounted = false);
  }, [token, loading, userAuthorization, loadUserAuthorization]);

  return {
    userAuthorization,
    getCurrentID,
    getAccountData,
    loading,
    error,
    success,
    getAgentData,
    setCurrentID,
    getBankAccount,
    setBankAccount,
    loadAgentConflict,
    setLoadAgentConflict,
  };
};

export default useAuthorization;
