import styled from 'styled-components';
import Configs from 'styles/variables';

export const MainContainer = styled.div`
  min-height: 100vh;
  display: grid;
  place-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
`;

export const Title = styled.h1`
  margin: 0;
  padding: 0;
  margin-bottom: 0.5rem;
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0.25rem;
  font-weight: 400;
`;

export const ButtonGroup = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: 2rem;
  > a {
    margin: auto 0;
  }

  @media only screen and (min-width: 640px) {
    flex-direction: row;
    > :first-child {
      margin-left: 0 !important;
    }

    > :last-child {
      margin-right: 0 !important;
    }

    > a {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
`;

export const Button = styled.a`
  backgroun-color: blue;
  border-radius: 0.375rem;
  align-items: center;
  display: inline-flex;
  text-decoration: inherit;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-color: transparent;
  border-width: 1px;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  margin-bottom: 1rem !important;
  color: ${(props) => (props.primary ? Configs.buttons.color : Configs.white)};
  background-color: ${(props) =>
    props.primary ? Configs.buttons.background : Configs.darkGray};
  &:hover {
    filter: brightness(90%);
  }
`;
