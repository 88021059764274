import Avatar from 'components/Avatar';
import Title from 'components/Title';
import styled from 'styled-components';
import MainTitle from 'components/MainTitle';

import media from 'styles/media';
import RoundedButton from 'components/RoundedButton';
import RemovableListItem from 'components/RemovableListItem';

export const Container = styled.div`
  width: 100%;
  padding: 104px 73px 50px 73px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  ${media.mobile`
    padding: 100px 32px 100px 32px;
  `}
`;

export const AvatarBox = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const UserAvatar = styled(Avatar)`
  border: solid 42px #e5e5e5;
  width: 180px;
  height: 180px;
`;

export const ContentBox = styled.div``;

export const TitleBox = styled(Title)`
  text-align: left;
  line-height: 1.3;
  padding-left: 16px;
  padding-bottom: 16px;
  ${media.mobile`
    font-family: "Manrope-Light";
    font-size: 16px;
    font-weight: normal;
    padding-right: 16px;
    br {
      display: block;
    }
  `}
`;

export const ValueBox = styled(Title)`
  text-align: left;
  line-height: 1.3;
  padding-left: 16px;
  ${media.mobile`
    font-family: "Manrope-Light";
    font-size: 16px;
    font-weight: normal;
    padding-right: 16px;
  `}
`;

export const DataText = styled.span`
  font-weight: bold;
  padding-left: 16px;
`;
export const MainTitleBox = styled(MainTitle)`
  margin-left: 0;
  padding-top: 32px;
`;

export const ListItem = styled(RemovableListItem)`
  width: 500px;
`;

export const RemoveButton = styled(RoundedButton)`
  align-self: flex-end;
  margin-bottom: 0;
`;

export const ListLabel = styled.div`
  display: flex;
  flex: 1;
`;
