import { ONBOARDING_STEPS } from 'pages/Onboarding/shared/onboarding-constants';

const STEPS = ONBOARDING_STEPS;

export const REGISTER_STEPS = [
  {
    id: STEPS.IDENTIFIER,
    docType: 'CPF',
    skip: false,
    visible: true,
  },
  {
    id: STEPS.NAME,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.SOCIAL_NAME,
    skip: true,
    visible: true,
  },
  {
    id: STEPS.SELECT_PEP,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.EMAIL,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.PASSWORD,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.PASSWORD_CONFIRMATION,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.ERROR_USER_CREATE,
    skip: true,
    visible: true,
  },
  {
    id: STEPS.ERROR_USER,
    skip: true,
    visible: true,
  },
  {
    id: STEPS.VALIDATE_EMAIL,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.LOADING_WAIT_VALIDATE_EMAIL,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.SUCCESS_VALIDATE_EMAIL,
    skip: false,
    visible: true,
  },
];
