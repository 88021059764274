import useMaskedInput from 'hooks/useMaskedInput';
import React, { forwardRef, useEffect, useState } from 'react';
import { onlyNumbers, toMask } from 'tools';
import { Field } from '../styles';

const cnpjMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

let CNPJInput = ({ className, inputId, ...props }, input) => {
  let onChange = useMaskedInput({
    input,
    mask: cnpjMask,
    onChange: props.onChange,
  });

  const [count, setCount] = useState(0);
  const [dirt, setDirt] = useState(false);

  useEffect(() => {
    let mounted = true;
    const el = input.current;

    if (
      mounted &&
      count === 0 &&
      !dirt &&
      el &&
      props.defaultValue &&
      props.defaultValue !== 'undefined'
    ) {
      const value = onlyNumbers(props.defaultValue);

      el.value = toMask(value, cnpjMask).conformedValue;
      setCount(count + 1);
    }
    return () => (mounted = false);
  }, [input, props.defaultValue, count, dirt]);

  const handleOnChange = (value) => {
    onChange(value);
    setDirt(true);
  };

  return (
    <Field
      {...props}
      className={className}
      id={inputId}
      ref={input}
      type="text"
      pattern="[0-9]*"
      defaultValue={props.defaultValue}
      onChange={handleOnChange}
    />
  );
};

export default forwardRef(CNPJInput);
