import { useEffect, useState } from 'react';
import { isMobile as detectIsMobile } from 'tools/supportNavigator';

export default function useResponsive(width) {
  const [isMobile, setIsMobile] = useState(detectIsMobile(width));

  useEffect(() => {
    function handleResize() {
      setIsMobile(detectIsMobile(width));
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  return {
    isMobile,
  };
}
