import React, { useState, useContext, useEffect, useMemo } from 'react';
import MenuContext from 'contexts/menu';
import Logo from 'assets/images/ContbankLogo';
import { useMediaQuery } from 'react-responsive';

import {
  Container,
  LogoBox,
  MenuBox,
  FloatingMenu,
  FloatingSubMenu,
  MenuList,
  MenuItem,
  LinkEl as Link,
  Teardrop,
  MenuIcon,
  UserBox,
  MenuBody,
  FloatingTabs,
  FloatingTab,
  LogoutBox,
  TextMenu,
  BackNavigation,
} from './styles';
import Avatar from 'components/Avatar';
import useKeyPress from 'hooks/useKeyPress';
import { MenuSearchField } from './components/SearchField';
import RoundedButton from 'components/RoundedButton';
import { useAuth0 } from '@auth0/auth0-react';
import Configs from 'styles/variables';
import { CloseBox } from 'components/CloseBox';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'Routes/constants';
import { SearchMenuProvider } from 'contexts/search-menu';
import useAuthorization from 'hooks/useAuthorization';
import WizardContext from 'contexts/wizard';
import MenuVisibilityContext from 'contexts/menuVisibility';
import useLogout from 'hooks/useLogout';

const Menu = ({ location, className, ...props }) => {
  const { total } = useContext(WizardContext);
  const { menuItens } = useContext(MenuContext);
  const [opened, setOpenedMenu] = useState(false);
  const { canShow, onFooter } = useContext(MenuVisibilityContext);
  const { getCurrentID, getAgentData } = useAuthorization();
  const [openSwitcher, setOpenSwitcher] = useState(false);
  const [tab] = useState(1);
  const [subOpened, setSubOpened] = useState(null);
  const history = useHistory();
  const [userInfo, setUserInfo] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setUserInfo(getAgentData(getCurrentID()));
    }
    return () => (mounted = false);
  }, [getAgentData, getCurrentID, setUserInfo]);

  const { user, isAuthenticated } = useAuth0();
  const { doLogout } = useLogout();

  const setOpened = (value) => {
    setOpenedMenu(value);
    if (value) {
      document.body.classList.add('no-scroll');
      setTimeout(() => window.scrollTo(0, 0), 500);
    } else {
      document.body.classList.remove('no-scroll');
      setSubOpened(null);
    }
  };

  const escape = useKeyPress('Escape');

  useEffect(() => {
    let cleanUp = false;

    if (!cleanUp) setOpened(false);
    return () => (cleanUp = true);
  }, [escape]);

  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${Configs.maxTabletWidth}px)`,
  });

  const userbox = useMemo(() => {
    const goToProfile = () => {
      history.push(PATHS.PROFILE);
    };

    return (
      isAuthenticated &&
      (userInfo || user) && (
        <>
          <UserBox className={opened ? 'open' : ''} onClick={goToProfile}>
            <Avatar
              avatar={user.picture}
              title={userInfo?.social_name || userInfo?.name || user.name}
            />
            <span>{userInfo?.social_name || userInfo?.name || user.name}</span>
          </UserBox>
        </>
      )
    );
  }, [user, opened, isAuthenticated, history, userInfo]);

  const navigateTo = (to) => {
    if (to) {
      setTimeout(() => history.push(to), 0);
    }
  };

  const goToDashboard = () => {
    history.push(PATHS.DASHBOARD);
  };

  const menuListItens = () => (
    <MenuList>
      {menuItens.map(
        (item) =>
          item.id && (
            <MenuItem key={item.id}>
              <Link
                href={item.location}
                onMouseEnter={() => {
                  closeMenus();
                  if (!isTabletOrMobile && item.children) {
                    clearTimeout(closing);
                    setSubOpened(item.id);
                  }
                  return;
                }}
                onTouchEnd={(e) => {
                  e.preventDefault();
                  if (isTabletOrMobile && item.children) {
                    clearTimeout(closing);
                    setSubOpened(item.id);
                  } else {
                    closeMenu();
                    navigateTo(item.location);
                  }
                }}
                onClick={(e) => {
                  e.preventDefault();
                  if (isTabletOrMobile && item.children) {
                    clearTimeout(closing);
                    setSubOpened(item.id);
                  } else {
                    closeMenu();
                    navigateTo(item.location);
                  }
                }}
                className={subOpened === item.id && 'active'}
              >
                {item.label}
              </Link>
              {item.children && (
                <FloatingSubMenu
                  className={subOpened === item.id && 'open'}
                  onMouseEnter={() => {
                    if (subOpened !== item.id) closeMenus();
                    if (
                      !isTabletOrMobile &&
                      item.children &&
                      subOpened === item.id
                    ) {
                      clearTimeout(closing);
                      setSubOpened(item.id);
                    }
                    return;
                  }}
                >
                  <MenuList>
                    {item.children.map(
                      (subitem) =>
                        subitem?.id && (
                          <MenuItem key={subitem.id} sub={true}>
                            <Link
                              href={subitem.location}
                              onClick={(e) => {
                                e.preventDefault();
                                closeMenu();
                                navigateTo(subitem.location);
                              }}
                              onTouchEnd={(e) => {
                                e.preventDefault();
                                closeMenu();
                                navigateTo(subitem.location);
                              }}
                            >
                              {subitem.label}
                            </Link>
                          </MenuItem>
                        )
                    )}
                  </MenuList>
                </FloatingSubMenu>
              )}
            </MenuItem>
          )
      )}
    </MenuList>
  );
  const closeMenu = () => {
    setOpened(false);
    setOpenSwitcher(false);
  };

  let closing;
  const closeMenus = () => {
    clearTimeout(closing);
    closing = setTimeout(() => {
      setSubOpened(null);
    }, 500);
  };

  return !canShow() ? (
    <></>
  ) : (
    <div className={className}>
      <Teardrop className={opened || openSwitcher ? 'open' : ''} />
      <CloseBox
        className={opened || openSwitcher ? 'open' : ''}
        onClick={closeMenu}
      />
      <BackNavigation
        className={subOpened && 'open'}
        onClick={() => setSubOpened(null)}
      />
      <LogoutBox className={opened ? 'open' : ''} onClick={() => doLogout()}>
        <RoundedButton>Sair do sistema</RoundedButton>
      </LogoutBox>
      <Container
        className={[
          'main-bar',
          opened || openSwitcher ? 'open' : '',
          total && 'hasbar',
        ]}
        {...props}
      >
        {!isTabletOrMobile && (
          <LogoBox className={opened ? 'open' : ''} onClick={goToDashboard}>
            <Logo />
          </LogoBox>
        )}
        <MenuBox
          onClick={() => setOpened(true)}
          className={[
            (opened || openSwitcher) && 'open',
            (location.pathname === '/' ||
              location.pathname === '/radar-de-oportunidades') &&
              'dashboard',
            'menu-open-icon',
            onFooter() && 'onfooter',
          ]}
        >
          <MenuIcon />
          <TextMenu>Menu</TextMenu>
        </MenuBox>
        {userbox}
        <MenuBody className={opened ? 'open' : ''}>
          {!menuItens ? null : (
            <FloatingMenu
              className={opened ? 'open' : ''}
              onMouseLeave={closeMenus}
            >
              {menuListItens()}
            </FloatingMenu>
          )}
          {opened && (
            <FloatingTabs>
              <FloatingTab
                className={tab === 1 && !isTabletOrMobile && 'open'}
                hideSearch={subOpened}
              >
                <SearchMenuProvider>
                  <MenuSearchField />
                </SearchMenuProvider>
              </FloatingTab>
            </FloatingTabs>
          )}
        </MenuBody>
      </Container>
    </div>
  );
};

export default Menu;
