import { createSlice } from '@reduxjs/toolkit';
import { onboardingRegisterPF } from './registers';
import ONBOARDING_STEPS from './steps';

const ONBOARDING_TYPE = {
  PF: 'PF',
  PJ: 'PJ',
};

export const BUSSINESS_TYPES = {
  SMALL: 'SMALL',
  CORPORATION: 'CORPORATION',
};

const FETCH_STATES = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

// export const fetchOnboardingInfo = createAsyncThunk(
//   'onboarding/get',
//   async () => {
//     // const response = await new Promise((resolve, reject) => {
//     //   resolve({ data: [] });
//     // });

//     return OnboardingAccountPFSteps;
//   }
// );

// const asyncThunksFunctions = {
//   [fetchOnboardingInfo.pending]: (state, action) => {
//     return { ...state, status: FETCH_STATES.LOADING };
//   },
//   [fetchOnboardingInfo.fulfilled]: (state, action) => {
//     return {
//       ...state,
//       status: FETCH_STATES.SUCCESS,
//       steps: action.payload.steps,
//     };
//   },
//   [fetchOnboardingInfo.rejected]: (state, action) => {
//     return { ...state, status: FETCH_STATES.ERROR, err: action };
//   },
// };
const functions = {
  setOnboardingStepsRegister(
    state,
    action: { type: '', payload: { value: null } }
  ) {
    return {
      ...state,
      ...{ steps: ONBOARDING_STEPS.REGISTER },
    };
  },
  setOnboardingStepsAccountPF(
    state,
    action: { type: '', payload: { value: null } }
  ) {
    return {
      ...state,
      ...{ steps: ONBOARDING_STEPS.PF },
    };
  },

  setOnboardingStepsAccountPJSMALL(
    state,
    action: { type: '', payload: { value: null } }
  ) {
    return {
      ...state,
      ...{ steps: ONBOARDING_STEPS.PJ_SMALL },
    };
  },

  setOnboardingStepsAccountPJCorporation(
    state,
    action: { type: '', payload: { value: null } }
  ) {
    return {
      ...state,
      ...{ steps: ONBOARDING_STEPS.PJ_CORPORATION },
    };
  },

  setOnboardingRegister(state, action: { type: '', payload: {} }) {
    return {
      ...state,
      register: {
        ...state.register,
        ...action.payload,
      },
    };
  },

  setOnboardingBusinessPartners(state, action: { type: '', payload: null }) {
    return {
      ...state,
      partners: {
        ...state.partners,
        ...action.payload,
      },
    };
  },

  setOnboardingBusinessPartnerValue(
    state,
    action: { type: '', payload: { id: null, value: null } }
  ) {
    return {
      ...state,
      partners: {
        ...state.partners,
        [action.payload.id]: action.payload.value,
      },
    };
  },

  setOnboardingBusinessPartnerMember(
    state,
    action: { type: '', payload: { pos: null, id: null, value: null } }
  ) {
    const { pos, id, value } = action.payload;
    const updatedPartners = { ...state?.partners };
    const updatedMembers = [...updatedPartners.member];

    if (id) {
      updatedMembers[pos] = { ...updatedMembers[pos], [id]: value };
    } else {
      updatedMembers[pos] = { ...updatedMembers[pos], ...value };
    }
    updatedPartners.member = updatedMembers;

    return {
      ...state,
      partners: updatedPartners,
    };
  },
  setOnboardingRegisterValue(
    state,
    action: { type: '', payload: { id: null, value: null } }
  ) {
    return {
      ...state,
      register: {
        ...state.register,
        [action.payload.id]: action.payload.value,
      },
    };
  },
  setOnboardingUser(state, action: { type: '', payload: null }) {
    return { ...state, user: action.payload };
  },

  setOnboardingType(state, action: { type: '', payload: null }) {
    return { ...state, type: action.payload };
  },

  setOnboardingSubType(state, action: { type: '', payload: null }) {
    return { ...state, subType: action.payload };
  },
  setOnboardingSteps(state, action: { type: '', payload: null }) {
    return { ...state, steps: action.payload };
  },
  setOnboardingLoading(state, action) {
    return { ...state, status: FETCH_STATES.LOADING };
  },

  setDefaultRegister(state, action: { type: '', payload: {} }) {
    return {
      ...state,
      status: FETCH_STATES.IDLE,
      type: ONBOARDING_TYPE.PF,
      subType: BUSSINESS_TYPES.SMALL,
      err: null,
      register: { ...onboardingRegisterPF },
      partners: {
        member: [],
      },
    };
  },
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    steps: null,
    status: FETCH_STATES.IDLE,
    type: ONBOARDING_TYPE.PF,
    subType: BUSSINESS_TYPES.SMALL,
    err: null,
    register: { ...onboardingRegisterPF },
    user: { id: null },
    partners: {
      member: [],
    },
  },
  reducers: {
    ...functions,
  },
  builder: (builder) => {},
});

export const {
  setOnboardingStepsRegister,
  setOnboardingStepsAccountPF,
  setOnboardingStepsAccountPJSMALL,
  setOnboardingStepsAccountPJCorporation,
  setOnboardingRegisterValue,
  setOnboardingRegister,
  setOnboardingBusinessPartners,
  setOnboardingBusinessPartnerValue,
  setOnboardingBusinessPartnerMember,
  setOnboardingSteps,
  setOnboardingType,
  setOnboardingSubType,
  setOnboardingUser,
  setDefaultRegister,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
