import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useCan from 'hooks/useCan';
import RegistrationsContext from './registrations';
import UserAuthorizationContext from './authorization';
import useNewMenu from 'hooks/useNewMenu';
import useConstants from 'services/contants';
import { getData, setData } from 'services/session-store';

export const MENU_TYPE = {
  BANK: '1',
  BUSINESS_AGENT: '2',
};

const NewMenuContext = React.createContext({
  menuItems: [],
  changeMenu: () => {},
  menuView: MENU_TYPE.BANK || MENU_TYPE.BUSINESS_AGENT,
});

export const NewMenuProvider = ({ children }) => {
  const { getCurrentID, getBankAccount, hasBusinessAgent } = useContext(
    UserAuthorizationContext
  );
  const { getMenus, menuContent } = useNewMenu();
  const { STORAGE_LABELS } = useConstants();
  const { hasTransactionToken, loading: loadingReg } =
    useContext(RegistrationsContext);
  const [menuItems, setMenuItems] = useState();
  const [userHasToken, setUserHasToken] = useState(false);
  const [lastId, setLastId] = useState(false);
  const [menuType, setMenuType] = useState(MENU_TYPE.BANK);
  const { permissions, loading } = useCan();
  const currId = getCurrentID();

  const changeMenu = useCallback(() => {
    const newType =
      menuType === MENU_TYPE.BANK ? MENU_TYPE.BUSINESS_AGENT : MENU_TYPE.BANK;

    setMenuType(newType);
    setData(STORAGE_LABELS.MV, newType);
  }, [STORAGE_LABELS, menuType]);

  const menuView = useMemo(() => menuType, [menuType]);

  /*
   * Set type on first load
   */
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      const type = !hasBusinessAgent
        ? MENU_TYPE.BANK
        : getData(STORAGE_LABELS.MV) || MENU_TYPE.BANK;

      setMenuType(type);
    }

    return () => (mounted = false);
  }, [hasBusinessAgent, STORAGE_LABELS]);

  /*
   * Reload menu when change user id
   */
  useEffect(() => {
    let mounted = true;

    if (mounted && menuItems && currId && currId !== lastId) {
      setLastId(currId);
      setMenuItems([]);
    }
    return () => (mounted = false);
  }, [currId, lastId, menuItems]);

  /*
   * SetMenuItems when was menu content
   */
  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (menuContent) setMenuItems(menuContent);
    }
    return () => (mounted = false);
  }, [menuContent]);

  const updateMenuItens = useCallback(
    (hasToken) => {
      getMenus(hasToken, getBankAccount(), menuType);
    },
    [getBankAccount, getMenus, menuType]
  );

  /*
   * Load menu itens on load menu and hasPermissions
   */
  useEffect(() => {
    let mounted = true;
    const hasPermissions = permissions && permissions.length > 0;

    if (mounted && !loading && !menuItems && hasPermissions && !loadingReg) {
      updateMenuItens(userHasToken);
    }
    return () => (mounted = false);
  }, [
    permissions,
    menuItems,
    loading,
    userHasToken,
    loadingReg,
    updateMenuItens,
  ]);

  /*
   * Load menu itens on change menu type
   */
  useEffect(() => {
    let mounted = true;
    const hasPermissions = permissions && permissions.length > 0;

    if (mounted && !loading && menuType && hasPermissions && !loadingReg) {
      updateMenuItens(userHasToken);
    }
    return () => (mounted = false);
  }, [
    permissions,
    menuType,
    loading,
    userHasToken,
    loadingReg,
    updateMenuItens,
  ]);

  useEffect(() => {
    let mounted = true;

    if (
      mounted &&
      menuItems &&
      !loadingReg &&
      userHasToken !== hasTransactionToken
    ) {
      setUserHasToken(hasTransactionToken);
      updateMenuItens(hasTransactionToken);
    }
    return () => (mounted = false);
  }, [
    hasTransactionToken,
    menuItems,
    userHasToken,
    updateMenuItens,
    loadingReg,
    menuType,
    changeMenu,
  ]);

  const defaultValue = {
    menuItems,
    changeMenu,
    menuView,
  };

  return (
    <NewMenuContext.Provider value={defaultValue}>
      {children}
    </NewMenuContext.Provider>
  );
};

const useNewMenuContext = () => useContext(NewMenuContext);

export default useNewMenuContext;
