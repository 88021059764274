import { useContext } from 'react';
import api from 'services/api';
import UserAuthorizationContext from 'contexts/authorization';

const useCatalogsService = () => {
  const { getCurrentID } = useContext(UserAuthorizationContext);

  const getCatalogs = async (token) =>
    await api.get(`/catalogs?page=1&per_page=10`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const getCatalogByProductId = async (id, token) =>
    await api.get(`/catalogs?page=1&per_page=10&&product_id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const postCatalog = async (data, token) =>
    api.post('/catalogs', data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  return {
    getCatalogs,
    getCatalogByProductId,
    postCatalog,
  };
};

export default useCatalogsService;
