import React from 'react';

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
    >
      <g>
        <path
          d="M16.616 11.663c.424-1.018.679-2.12.679-3.266 0-1.145-.212-2.247-.679-3.265-.424-1.018-1.018-1.909-1.781-2.672-.764-.764-1.654-1.357-2.672-1.781C11.145.254 10.043 0 8.897 0 7.752 0 6.65.212 5.632.679c-1.018.424-1.909 1.017-2.672 1.78-.764.764-1.357 1.655-1.781 2.673C.754 6.15.5 7.252.5 8.397s.212 2.248.679 3.266c.424 1.018 1.017 1.908 1.78 2.672.764.763 1.655 1.357 2.673 1.781 1.018.424 2.12.679 3.265.679s2.248-.212 3.266-.679c.763-.34 1.484-.763 2.12-1.272L18.44 19l1.06-1.06-4.156-4.157c.509-.636.933-1.357 1.272-2.12zm-7.719 3.605c-3.774 0-6.87-3.096-6.87-6.87 0-3.775 3.096-6.871 6.87-6.871 3.775 0 6.87 3.096 6.87 6.87 0 3.775-3.095 6.87-6.87 6.87z"
          transform="translate(-1309 -1463) translate(1164 1452) translate(145 11)"
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
