import { formatDateStringToIsoDateString } from 'tools';

export const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export function validIntervalDate(start, end, interval = 0) {
  const parseDate = (date) => new Date(formatDateStringToIsoDateString(date));

  const startDate = parseDate(start);
  const endDate = parseDate(end);

  if (isNaN(startDate) || isNaN(endDate) || startDate > endDate) {
    return false;
  }

  const intervalDate = Math.abs((endDate - startDate) / (24 * 60 * 60 * 1000));

  return intervalDate <= interval;
}

export const yearsUntilNow = () => {
  const now = Math.max(new Date().getFullYear(), 2022);
  const results = [];

  for (let i = 2021; i <= now; i++) {
    results.push(`${i}`);
  }
  return results;
};

export const yearsBeforeThisYear = (startYear = 2021) => {
  const now = Math.max(new Date().getFullYear(), 2022);
  const lastYear = now - 1;
  const results = [];

  for (let i = startYear; i <= lastYear; i++) {
    results.push(`${i}`);
  }
  return results;
};

export const padMonth = (month) => (month * 1 > 9 ? month : `0${month}`);

export function decreaseDate(days) {
  const date = new Date();

  date.setHours(0, 0, 0);
  date.setDate(date.getUTCDate() - days);
  return date;
}

export function increaseDate(days) {
  const date = new Date();

  date.setHours(0, 0, 0);
  date.setDate(date.getUTCDate() + days);
  return date;
}

export const addYearToDate = (yearsToAdd, date = new Date()) => {
  date.setHours(0, 0, 0);
  date.setFullYear(date.getFullYear() + yearsToAdd);
  return date;
};

export function increaseDateYear(years) {
  const date = new Date();

  date.setHours(0, 0, 0, 0);
  date.setFullYear(date.getFullYear() + years);
  date.setMonth(0); // January
  date.setDate(1); // 1st day of the month
  return date;
}

export const addYearToDateYear = (yearsToAdd, date = new Date()) => {
  date.setHours(0, 0, 0, 0);
  date.setFullYear(date.getFullYear() + yearsToAdd);

  // Ensure the year doesn't go beyond the current year
  const currentYear = new Date().getFullYear();

  if (date.getFullYear() > currentYear) {
    date.setFullYear(currentYear);
  }

  date.setMonth(0); // January
  date.setDate(1); // 1st day of the month
  return date;
};

export const getFirstDay = (month, year) => {
  const startDate = new Date();

  if (year !== undefined) {
    startDate.setFullYear(Number(year));
  }
  if (month !== undefined) {
    startDate.setMonth(Number(month), 1);
  }
  startDate.setUTCDate(1);
  startDate.setUTCHours(0, 0, 0);
  return startDate;
};

export const getLastDay = (month, year) => {
  const endDate = new Date();

  if (year !== undefined) {
    endDate.setFullYear(Number(year));
  }
  if (month !== undefined) {
    endDate.setMonth(Number(month) + 1, 1);
  }
  if (month === undefined) {
    endDate.setMonth(new Date().getMonth() + 1, 1);
  }
  endDate.setUTCDate(0);
  endDate.setUTCHours(23, 59, 59);

  return endDate;
};

export const SubtractYearToDate = (yearsToAdd, date = new Date()) => {
  date.setHours(0, 0, 0);
  date.setFullYear(date.getFullYear() - yearsToAdd);
  return date;
};

export function getDifferenceBetweenDates(start, end) {
  const startDate = new Date(formatDateStringToIsoDateString(start));
  const endDate = new Date(formatDateStringToIsoDateString(end));

  if (isNaN(startDate) || isNaN(endDate)) {
    return { error: 'Invalid date format' };
  }

  const differenceInMs = endDate - startDate;

  const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

  return differenceInDays;
}
