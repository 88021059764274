import React, { useContext } from 'react';
import Title from 'components/Title';

import { UserBox, Label, Value, Result, Column, Step } from '../styles';
import WizardContext from 'contexts/wizard';
import { WizardButton } from 'components/WizardBasicContainer/styles';

const CheckDataStep = ({ priceItem, buttonDisabled, save }) => {
  const { currentStep, total } = useContext(WizardContext);

  return (
    <Step className={[currentStep === total && 'active']}>
      <Title big>
        Confira as informações
        <br /> e clique em salvar
      </Title>
      <Result>
        <Column>
          <UserBox>
            <Label>Serviço:</Label>
            <Value>{priceItem.product_name}</Value>
          </UserBox>
          <UserBox>
            <Label>Categoria:</Label>
            <Value>
              {priceItem.parent_category} ➔ {priceItem.category}
            </Value>
          </UserBox>
        </Column>
      </Result>
      <WizardButton disabled={buttonDisabled} black center onClick={save}>
        Salvar
      </WizardButton>
    </Step>
  );
};

export default CheckDataStep;
