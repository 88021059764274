import React, { useCallback, useState } from 'react';

const WizardContext = React.createContext({
  setErrorStep: (step) => {},
  setCurrentStep: (step) => {},
  setTotal: (total) => {},
  setTotalAndMove: (total, step) => {},
  setPreviousStep: () => {},
  currentStep: 0,
  total: 0,
  lastStep: 0,
});

export const WizardProvider = ({ children }) => {
  const [nav, setNav] = useState({
    currentStep: 1,
    lastStep: 1,
    total: null,
  });

  let { total, currentStep, lastStep } = nav;

  const setTotal = (newTotal) => {
    setNav({ total: newTotal, currentStep, lastStep });
  };

  const setStep = useCallback(
    (newStep) => {
      setNav({
        total,
        currentStep: newStep,
        lastStep: currentStep,
      });
    },
    [setNav, currentStep, total]
  );

  const setCurrentStep = useCallback(
    (step) => {
      if (!total || step <= total) {
        setStep(step);
      }
    },
    [setStep, total]
  );

  const setTotalAndMove = (newTotal, newStep) => {
    setNav({
      total: newTotal,
      currentStep: newStep,
      lastStep: currentStep,
    });
  };

  const setErrorStep = () => {
    setStep(-1);
  };

  const setPreviousStep = () => {
    setStep(lastStep);
  };

  const values = {
    setErrorStep,
    setPreviousStep,
    setCurrentStep,
    setTotal,
    currentStep,
    lastStep,
    total,
    setTotalAndMove,
  };

  return (
    <WizardContext.Provider value={values}>{children}</WizardContext.Provider>
  );
};

export default WizardContext;
