import { useState, useEffect } from 'react';

function useDebounce(nextValue, delay) {
  const [value, setValue] = useState(nextValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setValue(nextValue);
    }, delay);

    return () => clearTimeout(handler);
  }, [nextValue, delay]);

  return value;
}

export default useDebounce;
