import UserAuthorizationContext from 'contexts/authorization';
import RegistrationsContext from 'contexts/registrations';
import useToken from 'hooks/useToken';
import { useContext } from 'react';
import api from './api';

const useAccountsService = (token) => {
  const tokenFromHook = useToken();
  const { getBankAccount } = useContext(UserAuthorizationContext);
  const { registrations } = useContext(RegistrationsContext);

  const serviceToken = token || tokenFromHook;
  const endpoint = '/accounts';

  const getAccountRegistry = async (paramToken, detailed) => {
    let url = `${endpoint}/registry`;
    const bank_account = getBankAccount() || registrations.identifier;

    if (!bank_account) {
      return await { error: true };
    }
    if (detailed) {
      url = `${url}/${bank_account}?result_level=DETAILED`;
    }
    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${serviceToken || paramToken}`,
        'X-Current-Identity': `${bank_account}`,
      },
    });
  };

  const getAccountByIdentifier = async (identifier, detailed) => {
    let url = `${endpoint}/registry/${identifier}`;

    url = detailed ? `${url}?result_level=DETAILED` : url;
    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${serviceToken}`,
      },
    });
  };

  const getAccountInfo = async () => {
    const url = `${endpoint}/information`;

    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const getAccountBusiness = async (no_approved = false, perPage = 20) => {
    let url = `${endpoint}/business?result_level=DETAILED&page=1&per_page=${perPage}`;

    url = no_approved ? `${url}&not_status=APPROVED` : url;

    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const getDocument = async (accountId, identifier) => {
    const url = `${endpoint}/${accountId}/document-analysis/${identifier}`;

    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const patchAccountConfirm = async (identifier, data = null) => {
    const url = `${endpoint}/confirm/${identifier}`;

    return await api.patch(url, data, {
      headers: {
        Authorization: `Bearer ${serviceToken}`,
      },
    });
  };

  const patchAccountBilling = async (accountId, data = null) => {
    const url = `${endpoint}/${accountId}/annual-billing`;

    return await api.patch(url, data, {
      headers: {
        Authorization: `Bearer ${serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const putSendDocument = async (accountId, identifier, data) => {
    const url = `${endpoint}/${accountId}/document-analysis/${identifier}`;

    return await api.put(url, data, {
      headers: {
        Authorization: `Bearer ${serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const postCreateCustomer = async (data) => {
    const url = `${endpoint}/customers`;

    // console.log(data);
    return await api.post(url, data, {
      headers: {
        Authorization: `Bearer ${serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const postCreateCustomerAccount = async () => {
    const url = `${endpoint}/customers`;

    return await api.post(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${serviceToken}`,
          'X-Current-Identity': `${getBankAccount()}`,
        },
      }
    );
  };

  const putEditCustomerAccount = async () => {
    const url = `${endpoint}/customers`;

    return await api.put(
      url,
      {},
      {
        headers: {
          Authorization: `Bearer ${serviceToken}`,
          'X-Current-Identity': `${getBankAccount()}`,
        },
      }
    );
  };

  const putCustomer = async (id, data) => {
    const url = `${endpoint}/customers/${id}`;

    return await api.put(url, data, {
      headers: {
        Authorization: `Bearer ${serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const postCreateBusiness = async (data) => {
    const url = `${endpoint}/business`;

    return await api.post(url, data, {
      headers: {
        Authorization: `Bearer ${serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const putBusiness = async (id, data) => {
    const url = `${endpoint}/business/${id}`;

    return await api.put(url, data, {
      headers: {
        Authorization: `Bearer ${serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const fetchConflict = async (id) => {
    const url = `${endpoint}/${id}/agent-assingment/conflicts`;

    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${serviceToken}`,
      },
    });
  };

  const updateConflict = async (id, data) => {
    const url = `${endpoint}/${id}/agent-assingment/sign`;

    return await api.patch(url, data, {
      headers: {
        Authorization: `Bearer ${serviceToken}`,
      },
    });
  };

  return {
    getAccountRegistry,
    getAccountByIdentifier,
    getAccountInfo,
    getAccountBusiness,
    getDocument,
    putSendDocument,
    patchAccountConfirm,
    patchAccountBilling,
    postCreateCustomer,
    postCreateBusiness,
    postCreateCustomerAccount,
    putEditCustomerAccount,
    putCustomer,
    putBusiness,
    fetchConflict,
    updateConflict,
  };
};

export default useAccountsService;
