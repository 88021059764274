import React, { useContext, useEffect } from 'react';
import Title from 'components/Title';
import { ChooseSystemButton, List, Step } from '../styles';
import WizardContext from 'contexts/wizard';
import RoundButtonLoader from 'components/Loaders/roundButton';
import ProductCategoriesContext from 'contexts/categories';
import TitleLegend from 'components/TitleLegend';

const ProductCategoryStep = ({ step, priceItem, setPriceItem }) => {
  const { currentStep, setTotalAndMove } = useContext(WizardContext);
  const { productCategories, loading, loadProductCategories } = useContext(
    ProductCategoriesContext
  );

  useEffect(() => {
    if (!productCategories) {
      loadProductCategories();
    }
  }, [productCategories, loadProductCategories]);

  const setCategory = (cat) => {
    let tech = priceItem;

    tech['parent_category_id'] = cat.id;
    tech['parent_category'] = cat.description;
    setPriceItem(tech);
    setTotalAndMove(3, step + 1);
  };

  return (
    <Step
      className={[
        currentStep === step && 'active',
        currentStep > step && 'old',
      ]}
    >
      <Title big>Escolha a categoria</Title>
      {priceItem && priceItem.product_name && (
        <TitleLegend>para o serviço {priceItem.product_name}</TitleLegend>
      )}
      <List>
        {!loading ? (
          productCategories &&
          productCategories.map(
            (cat) =>
              !cat.parents && (
                <ChooseSystemButton
                  medium
                  white
                  center
                  onClick={() => setCategory(cat)}
                  key={cat.id}
                  className={
                    priceItem.parent_category_id &&
                    priceItem.parent_category_id === cat.id &&
                    'active'
                  }
                >
                  {cat.description}
                </ChooseSystemButton>
              )
          )
        ) : (
          <RoundButtonLoader medium white center />
        )}
      </List>
    </Step>
  );
};

export default ProductCategoryStep;
