import Logout from 'pages/Logout';
import React from 'react';
import PublicWizard from 'Routes/components/PublicWizard';
import { PATHS } from 'Routes/constants';
const Register = React.lazy(() => import('pages/Registers'));
const Callback = React.lazy(() => import('pages/Callback'));
const Onboarding = React.lazy(() => import('pages/Onboarding'));
const WaitingValidation = React.lazy(() => import('pages/WaitValidation'));
const AccountStatus = React.lazy(() => import('pages/AccountStatus'));
const AppLinkRedirect = React.lazy(() => import('pages/AppLinkRedirect'));

const CreateAccountPublicDocuments = React.lazy(() =>
  import('pages/CreateAccountPublicDocuments')
);
const CreateAccountPublic = React.lazy(() =>
  import('pages/CreateAccountPublic')
);

export const publicRoutes = () => [
  <PublicWizard
    component={CreateAccountPublic}
    exact
    path={PATHS.ACCOUNT.PUBLIC.CREATE}
    key={PATHS.ACCOUNT.PUBLIC.CREATE}
  />,
  <PublicWizard
    component={Onboarding}
    exact
    path={PATHS.ONBOARDING.PF}
    key={PATHS.ONBOARDING.PF}
  />,
  <PublicWizard
    component={Onboarding}
    exact
    path={PATHS.ONBOARDING.PJ}
    key={PATHS.ONBOARDING.PJ}
  />,
  <PublicWizard
    component={Onboarding}
    exact
    path={PATHS.ONBOARDING.CORPORATION}
    key={PATHS.ONBOARDING.CORPORATION}
  />,
  <PublicWizard
    component={Onboarding}
    exact
    path={PATHS.ONBOARDING.INTRO}
    key={PATHS.ONBOARDING.INTRO}
  />,
  <PublicWizard
    component={Onboarding}
    exact
    path={PATHS.ONBOARDING.REGISTRO}
    key={PATHS.ONBOARDING.REGISTRO}
  />,
  <PublicWizard
    component={CreateAccountPublicDocuments}
    exact
    path={PATHS.ACCOUNT.PUBLIC.DOCUMENTS}
    key={PATHS.ACCOUNT.PUBLIC.DOCUMENTS}
  />,
  <PublicWizard
    component={WaitingValidation}
    exact
    path={PATHS.WAITVALIDATION}
    key={PATHS.WAITVALIDATION}
  />,
  <PublicWizard
    component={Register}
    exact
    path={PATHS.REGISTER}
    key={PATHS.REGISTER}
  />,
  <PublicWizard
    component={Callback}
    exact
    path={PATHS.CALLBACK}
    key={PATHS.CALLBACK}
  />,
  <PublicWizard
    component={AccountStatus}
    exact
    path={PATHS.ACCOUNT.PUBLIC.WAITING_ANALYSIS}
    key={PATHS.ACCOUNT.PUBLIC.WAITING_ANALYSIS}
  />,
  <PublicWizard
    component={AccountStatus}
    exact
    path={PATHS.ACCOUNT.PUBLIC.BLOCKED}
    key={PATHS.ACCOUNT.PUBLIC.BLOCKED}
  />,
  <PublicWizard
    component={AccountStatus}
    exact
    path={PATHS.ACCOUNT.PUBLIC.REVIEW_DATA}
    key={PATHS.ACCOUNT.PUBLIC.REVIEW_DATA}
  />,
  <PublicWizard
    component={AppLinkRedirect}
    path={PATHS.APP_LINK_REDIRECT}
    key={PATHS.APP_LINK_REDIRECT}
  />,
  <Logout path="/logout" key="logout" />,
];
