import { createSlice } from '@reduxjs/toolkit';
import {
  DEFAULT_PERIOD,
  STATEMENT_STATUS,
} from 'pages/BankStatement/shared/constants';
import { decreaseDate } from 'tools/time';
import SCHEDULE_STEPS from './steps';

const FETCH_STATES = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const functions = {
  setAccountManagementState(state, action: { type: '', payload: null }) {
    return { ...state, accountManagement: action.payload };
  },
  setTableFilterState(state, action: { type: '', payload: null }) {
    return { ...state, tableFilter: action.payload };
  },

  setTableFilterOperationTypeState(
    state,
    action: { type: '', payload: { id: null, value: null } }
  ) {
    return {
      ...state,
      tableFilter: {
        ...state.operationType,
        [action.payload.id]: action.payload.value,
      },
    };
  },

  setTableFilterPeriodState(state, action: { type: '', payload: null }) {
    return { ...state, tableFilter: action.payload };
  },

  setCreateScheduleValue(
    state,
    action: { type: '', payload: { id: null, value: null } }
  ) {
    return {
      ...state,
      scheduleManagement: {
        ...state.scheduleManagement,
        createSchedule: {
          ...state.scheduleManagement.createSchedule,
          [action.payload.id]: action.payload.value,
        },
      },
    };
  },
  setScheduleSteps(state, action: { type: '', payload: { value: null } }) {
    return {
      ...state,
      scheduleManagement: {
        ...state.scheduleManagement,
        ...{ steps: SCHEDULE_STEPS },
      },
    };
  },
  setPeriodicity(
    state,
    action: { type: '', payload: { id: null, value: null } }
  ) {
    return {
      ...state,
      scheduleManagement: {
        ...state.scheduleManagement,
        periodicity: {
          ...action.payload,
        },
      },
    };
  },
  setScheduleRegisterValue(
    state,
    action: { type: '', payload: { id: null, value: null } }
  ) {
    return {
      ...state,
      scheduleManagement: {
        ...state.scheduleManagement,
        register: {
          ...state.scheduleManagement.register,
          [action.payload.id]: action.payload.value,
        },
      },
    };
  },
  setScheduleRegister(
    state,
    action: { type: '', payload: { id: null, value: null } }
  ) {
    return {
      ...state,
      scheduleManagement: {
        ...state.scheduleManagement,
        register: {
          ...state.scheduleManagement.register,
          ...action.payload,
        },
      },
    };
  },
  setScheduleMode(state, action: { type: '', payload: null }) {
    return {
      ...state,
      scheduleManagement: {
        ...state.scheduleManagement,
        mode: action.payload,
      },
    };
  },
};

const defaultTableFilter = {
  period: DEFAULT_PERIOD,
  order: null,
  operationType: [],
  date: {
    [STATEMENT_STATUS.BEGIN_DATE_TIME]: decreaseDate(
      DEFAULT_PERIOD.value
    ).toISOString(),
    [STATEMENT_STATUS.END_DATE_TIME]: decreaseDate(0).toISOString(),
  },
};

const defaultScheduleManagement = {
  steps: null,
  status: FETCH_STATES.IDLE,
  err: null,
  periodicity: {},
  register: {},
};

const bankstatementSlice = createSlice({
  name: 'bankstatement',
  initialState: {
    tableFilter: defaultTableFilter,
    accountManagement: null,
    scheduleManagement: defaultScheduleManagement,
  },
  reducers: {
    ...functions,
  },
});

export const {
  setPeriodicity,
  setAccountManagementState,
  setTableFilterState,
  setTableFilterOperationTypeState,
  setTableFilterOrderState,
  setTableFilterPeriodState,
  setCreateScheduleValue,
  setScheduleSteps,
  setScheduleRegisterValue,
  setScheduleMode,
  setScheduleRegister,
} = bankstatementSlice.actions;
export default bankstatementSlice.reducer;
