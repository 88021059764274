import styled from 'styled-components';

export const Box = styled.div`
  margin: 0;
  padding: 24px 32px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  white-space: normal;
  position: relative;
  overflow: hidden;
`;
