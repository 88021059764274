import React from 'react';
import { useTranslation } from 'react-i18next';
import PersonIcon from 'assets/images/PersonIcon';
import CompanyIcon from 'assets/images/CompanyIcon';
import useAccounts from 'hooks/useAccounts';
import { maskCNPJ } from 'tools/cnpj-utils';
import { maskCPF } from 'tools/cpf-utils';
import CardSwitcher from './index';

export default function CardSwitcherBankAccount({ iconColor, iconSize, size }) {
  const { t } = useTranslation('menu');
  const { currentAccount } = useAccounts();

  if (!currentAccount) {
    return null;
  }

  return (
    <CardSwitcher
      iconColor={iconColor}
      iconSize={iconSize}
      size={size}
      label={currentAccount.name}
      description={
        currentAccount?.profile_type === 'PERSONAL'
          ? `${t('menu:new.cpf', 'CPF')}: ${maskCPF(
              currentAccount.current_identity
            )}`
          : `${t('menu:new.cnpj', 'CNPJ')}: ${maskCNPJ(
              currentAccount.current_identity
            )}`
      }
      icon={
        currentAccount?.profile_type === 'PERSONAL' ? (
          <PersonIcon />
        ) : (
          <CompanyIcon />
        )
      }
    />
  );
}
