import React, { useMemo } from 'react';
import { AvatarIcon } from './styles';

const Avatar = ({ avatar, title, className, ...props }) => {
  const getName = useMemo(() => {
    return `${title}`.trim().indexOf(' ') > -1
      ? `${title}`
          .replace(/[-_]/g, '')
          .replace(/\s\s/g, ' ')
          .split(' ')
          .filter((name) => !/^d(a|e|o)s?$/i.test(name))
          .slice(0, 2)
          .map((name) => (name ? name[0] : ''))
      : `${title}`.substr(0, 2);
  }, [title]);

  return (
    <AvatarIcon image={avatar ? avatar : null} className={className} {...props}>
      {avatar ? '' : getName}
    </AvatarIcon>
  );
};

export default Avatar;
