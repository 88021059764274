import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
  flex: 1;
`;

export const MainBox = styled.div`
  flex: 1;
  min-height: 100%;
  min-width: 100%;
`;
