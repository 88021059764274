import { useEffect } from 'react';

/**
 * Hook for clicks outside of the passed ref
 */
export default function useOutsideClick(
  ref,
  callback,
  closeOnClickSelfButtons
) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (closeOnClickSelfButtons) {
        handleClickSelfButtons(event.target, callback);
      }

      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback, closeOnClickSelfButtons]);
}

function handleClickSelfButtons(target, callback) {
  if (target) {
    const name = target.tagName ? target.tagName.toLowerCase() : '';

    switch (name) {
      case 'button':
      case 'a':
        callback();
        return;

      default:
        break;
    }
  }
}
