import useMaskedInput from 'hooks/useMaskedInput';
import React, { forwardRef, useEffect, useState } from 'react';
import { onlyAlphaNumeric, toMask } from 'tools';
import { Field } from '../styles';

const mask = [
  /[a-zA-Z0-9]/,
  ' ',
  /[a-zA-Z0-9]/,
  ' ',
  /[a-zA-Z0-9]/,
  ' ',
  /[a-zA-Z0-9]/,
  ' ',
  /[a-zA-Z0-9]/,
  ' ',
  /[a-zA-Z0-9]/,
  ' ',
  /[a-zA-Z0-9]/,
  ' ',
  /[a-zA-Z0-9]/,
  ' ',
  /[a-zA-Z0-9]/,
  ' ',
  /[a-zA-Z0-9]/,
  ' ',
  /[a-zA-Z0-9]/,
  ' ',
  /[a-zA-Z0-9]/,
];

let CodeUnlockCardInput = ({ className, inputId, ...props }, input) => {
  const [count, setCount] = useState(0);
  const [dirt, setDirt] = useState(false);

  let onChange = useMaskedInput({
    input,
    mask: mask,
    onChange: props.onChange,
  });

  const handleOnChange = (value) => {
    onChange(value);
    setDirt(true);
  };

  const toInputUppercase = (event) => {
    const input = event.target;

    if (
      input &&
      input.value &&
      input.selectionStart &&
      input.selectionEnd &&
      input.setSelectionRange
    ) {
      const start = input.selectionStart;
      const end = input.selectionEnd;

      input.value = ('' + input.value).toUpperCase();
      input.setSelectionRange(start, end);
    }
  };

  useEffect(() => {
    let mounted = true;
    const el = input.current;

    if (
      mounted &&
      count === 0 &&
      !dirt &&
      el &&
      props.defaultValue &&
      props.defaultValue !== 'undefined'
    ) {
      const value = onlyAlphaNumeric(props.defaultValue.toUpperCase());

      el.value = toMask(value, mask).conformedValue;
      setCount(count + 1);
    }
    return () => (mounted = false);
  }, [input, props.defaultValue, count, dirt]);
  return (
    <Field
      {...props}
      className={className}
      id={inputId}
      ref={input}
      type="text"
      pattern="[a-zA-Z0-9]*"
      autocomplete="one-time-code"
      defaultValue={props.defaultValue}
      onChange={handleOnChange}
      onInput={toInputUppercase}
    />
  );
};

export default forwardRef(CodeUnlockCardInput);
