import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Route, Redirect } from 'react-router-dom';
import { useApplicationContext } from 'contexts/application';
import { PATHS } from 'Routes/constants';
import Loading from 'components/_v2/Loading';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { loading, hasAccount } = useApplicationContext();

  if (loading) return <Loading />;

  return hasAccount ? (
    <Route {...rest} render={(props) => <Component {...props} />} />
  ) : (
    <Redirect to={PATHS.CALLBACK} />
  );
};

export default withAuthenticationRequired(PrivateRoute, {
  onRedirecting: () => <Loading />,
});
