import React from 'react';
import { Link } from 'react-router-dom';
import * as S from '../style';

export default function AccountInfoWithAvatar({
  icon,
  to,
  label,
  description,
  className,
  onClick,
  sizeIcon,
}) {
  return (
    <S.AccountInfo className={className}>
      {to && (
        <Link to={to || ''}>
          {icon && (
            <S.NavMenuItemIcon className={'icon'} size={sizeIcon}>
              {icon}
            </S.NavMenuItemIcon>
          )}{' '}
          <div>
            <h4 className="label">{label}</h4>
            {description && <h6 className="description">{description}</h6>}
          </div>
        </Link>
      )}

      {!to && onClick && (
        <button onClick={onClick}>
          {icon && (
            <S.NavMenuItemIcon className={'icon'} size={sizeIcon}>
              {icon}
            </S.NavMenuItemIcon>
          )}{' '}
          <div>
            <h4 className="label">{label}</h4>
            {description && <h6 className="description">{description}</h6>}
          </div>
        </button>
      )}

      {!to && !onClick && (
        <div>
          {icon && (
            <S.NavMenuItemIcon className={'icon'} size={sizeIcon}>
              {icon}
            </S.NavMenuItemIcon>
          )}{' '}
          <div>
            <h4 className="label">{label}</h4>
            {description && <h6 className="description">{description}</h6>}
          </div>
        </div>
      )}
    </S.AccountInfo>
  );
}
