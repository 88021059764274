import React from 'react';

const ArrowLeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="24"
      viewBox="0 0 39 24"
    >
      <g>
        <path
          d="M63.879 18.414L65.293 17 76.293 28 77 28.707 76.293 29.414 65.293 40.414 63.879 39 73.172 29.707 17 29.707 17 27.707 73.172 27.707z"
          transform="translate(-101 -760) matrix(-1 0 0 1 178 743)"
        />
      </g>
    </svg>
  );
};

export default ArrowLeftIcon;
