export const KEYS = {
  SENDER_ACCOUNT_STATUS_NOT_ALLOW_CASH_OUT:
    'SENDER_ACCOUNT_STATUS_NOT_ALLOW_CASH_OUT',
  RECIPIENT_ACCOUNT_STATUS_NOT_ALLOW_CASH_IN:
    'RECIPIENT_ACCOUNT_STATUS_NOT_ALLOW_CASH_IN',
  INVALID_RECIPIENT_ACCOUNT: 'INVALID_RECIPIENT_ACCOUNT',
  SENDER_ACCOUNT_NOT_FOUND: 'SENDER_ACCOUNT_NOT_FOUND',
  RECIPIENT_ACCOUNT_NOT_FOUND: 'RECIPIENT_ACCOUNT_NOT_FOUND',
  CASHOUT_LIMIT_NOT_ENOUGH: 'CASHOUT_LIMIT_NOT_ENOUGH',
  TIMEOUT: 'TIMEOUT',
  INVALID_BANK_BRANCH: 'INVALID_BANK_BRANCH',
  INVALID_BANK_ACCOUNT: 'INVALID_BANK_ACCOUNT',
  RECIPIENT_ACCOUNT_DOES_NOT_MATCH_THE_DOCUMENT:
    'RECIPIENT_ACCOUNT_DOES_NOT_MATCH_THE_DOCUMENT',
  SENDER_ACCOUNT_DOES_NOT_MATCH_THE_DOCUMENT:
    'SENDER_ACCOUNT_DOES_NOT_MATCH_THE_DOCUMENT',
  INSUFFICIENT_BALANCE: 'INSUFFICIENT_BALANCE',
  TRANSFER_WAS_REPROVED: 'TRANSFER_WAS_REPROVED',
  TRANSFER_AMOUNT_NOT_RESERVED: 'TRANSFER_AMOUNT_NOT_RESERVED',
  TRANSFER_ORDER_NOT_PROCESSED: 'TRANSFER_ORDER_NOT_PROCESSED',
  INTERNAL_TRANSFER_NOT_COMPLETED: 'INTERNAL_TRANSFER_NOT_COMPLETED',
  ACCOUNT_NOT_FOUND: 'ACCOUNT_NOT_FOUND',
  SCHEDULE_NOT_ALLOWED: 'SCHEDULE_NOT_ALLOWED',
  INVALID_END_TO_END_ID: 'INVALID_END_TO_END_ID',
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
  INVALID_PARAMETERS: 'INVALID_PARAMETERS',
  INVALID_PASSWORD: 'INVALID_PASSWORD',
  NOTFOUND: 'NOT_FOUND',
  GENERIC: 'GENERIC_ERROR',
  INVALID_PARAMETER: 'INVALID_PARAMETER',
  EXCEED_MAX_VALIDATE_ATTEMPTS: 'EXCEED_MAX_VALIDATE_ATTEMPTS',
  ADDRESSING_KEY_VALUE_AND_ACCOUNT_HOLDER_DOCUMENT_ARE_DIFFERENT:
    'ADDRESSING_KEY_VALUE_AND_ACCOUNT_HOLDER_DOCUMENT_ARE_DIFFERENT',
  ADDRESSING_KEY_TYPE_IS_INVALID_FOR_ACCOUNT_HOLDER_TYPE:
    'ADDRESSING_KEY_TYPE_IS_INVALID_FOR_ACCOUNT_HOLDER_TYPE',
  TARGET_ACCOUNT_DOES_NOT_EXIST: 'TARGET_ACCOUNT_DOES_NOT_EXIST',
  INVALID_ACCOUNT_STATUS: 'INVALID_ACCOUNT_STATUS',
  INVALID_ACCOUNT_HOLDER_STATUS: 'INVALID_ACCOUNT_HOLDER_STATUS',
  MAXIMUM_ENTRIES_COUNT_REGISTERED_FOR_ACCOUNT:
    'MAXIMUM_ENTRIES_COUNT_REGISTERED_FOR_ACCOUNT',
  ENTRY_ALREADY_EXISTS_TO_SAME_HOLDER_AND_ANOTHER_ACCOUNT:
    'ENTRY_ALREADY_EXISTS_TO_SAME_HOLDER_AND_ANOTHER_ACCOUNT',
  ENTRY_ALREADY_EXISTS_TO_ANOTHER_HOLDER:
    'ENTRY_ALREADY_EXISTS_TO_ANOTHER_HOLDER',
  ENTRY_ALREADY_EXISTS_TO_SAME_ACCOUNT: 'ENTRY_ALREADY_EXISTS_TO_SAME_ACCOUNT',
  ENTRY_ALREADY_EXISTS_TO_SAME_OWNER_INTO_ANOTHER_PLAYER:
    'ENTRY_ALREADY_EXISTS_TO_SAME_OWNER_INTO_ANOTHER_PLAYER',
  EVP_ENTRY_CANNOT_REGISTER_SHORT_INTERVAL:
    'EVP_ENTRY_CANNOT_REGISTER_SHORT_INTERVAL',
  INVALID_USER_ID: 'INVALID_USER_ID',
  PERMISSION_NOT_GRANTED: 'PERMISSION_NOT_GRANTED',
  DUPLICATE_ENTITY: 'DUPLICATE_ENTITY',
  CANCEL_NOT_ALLOWED: 'CANCEL_NOT_ALLOWED',
  INVITATIONS_NOT_FOUND: 'INVITATIONS_NOT_FOUND',
  INVALID_ACCOUNT_BRANCH_OR_NUMBER: 'INVALID_ACCOUNT_BRANCH_OR_NUMBER',
};
