import { useContext, useMemo } from 'react';
import UserAuthorizationContext from 'contexts/authorization';
import { ACCOUNT_TYPE } from 'config/constants';
import useModalNotificationsContext from 'contexts/modal-notifications';

const useAccounts = () => {
  const { userAuthorization, currentAccountId, setBankAccount } = useContext(
    UserAuthorizationContext
  );

  const { clearModalView } = useModalNotificationsContext();

  const currentID = useMemo(() => currentAccountId, [currentAccountId]);

  const accountsMemo = useMemo(
    () => userAuthorization.banks || [],
    [userAuthorization]
  );

  const accounts = Array.isArray(accountsMemo) ? accountsMemo : [];

  const accountsPF = accounts.filter(
    (account) => account?.profile_type === ACCOUNT_TYPE.PERSONAL
  );

  const accountsPJ = accounts.filter(
    (account) => account?.profile_type !== ACCOUNT_TYPE.PERSONAL
  );

  const accountsWithoutCurrent = accounts.filter(
    (account) => account?.current_identity !== currentID
  );

  const accountsPFWithoutCurrent = accountsWithoutCurrent.filter(
    (account) => account?.profile_type === ACCOUNT_TYPE.PERSONAL
  );

  const accountsPJWithoutCurrent = accountsWithoutCurrent.filter(
    (account) => account?.profile_type !== ACCOUNT_TYPE.PERSONAL
  );

  const currentAccount = accounts.find(
    (account) => account?.current_identity === currentID
  );

  const currentAccountPF =
    currentAccount && currentAccount.profile_type === ACCOUNT_TYPE.PERSONAL
      ? currentAccount
      : null;

  const currentAccountPJ =
    currentAccount && currentAccount.profile_type !== ACCOUNT_TYPE.PERSONAL
      ? currentAccount
      : null;

  const setAccount = (id) => {
    setBankAccount(id);
    clearModalView();
    document.location.reload(true);
  };

  return {
    accounts,
    accountsWithoutCurrent,
    accountsPF,
    accountsPFWithoutCurrent,
    accountsPJ,
    accountsPJWithoutCurrent,
    currentAccount,
    currentAccountPF,
    currentAccountPJ,
    currentID,
    setAccount,
  };
};

export default useAccounts;
