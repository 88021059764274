import React from 'react';

import { TitleTag } from './styles';

const Title = ({ className, children, prose, ...props }) => (
  <TitleTag {...props} className={className} prose={prose}>
    {children}
  </TitleTag>
);

export default Title;
