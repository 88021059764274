import React, { Suspense } from 'react';
import { WizardProvider } from 'contexts/wizard';
import { MainBox, MainContainer, ProgressBarComp } from './styles';
import NewMenu from 'components/NewMenu';
import { MenuVisibilityProvider } from 'contexts/menuVisibility';
import { MenuProvider } from 'contexts/menu';
import Loading from 'components/_v2/Loading';
// import { MenuBox } from './styles';
// import { SubmenuProvider } from "contexts/submenu";

const WizardSingle = ({ children, ...rest }) => {
  return (
    <WizardProvider>
      <MainContainer className="section-wizard">
        <MenuVisibilityProvider>
          <MenuProvider>
            {/* <SubmenuProvider> */}
            {/* <MenuBox hydeuser {...rest} className="main-menu" /> */}

            <NewMenu />
            <MainBox className="main-box">
              <Suspense fallback={<Loading />}>{children}</Suspense>
            </MainBox>
            <ProgressBarComp />
            {/* </SubmenuProvider> */}
          </MenuProvider>
        </MenuVisibilityProvider>
      </MainContainer>
    </WizardProvider>
  );
};

export default WizardSingle;
