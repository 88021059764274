import React, { useCallback, useContext, useEffect, useState } from 'react';
import useAccountsService from 'services/accounts';
import useToken from 'hooks/useToken';

const AccountBusinessContext = React.createContext({
  accounts: null,
  message: null,
  success: false,
  error: false,
  loading: false,
  loadAccountBusiness: () => {},
});

export const AccountBusinessProvider = ({ children }) => {
  const [accounts, setAccounts] = useState();
  const [fetchState, setFetchState] = useState('');
  const [message, setMessage] = useState();
  const token = useToken();
  const { getAccountBusiness } = useAccountsService(token);

  const initial = fetchState === 'initial';
  const loading = fetchState === 'loading';
  const error = fetchState === 'error';
  const success = fetchState === 'success';

  const handleError = useCallback((e) => {
    console.error(e.message, '.Error trying to fetch account business');
    if (
      e.response &&
      e.response.data &&
      e.response.data.code >= 400 &&
      e.response.data.code < 500
    ) {
      if (e.response.data.code === 404) {
        setMessage('Ops... parece que sua conta não foi registrada!');
      } else {
        setMessage('Ocorreu um erro ao buscar os dados.');
      }
    } else {
      setMessage('Oops... ocorreu um erro inesperado!');
    }
    setAccounts(null);
    setFetchState('error');
  }, []);

  const handleResponse = useCallback((response, actions) => {
    const { data } = response;

    setAccounts(data ? data.accounts : null);
    setFetchState('success');
  }, []);

  useEffect(() => {
    let cleanUp = false;
    const fetchAccountBusiness = async () => {
      setFetchState('loading');
      try {
        handleResponse(await getAccountBusiness(token));
      } catch (e) {
        handleError(e);
      }
    };

    if (token && !cleanUp && initial) {
      fetchAccountBusiness();
    }
    return () => (cleanUp = true);
  }, [token, initial, handleError, handleResponse, getAccountBusiness]);

  const loadAccountBusiness = () => {
    setFetchState('initial');
  };

  const defaultValues = {
    accounts,
    message,
    success,
    error,
    loading,
    loadAccountBusiness,
  };

  return (
    <AccountBusinessContext.Provider value={defaultValues}>
      {children}
    </AccountBusinessContext.Provider>
  );
};

export const useAccountBusinessContext = () =>
  useContext(AccountBusinessContext);

export default AccountBusinessContext;
