import React, { useEffect, useState } from 'react';

import { ButtonBox, Label, ButtonEl } from './styles';

const RadioButton = ({
  id,
  name,
  text,
  big,
  value,
  checked,
  className,
  children,
  theme = '',
  onChange = null,
  noTextOverflow,
}) => {
  const [active, setActive] = useState(null);

  useEffect(() => {
    setActive(!!checked);
  }, [checked]);
  return (
    <>
      <ButtonEl
        type="radio"
        id={id}
        name={name}
        value={value}
        className={className}
        defaultChecked={active}
        onChange={onChange}
        theme={theme}
      />
      <ButtonBox htmlFor={id} noTextOverflow={noTextOverflow} big={big}>
        <Label>{text ? text : children ? children : null}</Label>
      </ButtonBox>
    </>
  );
};

export default RadioButton;
