import React from 'react';

const DashboardIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path d="M0 2.88889C0 1.2934 1.2934 0 2.88889 0H13.1111C14.7066 0 16 1.2934 16 2.88889V13.1111C16 14.7066 14.7066 16 13.1111 16H2.88889C1.2934 16 0 14.7066 0 13.1111V2.88889ZM1.33333 8.44444V13.1111C1.33333 13.9702 2.02978 14.6667 2.88889 14.6667H9.77778V8.44444L1.33333 8.44444ZM9.77778 7.11111V1.33333H2.88889C2.02978 1.33333 1.33333 2.02978 1.33333 2.88889V7.11111L9.77778 7.11111ZM14.6667 5.77778H11.1111V10.2222H14.6667V5.77778ZM14.6667 11.5556H11.1111V14.6667H13.1111C13.9702 14.6667 14.6667 13.9702 14.6667 13.1111V11.5556ZM14.6667 4.44444V2.88889C14.6667 2.02978 13.9702 1.33333 13.1111 1.33333H11.1111V4.44444L14.6667 4.44444Z" />
      </g>
    </svg>
  );
};

export default DashboardIcon;
