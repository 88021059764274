import React from 'react';

import { Route, Switch } from 'react-router-dom';
import { PATHS } from 'Routes/constants';

import { PricesProvider } from 'contexts/prices';
import AssociateProduct from './Associate';
import ProductDetails from './Details';
import { ProductCategoriesProvider } from 'contexts/categories';

const PriceList = React.lazy(() => import('./List'));
const PricesForm = React.lazy(() => import('./Add'));

const Prices = () => {
  return (
    <Switch>
      <Route path={PATHS.PRICELIST.ADD} exact>
        <PricesForm />
      </Route>
      <Route path={PATHS.PRICELIST.EDIT} exact>
        <PricesForm />
      </Route>
      <Route path={PATHS.PRICELIST.LIST} exact>
        <PricesProvider>
          <PriceList />
        </PricesProvider>
      </Route>
      <Route path={PATHS.PRICELIST.ASSOCIATE} exact>
        <PricesProvider>
          <AssociateProduct />
        </PricesProvider>
      </Route>
      <Route path={PATHS.PRICELIST.DETAILS} exact>
        <ProductCategoriesProvider>
          <ProductDetails />
        </ProductCategoriesProvider>
      </Route>
    </Switch>
  );
};

export default Prices;
