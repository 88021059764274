import { Card } from '@contbank/components';
import React from 'react';

import { Container } from './styles';

const Components = () => {
  return (
    <Container>
      <h1>Componentes</h1>
      -----------------------------------------------------------------------------------------------------
      <Card>lalala</Card>
    </Container>
  );
};

export default Components;
