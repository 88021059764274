import styled, { keyframes } from 'styled-components';
import Configs from 'styles/variables';

const movement = keyframes`
  0% {
    transform: translateX(0);
  }
  60%{
    transform: translateX(10%);
  }
  100%{
    transform: translateX(0);
  }
`;

export const ButtonBox = styled.div`
  width: 60px;
  height: 60px;
  background-color: ${Configs.yellow};
  border-radius: 28px;
  transition: all 0.25s ease;
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  &:hover {
    background-color: ${Configs.black};
    & svg {
      fill: ${Configs.yellow};
      animation: ${movement} 0.25s linear forwards;
    }
  }
`;

export const Arrow = styled.div`
  width: 20px;
  overflow: hidden;
  & > svg {
    margin-top: 6px;
    width: 39px;
    height: 20px;
    fill: ${Configs.black};
  }
`;
