import React from 'react';

const NavigatorIcon = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="#fff"
        d="M2 1.25a.75.75 0 0 0-1.5 0v14.5c0 .966.784 1.75 1.75 1.75h14.5a.75.75 0 0 0 0-1.5H2.25a.25.25 0 0 1-.25-.25V1.25Zm9 2.5a.75.75 0 0 1 .75-.75h5.002a.75.75 0 0 1 .75.75v5.005a.75.75 0 1 1-1.5 0V5.559L10.78 10.78a.75.75 0 0 1-1.06 0L7.75 8.81l-3.22 3.22a.75.75 0 0 1-1.06-1.06l3.75-3.75a.75.75 0 0 1 1.06 0l1.97 1.97 4.69-4.69h-3.19a.75.75 0 0 1-.75-.75Z"
      />
    </svg>
  );
};

export default NavigatorIcon;
