import styled from 'styled-components';
import Configs from 'styles/variables';

export const ButtonBox = styled.a.attrs((props) => ({
  background: props.black
    ? Configs.black
    : props.white
    ? Configs.white
    : props.yellow
    ? Configs.yellow
    : Configs.buttons.background,
  color: props.black ? Configs.white : Configs.buttons.color,
  paddingX: props.big ? 35 : Configs.buttons.paddingX,
  paddingY: props.big ? 35 : props.medium ? 24 : 0,
  size: 24,
}))`
  border: none;
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  display: flex;
  height: ${Configs.buttons.height}px;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.paddingX}px;
  font-size: ${(props) => props.size}px;
  padding: ${(props) => props.paddingY}px ${(props) => props.paddingX}px;
  transition: all 0.25s ease;
  box-shadow: ${(props) => (props.big ? '0 0 10px 0 rgba(0, 0, 0, 0.09)' : '')};
  cursor: pointer;
  text-decoration: none;
  * &:hover {
    background: ${Configs.buttons.hover.background};
    color: ${Configs.buttons.hover.color};
  }
  &.active {
    background: ${Configs.buttons.active.background};
    color: ${Configs.buttons.active.color};
  }
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 2.29;
  letter-spacing: -0.28px;
  display: block;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
`;
