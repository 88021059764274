import SearchMenuContext from 'contexts/search-menu';
import React, { useContext } from 'react';
import { PATHS } from 'Routes/constants';
import { maskCNPJ, maskPartialCNPJ } from 'tools/cnpj-utils';
import { separateSearchWordFromPhrase } from 'tools/search-menu';
// import { useTranslation } from "react-i18next";
import S from './styles';

export const MenuSearchResults = () => {
  const { customers, searchText, success } = useContext(SearchMenuContext);
  // const { t } = useTranslation();
  // const tags = [
  //   "Serviços",
  //   "Armando Loureiro da Silva",
  //   "Alterar endereço",
  //   "Cadastro de serviços",
  //   "Três Coroas Parafusos",
  // ];

  const highlightSearchWordCustomerIdentifier = (customer) => {
    const { identifier } = customer;
    const separatedIdentifier = separateSearchWordFromPhrase(
      maskCNPJ(identifier),
      maskPartialCNPJ(searchText)
    );
    const [init, word, end] = separatedIdentifier;

    return (
      <>
        {init}
        <S.Find>{word}</S.Find>
        {end}
      </>
    );
  };

  const highlightSearchWordCustomerName = (customer) => {
    const { name } = customer;
    const separatedName = separateSearchWordFromPhrase(name, searchText);
    const [init, word, end] = separatedName;

    return (
      <>
        {init}
        <S.Find>{word}</S.Find>
        {end}
      </>
    );
  };

  const highlightSearchWord = (customer) => (
    <>
      {highlightSearchWordCustomerName(customer)},{' '}
      {highlightSearchWordCustomerIdentifier(customer)}
    </>
  );

  const getLinks = () => {
    return customers.map((customer) => (
      <S.Link
        key={`${customer.id}`}
        to={PATHS.CLIENTS.VIEW.replace(':id', customer.id)}
      >
        {highlightSearchWord(customer)}
      </S.Link>
    ));
  };

  return (
    <S.Container>
      {/*<>
          <S.Title>{t("menu.last_searchs", "Últimas buscas")}</S.Title>
          <S.TagList>
            {tags.map((tag) => (
              <S.Tag key={tag}>{tag}</S.Tag>
            ))}
          </S.TagList>
        </>
      */}
      {success && customers && customers.length > 0 && (
        <S.ResultWrapper>
          <S.Subtitle>Clientes</S.Subtitle>
          <S.ResultList>{getLinks().map((link) => link)}</S.ResultList>
        </S.ResultWrapper>
      )}
    </S.Container>
  );
};
