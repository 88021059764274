import React, { Suspense } from 'react';
import { WizardProvider } from 'contexts/wizard';

import { MainBox, MainContainer, ProgressBarComp } from './styles';
import Loading from 'components/_v2/Loading';

const WizardPublic = ({ children, ...rest }) => {
  return (
    <MainContainer className="section-wizard">
      <WizardProvider>
        <MainBox className="main-box">
          <Suspense fallback={<Loading />}>{children}</Suspense>
        </MainBox>
        <ProgressBarComp />
      </WizardProvider>
    </MainContainer>
  );
};

export default WizardPublic;
