import React from 'react';

const Logo = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20"
      viewBox="0 0 24 20"
      {...props}
    >
      <g>
        <path
          d="M15.757 0c3.162 0 6.004 1.267 7.973 3.285l-3.888 3.758C18.946 5.688 17.451 4.8 15.757 4.8c-2.742 0-4.965 2.328-4.965 5.2 0 2.872 2.223 5.2 4.965 5.2 1.813 0 3.399-1.017 4.266-2.536l3.938 3.806C21.988 18.63 19.045 20 15.757 20 9.815 20 5 15.523 5 10S9.815 0 15.757 0z"
          transform="translate(-21 -18) translate(21 18)"
        />
        <path
          d="M7.481 11.2v4H0l.662-4h6.82zm2.483-6.4v4H1.06l.662-4h8.243z"
          transform="translate(-21 -18) translate(21 18)"
        />
      </g>
    </svg>
  );
};

export default Logo;
