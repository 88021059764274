import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useToken from 'hooks/useToken';
import useAccountsService from 'services/accounts';
import useConstants from 'services/contants';

export const ACCOUNT_TYPE = {
  BUSINESS: 'BUSINESS',
  PERSONAL: 'PERSONAL',
};

const AccountRegistryContext = React.createContext({
  registry: null,
  message: null,
  success: false,
  error: false,
  loading: false,
  loadAccountRegistry: () => {},
});

export const AccountRegistryProvider = ({ children }) => {
  const [registry, setRegistry] = useState();
  const [fetchState, setFetchState] = useState('');
  const [message, setMessage] = useState();
  const [detailed, setDetailed] = useState(false);
  const token = useToken();
  const { getAccountRegistry } = useAccountsService();
  const { ACCOUNT_TYPES } = useConstants();
  const loading = fetchState === 'loading';
  const error = fetchState === 'error';
  const success = fetchState === 'success';
  const initial = fetchState === 'initial';

  const isPersonal = useMemo(
    () => registry?.account_type === ACCOUNT_TYPES.PERSONAL,
    [registry, ACCOUNT_TYPES]
  );

  const isBusiness = useMemo(
    () => registry?.account_type === ACCOUNT_TYPES.BUSINESS,
    [registry, ACCOUNT_TYPES]
  );

  const handleError = useCallback((e) => {
    console.error(e.message, '.Error trying to fetch account registry');
    if (
      e.response &&
      e.response.data &&
      e.response.data.code >= 400 &&
      e.response.data.code < 500
    ) {
      if (e.response.data.code === 404) {
        setMessage('Ops... parece que sua conta não foi registrada!');
      } else {
        setMessage('Ocorreu um erro ao buscar os dados.');
      }
    } else {
      setMessage('Oops... ocorreu um erro inesperado!');
    }
    setRegistry(null);
    setFetchState('error');
  }, []);

  const handleResponse = useCallback((response, actions) => {
    const { data } = response;

    setRegistry(data ? data : null);
    setFetchState('success');
  }, []);

  useEffect(() => {
    let mounted = true;

    const fetchAccountRegistry = async () => {
      setFetchState('loading');
      try {
        handleResponse(await getAccountRegistry(token, detailed));
      } catch (e) {
        handleError(e);
      }
    };

    if (mounted && token && initial) {
      fetchAccountRegistry();
    }
    return () => (mounted = false);
  }, [
    detailed,
    token,
    initial,
    handleError,
    handleResponse,
    getAccountRegistry,
  ]);

  const loadAccountRegistry = useCallback((detailed = false) => {
    setFetchState('initial');
    setDetailed(detailed);
  }, []);

  const defaultValues = {
    registry,
    message,
    success,
    error,
    loading,
    loadAccountRegistry,
    isPersonal,
    isBusiness,
  };

  return (
    <AccountRegistryContext.Provider value={defaultValues}>
      {children}
    </AccountRegistryContext.Provider>
  );
};
const useAccountRegistry = () => useContext(AccountRegistryContext);

export default useAccountRegistry;
