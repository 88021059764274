import React, { forwardRef, useContext } from 'react';
import * as S from '../style';
import MenuHeader from './MenuHeader';
import MenuNavSwitcher from './MenuNavSwitcher';
import MenuNavPrimary from './MenuNavPrimary';
import MenuFloatButton from './MenuFloatButton';
import MenuVisibilityContext from 'contexts/menuVisibility';

const MenuWrapper = forwardRef(
  ({ menuState, handleCloseMenu, onClickOverlay }, ref) => {
    const { onFooter } = useContext(MenuVisibilityContext);

    return (
      <S.Container menuState={menuState} ref={ref}>
        <MenuHeader />
        <MenuNavPrimary />
        <MenuNavSwitcher />
        <MenuFloatButton
          className={[onFooter() && 'onfooter']}
          onClick={handleCloseMenu}
        />
        <S.MenuOverlay onClick={onClickOverlay} />
      </S.Container>
    );
  }
);

export default MenuWrapper;
