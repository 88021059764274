export function forceStringCapitalize(value = '') {
  const valueLowerCase = value.toLowerCase() + '';
  const valueStringArray = valueLowerCase.split(' ');

  return valueStringArray
    .map((word) => {
      const wordInLowerCase = word.toLowerCase();

      if (
        wordInLowerCase === 'de' ||
        wordInLowerCase === 'dos' ||
        wordInLowerCase === 'da' ||
        wordInLowerCase === 'das'
      ) {
        return wordInLowerCase;
      }

      if (wordInLowerCase === 'pf' || wordInLowerCase === 'pj') {
        return word.toUpperCase();
      }

      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

export const clearWhitespace = (value) => `${value}`.replace(/\s+/g, '');

export const truncate = (str, limit) => {
  const word = `${str || '-'}`;
  const wordTruncated =
    word && word.length < limit ? word : word.substring(0, limit) + '...';

  return wordTruncated;
};
