import { MODAL_SEND_STATEMENTS_STEPS } from 'pages/BankStatement/shared/constants';

const STEPS = MODAL_SEND_STATEMENTS_STEPS;

export const SCHEDULE_UPDATE = [
  {
    id: STEPS.UPDATE_REVIEW,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.UPDATE_FILTER,
    skip: true,
    visible: true,
  },
  {
    id: STEPS.UPDATE_EMAIL,
    skip: true,
    visible: true,
  },
  {
    id: STEPS.UPDATE_FILE_TYPE,
    skip: true,
    visible: true,
  },
  {
    id: STEPS.UPDATE_DESCRIPTION,
    skip: true,
    visible: true,
  },
];
