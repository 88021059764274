import React from 'react';

import { Title } from './styles';

const MainTitle = ({ text, className, children, ...props }) => {
  return text ? (
    <Title
      className={className}
      {...props}
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  ) : (
    <Title className={className} {...props}>
      {children}
    </Title>
  );
};

export default MainTitle;
