import React from 'react';
import WizardSingle from 'containers/WizardSingle';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Route, Redirect } from 'react-router-dom';
import { useApplicationContext } from 'contexts/application';
import { PATHS } from 'Routes/constants';
import Loading from 'components/_v2/Loading';

const PrivateWizardSingle = ({ component: Component, ...rest }) => {
  const { isLoading } = useAuth0();

  const { loading, hasAccount } = useApplicationContext();

  if (loading) return <Loading />;

  return hasAccount ? (
    !isLoading && (
      <Route
        {...rest}
        render={(props) => (
          <WizardSingle {...props}>
            <Component {...props} />
          </WizardSingle>
        )}
      />
    )
  ) : (
    <Redirect to={PATHS.CALLBACK} />
  );
};

export default withAuthenticationRequired(PrivateWizardSingle, {
  onRedirecting: () => <Loading />,
});
