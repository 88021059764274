import { createSlice } from '@reduxjs/toolkit';

const functions = {
  setUserIdState(state, action: { type: '', payload: null }) {
    return { ...state, userId: action.payload };
  },
  setAccountIdState(state, action: { type: '', payload: null }) {
    return { ...state, accountId: action.payload };
  },
  setAccountTypeState(state, action: { type: '', payload: null }) {
    return { ...state, accountType: action.payload };
  },
};

const authorizationSlice = createSlice({
  name: 'authorization',
  initialState: {
    userId: null,
    accountId: null,
    accountType: null,
  },
  reducers: {
    ...functions,
  },
});

export const { setAccountIdState, setUserIdState, setAccountTypeState } =
  authorizationSlice.actions;
export default authorizationSlice.reducer;
