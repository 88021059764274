import { MODAL_SEND_STATEMENTS_STEPS as STEPS } from 'pages/BankStatement/shared/constants';
import { SCHEDULE_UPDATE } from './ScheduleUpdate';
import { SCHEDULE_REGISTER } from './ScheduleRegister';

const SCHEDULE_STEPS = [
  {
    id: STEPS.CONFIG,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.LIST_RECEIPTS,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.DELETE,
    skip: false,
    visible: true,
  },
  ...SCHEDULE_REGISTER,
  ...SCHEDULE_UPDATE,
];

export default SCHEDULE_STEPS;
