import { pageview } from 'services/analytics';

export const updateQueryString = (name = '', value = '') => {
  if ('URLSearchParams' in window) {
    const searchParams = new URLSearchParams();
    let newRelativePathQuery = window.location.pathname;

    if (name) {
      searchParams.set(name, value);
      newRelativePathQuery += '?' + searchParams.toString();
    }

    pageview(newRelativePathQuery);
    window.history.pushState(null, '', newRelativePathQuery);
  }
};
