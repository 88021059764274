import React from 'react';
import LineLogo from 'assets/images/ContbankLogoLine';

import { Container, Text } from './styles';
import { withTranslation } from 'react-i18next';

const Loading = ({ t, portability }) => {
  return (
    <Container>
      <LineLogo />
      <Text>
        {portability
          ? t('common:labels.processing', 'Processando solicitação')
          : t('common:labels.loading', 'Carregando')}
        ...
      </Text>
    </Container>
  );
};

export default withTranslation('common')(Loading);
