import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCan from 'hooks/useCan';
import { PATHS } from 'Routes/constants';
import { clearURL } from 'tools';
import UserAuthorizationContext from 'contexts/authorization';

const useMenu = () => {
  const { t } = useTranslation('menu');
  const [canRender, setCanRender] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  const [hasBank, setHasBank] = useState(false);
  const [menuContent, setMenuContent] = useState(null);
  const { canDo, loading } = useCan();
  const { userAuthorization } = useContext(UserAuthorizationContext);

  const getMenuData = useCallback(async () => {
    const userHasToken = hasToken;
    const hasBankAccount = hasBank;

    const content = await [
      {
        id: 1,
        location: PATHS.DASHBOARD,
        label: t('menu:dashboard', 'Dashboard'),
      },
      {
        id: 2,
        location: clearURL(PATHS.PROFILE),
        label: t('menu:my_profile', 'Meu perfil'),
        children: [
          {
            id: '1.1',
            location: PATHS.PROFILE,
            label: t('menu:submenus.profile.my_data', 'Meus dados'),
          },
          // {
          //   id: "1.2",
          //   location: PATHS.PRICELIST.LIST,
          //   label: t("menu.b", "Lista de Preços"),
          // },
          ...[
            userAuthorization?.business_agents?.length && {
              id: '1.3',
              location: PATHS.AGENT.PROFILE,
              label: t(
                'menu:submenus.profile.agent_profile',
                'Meu Perfil de agente'
              ),
            },
          ],
          ...[
            userAuthorization?.business_agents?.length > 1 && {
              id: '1.4',
              location: clearURL(PATHS.AGENT.SWITCH_AGENT_ACCOUNTS),
              label: 'Trocar perfil de agente',
            },
          ],

          // ...[
          //   canDo("read:backups", "business") === true && {
          //     id: "1.5",
          //     location: clearURL(PATHS.BACKUPS),
          //     label: t("menu:submenus.profile.backups"),
          //   },
          // ],
          // { id: "1.4", location: PATHS.NOROUTE, label: t("menu.b", "Empresas") },
          // {
          //   id: "1.6",
          //   location: PATHS.NOROUTE,
          //   label: t("menu.b", "Minha apresentação"),
          // },
        ],
      },
      ...[
        canDo('read:customers', 'business') === true && {
          id: 3,
          location: PATHS.CUSTOMERS,
          label: t('menu:customers', 'Clientes'),
          // label: "Clientes",
        },
      ],
      // {
      //   id: 4,
      //   location: PATHS.OPPORTUNITIES,
      //   label: t("menu.opportunities", "Oportunidades"),
      // },
      // {
      //   id: 5,
      //   location: PATHS.VISIBILITY,
      //   label: t("menu.visibility", "Visibilidade"),
      // },
      // {
      //   id: 6,
      //   location: PATHS.CONTRACTS,
      //   label: t("menu.contracts", "Contratos"),
      // },
      {
        id: 9,
        location: hasBankAccount ? PATHS.BANK.DASHBOARD : PATHS.ACCOUNT.CREATE,
        label: t('menu:contbank', 'Contbank'),
        children: [
          ...[
            hasBankAccount && {
              id: '9.0',
              location: PATHS.BANK.DASHBOARD,
              label: 'Meu Contbank',
            },
          ],
          {
            id: '9.1',
            location: PATHS.ACCOUNT.CREATE,
            label: t('menu:submenus.contbank.open_account', 'Abrir conta'),
          },
          ...[
            hasBankAccount &&
              canDo('read:transactions', 'bank') === true && {
                id: '9.2',
                location:
                  window.ReactNativeWebView && window.enableCamera
                    ? PATHS.PAYMENT.PAY_MOBILE
                    : PATHS.PAYMENT.PAY,
                label: t('menu:submenus.contbank.payments', 'Pagamentos'),
              },
          ],
          ...[
            hasBankAccount &&
              canDo('read:transactions', 'bank') === true && {
                id: '9.3',
                location: clearURL(PATHS.TRANSFER.SEND),
                label: t('menu:submenus.contbank.transfers', 'Transferências'),
              },
          ],
          ...[
            hasBankAccount && {
              id: '9.4',
              location: PATHS.CONTACTS.LIST,
              label: t('menu:submenus.contbank.contacts', 'Contatos'),
            },
          ],
          ...[
            hasBankAccount && {
              id: '9.5',
              location: PATHS.BANKSLIP.LIST,
              label: 'Boletos',
            },
          ],
          ...[
            hasBankAccount && {
              id: '9.6',
              location: PATHS.BANK.INCOME_REPORT,
              label: t(
                'menu:submenus.contbank.income_report',
                'Informe de rendimentos'
              ),
            },
          ],
          // ...[
          //   has_bank_account && {
          //     id: "9.6",
          //     location: PATHS.PAYER.LIST,
          //     label: t("menu:submenus.contbank.payers"),
          //   },
          // ],
          ...[
            hasBankAccount &&
              !userHasToken && {
                id: '9.7',
                location: PATHS.TRANSACTIONAL_PASSWORD.ADD,
                label: 'Cadastrar Senha',
              },
          ],
        ],
      },
      ...[
        canDo('read:backups', 'business') === true && {
          id: 8,
          location: PATHS.PREFERENCES,
          label: t('menu:configurations', 'Configurações'),
          children: [
            {
              id: '8.2',
              location: PATHS.PREFERENCES,
              label: t('menu:preferences', 'Preferências'),
            },
            // {
            //   id: "8.1",
            //   location: PATHS.PERMISSIONS,
            //   label: "Permissões",
            // },
            ...[
              canDo('read:technologies', 'business') === true && {
                id: '1.3',
                location: clearURL(PATHS.TECHNOLOGIES.LIST),
                label: t(
                  'menu:submenus.profile.cont_systems',
                  'Sistemas Contábeis'
                ),
              },
            ],
            ...[
              canDo('read:technologies', 'business') === true && {
                id: '1.3.1',
                location: clearURL(PATHS.TECHNOLOGIES.LIST_NF),
                label: t(
                  'menu:submenus.profile.invoices',
                  'Sistemas de NF/ERP’s'
                ),
              },
            ],
          ],
        },
      ],
    ];

    setMenuContent(content);
    setCanRender(false);
  }, [canDo, t, hasBank, hasToken, userAuthorization]);

  useEffect(() => {
    let mounted = true;

    if (mounted && !loading && canRender) {
      getMenuData();
    }
    return () => (mounted = false);
  }, [loading, canRender, getMenuData, menuContent]);

  const getMenus = (userHasToken, hasBankAccount) => {
    setHasToken(userHasToken);
    setHasBank(hasBankAccount);
    setCanRender(true);
  };

  return {
    getMenus,
    menuContent,
  };
};

export default useMenu;
