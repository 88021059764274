import useCan from 'hooks/useCan';
import React, { useEffect, useState } from 'react';
import PrivateLayouted from 'Routes/components/PrivateLayouted';
import PrivateWizardSingle from 'Routes/components/PrivateWizardSingle';
import { PATHS } from 'Routes/constants';

const Clients = React.lazy(() => import('pages/Clients'));
const Balance = React.lazy(() => import('pages/Balance'));
const BalanceSheet = React.lazy(() => import('pages/BalanceSheet'));
const Statements = React.lazy(() => import('pages/Statements'));
const AccountChart = React.lazy(() => import('pages/AccountChart'));

export const ClientsRoutes = () => {
  const { permissions, businessPermissions, loading, canDo } = useCan();
  const [canSeeCustomers, setCanSeeCustomers] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (mounted && !loading) {
      setCanSeeCustomers(canDo('read:customers', 'business'));
    }
    return () => (mounted = false);
  }, [permissions, canDo, loading, businessPermissions]);

  if (!canSeeCustomers) return [];

  return [
    <PrivateLayouted
      component={Clients}
      exact
      path={PATHS.CLIENTS.LIST}
      key={PATHS.CLIENTS.LIST}
    />,
    <PrivateWizardSingle
      component={Clients}
      exact
      path={PATHS.CLIENTS.VIEW}
      key={PATHS.CLIENTS.VIEW}
    />,
    ...[
      canDo('write:customers', 'business') && (
        <PrivateWizardSingle
          component={Clients}
          exact
          path={PATHS.CLIENTS.NEW_FILE}
          key={PATHS.CLIENTS.NEW_FILE}
        />
      ),
    ],
    ...[
      canDo('read:customers', 'business') && (
        <PrivateWizardSingle
          component={Clients}
          exact
          path={PATHS.CLIENTS.FILES}
          key={PATHS.CLIENTS.FILES}
        />
      ),
    ],
    ...[
      canDo('write:customers', 'business') && (
        <PrivateWizardSingle
          component={Clients}
          exact
          path={PATHS.CLIENTS.EDIT_FILE}
          key={PATHS.CLIENTS.EDIT_FILE}
        />
      ),
    ],
    ...[
      canDo('write:customers', 'business') && (
        <PrivateWizardSingle
          component={Clients}
          exact
          path={PATHS.CLIENTS.EDIT}
          key={PATHS.CLIENTS.EDIT}
        />
      ),
    ],
    ...[
      canDo('write:customers', 'business') && (
        <PrivateWizardSingle
          component={Clients}
          exact
          path={PATHS.CLIENTS.ADD}
          key={PATHS.CLIENTS.ADD}
        />
      ),
    ],
    ...[
      canDo('write:customers', 'business') && (
        <PrivateWizardSingle
          component={Clients}
          exact
          path={PATHS.CLIENTS.EDIT_FILE}
          key={PATHS.CLIENTS.EDIT_FILE}
        />
      ),
    ],
    ...[
      canDo('write:customers', 'business') && (
        <PrivateWizardSingle
          component={Clients}
          exact
          path={PATHS.CLIENTS.MEMBER.EDIT_FILE}
          key={PATHS.CLIENTS.MEMBER.EDIT_FILE}
        />
      ),
    ],
    ...[
      canDo('write:customers', 'business') && (
        <PrivateWizardSingle
          component={Clients}
          exact
          path={PATHS.CLIENTS.MEMBER.EDIT}
          key={PATHS.CLIENTS.MEMBER.EDIT}
        />
      ),
    ],
    ...[
      canDo('write:customers', 'business') && (
        <PrivateWizardSingle
          component={Clients}
          exact
          path={PATHS.CLIENTS.MEMBER.INVITE}
          key={PATHS.CLIENTS.MEMBER.INVITE}
        />
      ),
    ],
    ...[
      canDo('read:customers', 'business') && (
        <PrivateWizardSingle
          component={Clients}
          exact
          path={PATHS.CLIENTS.MEMBER.FILES}
          key={PATHS.CLIENTS.MEMBER.FILES}
        />
      ),
    ],
    ...[
      canDo('read:standard-chart-of-accounts', 'business') && (
        <PrivateLayouted
          component={AccountChart}
          exact
          path={PATHS.ACCOUNT_CHART}
          key={PATHS.ACCOUNT_CHART}
        />
      ),
    ],
    <PrivateLayouted
      component={Balance}
      exact
      path={PATHS.BALANCE}
      key={PATHS.BALANCE}
    />,
    <PrivateLayouted
      component={BalanceSheet}
      exact
      path={PATHS.BALANCE_SHEET}
      key={PATHS.BALANCE_SHEET}
    />,
    ...[
      canDo('read:statement', 'business') && (
        <PrivateLayouted
          component={Statements}
          exact
          path={PATHS.STATEMENTS}
          key={PATHS.STATEMENTS}
        />
      ),
    ],
  ];
};
