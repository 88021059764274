import styled from 'styled-components';
import Configs from 'styles/variables';

export const AvatarIcon = styled.div.attrs((props) => ({
  size: props.big ? 100 : 40,
  font: props.big ? 48 : 16,
}))`
  background: center center no-repeat #d8dfe4;
  background-image: url(${(props) => (props.image ? props.image : null)});
  background-size: contain;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: ${(props) => props.size}px;
  margin-bottom: 18px;
  text-align: center;
  font-size: ${(props) => props.font}px;
  font-weight: 900;
  line-height: 1;
  letter-spacing: -0.22px;
  text-align: center;
  color: #ffffff;
  line-height: ${(props) => props.size}px;
  text-transform: uppercase;
  ${({ yellow }) => yellow && `background-color: ${Configs.yellow};`}
  ${({ yellow }) => yellow && `color: ${Configs.darkText};`}
`;
