import React from 'react';

const BriefcaseIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.11111 0H10.8889C11.2264 0 11.5053 0.250803 11.5495 0.576204L11.5556 0.666667V3.55556H13.1111C14.7066 3.55556 16 4.84895 16 6.44444V12.2222C16 13.8177 14.7066 15.1111 13.1111 15.1111H2.88889C1.2934 15.1111 0 13.8177 0 12.2222V6.44444C0 4.84895 1.2934 3.55556 2.88889 3.55556H4.44444V0.666667C4.44444 0.329159 4.69525 0.0502303 5.02065 0.00608593L5.11111 0H10.8889H5.11111ZM13.1111 4.88889H2.88889C2.02978 4.88889 1.33333 5.58533 1.33333 6.44444V12.2222C1.33333 13.0813 2.02978 13.7778 2.88889 13.7778H13.1111C13.9702 13.7778 14.6667 13.0813 14.6667 12.2222V6.44444C14.6667 5.58533 13.9702 4.88889 13.1111 4.88889ZM10.2222 1.33333H5.77778V3.55556H10.2222V1.33333Z" />
    </svg>
  );
};

export default BriefcaseIcon;
