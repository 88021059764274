import { useCallback, useLayoutEffect, useRef } from 'react';
import { createTextMaskInputElement } from 'text-mask-core';

export default function useMaskedInput({
  guide,
  input,
  keepCharPositions,
  mask,
  onChange,
  pipe,
  placeholderChar,
  showMask,
  value = '',
}) {
  let textMask = useRef();

  const init = useCallback(() => {
    if (!input?.current) return;

    textMask.current = createTextMaskInputElement({
      guide,
      inputElement: input.current,
      keepCharPositions,
      mask,
      pipe,
      placeholderChar,
      showMask,
    });
    textMask.current.update(value);
  }, [
    guide,
    keepCharPositions,
    mask,
    pipe,
    placeholderChar,
    showMask,
    value,
    input,
  ]);

  useLayoutEffect(init, [
    guide,
    keepCharPositions,
    mask,
    pipe,
    placeholderChar,
    showMask,
    value,
    input,
    init,
  ]);

  useLayoutEffect(() => {
    if (value === input?.current?.value) return;
    init();
  }, [value, input, init]);

  return (event) => {
    if (textMask.current) {
      textMask.current.update();
    }

    if (typeof onChange === 'function') {
      onChange(event);
    }
  };
}
