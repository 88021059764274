import React, { useContext } from 'react';
import { isMobile } from 'react-device-detect';
import * as S from './style';
import { MenuVisibilityContext } from 'contexts/menuVisibility';
import { forceStringCapitalize, truncate } from 'tools/string';

export default function CardSwitcher({
  icon,
  iconColor,
  iconSize,
  size,
  label,
  description,
}) {
  const { switcherIsOpen, handleCloseSwitcher } = useContext(
    MenuVisibilityContext
  );

  return (
    <S.CardSwitcher
      iconColor={iconColor}
      iconSize={iconSize}
      size={size}
      onClick={isMobile ? handleCloseSwitcher : undefined}
    >
      <div>
        <span className="icon">{icon}</span>
        <div className="info">
          <p>{forceStringCapitalize(truncate(label, 20))}</p>
          <span>{description}</span>
        </div>
      </div>
      <button onClick={handleCloseSwitcher}>
        {switcherIsOpen && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 15l7-7 7 7"
            />
          </svg>
        )}

        {!switcherIsOpen && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        )}
      </button>
    </S.CardSwitcher>
  );
}
