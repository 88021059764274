import React from 'react';

const ShowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <path d="M 11.83344,6.9245256 C 8.0514089,7.0877356 4.4193102,8.9143226 0.5253042,11.816262 L 0,12.205756 0.3920563,12.733623 c 1.8189423,2.447667 3.0806,3.14045 5.9910312,4.353619 L 6.8878922,15.875199 C 4.3213546,14.805376 3.3439098,14.196097 1.950032,12.474814 5.4750349,9.9465616 8.6968629,8.3744046 11.892376,8.2365046 c 3.240503,-0.139841 6.59133,1.193115 10.421524,4.2741844 -0.216003,0.334666 -0.541291,0.803826 -1.247917,1.355541 -0.882605,0.689113 -1.997218,1.372686 -2.959642,1.839847 l 0.573991,1.181293 c 1.056148,-0.512654 2.223125,-1.231353 3.192826,-1.988468 0.969701,-0.757118 1.77356,-1.462448 2.019218,-2.390775 L 24,12.10582 23.682254,11.839324 C 19.460636,8.2906476 15.615471,6.7613156 11.83344,6.9245256 Z m 0.46893,1.624599 a 4.2193189,3.982571 0 0 0 -1.711724,0.345933 1.6726883,1.5145597 0 0 1 1.660475,1.5144134 1.6726883,1.5145597 0 0 1 -1.670724,1.514414 1.6726883,1.5145597 0 0 1 -1.6732861,-1.514414 1.6726883,1.5145597 0 0 1 0.02819,-0.269059 4.2193189,3.982571 0 0 0 -0.8533,2.390777 4.2193189,3.982571 0 0 0 4.2203721,3.982062 4.2193189,3.982571 0 0 0 4.220372,-3.982062 4.2193189,3.982571 0 0 0 -4.220372,-3.9820644 z" />
      </g>
    </svg>
  );
};

export default ShowIcon;
