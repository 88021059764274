import api from 'services/api';
import useToken from 'hooks/useToken';
import { useContext } from 'react';
import UserAuthorizationContext from 'contexts/authorization';

const useCustomersService = (token) => {
  const serviceToken = useToken();
  const { getCurrentID } = useContext(UserAuthorizationContext);

  const getCustomer = async (id, localToken) =>
    await api.get(`/customers/${id}`, {
      headers: {
        Authorization: `Bearer ${localToken || token || serviceToken}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const saveCustomer = async (id, data) =>
    await api.put(`/customers/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const addCustomer = async (data) =>
    await api.post(`/customers`, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const getList = async (page, filter, tk) => {
    if (token || tk) {
      return await api.get(
        `/customers?page=${page}&per_page=10${filter ? `&text=${filter}` : ''}`,
        {
          headers: {
            Authorization: `Bearer ${token || tk || serviceToken}`,
            'X-Current-Identity': `${getCurrentID()}`,
          },
        }
      );
    }
    return null;
  };

  const updateFile = async (id, fileId, data) =>
    await api.put(`/customers/${id}/files/${fileId}`, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const activateCustomer = async (id, data) =>
    await api.patch(`/customers/${id}/activate`, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const deactivateCustomer = async (id, data) =>
    await api.patch(`/customers/${id}/deactivate`, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const sendFile = async (id, data) =>
    await api.post(`/customers/${id}/files`, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const getFiles = async (id, localToken) =>
    await api.get(`/customers/${id}/files?page=1&per_page=10`, {
      headers: {
        Authorization: `Bearer ${localToken || token || serviceToken}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const sendInvite = async (id, data) =>
    await api.post(`/customers/${id}/invite`, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  return {
    getList,
    getCustomer,
    sendFile,
    getFiles,
    updateFile,
    saveCustomer,
    activateCustomer,
    deactivateCustomer,
    addCustomer,
    sendInvite,
  };
};

export default useCustomersService;
