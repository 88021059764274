import WizardBasicHeader from 'components/WizardBasicHeader';
import React from 'react';

import { Container, Close } from './styles';

const WizardBasicContainer = ({
  className,
  closeAction,
  goBackAction,
  children,
  withHeader,
  headerRightButtom,
  ...props
}) => (
  <Container {...props} className={className}>
    {withHeader && (
      <WizardBasicHeader
        actionClose={closeAction}
        actionGoBack={goBackAction}
        rightButtom={headerRightButtom}
      />
    )}
    {closeAction && !withHeader && (
      <Close className="open close-button-container" onClick={closeAction} />
    )}
    {children}
  </Container>
);

export default WizardBasicContainer;
