import React from 'react';

const HydeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g>
        <path d="M 21.521484 1.5722656 L 15.720703 7.3730469 C 14.410037 7.0255863 13.117844 6.8684244 11.833984 6.9238281 C 8.0519533 7.0870381 4.4193966 8.9144669 0.52539062 11.816406 L 0 12.205078 L 0.39257812 12.734375 C 2.149242 15.098237 3.4168529 15.832863 6.1210938 16.972656 L 2.6152344 20.478516 L 4.0292969 21.892578 L 10.033203 15.888672 A 4.2193189 3.982571 0 0 0 12.302734 16.513672 A 4.2193189 3.982571 0 0 0 16.523438 12.53125 A 4.2193189 3.982571 0 0 0 15.720703 10.203125 L 16.789062 9.1328125 C 18.539425 9.8315339 20.364254 10.940994 22.314453 12.509766 C 22.09845 12.844432 21.773032 13.315473 21.066406 13.867188 C 20.183801 14.556301 19.067893 15.23987 18.105469 15.707031 L 18.679688 16.886719 C 19.735836 16.374065 20.903346 15.655552 21.873047 14.898438 C 22.842748 14.141319 23.64692 13.436139 23.892578 12.507812 L 24 12.105469 L 23.681641 11.839844 C 21.652863 10.134461 19.71389 8.9065893 17.824219 8.0976562 L 22.935547 2.9882812 L 21.521484 1.5722656 z M 11.892578 8.2363281 C 12.789132 8.1976382 13.695928 8.2839457 14.617188 8.4765625 L 14.142578 8.9511719 A 4.2193189 3.982571 0 0 0 12.302734 8.5488281 A 4.2193189 3.982571 0 0 0 10.589844 8.8945312 A 1.6726883 1.5145597 0 0 1 12.251953 10.410156 A 1.6726883 1.5145597 0 0 1 12.136719 10.957031 L 11.332031 11.761719 A 1.6726883 1.5145597 0 0 1 10.580078 11.923828 A 1.6726883 1.5145597 0 0 1 8.90625 10.410156 A 1.6726883 1.5145597 0 0 1 8.9355469 10.140625 A 4.2193189 3.982571 0 0 0 8.0820312 12.53125 A 4.2193189 3.982571 0 0 0 8.6210938 14.472656 L 6.6523438 16.441406 L 6.8886719 15.875 C 4.3221343 14.805177 3.3430965 14.195892 1.9492188 12.474609 C 5.4742216 9.946357 8.697065 8.3742281 11.892578 8.2363281 z " />
      </g>
    </svg>
  );
};

export default HydeIcon;
