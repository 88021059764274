import api from 'services/api';

import { useContext } from 'react';
import UserAuthorizationContext from 'contexts/authorization';
import useToken from 'hooks/useToken';

const useUserService = (token) => {
  const serviceToken = useToken();
  const { getCurrentID } = useContext(UserAuthorizationContext);

  const postCreateRegistration = async (user, xToken, localToken) =>
    await api.post('/registrations', user, {
      headers: {
        Authorization: `Bearer ${localToken || serviceToken}`,
        'X-Token': `${xToken}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const putUpdateRegistration = async (
    identifier,
    data,
    xToken,
    localToken
  ) => {
    await api.put(`/registrations/${identifier}`, data, {
      headers: {
        Authorization: `Bearer ${localToken || serviceToken || token}`,
        'X-Token': `${xToken}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });
  };

  const putUpdateRegistrationAddress = async (
    identifier,
    data,
    xToken,
    localToken
  ) => {
    await api.put(`/registrations/${identifier}/address`, data, {
      headers: {
        Authorization: `Bearer ${localToken || serviceToken || token}`,
        'X-Token': `${xToken}`,
        'X-Current-Identity': `${identifier || getCurrentID()}`,
      },
    });
  };

  const save = async (user, xToken) =>
    await api.post('/users', user, {
      headers: {
        Authorization: `Bearer ${serviceToken || token}`,
        'X-Token': `${xToken}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const addRole = async (id, role) =>
    await api.post(`/users/${id}/roles`, role, {
      headers: {
        Authorization: `Bearer ${serviceToken || token}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const getUser = async (id) =>
    await api.get(`/users/${id}`, {
      headers: {
        Authorization: `Bearer ${serviceToken || token}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const getUserCredentials = async () =>
    await api.get(`/credentials?type=auth0`, {
      headers: {
        Authorization: `Bearer ${serviceToken || token}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const getRegister = async (localToken) =>
    await api.get(`/registrations`, {
      headers: {
        Authorization: `Bearer ${localToken || serviceToken || token}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const getAuth = async (localToken) => {
    return await api.get(`/authorization`, {
      headers: {
        Authorization: `Bearer ${token || localToken}`,
      },
    });
  };

  return {
    postCreateRegistration,
    putUpdateRegistration,
    putUpdateRegistrationAddress,
    save,
    addRole,
    getUser,
    getUserCredentials,
    getRegister,
    getAuth,
  };
};

export default useUserService;
