import React from 'react';

export const ContbankLogoFigma = (props) => {
  return (
    <svg
      width="108"
      height="92"
      viewBox="0 0 108 92"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M79.8078 54.5134C79.8078 54.5134 75.749 60.7633 66.6161 60.7633C57.4832 60.7633 51.6205 53.9745 51.6205 45.9998C51.6205 38.0255 57.4832 31.2361 66.6161 31.2361C74.734 31.2361 79.1313 36.6243 79.1313 36.6243L88.4892 28.65C88.4892 28.65 80.9349 19.1665 66.6161 19.1665C55.5812 19.1665 46.0999 24.7473 41.2725 33.1554H24.7041L22.8796 41.8066H38.2009C37.98 43.1698 37.865 44.5695 37.865 45.9998C37.865 47.4285 37.9817 48.8288 38.2053 50.1933H21.1102L19.2856 58.8446H41.2992C46.135 67.2227 55.6018 72.8332 66.6158 72.8332C81.0476 72.8332 88.7142 63.1344 88.7142 63.1344L79.8078 54.5134Z" />
    </svg>
  );
};
