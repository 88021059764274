import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useNewMenuContext, { MENU_TYPE } from 'contexts/new-menu';
import { toAccountNumber } from 'tools';
import { forceStringCapitalize, truncate } from 'tools/string';
import CompanyIcon from 'assets/images/CompanyIcon';
import PersonIcon from 'assets/images/PersonIcon';
import Configs from 'styles/variables';
import AccountInfoWithAvatar from './AccountInfoWithAvatar';
import BriefcaseIcon from 'assets/images/BriefcaseIcon';

const MenuNavPrimaryAccountInfo = ({ account, agent, isBusinessAccount }) => {
  const { t } = useTranslation('menu');
  const { menuView } = useNewMenuContext();

  const bankIcon = useMemo(
    () =>
      isBusinessAccount ? (
        <CompanyIcon fill={Configs.newColors.n200} />
      ) : (
        <PersonIcon fill={Configs.newColors.n200} />
      ),
    [isBusinessAccount]
  );

  const avatarDescription = useMemo(
    () =>
      isBusinessAccount
        ? t('menu:new.labels.account_pj', {
            value: toAccountNumber(account?.information?.number),
          })
        : t('menu:new.labels.account_pf'),
    [account, isBusinessAccount, t]
  );

  if (menuView === MENU_TYPE.BANK) {
    return (
      <AccountInfoWithAvatar
        icon={bankIcon}
        label={forceStringCapitalize(
          truncate(account?.business_name || account?.register_name, 60)
        )}
        description={avatarDescription}
        className={'active'}
        to="/contbank"
      />
    );
  }

  return (
    <AccountInfoWithAvatar
      icon={<BriefcaseIcon fill={Configs.newColors.n200} />}
      label={forceStringCapitalize(truncate(agent?.name, 56))}
      description={t('menu:new.contbank_agent', 'Agente Contbank')}
      className={'active'}
      to="/"
    />
  );
};

export default MenuNavPrimaryAccountInfo;
