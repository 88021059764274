export const ONBOARDING_ACTIONS = {
  CREATE_USER: 'CREATE_USER',
  UPDATE_USER: 'UPDATE_USER',
  VERIFY_USER_EMAIL: 'VERIFY_USER_EMAIL',
  NEXT_STEP: 'NEXT_STEP',
  PREVIOUS_STEP: 'PREVIOUS_STEP',
  PREVIOUS_STEP_NO_SKIP: 'PREVIOUS_STEP_NO_SKIP',
  GO_TO_INITIAL_STEP: 'GO_TO_INITIAL_STEP',
  CLOSE: 'CLOSE',
  OPEN_TERMS: 'OPEN_TERMS',
  GO_TO_CONFIRM: 'GO_TO_CONFIRM',
  OPEN_WHAT_IS_PARTNER_QUALIFICATION: 'OPEN_WHAT_IS_PARTNER_QUALIFICATION',
  VERIFY_TRADING_NAME: 'VERIFY_TRADING_NAME',
  CLOSE_TERMS: 'CLOSE_TERMS',
  ACCEPT_TERMS: 'ACCEPT_TERMS',
  VALIDATE_ONBOARDING: 'VALIDATE_ONBOARDING',
  VERIFY_PJ_ONBOARDING: 'VERIFY_PJ_ONBOARDING',
  VERIFY_TYPE_PERSON: 'VERIFY_TYPE_PERSON',
  RATE: 'RATE',
  NEXT_STEP_NO_SKIP: 'NEXT_STEP_NO_SKIP',

  EDIT_PARTNER: 'EDIT_PARTNER',
  GO_MANAGE_PARTNER: 'GO_MANAGE_PARTNER',
  CORPORATION_SEND_PHOTOS: 'CORPORATION_SEND_PHOTOS',
  CORPORATION_SUCCESS_SENDED_DOCUMENTS: 'CORPORATION_SUCCESS_SENDED_DOCUMENTS',
  CORPORATION_TO_GO_BUSINESS: 'CORPORATION_TO_GO_BUSINESS',
};

export const ONBOARDING_BUSINESS_CODE_ERROR = {
  INVALID_COMPANIES_STATUS: 'INVALID_COMPANIES_STATUS',
  ONBOARDING_NOT_ALLOWED: 'ONBOARDING_NOT_ALLOWED',
  COMPANIES_NOT_FOUND: 'COMPANIES_NOT_FOUND',
  LEGAL_NATURE_NOT_ALLOWED: 'LEGAL_NATURE_NOT_ALLOWED',
  INVALID_DOCUMENT_ANALYSIS_STATUS: 'INVALID_DOCUMENT_ANALYSIS_STATUS',
  ACCOUNT_ALREADY_CONFIRMED: 'ACCOUNT_ALREADY_CONFIRMED',
  INVALID_LEGAL_REPRESENTATIVES_FOR_BUSINESS_TYPE:
    'INVALID_LEGAL_REPRESENTATIVES_FOR_BUSINESS_TYPE',
  INVALID_ONBOARDING_STATUS: 'INVALID_ONBOARDING_STATUS',
  WAITING_ANALYSIS_ACCOUNT: 'WAITING',
  INVALID_CORPORATE_MEMBERSHIP: 'INVALID_CORPORATE_MEMBERSHIP',
  REGISTRATIONS_API_UNKNOWN_ERR: 'REGISTRATIONS_API_UNKNOWN_ERR',
  ACCOUNTS_API_CONFIRM_ERR: 'ACCOUNTS_API_CONFIRM_ERR',
  REQUIRED_FIELD_EMPTY: 'REQUIRED_FIELD_EMPTY',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
};

export const ONBOARDING_SHEETS = Object.freeze({
  NONE: 'NONE',
  PEP: 'ONBOARDING-PEP',
  SOCIAL_NAME: 'ONBOARDING-SOCIAL-NAME',
  TERM_OF_USE: 'ONBOARDING-TERM-OF-USE',
  TERM_OF_USE_CORPORATION: 'TERM_OF_USE_CORPORATION',
  PARTNER_QUALIFICATION: 'ONBOARDING-PARTNER_QUALIFICATION',
});

export const ONBOARDING_UPLOAD_FILE_NAME = {
  ESTATUTO: 'ESTATUTO',
  ELEICAO: 'ELEICAO',
  CONTRATO_SOCIAL: 'CONTRATO_SOCIAL',
  ALTERACAO_CONTRATUAL: 'ALTERACAO_CONTRATUAL',
  BALANCO: 'BALANCO',
  PROCURACAO: 'PROCURACAO',
};

export const ONBOARDING_DOCUMENT_TYPE = {
  RG: 'RG',
  CNH: 'CNH',
};

export const ONBOARDING_PEP_TYPES = {
  NONE: 'NONE',
  SELF: 'SELF',
  RELATED: 'RELATED',
};

export const ONBOARDING_DOC_TYPES = {
  SELFIE: 'selfie',
  CNH: 'cnh',
  RG: 'rg',
};

export const ONBOARDING_DOC_SIDES = {
  FRONT: 'front',
  BACK: 'back',
};

export const ONBOARDING_PHONE_TYPE = {
  PHONE: 'PHONE',
  CELLPHONE: 'CELLPHONE',
};

export const ONBOARDING_ACCOUNT_TYPES = {
  PJ: 'BUSINESS',
  PF: 'PERSONAL',
  COLABORATOR: 'COLABORATOR',
  INVITATION: 'INVITATION',
};

export const ONBOARDING_BUSINESS_TYPE = {
  PARTNER: 'PARTNER',
  EMPLOYEE: 'EMPLOYEE',
  OTHER: 'OTHER',
};

export const ONBOARDING_COMPANY_TYPE = {
  MEI: 'MEI',
  EI: 'EI',
  EIRELI: 'EIRELI',
  LTDA: 'LTDA',
  SA: 'SA',
};

export const ONBOARDING_SIZE_COMPANY = {
  MEI: 'MEI',
  ME: 'ME',
  EPP: 'EPP',
  MEDIO_PORTE: 'medio-porte',
  GRANDE_PORTE: 'grande-porte',
  DEMAIS: 'demais',
};

export const ONBOARDING_BUSINESS_SUBTYPE = {
  SMALL: 'SMALL',
  CORPORATION: 'CORPORATION',
};

export const ONBOARDING_STEPS_TYPES = {
  IDENTIFIER: 'identifier',
  BUSINESS_IDENTIFIER: 'business_identifier',
  CPF_CNPJ: 'cpf-cnpj',
  NAME: 'name',
  SELECT_PEP: 'select-pep',
  SOCIAL_NAME: 'social-name',
  PHONE: 'phone',
  EMAIL: 'email',
  DATE_OF_BIRTH: 'date-of-birth',
  SELECT: 'select',
  SUCCESS: 'success',
  AUTHORIZATION: 'authorization',
  MANAGE_PARTNERS: 'manage_partners',
  CURRENCY: 'currency',
  PERCENT: 'percent',
  ATTENTION: 'attention',
  ADDRESS: 'address',
  CONFIRM: 'confirm',
  CONFIRM_BUSINESS: 'confirm-business',
  CONFIRM_CORPORATION: 'confirm-corporation',
  ERROR_BUSINESS_UPDATE: 'error-business-update',
  SUCCESS_PHOTOS: 'success-photos',
  DOCUMENT_TYPE: 'document-type',
  BUSINESS_TYPE: 'business-type',
  COMPANY_TYPE: 'company-type',
  SIZE_COMPANY: 'size-company',
  TIPS: 'tips',
  DOCUMENT: 'document',
  SELFIE: 'selfie',
  LOADING: 'loading',
  PHOTOS_INVALIDS: 'photos-invalids',
  SELFIE_INVALID: 'selfie-invalid',
  DOCUMENTS_INVALIDS: 'documents-invalids',
  PHONE_SMS: 'phone-sms',
  TOKEN_SMS: 'token-sms',
  SUCCESS_ACCOUNT: 'success-account',
  REPROVED: 'reproved-account',
  PASSWORD: 'password',
  UPLOAD_PJ_DOCUMENT: 'upload-pj-document',
};

export const ONBOARDING_STEPS = {
  IDENTIFIER: 'identifier',
  BUSINESS_IDENTIFIER: 'business_identifier',
  NAME: 'name',
  SELECT_PEP: 'select-pep',
  SOCIAL_NAME: 'social-name',
  MOTHER_NAME: 'mother-name',
  EMAIL: 'email',
  PHONE: 'phone',
  SELECT_AGENT: 'select-agent',
  DATE_OF_BIRTH: 'date-of-birth',
  OCUPATION: 'ocupation',

  INCOME: 'income',
  ADDRESS: 'address',
  DOCUMENT_TYPE: 'document-type',
  DOCUMENT_TIPS: 'document-tips',
  DOCUMENT_FRONT: 'document-front',
  DOCUMENT_BACK: 'document-back',
  SELFIE_TIPS: 'selfie-tips',
  LOADING_WAIT_VALIDATE_EMAIL: 'loading-wait-validate-email',
  LOADING_WAIT_SEND_PHOTO: 'loading-wait-send-photo',
  LOADING_WAIT_USER_UPDATE: 'loading-wait-user-update',
  LOADING_WAIT_BUSINESS_UPDATE: 'loading-wait-business-update',
  LOADING_WAIT_BUSINESS_VERIFY: 'loading-wait-business-verify',
  LOADING_WAIT_ACCOUNT_CREATION: 'loading-wait-account-creation',
  LOADING_WAIT_VALIDATE_BUSINESS: 'loading-wait-validate-business',
  LOADING_WAIT_STRUCTURE_BUSINESS: 'loading-wait-structure-business',
  // LOADING_WAIT_USER_CREATION: 'loading-wait-user-creation',
  ERROR_USER_UPDATE: 'error-user-update',
  ERROR_BUSINESS_UPDATE: 'error-business-update',
  ERROR_USER_CREATE: 'error-user-create',
  ERROR_INVALID_COMPANIES_STATUS: 'error_invalid_companies_status',
  ERROR_ONBOARDING_NOT_ALLOWED: 'error_onboarding_not_allowed',
  ERROR_COMPANIES_NOT_FOUND: 'error_companies_not_found',
  ERROR_LEGAL_NATURE_NOT_ALLOWED: 'error_legal_nature_not_allowed',
  PF_ERROR_ACCEPT_TERM: 'PF_ERROR_ACCEPT_TERM',
  LOADING: 'loading',
  CONFIRM: 'confirm',
  CONFIRM_BUSINESS: 'confirm-business',
  VALIDATE_EMAIL: 'validate-email',
  SUCCESS_VALIDATE_EMAIL: 'success-validate-email',
  ERROR_PHOTOS: 'error-photos',
  ERROR_SELFIE: 'error-selfie',
  ERROR_DOCUMENTS: 'error-documents',
  ERROR_USER: 'error-user',
  ERROR_INSTABILITY: 'error-instability',
  ERROR_INSTABILITY_BUSINESS_VERIFY: 'error-instability-business-verify',
  ERROR_VALIDATE_BUSINESS: 'error-validate-business',
  SUCCESS_SAVED_USER: 'success-saved-user',
  AUTHORIZATION_CAMERA: 'authorization-camera',
  SUCCESS_SAVED_PARTNER: 'success-saved-partner',
  SUCCESS_SAVED_BUSINESS: 'success-saved-business',
  SUCCESS_PJ_SAVED_USER: 'success-pj-saved-user',
  SUCCESS_SENDED_DOCUMENTS: 'success-sended-documents',
  ANALYSING_ACCOUNT: 'analysing-account',
  SELFIE: 'selfie',
  PHONE_SMS: 'phone-sms',
  SUCCESS_OPENED_ACCOUNT: 'success-opened-account',
  REPROVED_ACCOUNT: 'reproved-account',
  PASSWORD: 'password',
  PASSWORD_CONFIRMATION: 'password-confirmation',
  VERIFY_PJ_IDENTIFIER: 'business-pj-identifier',
  BUSINESS_FANTASY_NAME: 'business-fantasy-name',
  BUSINESS_EMAIL: 'business-email',
  BUSINESS_NAME: 'business-name',
  TRADING_NAME: 'trading-name',
  BUSINESS_CURRENCY: 'business-currency',
  BUSINESS_ADDRESS: 'business-address',
  BUSINESS_PHONE: 'business-phone',
  COMPANY_TYPE: 'company-type',
  SIZE_COMPANY: 'size-company',
  WAITING_ANALYSIS_ACCOUNT: 'waiting-analysis-account',
  MANAGE_PARTNERS: 'manage_partners',
  //////
  CORPORATION_PERSONAL_NAME: 'corporation_personal_name',
  CORPORATION_PERSONAL_SOCIAL_NAME: 'corporation_personal_social_name',
  CORPORATION_PERSONAL_SELECT_PEP: 'corporation_personal_select_pep',
  CORPORATION_PERSONAL_MOTHER_NAME: 'corporation_personal_mother_name',
  CORPORATION_PERSONAL_EMAIL: 'corporation_personal_email',
  CORPORATION_PERSONAL_PHONE: 'corporation_personal_phone',
  CORPORATION_PERSONAL_DATE_OF_BIRTH: 'corporation_personal_date_of_birth',
  CORPORATION_PERSONAL_OCUPATION: 'corporation_personal_ocupation',
  CORPORATION_PERSONAL_INCOME: 'corporation_personal_income',
  CORPORATION_PERSONAL_QUALIFICATION: 'corporation_personal_qualification',
  CORPORATION_PERSONAL_PERCENT_PARTICIPATION_COMPANY:
    'corporation_personal_percent_participation_company',
  CORPORATION_PERSONAL_ADDRESS: 'corporation_personal_address',
  CORPORATION_PERSONAL_CONFIRM: 'corporation_personal_confirm',
  CORPORATION_PERSONAL_LOADING_SENDING: 'corporation_personal_loading_sending',
  CORPORATION_PERSONAL_ERROR_PARTNER_UPDATE:
    'corporation_personal_error_partner_update',
  CORPORATION_PERSONAL_SUCCESS_SAVED_PARTNER:
    'corporation_personal_saved_partner',

  CORPORATION_BUSINESS_NAME: 'corporation_business_name',
  CORPORATION_TRADING_NAME: 'corporation_business_trading_name',
  CORPORATION_BUSINESS_EMAIL: 'corporation_business_email',
  CORPORATION_BUSINESS_PHONE: 'corporation_business_phone',
  CORPORATION_BUSINESS_MONTHLY_CURRENCY:
    'corporation_business_monthly_currency',
  CORPORATION_BUSINESS_ANNUAL_CURRENCY: 'corporation_business_annual_currency',
  CORPORATION_BUSINESS_PERCENT_PARTICIPATION_COMPANY:
    'corporation_business_percent_participation_company',
  CORPORATION_BUSINESS_ADDRESS: 'corporation_business_address',
  CORPORATION_BUSINESS_CONFIRM: 'corporation_business_confirm',
  CORPORATION_BUSINESS_LOADING_SENDING: 'corporation_business_loading_sending',
  CORPORATION_BUSINESS_ERROR_PARTNER_UPDATE:
    'corporation_business_error_partner_update',
  CORPORATION_BUSINESS_SUCCESS_SAVED_PARTNER:
    'corporation_business_saved_partner',

  CORPORATION_PARTNER_PERCENT_PARTICIPATION_COMPANY:
    'corporation_partner_percent_participation_company',
  CORPORATION_PARTNER_QUALIFICATION: 'corporation_partner_qualification',
  CORPORATION_ERROR_UPDATE: 'corporation_error_update',
  CORPORATION_WAIT_UPDATE: 'corporation_wait_update',
  CORPORATION_SUCCESS_SENDED_DOCUMENTS: 'corporation-success-sended-documents',
  CORPORATION_ERROR_ACCEPT_TERM: 'corporation-error-accept-term',
  UPLOAD_PJ_DOCUMENT: 'upload-pj-document',
  UPLOAD_PJ_DOCUMENT_ESTATUTO: 'upload-pj-document-estatuto',
  UPLOAD_PJ_DOCUMENT_ATA_ELEICAO: 'upload-pj-document-ata-eleicao',
  UPLOAD_PJ_DOCUMENT_CONTRATO_SOCIAL: 'upload-pj-document-contrato-social',
  UPLOAD_PJ_DOCUMENT_ALTERACAO_CONTRATUAL:
    'upload-pj-document-alteracao-contratual',
  UPLOAD_PJ_DOCUMENT_CERTIDAO_BREVE_RELATO: 'upload-pj-document-breve-relato',
  UPLOAD_PJ_DOCUMENT_PROCURACAO: 'upload-pj-document-procuracao',
  CORPORATION_IDENTIFIER: 'corporation_identifier',
};

export const ONBOARDING_STATUS = {
  INVALID_DATA: 'INVALID_DATA',
  SUCCESS: 'SUCCESS',
  REQUIRED_FIELD_EMPTY: 'REQUIRED_FIELD_EMPTY',
};

export const ONBOARDING_STATES = {
  LOGOUT: 'LOGOUT',
  AGENT: 'AGENT',
  REVIEW_BUSINESS: 'REVIEW-BUSINESS',
  CNPJ: 'CNPJ',
  REVIEW: 'REVIEW',
  INVALID_DATA_REVIEW: 'INVALID-DATA-REVIEW',
  INVALID_DATA_REVIEW_BUSINESS: 'INVALID-DATA-REVIEW-BUSINESS',
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  SELFIE: 'SELFIE',
  DOCS: 'DOCS',
  WAITING: 'WAITING',
  COMPLETED: 'COMPLETED',
  INITIAL: 'INITIAL',
  AUTH0_USER_CONSENT: "Cannot read property 'updated_at' of undefined",
  AUTH0_CONSENT: 'Consent required',
  AUTH0_VERIFY_EMAIL: 'Verifique seu e-mail antes de fazer login.',
  AUTH0_LOGOUT: 'Login required',
  AUTH0_INVALID_STATE: 'Invalid state',
  OLD_LOGIN: 'OLD_LOGIN',
  OPERATOR_PERMISSIONS: 'OPERATOR_PERMISSIONS',
};

export const ONBOARDING_LIST_MEMBERSHIP_QUALIFICATION = [
  { value: 5, label: 'Administrador' },
  { value: 51, label: 'Candidato a Cargo Político Eletivo' },
  { value: 39, label: 'Diplomata' },
  { value: 10, label: 'Diretor' },
  { value: 50, label: 'Empresário' },
  { value: 16, label: 'Presidente' },
  { value: 17, label: 'Procurador' },
  { value: 59, label: 'Produtor Rural' },
  { value: 62, label: 'Representante da Instituição Extraterritorial' },
  { value: 41, label: 'Representante de Organização Internacional' },
  { value: 43, label: 'Responsável' },
  { value: 61, label: 'Responsável Indígena' },
  { value: 22, label: 'Sócio' },
  { value: 49, label: 'Sócio Administrador' },
  { value: 29, label: 'Sócio Acionista Incapaz' },
  { value: 30, label: 'Sócio Acionista Menor ou Assistido' },
  { value: 24, label: 'Sócio Comanditado' },
  { value: 31, label: 'Sócio Ostensivo' },
  { value: 42, label: 'Oficial de Registro' },
  { value: 32, label: 'Tabelião' },
  { value: 34, label: 'Titular de Empresa Individual Imobiliária' },
  {
    value: 65,
    label: 'Titular Pessoa Física Residente ou Domiciliado no Brasil',
  },
  {
    value: 38,
    label: 'Titular Pessoa Física Residente ou Domiciliado no Exterior',
  },
];

export const ONBOARDING_LIST_OCUPATIONS = [
  { value: 'OCP0001', label: 'Administrador Iniciativa Privada' },
  { value: 'OCP0002', label: 'Administrador Público' },
  { value: 'OCP0003', label: 'Advogado' },
  { value: 'OCP0004', label: 'Agricultor' },
  { value: 'OCP0005', label: 'Agrônomo' },
  { value: 'OCP0006', label: 'Agropecuarista' },
  { value: 'OCP0007', label: 'Analista e Desenvolvedor de Sistemas' },
  { value: 'OCP0008', label: 'Animador' },
  { value: 'OCP0009', label: 'Arqueólogo' },
  { value: 'OCP0010', label: 'Arquiteto' },
  { value: 'OCP0011', label: 'Arquivologista' },
  { value: 'OCP0012', label: 'Artes e Design' },
  { value: 'OCP0013', label: 'Artista Plástico' },
  { value: 'OCP0014', label: 'Astrônomo' },
  { value: 'OCP0015', label: 'Ator' },
  { value: 'OCP0016', label: 'Biblioteconomista' },
  { value: 'OCP0017', label: 'Biomédico' },
  { value: 'OCP0018', label: 'Bioquímico' },
  { value: 'OCP0019', label: 'Biotecnólogo' },
  { value: 'OCP0020', label: 'Chefe de Cozinha' },
  { value: 'OCP0021', label: 'Ciências Sociais e Humanas' },
  { value: 'OCP0022', label: 'Cientista da Computação' },
  { value: 'OCP0023', label: 'Construtor Civil' },
  { value: 'OCP0024', label: 'Construtor Naval' },
  { value: 'OCP0025', label: 'Contabilista' },
  { value: 'OCP0026', label: 'Cooperativista' },
  { value: 'OCP0027', label: 'Dançarino' },
  { value: 'OCP0028', label: 'Dentista' },
  { value: 'OCP0029', label: 'Designer' },
  { value: 'OCP0030', label: 'Designer de Games' },
  { value: 'OCP0031', label: 'Designer de Interiores' },
  { value: 'OCP0032', label: 'Designer de Moda' },
  { value: 'OCP0033', label: 'Ecologista' },
  { value: 'OCP0034', label: 'Economista' },
  { value: 'OCP0035', label: 'Educador Físico' },
  { value: 'OCP0036', label: 'Educomunicador' },
  { value: 'OCP0037', label: 'Enfermeiro' },
  { value: 'OCP0038', label: 'Engenheiro' },
  { value: 'OCP0039', label: 'Especialista em Comércio Exterior' },
  { value: 'OCP0040', label: 'Estatístico' },
  { value: 'OCP0041', label: 'Esteticista' },
  { value: 'OCP0042', label: 'Farmacêutico' },
  { value: 'OCP0043', label: 'Filósofo' },
  { value: 'OCP0044', label: 'Físico' },
  { value: 'OCP0045', label: 'Fisioterapeuta' },
  { value: 'OCP0046', label: 'Fonoaudiólogo' },
  { value: 'OCP0047', label: 'Fotógrafo' },
  { value: 'OCP0048', label: 'Geofísico' },
  { value: 'OCP0049', label: 'Geógrafo' },
  { value: 'OCP0050', label: 'Geólogo' },
  { value: 'OCP0051', label: 'Gerente Comercial' },
  { value: 'OCP0052', label: 'Gerontólogo' },
  { value: 'OCP0053', label: 'Gestor Ambiental' },
  { value: 'OCP0054', label: 'Gestor da Qualidade' },
  { value: 'OCP0055', label: 'Gestor da Tecnologia da Informação' },
  { value: 'OCP0056', label: 'Gestor de Recursos Humanos' },
  { value: 'OCP0057', label: 'Gestor de Turismo' },
  { value: 'OCP0058', label: 'Gestor em Saúde' },
  { value: 'OCP0059', label: 'Gestor Hospitalar' },
  { value: 'OCP0060', label: 'Gestor Público' },
  { value: 'OCP0061', label: 'Historiador' },
  { value: 'OCP0062', label: 'Historiador da Arte' },
  { value: 'OCP0063', label: 'Hoteleiro' },
  { value: 'OCP0064', label: 'Jornalista' },
  { value: 'OCP0065', label: 'Linguista' },
  { value: 'OCP0066', label: 'Matemático' },
  { value: 'OCP0067', label: 'Médico' },
  { value: 'OCP0068', label: 'Meteorologista' },
  { value: 'OCP0069', label: 'Minerador' },
  { value: 'OCP0070', label: 'Museologista' },
  { value: 'OCP0071', label: 'Músico' },
  { value: 'OCP0072', label: 'Musicoterapeuta' },
  { value: 'OCP0073', label: 'Nanotecnólogo' },
  { value: 'OCP0074', label: 'Nutricionista' },
  { value: 'OCP0075', label: 'Oceanógrafo' },
  { value: 'OCP0076', label: 'Pedagogo' },
  { value: 'OCP0077', label: 'Piloto de Avião' },
  { value: 'OCP0078', label: 'Produtor Audiovisual' },
  { value: 'OCP0079', label: 'Produtor Cênico' },
  { value: 'OCP0080', label: 'Produtor Cultural' },
  { value: 'OCP0081', label: 'Produtor Fonográfico' },
  { value: 'OCP0082', label: 'Professor' },
  { value: 'OCP0083', label: 'Psicólogo' },
  { value: 'OCP0084', label: 'Psicopedagogo' },
  { value: 'OCP0085', label: 'Publicitário' },
  { value: 'OCP0086', label: 'Químico' },
  { value: 'OCP0087', label: 'Radialista' },
  { value: 'OCP0088', label: 'Radiologista' },
  { value: 'OCP0089', label: 'Relações Internacionais' },
  { value: 'OCP0090', label: 'Relações Públicas' },
  { value: 'OCP0091', label: 'Saúde e Bem-estar' },
  { value: 'OCP0092', label: 'Secretária' },
  { value: 'OCP0093', label: 'Silvicultor' },
  { value: 'OCP0094', label: 'Sociólogo' },
  { value: 'OCP0095', label: 'Teólogo' },
  { value: 'OCP0096', label: 'Terapeuta Ocupacional' },
  { value: 'OCP0097', label: 'Tradutor e Intérprete' },
  { value: 'OCP0098', label: 'Turismólogo' },
  { value: 'OCP0099', label: 'Veterinário' },
  { value: 'OCP0100', label: 'Zootecnólogo' },
  { value: 'OCP0101', label: 'Autônomo' },
  { value: 'OCP0102', label: 'Aposentado' },
  { value: 'OCP0103', label: 'Sem Ocupação' },
  { value: 'OCP0104', label: 'Estudante' },
  { value: 'OCP0105', label: 'Outros' },
];

export const ONBOARDING_STEP_DOC_ACTIONS = {
  NEXT: 'next',
  PREVIOUS: 'previous',
};
