import React, { Suspense } from 'react';
import { MainBox, MainContainer } from './styles';
import { MenuVisibilityProvider } from 'contexts/menuVisibility';
import NewMenu from 'components/NewMenu';
import Loading from 'pages/Loading';

const Layout = ({ children, ...rest }) => {
  return (
    <MainContainer {...rest}>
      <MenuVisibilityProvider>
        {/* <ScrollProvider> */}
        {/* <SubmenuProvider> */}
        {/* <Menu {...rest} className="main-menu" /> */}
        <NewMenu />
        <MainBox className="main-box">
          <Suspense fallback={<Loading />}>{children}</Suspense>
        </MainBox>
        {/* </SubmenuProvider> */}
        {/* </ScrollProvider> */}
      </MenuVisibilityProvider>
    </MainContainer>
  );
};

export default Layout;
