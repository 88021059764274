import React from 'react';

import { LoaderContainer, LoaderEl, LoaderRow, ImageEl } from './styles';

/*

const pattern = [
    { children: [{ w: 2, empty: true }, { w: 4, h: "big" }, { w: 6 }] },
    { children: [{ w: 2 }, { w: 2, image: true }, { w: 2 }, { w: 2 }] },
  ];
  
*/

const DataHolder = ({ pattern, className }) => {
  const getElements = (sizes) => {
    return (
      sizes &&
      sizes.map((el, i) =>
        el.w || el.image ? (
          <LoaderEl
            key={i}
            size={el.w}
            className={[
              (el.empty || el.image) && 'empty',
              el.h && el.h,
              el.bottom && 'bottom',
            ]}
          >
            {el.image && <ImageEl />}
            {el.children && (
              <LoaderRow key={i} className={[el.bottom && 'bottom']}>
                {getElements(el.children)}
              </LoaderRow>
            )}
          </LoaderEl>
        ) : (
          <LoaderRow key={i} className={[el.bottom && 'bottom']}>
            {getElements(el.children)}
          </LoaderRow>
        )
      )
    );
  };

  return (
    pattern && (
      <LoaderContainer className={className}>
        {getElements(pattern)}
      </LoaderContainer>
    )
  );
};

export default DataHolder;
