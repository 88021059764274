import React from 'react';

const NavigationIcon = () => {
  return (
    <svg
      width="21"
      height="15"
      viewBox="0 0 21 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.25257 12.9997H19.7526C20.1668 12.9997 20.5026 13.3355 20.5026 13.7497C20.5026 14.1294 20.2204 14.4432 19.8543 14.4928L19.7526 14.4997H1.25257C0.838355 14.4997 0.502568 14.1639 0.502568 13.7497C0.502568 13.37 0.784722 13.0562 1.1508 13.0065L1.25257 12.9997H19.7526H1.25257ZM1.25257 6.5027H19.7526C20.1668 6.5027 20.5026 6.83848 20.5026 7.2527C20.5026 7.63239 20.2204 7.94619 19.8543 7.99585L19.7526 8.0027H1.25257C0.838355 8.0027 0.502568 7.66691 0.502568 7.2527C0.502568 6.873 0.784722 6.55921 1.1508 6.50954L1.25257 6.5027H19.7526H1.25257ZM1.25171 0.00292969H19.7517C20.1659 0.00292969 20.5017 0.338716 20.5017 0.75293C20.5017 1.13263 20.2196 1.44642 19.8535 1.49608L19.7517 1.50293H1.25171C0.837495 1.50293 0.501709 1.16714 0.501709 0.75293C0.501709 0.373234 0.783863 0.0594385 1.14994 0.00977612L1.25171 0.00292969H19.7517H1.25171Z" />
    </svg>
  );
};

export default NavigationIcon;
