import React, { useContext } from 'react';
import WizardContext from 'contexts/wizard';
import { ProgressBarBox, ProgressBarInner } from './styles';
import { useParams, useLocation } from 'react-router-dom';

const ProgressBar = ({ className, ...props }) => {
  const { currentStep, total, setCurrentStep } = useContext(WizardContext);
  const { step } = useParams();
  const { pathname } = useLocation();

  // TODO: Adicionar novas regras ou melhorar implementação
  if (
    pathname.startsWith('/cobranca') ||
    pathname.startsWith('/onboarding-v2')
  ) {
    return null;
  }

  if (total && step !== currentStep) {
    setCurrentStep(step);
  }
  return (
    total && (
      <ProgressBarBox className={className} {...props}>
        <ProgressBarInner
          className={
            (currentStep / total === 1 || currentStep === -1) && 'done'
          }
          style={{ width: `${(currentStep / total) * 100 || 0}%` }}
        />
      </ProgressBarBox>
    )
  );
};

export default ProgressBar;
