export const validateCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj.length !== 14) return false;
  if (new Set(cnpj.split('')).size === 1) return false;

  let size = cnpj.length - 2;
  let numbers = cnpj.substring(0, size);
  let digits = cnpj.substring(size);
  let sum = 0;
  let pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (result.toString() !== digits.charAt(0).toString()) return false;
  size = size + 1;
  numbers = cnpj.substring(0, size);
  sum = 0;
  pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--;
    if (pos < 2) pos = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result.toString() !== digits.charAt(1).toString()) return false;
  return true;
};

export const maskCNPJ = (cnpj) =>
  (cnpj = `${cnpj}`
    .replace(/[^\d]+/g, '')
    .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'));

export const maskHiddenCNPJ = (cnpj) =>
  (cnpj = `${cnpj}`.replace(
    /^((\d|\*){2})((\d|\*){3})((\d|\*){3})((\d|\*){4})((\d|\*){2})/,
    '$1.$3.$5/$7-$9'
  ));

export const maskPartialCNPJ = (text) => {
  if (text.length > 0 && text.length <= 3) {
    return text.replace(/^(\d{2})(\d{1})/, '$1.$2');
  }
  if (text.length > 3 && text.length <= 5) {
    return text.replace(/^(\d{2})(\d{3}|\d{2})/, '$1.$2');
  }
  if (text.length > 5 && text.length <= 8) {
    return text.replace(/^(\d{2})(\d{3})(\d{3}|\d{2}|\d{1})/, '$1.$2.$3');
  }
  if (text.length > 5 && text.length <= 8) {
    return text.replace(/^(\d{2})(\d{3})(\d{3}|\d{2}|\d{1})/, '$1.$2.$3');
  }
  if (text.length > 8 && text.length <= 12) {
    return text.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4}|\d{3}|\d{2}|\d{1})/,
      '$1.$2.$3/$4'
    );
  }
  if (text.length > 12 && text.length <= 18) {
    return text.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}|\d{1})/,
      '$1.$2.$3/$4-$5'
    );
  }
};

export const maskCNPJWithHiddenFields = (cnpj) =>
  (cnpj = `${cnpj}`
    .replace(/[^\d]+/g, '')
    .replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '**.$2.$3/$4-**'));
