import { configureStore } from '@reduxjs/toolkit';
import sentryReduxEnhancer from './features/sentry/sentry-enhancer';
import authorizationReducer from './features/authorization/authorization-slice';
import onboardingReducer from './features/onboarding/onboarding-slice';
import bankstatementReducer from './features/bankstatement/bankstatement-slice';

const store = configureStore({
  reducer: {
    authorization: authorizationReducer,
    onboarding: onboardingReducer,
    bankstatement: bankstatementReducer,
  },
  enhancers: [sentryReduxEnhancer],
});

export default store;
