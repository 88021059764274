import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { clearData } from 'services/session-store';

const clearLocalStorage = () => localStorage.clear();
const clearCookies = () =>
  document.cookie.split(';').forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });

const useLogout = () => {
  const { logout } = useAuth0();
  const doLogout = useCallback(
    (returnTo, ...props) => {
      try {
        clearCookies();
        clearData();
        clearLocalStorage();
        logout({
          returnTo: returnTo ? returnTo : window.location.origin,
          client_id: process.env.REACT_APP_CLIENT_ID,
          ...props,
        });
      } catch (e) {
        console.error('Error trying to logout. ', e.message);
      }
    },
    [logout]
  );

  return {
    doLogout,
  };
};

export default useLogout;
