import { MODAL_SEND_STATEMENTS_STEPS } from 'pages/BankStatement/shared/constants';

const STEPS = MODAL_SEND_STATEMENTS_STEPS;

export const SCHEDULE_REGISTER = [
  {
    id: STEPS.REGISTER_FILTER,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.REGISTER_EMAIL,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.REGISTER_FILE_TYPE,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.REGISTER_DESCRIPTION,
    skip: false,
    visible: true,
  },
  {
    id: STEPS.REGISTER_REVIEW,
    skip: false,
    visible: true,
  },
];
