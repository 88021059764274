import styled from 'styled-components';
import Configs from 'styles/variables';

export const Block = styled.button`
  border: none;
  background: transparent;
  padding: 6px 6px 3px 5px;
  margin-left: 16px;
  cursor: pointer;
  border-radius: 20px;
  transform: translateY(2px);
  svg {
    fill: ${Configs.mediumGray};
    width: 24px;
    height: 24px;
  }
  &:hover {
    background-color: ${Configs.yellow};
    svg {
      fill: ${Configs.black};
    }
  }
`;
