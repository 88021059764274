import React from 'react';

import { ButtonBox, Arrow } from './styles';
import ArrowLeftIcon from 'assets/images/ArrowLeftIcon';

const BackButton = ({ className, ...props }) => {
  return (
    <ButtonBox {...props} className={className}>
      <Arrow>
        <ArrowLeftIcon />
      </Arrow>
    </ButtonBox>
  );
};

export default BackButton;
