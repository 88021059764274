import { useContext, useMemo } from 'react';
import UserAuthorizationContext from 'contexts/authorization';

const useAgentBusinessProfiles = () => {
  const { userAuthorization, currentUserId, setCurrentID } = useContext(
    UserAuthorizationContext
  );

  const currentID = useMemo(() => currentUserId, [currentUserId]);

  const agentProfilesMemo = useMemo(
    () => userAuthorization?.business_agents,
    [userAuthorization]
  );

  const agentProfiles = Array.isArray(agentProfilesMemo)
    ? agentProfilesMemo
    : [];

  const agentProfilesWithoutCurrent = agentProfiles.filter(
    (ag) => ag?.current_identity !== currentID
  );
  const currentAgentProfile = agentProfiles.find(
    (ag) => ag?.current_identity === currentID
  );

  const setAgentProfile = (id) => {
    setCurrentID(id);
    document.location.reload(true);
  };

  return {
    agentProfiles,
    agentProfilesWithoutCurrent,
    currentAgentProfile,
    currentID,
    setAgentProfile,
  };
};

export default useAgentBusinessProfiles;
