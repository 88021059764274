import React, { useState } from 'react';
import TwoColumnsLayout from 'components/TwoColumnsLayout';
import useAuthorization from 'hooks/useAuthorization';
import {
  ColumnBig,
  Container,
  TitleLine,
  AccountList,
  AuthCard,
  CloseButton,
} from './styles';
import RadioButton from 'components/RadioButton';
import { PATHS } from 'Routes/constants';
import { useHistory } from 'react-router-dom';

const SwitchBankAgent = () => {
  const { userAuthorization, getCurrentID, setCurrentID } = useAuthorization();
  const [currentAccount, setCurrentAccount] = useState({
    value: getCurrentID(),
  });
  const history = useHistory();

  const GoToDashboard = () => {
    setCurrentID(currentAccount);
    history.push(PATHS.DASHBOARD);
  };

  return (
    userAuthorization && (
      <Container>
        <TwoColumnsLayout>
          <ColumnBig>
            <TitleLine big>
              Qual agente deseja utilizar?
              <CloseButton medium yellow onClick={GoToDashboard}>
                Selecionar e continuar
              </CloseButton>
            </TitleLine>
          </ColumnBig>
          <ColumnBig>
            {userAuthorization && userAuthorization.business_agents && (
              <AccountList>
                {userAuthorization.business_agents.map(
                  (agent) =>
                    agent.current_identity && (
                      <AuthCard
                        key={agent.current_identity}
                        white
                        noTextOverflow
                        title={agent.name}
                      >
                        <RadioButton
                          id={agent.current_identity}
                          name="agent_id"
                          text={agent.name}
                          value={agent.current_identity}
                          checked={getCurrentID() === agent.current_identity}
                          className=""
                          onChange={(ev) => setCurrentAccount(ev.target.value)}
                          noTextOverflow
                        />
                      </AuthCard>
                    )
                )}
              </AccountList>
            )}
          </ColumnBig>
        </TwoColumnsLayout>
      </Container>
    )
  );
};

export default SwitchBankAgent;
