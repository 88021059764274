import React from 'react';
import Title from 'components/Title';

import { Alert } from '../styles';
import RoundedButton from 'components/RoundedButton';

const SavedStep = ({ register, saved, addCategoryAction, listAction }) => {
  return (
    <Alert className={[saved === true && 'active', 'alert']}>
      <Title big white>
        Parabéns, o serviço <br />
        <strong>{register.product_name}</strong> <br />
        foi associado a categoria <br />
        <strong>
          {register.parent_category} ➔ {register.category}
        </strong>
        <br />
        com sucesso!
      </Title>
      <RoundedButton yellow big onClick={listAction}>
        Voltar para a lista
      </RoundedButton>
      <RoundedButton yellow big onClick={addCategoryAction}>
        Associar à outra categoria
      </RoundedButton>
    </Alert>
  );
};

export default SavedStep;
