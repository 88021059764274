import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import * as S from '../style';
import Logo from 'assets/images/ContbankLogo';
import Configs from 'styles/variables';
import MenuHeaderCardSwitcher from './MenuHeaderCardSwitcher';
import { MenuVisibilityContext } from 'contexts/menuVisibility';
import { NAV_MENU_OPEN_CLASS, NAV_MENU_CLOSE_CLASS } from '../constants';
import { PATHS } from 'Routes/constants';
import { useSelector } from 'react-redux';

export default function MenuHeader() {
  const { handleCloseMenu, menuIsOpen } = useContext(MenuVisibilityContext);
  const { accountType } = useSelector((state) => state.authorization);

  const dashboardPath = useMemo(() => {
    return accountType === '1' ? PATHS.BANK.DASHBOARD : PATHS.AGENT.DASHBOARD;
  }, [accountType]);

  return (
    <S.Header>
      <S.HeaderNavMenuPrimary>
        <S.NavMenuHeaderLogo>
          <Link to={dashboardPath}>
            <Logo fill={Configs.newColors.p800} />
          </Link>
        </S.NavMenuHeaderLogo>
        <S.HeaderMenuButton>
          <button onClick={handleCloseMenu}>
            <span className={NAV_MENU_OPEN_CLASS}>Fechar</span>
            <span className={NAV_MENU_CLOSE_CLASS}>Menu</span>
            {menuIsOpen && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            )}
          </button>
        </S.HeaderMenuButton>
      </S.HeaderNavMenuPrimary>
      <S.HeaderNavMenuSwitcher>
        <MenuHeaderCardSwitcher />
      </S.HeaderNavMenuSwitcher>
    </S.Header>
  );
}
