import UserAuthorizationContext from 'contexts/authorization';
import { useContext } from 'react';
import api from 'services/api';

const useCategoriesService = () => {
  const { getCurrentID } = useContext(UserAuthorizationContext);

  const getCategories = async (token, page = 1, perPage = 150) => {
    const url = `/categories?page=${page}&per_page=${perPage}`;

    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });
  };

  return {
    getCategories,
  };
};

export default useCategoriesService;
