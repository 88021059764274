import { useCallback, useContext } from 'react';
import Configs from 'styles/variables';
import MenuVisibilityContext from 'contexts/menuVisibility';
import { useMediaQuery } from 'react-responsive';

export default function useMenuVisibility() {
  const { setInvisible, setVisible, isVisible } = useContext(
    MenuVisibilityContext
  );
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${Configs.maxTabletWidth}px)`,
  });

  const disableMenu = useCallback(() => {
    setInvisible();
  }, [setInvisible]);

  const disableMenuOnTabletAndMobile = useCallback(() => {
    if (isTabletOrMobile) {
      disableMenu();
    }
  }, [disableMenu, isTabletOrMobile]);

  const enableMenu = useCallback(() => {
    setVisible();
  }, [setVisible]);

  return {
    disableMenuOnTabletAndMobile,
    enableMenu,
    disableMenu,
    isVisible,
  };
}
