import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { APP_CONTBANNK_VERSION } from '../config/constants';

const analyticsInfoDefault = {
  userId: '',
  accountId: '',
  appVersion: APP_CONTBANNK_VERSION,
};

const AnalyticsContext = React.createContext({
  analyticsInfo: analyticsInfoDefault,
  setAnalyticsInfo: () => {},
});

export const AnalyticsProvider = ({ children }) => {
  // const { getCurrentID } = useAuthorization();
  // const { registry } = useAccountRegistry();
  // const userId = getCurrentID();
  // const { STORAGE_LABELS } = useConstants();
  // const accountId = registry?.id;
  const userId = useSelector((state) => state.authorization.userId);
  const accountId = useSelector((state) => state.authorization.accountId);

  const [analyticsInfo, setAnalyticsInfo] = useState(analyticsInfoDefault);

  const defaultValues = {
    analyticsInfo,
    setAnalyticsInfo,
  };

  // Update userId
  useEffect(() => {
    let mounted = true;

    if (mounted && userId && userId !== analyticsInfo.userId) {
      setAnalyticsInfo((prev) => ({ ...prev, userId: userId }));
    }

    return () => (mounted = false);
  }, [analyticsInfo.userId, userId]);

  // Update accountId
  useEffect(() => {
    let mounted = true;

    if (mounted && accountId && accountId !== analyticsInfo.accountId) {
      setAnalyticsInfo((prev) => ({ ...prev, accountId: accountId }));
    }
    return () => (mounted = false);
  }, [analyticsInfo.accountId, accountId]);

  return (
    <AnalyticsContext.Provider value={defaultValues}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalyticsContext = () => useContext(AnalyticsContext);

export default AnalyticsContext;
