import { css } from 'styled-components';
import Configs from './variables';

const sizes = {
  smallMobile: Configs.maxSmallMobileWidth,
  mediumMobile: Configs.maxMediumMobileWidth,
  largeMobile: Configs.maxLargeMobileWidth,
  mobile: Configs.maxMobileWidth,
  tablet: Configs.maxTabletWidth,
  screenWidth1190: Configs.screenWidth1190,
  screenWidth1180: Configs.screenWidth1180,
  screenWidth1160: Configs.screenWidth1160,
  screenWidth1150: Configs.screenWidth1150,
  screenWidth1080: Configs.screenWidth1080,
  screenWidth1030: Configs.screenWidth1030,
  screenWidth990: Configs.screenWidth990,
  screenWidth910: Configs.screenWidth910,
  screenWidth900: Configs.screenWidth900,
  screenWidth880: Configs.screenWidth880,
  screenWidth670: Configs.screenWidth670,
  screenWidth490: Configs.screenWidth490,
  screenWidth360: Configs.screenWidth360,
  tabletMedium: Configs.maxTabletMedium,
  screenHeight630: Configs.screenHeight630,
  bigDesktop: Configs.bigDesktop,
};

export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
