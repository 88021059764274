import React from 'react';

import { ButtonBox, Label } from './styles';

const RoundedButtonLink = ({ children, className, ...props }) => (
  <ButtonBox className={[className, 'btn']} {...props}>
    <Label>{children}</Label>
  </ButtonBox>
);

export default RoundedButtonLink;
