import pckg from '../../package.json';

export const bankNumber = '332';
export const bankBranch = '0001';
export const bankName = 'Acesso Soluções Pagamento SA';
export const bankNameAlternative = 'Contbank SA';
export const APP_CONTBANNK_VERSION = `${pckg.version}`;

export const EXTERNAL_LINK_PARAM = 'openExternalBrowser=1';

export const REACT_NATIVE_WEB_VIEW = 'ReactNativeWebView';

export const ENABLE_EXTERNAL_BROWSER = 'enableExternalBrowser';

export const BANKSLIP_MINVALUE = 5;
export const BANKSLIP_MAXVALUE = 120_000;

export const BILLING_TYPE = {
  ONE_BILLING: 'one-billing',
  RECURRING_BANK: 'recurring-bank',
};

export const BILLING_METHOD_TYPE = {
  BOLETO: 'BOLETO',
  PIX: 'PIX',
};

export const BILLING_STATUS = {
  REGISTERED: 'Registrado',
  PENDING: 'Pendente',
  NEW: 'Novo',
  TO_RECEIVE: 'A Receber',
  RECEIVED: 'Recebido',
  DELAYED: 'Vencido',
  CANCELED: 'Cancelado',
  SCHEDULED: 'Agendado',
  WAITING_REGISTER: 'Aguardando Registro',
  ERRO: 'Erro',
  TODOS: 'TODOS',
};

export const ACCOUNT_TYPE = {
  PERSONAL: 'PERSONAL',
  BUSINESS: 'BUSINESS',
  BANK: 'BANK',
  BUSINESS_AGENT: 'BusinessAgent',
};

export const ONBOARDING_DOC_TYPES = {
  SELFIE: 'SELFIE',
  CNH: 'CNH',
  RG: 'RG',
};

export const isSandboxEnv =
  window.location.hostname === 'sandbox.contbank.com.br';
export const isDevelopmentEnv =
  window.location.hostname === 'develop.contbank.com.br';
export const isProductionEnv = window.location.hostname === 'app.contbank.com';
export const isLocalhostEnv = window.location.hostname === 'localhost';
export const enabledCamera = window.enableCamera;
export const enableShareOFX = window.enableShareOFX;
export const enablePIXQRCode = window.enablePIXQRCode;
export const enableShareText = window.enableShareText;
export const enableCardQRCode = window.enableCardQRCode;
export const enableExternalBrowser = window.enableExternalBrowser;
export const enablePixArea =
  window[REACT_NATIVE_WEB_VIEW] ||
  isSandboxEnv ||
  isDevelopmentEnv ||
  isLocalhostEnv;
