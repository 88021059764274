import BackButton from 'components/BackButton';
import styled from 'styled-components';
import media from 'styles/media';

export const WizardButtonBack = styled(BackButton)`
  opacity: 0;
  position: absolute;
  top: 40%;
  left: 120px;
  ${media.tablet`
    left: 40px;
    &.request-card {
      top: 50px;
    }
  `}
  transition: opacity 0.25s ease;
  z-index: -1;
  &.visible {
    opacity: 1;
    z-index: 10;
  }

  ${media.mobile`
    // top: 100px;
    // left: 32px;
    // width: 32px;
    // height: 32px;
    // background-color: transparent;
    &.back-mobile-card {
      top: 50px;
    }
    top: -13px;
    left: 32px;
    width: 32px;
    height: 32px;
    background-color: transparent;

    &.on-public {
      top: 49px;
      left: 32px;
      width: 32px;
      height: 32px;
      background-color: transparent;
    }
  `}
  svg {
    ${media.mobile`
      margin-top: 0;
      width: 39px;
      height: 26px;
    `}
  }
`;
