import styled from 'styled-components';
import Configs from 'styles/variables';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h1`
  font-size: 16vw;
  padding: 0;
  margin: 0;
  color: ${Configs.darkGray};
`;

export const Text = styled.div`
  font-size: 2vw;
  color: ${Configs.darkGray};
  padding: 0 32px;
  text-align: center;
`;
