import useToken from 'hooks/useToken';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useContactsService from 'services/contacts';

const ContactContext = React.createContext({
  contact: null,
  loading: false,
  error: false,
  success: false,
  message: null,
  loadContact: (id) => {},
});

export const ContactProvider = ({ children }) => {
  const [contact, setContact] = useState(null);
  const [id, setId] = useState(null);
  const [message, setMessage] = useState();
  const [fetchState, setFetchState] = useState('');
  const token = useToken();
  const { getContactById } = useContactsService(token);
  const { t } = useTranslation('contacts');

  const loading = fetchState === 'loading';
  const error = fetchState === 'error';
  const success = fetchState === 'success';

  const handleError = useCallback(
    (e) => {
      setMessage(t('contacts:messages.error_contact'));
      console.error(e.message, '.Error trying to fetch Contact');
      setFetchState('error');
    },
    [t]
  );

  const handleResponse = useCallback((response) => {
    const { data } = response;

    setContact(data ? data : null);
    setFetchState('success');
  }, []);

  useEffect(() => {
    let cleanUp = false;
    const fetchContacts = async () => {
      setFetchState('loading');
      try {
        handleResponse(await getContactById(id));
      } catch (e) {
        handleError(e);
      }
    };

    if (token && id && !cleanUp && fetchState === 'initial') {
      fetchContacts();
    }
    return () => (cleanUp = true);
  }, [
    token,
    fetchState,
    contact,
    id,
    getContactById,
    handleError,
    handleResponse,
  ]);

  const loadContact = (id) => {
    setFetchState('initial');
    setId(id);
  };

  const defaultValues = {
    contact,
    loadContact,
    loading,
    error,
    success,
    message,
  };

  return (
    <ContactContext.Provider value={defaultValues}>
      {children}
    </ContactContext.Provider>
  );
};

export default ContactContext;
