import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import { withTranslation } from 'react-i18next';
import { ContbankLogoFigma } from 'assets/images/ContbankLogoFigma';
import Reticence from 'components/_v2/Reticence';
import useMenuVisibility from 'hooks/useMenuVisibility';
import Configs from 'styles/variables';

const Loading = ({ t, message, maxWidth }) => {
  const { disableMenu, enableMenu } = useMenuVisibility();

  const mounted = React.useRef(false);

  React.useEffect(() => {
    if (mounted.current) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({ color: Configs.yellow })
      );
      disableMenu();
    } else {
      mounted.current = true;
    }

    return () => {
      if (mounted.current) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({ color: Configs.newColors.n50 })
        );
      }

      enableMenu();
    };
  }, [disableMenu, enableMenu]);

  return (
    <S.Container>
      <S.LogoContainer>
        <ContbankLogoFigma />
      </S.LogoContainer>
      <S.Wrapper>
        <S.TextContainer maxWidth={maxWidth}>
          {message || t('common:labels.loading', 'Carregando')} <Reticence />
        </S.TextContainer>
      </S.Wrapper>
    </S.Container>
  );
};

Loading.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.arrayOf([PropTypes.element]),
    PropTypes.element,
    PropTypes.node,
    PropTypes.object,
  ]),
  maxWidth: PropTypes.string,
};

export default withTranslation('common')(Loading);
