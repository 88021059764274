import useToken from 'hooks/useToken';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useBankSlipsService from 'services/bank-slips';

const BankSlipsContext = React.createContext({
  bankSlips: null,
  total: null,
  setBankSlips: (array) => {},
  loadBankSlips: () => {},
  loadOrderedBankSlips: (text, dsc, perPage) => {},
  nextPageBankSlips: (text, dsc, perPage) => {},
  loading: false,
  error: false,
  success: false,
  message: null,
});

export const BankSlipsProvider = ({ children }) => {
  const [bankSlips, setBankSlips] = useState(null);
  const [dsc, setDsc] = useState(true);
  const [text, setText] = useState();
  const [fetchState, setFetchState] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [message, setMessage] = useState(0);
  const [total, setTotal] = useState();
  const token = useToken();
  const { getBankSlips } = useBankSlipsService(token);
  const { t } = useTranslation('bank-slips');

  const loading = fetchState === 'loading';
  const error = fetchState === 'error';
  const success = fetchState === 'success';

  const handleError = useCallback(
    (e) => {
      setMessage(
        t(
          'bank-slips:messages.error_fetch',
          'Ocorreu um erro ao buscar os boletos. Por favor, <strong>tente novamente</strong>.'
        )
      );
      console.error(e.message, '.Error trying to fetch bank slips');
      setFetchState('error');
    },
    [t]
  );

  const handleResponse = useCallback((response, actions) => {
    const { data } = response;

    setBankSlips(data ? data.boletos : null);
    setTotal(data.total);
    setFetchState('success');
  }, []);

  const handleNextPageResponse = useCallback((response) => {
    const { data } = response;

    setBankSlips((prev) => (data ? prev.concat(data.boletos) : prev));
    setTotal(data.total);
    setFetchState('success');
  }, []);

  const fetchBankSlipsNextPage = useCallback(
    async (page, perPage) => {
      setFetchState('loading');
      try {
        handleNextPageResponse(await getBankSlips(text, dsc, page, perPage));
      } catch (e) {
        handleError(e);
      }
    },
    [getBankSlips, text, dsc, handleNextPageResponse, handleError]
  );

  const fetchOrderedBankSlips = useCallback(
    async (page, perPage, desc) => {
      setFetchState('loading');
      try {
        handleResponse(await getBankSlips(text, desc, page, perPage));
      } catch (e) {
        handleError(e);
      }
    },
    [getBankSlips, text, handleResponse, handleError]
  );

  useEffect(() => {
    let mounted = true;
    const fetchContacts = async () => {
      setFetchState('loading');
      try {
        handleResponse(await getBankSlips(text, dsc, page, perPage));
      } catch (e) {
        handleError(e);
      }
    };

    if (token && fetchState === 'initial' && mounted) {
      fetchContacts();
    }
    return () => (mounted = false);
  }, [
    token,
    fetchState,
    bankSlips,
    dsc,
    text,
    page,
    perPage,
    getBankSlips,
    handleError,
    handleResponse,
  ]);

  const loadBankSlips = useCallback((text = null, dsc = true, perPage = 10) => {
    setFetchState('initial');
    setFetchState('initial');
    setDsc(dsc);
    setText(text);
    setPage(1);
    setPerPage(perPage);
  }, []);

  const nextPageBankSlips = (text = null, dsc = true, perPage = 10) => {
    const nextPage = page + 1;

    setPage(nextPage);
    setPerPage(perPage);
    setDsc(dsc);
    setText(text);
    fetchBankSlipsNextPage(nextPage, perPage);
  };

  const loadOrderedBankSlips = (text, dsc, perPage = 50) => {
    setDsc(dsc);
    setText(text);
    if (page > 1) {
      const totalPerPage = page * perPage;

      fetchOrderedBankSlips(1, totalPerPage, dsc);
    } else {
      fetchOrderedBankSlips(1, perPage, dsc);
    }
  };

  const values = {
    bankSlips,
    total,
    setBankSlips,
    loadBankSlips,
    loadOrderedBankSlips,
    nextPageBankSlips,
    loading,
    error,
    success,
    message,
  };

  return (
    <BankSlipsContext.Provider value={values}>
      {children}
    </BankSlipsContext.Provider>
  );
};

export default BankSlipsContext;
