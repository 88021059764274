import { createGlobalStyle } from 'styled-components';
import media from './media';
import Configs from './variables';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: 0;
  }
  body, html {
    background: ${Configs.pageColor};
    font-family: "Manrope", 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    height: 100%;
    width: 100%;
    color: ${Configs.darkText};
  }
  body{
    transition: overflow .1s ease;
  }
  body.no-scroll{
    overflow: hidden;
    padding-right: 15px;
    ${media.mobile`
      padding-right: 0;
    `}
  }
  #root {
    height: 100%;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
  }

  :root {
    --vh-base: 100vh;
    --vh: 100vh; // Fallback for old navigators
    --vh: calc(var(--vh-base, 1vh) * 100);
  }

  input,
  textarea,
  button,
  select,
  div,
  a {
      -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
`;

export default GlobalStyle;
