import styled, { keyframes } from 'styled-components';
import Configs from 'styles/variables';

const ellipsis = keyframes`
        0%   { content: ''; }
        25%  { content: '.'; }
        50%  { content: '..'; }
        75%  { content: '...'; }
        100% { content: ''; }
`;

export const Reticence = styled.span`
  &:after {
    color: ${Configs.newColors.n900};
    position: absolute;
    animation: ${ellipsis} steps(1, end) 3s infinite;
    content: '';
  }
`;
