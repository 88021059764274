const fontFamily =
  '"Manrope", "Helvetica Neue", "Helvetica", Arial, sans-serif';

const Configs = {
  fontFamily: fontFamily,
  maxSmallMobileWidth: 320,
  maxMediumMobileWidth: 375,
  maxLargeMobileWidth: 414,
  maxMobileWidth: 720,
  maxTabletMedium: 1024,
  maxTabletWidth: 1224,
  screenWidth1180: 1180,
  screenWidth1160: 1160,
  screenWidth1150: 1150,
  screenWidth1080: 1080,
  screenWidth1030: 1030,
  screenWidth990: 990,
  screenWidth910: 910,
  screenWidth900: 900,
  screenWidth880: 880,
  screenWidth670: 670,
  screenWidth490: 490,
  screenWidth360: 360,
  bigDesktop: 1360,
  screenHeight630: 630,
  header: {
    backgroundLogo: '#101114',
    hamburgerColor: '#ffffff',
    backgroundMenu: '#101114',
    backgroundMenuArea: '#1b1c22',
    backgroundSubmenu: '#ffffff',
    menuColor: '#ffffff',
    dividerColor: '#979797',
    height: 56,
  },
  opportunities: {
    background: '#121212',
    backgroundHover: '#444',
  },
  buttons: {
    background: '#d8dfe4',
    height: 40,
    heightBig: 136,
    paddingX: 30,
    color: '#03071f',
    active: {
      background: '#101114',
      color: '#ffffff',
    },
    hover: {
      background: '#FFE03D',
      color: '#03071f',
    },
  },
  buttonGroup: {
    spacing: {
      x: 8,
    },
  },
  dashboard: {
    slider: {
      baseWidth: 185,
    },
  },
  progressBar: {
    yellow: '#f5ff46',
    gray: '#232429',
    primaryYellow: '#F0C100',
  },
  input: {
    borderColor: '#979797',
  },
  semaphore: {
    received: {
      background: '#C9FCC8',
      border: '#88E386',
    },
    toReceive: {
      background: '#FCEDC8',
      border: '#E3C986',
    },
    overdue: {
      background: '#FCC8D1',
      border: '#E38696',
    },
    canceled: {
      background: '#E0E1E2',
      border: '#D4D5D6',
    },
  },
  pageColor: '#f1f1f1',
  yellow: '#FFE03D',
  darkYellow: '#FFE03D',
  yellowSoft: '#FFEC8A',
  black: '#101114',
  darkGray: '#3e3e43',
  gray: '#d8dfe4',
  gray2: '#E6E6E8',
  grayAlt: '#F4F4F4',
  white: '#ffffff',
  darkText: '#03071f',
  darkText2: '#1e2124',
  borderColor: '#333',
  mediumGray: '#979797',
  lightGray: '#e6e6e8',
  lightGray2: '#959393',
  lightBlue: '#d2dbe1',
  errorColor: '#e1626b',
  labelGray: '#21242a',
  disabledButtonBg: '#d8dfe4',
  textDisabled: '#9c9c9c',
  cardDisabled: '#d8d8d8',
  primaryDark: '#101A23',
  primaryDarkSoft: '#1B2537',
  newColors: {
    a100: '#FCC8D1',
    a400: '#EA3D4E',
    p800: '#F0C100',
    p600: '#FFE03D',
    p200: '#FCF277',
    s900: '#101A23',
    s800: '#1B2537',
    s700: '#364E63',
    s600: '#626C77',
    s400: '#AAB4C1',
    s200: '#DEE9F6',
    s100: '#E8F3FF',
    t500: '#8D897E',
    t400: '#A29E91',
    t300: '#E7E1CF',
    t200: '#EBE6D7',
    n900: '#1E2124',
    n800: '#272B2F',
    n700: '#32383D',
    n600: '#41484E',
    n500: '#474F56',
    n400: '#6C7278',
    n300: '#84898E',
    n200: '#C6C8CB',
    n100: '#E0E1E2',
    n50: '#F7F6F2',
    n0: '#FFFFFF',
    green: '#29CC6A',
    green02: '#149851',
  },
  typography: {
    desktop: {
      h1: {
        fontFamily: fontFamily,
        size: '48px',
        lineHeight: '54px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      h2: {
        fontFamily: fontFamily,
        size: '40px',
        lineHeight: '46px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      h3: {
        fontFamily: fontFamily,
        size: '32px',
        lineHeight: '38px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      h4: {
        fontFamily: fontFamily,
        size: '22px',
        lineHeight: '24px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      h5: {
        fontFamily: fontFamily,
        size: '18px',
        lineHeight: '24px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      h6: {
        fontFamily: fontFamily,
        size: '22px',
        lineHeight: '40px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      paragraphLarge: {
        fontFamily: fontFamily,
        size: '16px',
        lineHeight: '22px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      paragraphSmall: {
        fontFamily: fontFamily,
        size: '14px',
        lineHeight: '20px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      paragraphMedium: {
        fontFamily: fontFamily,
        size: '16px',
        lineHeight: '22px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      caption: {
        fontFamily: fontFamily,
        size: '12px',
        lineHeight: '18px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
    },
    mobile: {
      h1: {
        fontFamily: fontFamily,
        size: '32px',
        lineHeight: '40px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      h2: {
        fontFamily: fontFamily,
        size: '28px',
        lineHeight: '32px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      h3: {
        fontFamily: fontFamily,
        size: '20px',
        lineHeight: '26px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      h4: {
        fontFamily: fontFamily,
        size: '18px',
        lineHeight: '24px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      h5: {
        fontFamily: fontFamily,
        size: '16px',
        lineHeight: '22px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      h6: {
        fontFamily: fontFamily,
        size: '22px',
        lineHeight: '40px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      paragraphLarge: {
        fontFamily: fontFamily,
        size: '16px',
        lineHeight: '24px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      paragraphMedium: {
        fontFamily: fontFamily,
        size: '16px',
        lineHeight: '24px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      paragraphSmall: {
        fontFamily: fontFamily,
        size: '14px',
        lineHeight: '20px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
      caption: {
        fontFamily: fontFamily,
        size: '12px',
        lineHeight: '18px',
        fontWeight: 500,
        fontWeightBold: 700,
      },
    },
  },
  newDimensions: {
    headerMobileHeight: '110px',
    footerMobileHeight: '96px',
    headerDesktopHeight: '76px',
    footerDesktopHeight: '64px',
    maxWidthDesktopContainerPage: '1292px',
    menuHeight: '60px',
    modalWidth: '440px',
  },
};

export default Configs;
