import React from 'react';

const ContactIcon = (props) => (
  <svg
    width="16"
    height="22"
    viewBox="0 0 16 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 7.33333C10 8.4379 9.10457 9.33333 8 9.33333C6.89543 9.33333 6 8.4379 6 7.33333C6 6.22876 6.89543 5.33333 8 5.33333C9.10457 5.33333 10 6.22876 10 7.33333ZM4 11.6667V12C4 13.4728 5.19391 14.6667 6.66667 14.6667H9.33333C10.8061 14.6667 12 13.4728 12 12V11.6667C12 11.1144 11.5523 10.6667 11 10.6667H5C4.44772 10.6667 4 11.1144 4 11.6667ZM0 2.66667V18.6667C0 20.1394 1.19391 21.3333 2.66667 21.3333H15.3333C15.7015 21.3333 16 21.0349 16 20.6667C16 20.2985 15.7015 20 15.3333 20H2.66667C1.93029 20 1.33333 19.403 1.33333 18.6667H14.6667C15.403 18.6667 16 18.0697 16 17.3333V2.66667C16 1.19391 14.8061 0 13.3333 0H2.66667C1.19391 0 0 1.19391 0 2.66667ZM13.3333 1.33333C14.0697 1.33333 14.6667 1.93029 14.6667 2.66667V17.3333H1.33333V2.66667C1.33333 1.93029 1.93029 1.33333 2.66667 1.33333H13.3333Z"
      // fill="white"
    />
  </svg>
);

export default ContactIcon;
