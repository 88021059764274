import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useLogout from 'hooks/useLogout';

let timer = undefined;

const useToken = (catchError, ignoreCache) => {
  const [token, setToken] = useState(null);
  const [tokenError, setTokenError] = useState(null);
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const { doLogout } = useLogout();

  useEffect(() => {
    let mounted = true;
    const fetchToken = async () => {
      try {
        const tokenReq = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUDIENCE,
        });

        setToken(tokenReq);
        setTokenError(null);
      } catch (e) {
        console.error(e);
        setTokenError(e.message);
      }
    };

    clearTimeout(timer);
    timer = setTimeout(() => {
      doLogout();
    }, 1800000); // 30 minutos

    if (mounted && (!token || ignoreCache) && !isLoading) {
      fetchToken();
    }
    return () => (mounted = false);
  }, [getAccessTokenSilently, isLoading, ignoreCache, token, doLogout]);

  return token
    ? token
    : catchError && tokenError
    ? { error: tokenError }
    : null;
};

export default useToken;
