import { useContext } from 'react';
import api from './api';
import UserAuthorizationContext from 'contexts/authorization';
import useToken from 'hooks/useToken';

const useContactsService = (token) => {
  const serviceToken = useToken();
  const { getBankAccount } = useContext(UserAuthorizationContext);

  const getContacts = async (
    text,
    dsc = false,
    favorites = false,
    page = 1,
    perPage = 10
  ) => {
    let url = `/contacts?page=${page}&per_page=${perPage}`;

    url = text ? `${url}&text=${text}` : url;
    url = dsc ? `${url}&desc=true` : url;
    url = favorites ? `${url}&favorite=true` : url;
    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const getContact = async (identifier, page = 1, perPage = 50) => {
    let url = `/contacts?page=${page}&per_page=${perPage}`;

    url = identifier ? `${url}&text=${identifier}` : url;
    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const getContactById = async (id) => {
    let url = `/contacts/${id}`;

    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const getContactAccounts = async (id) => {
    let url = `/contacts/accounts/${id}`;

    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const postContact = async (data) => {
    let url = `/contacts`;

    return await api.post(url, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const postContactAccount = async (id, data) => {
    let url = `/contacts/accounts/${id}`;

    return await api.post(url, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const putContact = async (id, data) => {
    let url = `/contacts/${id}`;

    return await api.put(url, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const patchContactFavorites = async (id, isFavorite = true) => {
    let url = `/contacts/${id}/favorites`;

    return await api.patch(
      url,
      { favorite: isFavorite },
      {
        headers: {
          Authorization: `Bearer ${token || serviceToken}`,
          'X-Current-Identity': `${getBankAccount()}`,
        },
      }
    );
  };

  const putContactAccount = async (id, data) => {
    let url = `/contacts/accounts/${id}`;

    return await api.put(url, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  return {
    getContacts,
    getContact,
    getContactById,
    getContactAccounts,
    postContactAccount,
    postContact,
    putContactAccount,
    putContact,
    patchContactFavorites,
  };
};

export default useContactsService;
