import React from 'react';
import { PATHS } from 'Routes/constants';
import PrivateLayouted from 'Routes/components/PrivateLayouted';
import PrivateWizardSingle from 'Routes/components/PrivateWizardSingle';
import useCan from 'hooks/useCan';
const Technologies = React.lazy(() => import('pages/Technologies'));

export const TechnologiesRoutes = () => {
  const { canDo } = useCan();

  if (!canDo('read:technologies', 'business')) return [];

  return [
    <PrivateLayouted
      component={Technologies}
      exact
      path={PATHS.TECHNOLOGIES.LIST}
      key={PATHS.TECHNOLOGIES.LIST}
    />,
    ...[
      canDo('write:technologies', 'business') && (
        <PrivateWizardSingle
          component={Technologies}
          exact
          path={PATHS.TECHNOLOGIES.EDIT}
          key={PATHS.TECHNOLOGIES.EDIT}
        />
      ),
    ],
    ...[
      canDo('write:technologies', 'business') && (
        <PrivateWizardSingle
          component={Technologies}
          exact
          path={PATHS.TECHNOLOGIES.ADD}
          key={PATHS.TECHNOLOGIES.ADD}
        />
      ),
    ],
    <PrivateLayouted
      component={Technologies}
      exact
      path={PATHS.TECHNOLOGIES.LIST_NF}
      key={PATHS.TECHNOLOGIES.LIST_NF}
    />,
    ...[
      canDo('write:technologies', 'business') && (
        <PrivateWizardSingle
          component={Technologies}
          exact
          path={PATHS.TECHNOLOGIES.EDIT_NF}
          key={PATHS.TECHNOLOGIES.EDIT_NF}
        />
      ),
    ],
    ...[
      canDo('write:technologies', 'business') && (
        <PrivateWizardSingle
          component={Technologies}
          exact
          path={PATHS.TECHNOLOGIES.ADD_NF}
          key={PATHS.TECHNOLOGIES.ADD_NF}
        />
      ),
    ],
  ];
};
