import React from 'react';

const LineLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="20"
      viewBox="0 0 24 20"
    >
      <path d="m 15.373201,0.73280395 c -3.657922,0 -6.852733,1.80997005 -8.6287609,4.51361835 H 2.1775689 L 1.5550643,9.007159 h 3.7827722 c -0.047631,0.3701172 -0.078961,0.7454373 -0.078961,1.127487 0,0.382049 0.03133,0.757369 0.078961,1.127486 H 1.1804596 L 0.55795488,15.022869 H 6.7444401 c 1.7760279,2.703648 4.9708389,4.513618 8.6287609,4.513618 3.091326,0 5.857465,-1.287391 7.712448,-3.318188 l -3.701975,-3.578945 c -0.81514,1.42814 -2.305919,2.383515 -4.010473,2.383515 -2.577985,0 -4.667867,-2.188015 -4.667867,-4.888223 0,-2.7002093 2.089882,-4.8882237 4.667867,-4.8882237 1.592672,1e-7 2.997292,0.8341197 3.839697,2.1080694 L 22.868966,3.8214558 C 21.017743,1.9241641 18.346063,0.73280395 15.373201,0.73280395 Z" />
    </svg>
  );
};

export default LineLogo;
