// import CloseIcon from 'assets/images/CloseIcon';
import { ReactComponent as CloseIcon } from 'assets/images/CloseIcon.svg';
import React from 'react';

import { Container } from './styles';

export const CloseBox = ({ className, animated = true, ...props }) => {
  return (
    <Container className={className} animated={animated} {...props}>
      <CloseIcon />
    </Container>
  );
};
