import styled, { css, keyframes } from 'styled-components';
import Configs from 'styles/variables';
import media from 'styles/media';
import BackButton from 'components/BackButton';
import RoundedButton from 'components/RoundedButton';

const BOX_WIDTH = 67;
const SUB_WIDTH = 570;
const BACKGROUND_MENU = Configs.header.backgroundMenu;
const BACKGROUND_SUBMENU = Configs.header.backgroundMenuArea;
const BACKGROUND_LOGO = Configs.header.backgroundLogo;
const USER_BOX_WIDTH = 380 - BOX_WIDTH * 2;
const SPEED_BASE = 0.25;

const closebutton = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
`;

const opensub = keyframes`
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100%{
    transform: translateY(0px);
    opacity: 1;
  }
`;

const opensubMob = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100%{
    transform: translateX(0);
    opacity: 1;
  }
`;

const opentab = keyframes`
  0% {
    transform: translateX(10%);
    opacity: 0;
  }
  100%{
    transform: translateX(0);
    opacity: 1;
  }
`;

const showLink = keyframes`
  0% {
    transform: translateX(-35px);
    opacity: 0;
  }
  100%{
    transform: translateX(0);
    opacity: 1;
  }
`;

const createLink = (total, delay = 0) => {
  let styles = '';

  for (let i = 0; i < total; i += 1) {
    styles += `
       &:nth-child(${i}) > a {
         animation-delay: ${i * 0.05 + delay}s;
       }
     `;
  }

  return css`
    ${styles}
  `;
};

export const Container = styled.div`
  position: fixed;
  display: flex;
  z-index: 90;
  width: 100%;
  height: ${Configs.header.height}px;
  flex-direction: row;
  &.open {
    z-index: 1023;
  }
  ${media.tablet`
    position: absolute;
    top: 0;
    left: 0;
  `}
  ${media.mobile`
    z-index: 1229;
  `}
  &.hasbar {
    ${media.mobile`
      padding-top: 16px;
      z-index: 1229;
    `}
  }
`;

export const LogoBox = styled.div`
  background: ${BACKGROUND_LOGO};
  border: none;
  height: ${Configs.header.height}px;
  width: ${BOX_WIDTH}px;
  z-index: 4;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  fill: ${Configs.yellow};
  transition: all ${SPEED_BASE}s ease;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    transform: translateY(-50%);
    height: 25px;
    opacity: 0.2;
    background: ${Configs.header.dividerColor};
    transition: opacity 0.05s ease;
  }
  img {
    width: 24px;
    height: 20px;
    object-fit: contain;
  }
  &.open {
    background-color: #1a1b21;
    &:after {
      opacity: 0;
    }
  }
`;

export const MenuBox = styled.div`
  background: ${BACKGROUND_LOGO};
  position: relative;
  border: none;
  height: ${Configs.header.height}px;
  width: ${BOX_WIDTH}px;
  z-index: 4;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: all ${SPEED_BASE * 1.1}s ease;
  cursor: pointer;
  &.open {
    opacity: 0;
    transform: translateY(-100%);
  }
  ${media.tablet`
    position: fixed;
    right: 32px;
    border-radius: 32px;
    width: ${Configs.header.height}px;
    bottom: 32px;
    &.dashboard {
      bottom: 32px;
    }
    &.open {
      opacity: 0;
      transform: translateY(100%);
    }
  `}
`;

export const SwitchUserBox = styled.div`
  background: ${Configs.yellow};
  position: relative;
  border: none;
  height: ${Configs.header.height}px;
  width: ${BOX_WIDTH}px;
  z-index: 4;
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: 1;
  transition: all ${SPEED_BASE * 1.1}s ease;
  cursor: pointer;
  padding-left: 8px;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    transform: translateY(-50%);
    height: 25px;
    opacity: 0.3;
    background: ${Configs.darkGray};
    transition: opacity 0.05s ease;
  }
  &.open {
    opacity: 0;
    transform: translateY(-100%);
  }
`;

export const UserBox = styled.div`
  background: ${Configs.yellow};
  position: relative;
  border: none;
  height: ${Configs.header.height}px;
  min-width: ${USER_BOX_WIDTH}px;
  max-width: ${USER_BOX_WIDTH + 100}px;
  z-index: 5;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  padding-left: 22px;
  padding-right: 22px;
  font-size: 16px;
  letter-spacing: -0.22px;
  color: ${Configs.darkText};
  opacity: 1;
  transition: all ${SPEED_BASE}s ${SPEED_BASE / 4}s ease;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & > div {
    display: inline-block;
    margin: 10px 10px 10px 0;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }
  ${media.tablet`
      height: ${Configs.header.height}px;
      min-width: ${Configs.header.height}px;
      max-width: ${Configs.header.height}px;
      border-radius: ${Configs.header.height}px;
      padding: 0;
      position: absolute;
      top: 34px;
      right: 34px;
      span {
        display: none;
      }
      & > div {
        display: inline-block;
        margin: 0;
        height: ${Configs.header.height}px;
        min-width: ${Configs.header.height}px;
        max-width: ${Configs.header.height}px;
        border-radius: ${Configs.header.height}px;
      }
  `}
  &.open {
    transition: all ${SPEED_BASE}s ease;
    opacity: 0;
    transform: translateY(-100%);
  }
  ${media.mobile`
      display: none;
  `}
`;

export const Submenu = styled.div`
  background: ${Configs.header.backgroundSubmenu};
  position: relative;
  border: none;
  height: ${Configs.header.height}px;
  flex: 1;
  z-index: 5;
  align-items: center;
  justify-content: left;
  display: flex;
  padding-left: 22px;
  font-size: 24px;
  letter-spacing: -0.22px;
  color: ${Configs.darkText};
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.25s ease;
  &.show {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const MenuIcon = styled.div`
  position: relative;
  background: ${Configs.header.hamburgerColor};
  height: 2px;
  margin-top: 10px;
  width: 22px;
  &:before,
  &:after {
    content: '';
    position: absolute;
    background: ${Configs.header.hamburgerColor};
    height: 2px;
    width: 22px;
    transform: translateY(-7px);
  }
  &:after {
    transform: translateY(7px);
  }
`;

export const TextMenu = styled.span`
  color: #fff;
  font-size: 9px;
  padding-top: 11px;
`;

export const FloatingMenu = styled.div`
  position: relative;
  width: ${SUB_WIDTH}px;
  background: ${BACKGROUND_MENU};
  left: -${BOX_WIDTH}px;
  top: 0;
  transform: translateX(-${SUB_WIDTH * 2}px);
  height: 100%;
  opacity: 0;
  transition: all 0.25s ease;
  padding: ${Configs.header.height}px 0 70px;
  display: flex;
  align-items: center;
  &.open {
    z-index: 1;
    transition: all 0.25s 0.75s ease;
    transform: translateX(${BOX_WIDTH}px);
    opacity: 1;
    ${media.tablet`
      transform: none;
      width: 100%;
    `}
    & > ul > li {
      & > a {
        animation: ${showLink} 0.25s ease forwards;
      }
      ${createLink(12, 0.75)};
    }
  }
  ${media.tablet`
    left: 0;
  `}
`;

export const Teardrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
  transform: translate(-100%, -100%) scale(0);
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: ${Configs.header.backgroundMenuArea};
    width: 300vw;
    height: 300vw;
    transform: translate(-100%, -100%) scale(0);
    border-radius: 0 0 200vw 0;
    opacity: 0;
    transition: opacity ${SPEED_BASE}s ${SPEED_BASE * 2}s linear,
      transform ${SPEED_BASE * 2}s ${SPEED_BASE}s linear;
  }
  &.open {
    transform: translate(0) scale(1);
    z-index: 1022;
    &:before {
      opacity: 1;
      transition: opacity ${SPEED_BASE / 2}s linear,
        transform ${SPEED_BASE * 1.75}s linear;
      transform: translate(0) scale(1);
    }
  }
`;

export const FloatingSubMenu = styled.div`
  position: absolute;
  width: calc(100vw - ${SUB_WIDTH}px);
  background: ${BACKGROUND_SUBMENU};
  left: ${SUB_WIDTH - 87}px;
  top: 0;
  height: 100%;
  transform: translateY(30px);
  opacity: 0;
  z-index: -1;
  padding: ${Configs.header.height}px 0 70px;
  & ul {
    justify-content: flex-start;
    padding-left: 70px;
  }
  &.open {
    z-index: 2;
    animation: ${opensub} 0.25s ease forwards;
    ${media.tablet`
      animation: ${opensubMob} 0.45s ease forwards;
      z-index: 999;
    `}
    & > ul > li {
      a {
        animation: ${showLink} 0.1s ease forwards;
      }
      ${createLink(12, 0.1)};
    }
  }
  ${media.tablet`
    transform: translateX(100%);
    left: 0;
    width: 100%;
  `}
`;

export const MenuList = styled.ul`
  list-style: none;
  padding: 30px 0 0 130px;
  margin: 0;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 500px;
  flex: 1;
  ${media.tablet`
    width: 100%;
    padding: 0 0 100px 45px;
    justify-content: flex-start;
  `}
`;

export const MenuItem = styled.li.attrs((props) => ({
  size: props.sub ? 24 : 40,
}))`
  font-size: ${(props) => props.size}px;
  list-style: none;
  margin: 0;
  ${media.tablet`
    font-size: 24px;
  `}
`;

export const LinkEl = styled.a`
  text-decoration: none;
  color: ${Configs.header.menuColor};
  font-weight: normal;
  padding: 10px 0;
  display: block;
  width: 100%;
  position: relative;
  transform: translateX(-35px);
  opacity: 0;
  line-height: 1;
  letter-spacing: -0.8px;
  transition: all 0.5s ease;
  ${media.tablet`
    padding: 12px 0;
  `}
  &:hover,
  &.active {
    color: ${Configs.yellow};
    text-decoration: underline;
  }
`;

export const SubmenuItem = styled.div`
  position: relative;
  align-items: center;
  justify-content: left;
  display: flex;
  padding: 12px 20px;
  font-size: 14px;
  letter-spacing: -0.22px;
  color: ${Configs.darkText};
  transition: all 0.4s ease;
  border-radius: 30px;
  cursor: default;
  &:hover {
    background: ${Configs.yellow};
  }
`;

export const LogoutBox = styled.div`
  position: absolute;
  left: ${BOX_WIDTH}px;
  top: 12px;
  width: 90px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  opacity: 0;
  transition: all 0.15s ease;
  transform: translateY(-100%);
  padding: 5px 0 0 ${BOX_WIDTH + 10}px;
  .btn {
    background-color: transparent;
    color: ${Configs.gray};
    &:hover {
      background-color: ${Configs.yellow};
      color: ${Configs.black};
    }
  }
  &.open {
    animation: ${closebutton} 0.25s 0.5s ease forwards;
  }
  ${media.tablet`
    left: unset;
    right: 10px;
    left: unset;
    width: auto;
  `}
`;

export const MenuBody = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  transition: all 0.5s ease;
  &.open {
    transition: none;
    transform: translateX(0);
  }
`;
export const FloatingTabs = styled.div`
  flex: 1;
  display: flex;
  ${media.tablet`
    position: fixed;
  `}
`;
export const FloatingTab = styled.div`
  flex: 1;
  background-color: #1b1c22;
  opacity: 0;
  display: flex;
  &.open {
    animation: ${opentab} 0.25s 0.75s ease forwards;
    ${({ hideSearch }) => (hideSearch ? 'z-index: -1' : 'z-index: 2')};
  }
`;

export const BackNavigation = styled(BackButton)`
  position: fixed;
  bottom: 40px;
  left: 32px;
  top: unset;
  background: transparent;
  display: none;
  ${media.tablet`
    &.open {
      display: flex;
      z-index: 1999;
    }
  `}
  svg {
    fill: ${Configs.white};
    height: 26px;
  }
`;

export const SwitchUserScreen = styled.div``;

export const FloatingMenuBank = styled(FloatingMenu)`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  &:nth-child(1) {
    ${media.mobile`
      padding-bottom: 0;
    `}
  }
`;

export const AuthCard = styled(RoundedButton)`
  width: auto;
  min-height: 60px;
  ${media.mobile`
    width: 90%;
  `}
  padding-left: 12px;
  span {
    ${media.mobile`
      text-align: left;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  }
`;

export const MenuBodySelect = styled(MenuBody)`
  ${media.mobile`
    flex-direction: column;
    overflow: auto;
  `}
`;
