/* eslint-disable import/no-anonymous-default-export */
import { Link as RouterLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import media from 'styles/media';
import Configs from 'styles/variables';

const ShowLink = keyframes`
  0% {
    transform: translateX(-35px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  };
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-y: auto;
  margin-top: 20px;
`;

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const ResultList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: -0.8px;
  color: #ffffff;
  padding-top: 25px;
`;

const Subtitle = styled.label`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.2px;
  color: #d7d7d7;
  margin: 40px 0;
`;

const Label = styled.label`
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #d7d7d7;
  margin: 8px 0;
  font-family: 'Manrope';
  animation: ${ShowLink} 0.25s ease forwards;
`;

const TagList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  font-size: 14px;
  line-height: 2.29;
  letter-spacing: -0.28px;
  text-align: center;
  color: #ffffff;
  margin: 0 16px 16px 0;
  padding: 3px 20px;
  border-radius: 23px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px #ffffff;
  transition: all 0.25s ease;
  &:hover {
    background: ${Configs.yellow};
    border-color: ${Configs.black};
    color: ${Configs.black};
  }
`;

const Find = styled.span`
  color: ${Configs.yellow};
`;

const Link = styled(RouterLink)`
  text-decoration: none;
  color: ${Configs.header.menuColor};
  font-weight: normal;
  margin: 8px 0;
  /* padding: 10px 0; */
  display: block;
  width: 100%;
  position: relative;
  transform: translateX(-35px);
  letter-spacing: -0.8px;
  transition: all 0.5s ease-out;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #d7d7d7;
  font-family: 'Manrope';
  animation: ${ShowLink} 0.25s ease-out forwards;
  ${media.tablet`
    margin: 12px 0;
  `}

  &:hover,
  &.active {
    /* color: ${Configs.yellow}; */
    text-decoration: underline;
  }
`;

export default {
  Container,
  ResultWrapper,
  ResultList,
  TagList,
  Tag,
  Title,
  Find,
  Subtitle,
  Label,
  Link,
};
