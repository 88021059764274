import React from 'react';
import * as S from './style';
import PropTypes from 'prop-types';

const Reticence = ({ children }) => {
  return <S.Reticence>{children}</S.Reticence>;
};

Reticence.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

export default Reticence;
