import React from 'react';
import * as Style from './styles';
import { errorEvent } from 'services/analytics';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const message = error && error.message ? error.message : 'Sem Mensagem';

    errorEvent(`ErrorBoundary: ${message}`, window.location.pathname);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Style.MainContainer>
          <main>
            <div>
              <Style.Title>Algo deu errado.</Style.Title>
              <Style.Text>
                Ocorreu um erro inesperado. Por favor, tente novamente mais
                tarde.
              </Style.Text>
            </div>
            <Style.ButtonGroup>
              <Style.Button onClick={() => window.location.reload()}>
                Atualizar a página
              </Style.Button>
              <Style.Button href={window.location.origin} primary>
                Voltar para o início
              </Style.Button>
            </Style.ButtonGroup>
          </main>
        </Style.MainContainer>
      );
    }

    return this.props.children;
  }
}
