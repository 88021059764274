import styled, { keyframes } from 'styled-components';
import Configs from 'styles/variables';

const dash = keyframes`
  0% {
      stroke-dashoffset: 120;
  }
  20% {
      stroke-dashoffset: 120;
  }
  50% {
      stroke-dashoffset: 0;
  }
  80% {
      stroke-dashoffset: 0;
  }
  100% {
      stroke-dashoffset: 120;
  }
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${Configs.lightGray};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 400;
  svg {
    stroke: ${Configs.black};
    fill: transparent;
    width: 120px;
    height: 120px;
    transform: translateX(-25px);
    stroke-dasharray: 120;
    stroke-dashoffset: 120;
    animation: ${dash} 5s ease-in-out infinite;
  }
`;

export const Text = styled.div`
  padding-top: 8px;
  color: ${Configs.black};
  font-size: 24px;
`;
