import HydeButton from 'components/HydeButton';
import ShowButton from 'components/ShowButton';
import React, { forwardRef, useState } from 'react';
import { Field, PwdControll } from '../styles';

let PasswordInput = ({ className, inputId, ...props }, input) => {
  const [fieldType, setFieldType] = useState('password');

  const showPwd = () => setFieldType('text');
  const hydePwd = () => setFieldType('password');

  return (
    <>
      <Field
        {...props}
        className={className}
        id={inputId}
        ref={input}
        autoComplete={fieldType === 'text' ? props.name : 'disabled'}
        type={fieldType || 'password'}
      />
      {fieldType === 'password' ? (
        <PwdControll onClick={showPwd} {...props}>
          <ShowButton />
        </PwdControll>
      ) : (
        <PwdControll onClick={hydePwd} {...props}>
          <HydeButton />
        </PwdControll>
      )}
    </>
  );
};

export default forwardRef(PasswordInput);
