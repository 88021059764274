const PixOptions = {
  KEY: 'KEY',
  MY_KEYS: 'MY_KEYS',
  ACCOUNT: 'ACCOUNT',
  QRCODE: 'QRCODE',
  COPY_AND_PASTE: 'COPY_AND_PASTE',
};

const PixRegisterKeysTypes = {
  CPF: 'CPF',
  CNPJ: 'CNPJ',
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  RANDOM: 'EVP',
};

export { PixOptions, PixRegisterKeysTypes };
