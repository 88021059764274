import React, { useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'Routes';
import GlobalStyle from 'styles/global';
import { Suspense } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { UserAuthorizationProvider } from 'contexts/authorization';
import { RegistrationsProvider } from 'contexts/registrations';
import i18n from 'translate/i18n';
import * as analytics from 'services/analytics';
import pckg from '../../package.json';
import ErrorBoundary from 'containers/errorBoundary/ErrorBoundary';
import { AccountBusinessProvider } from 'contexts/account-business';
import { AccountRegistryByIdentifierProvider } from 'contexts/account-registry-by-identifier';
import { ApplicationProvider } from 'contexts/application';
import AppLoadInfoHOC from 'components/AppLoadInfoHOC/app-load-info-hoc';
import { AccountRegistryProvider } from 'contexts/account-registry';
import { AgentConflicProvider } from 'contexts/agent-conflict';
import { NewMenuProvider } from 'contexts/new-menu';
import { ModalNotificationsProvider } from 'contexts/modal-notifications';
import { PixKeysProvider } from 'contexts/pix-keys';
import { ModalManagerProvider } from 'contexts/modal-manager';
import { AnalyticsProvider } from 'contexts/analyticsContext';
import Loading from 'components/_v2/Loading';
import { ConfigCatProvider } from 'configcat-react';
import useResponsive from 'hooks/useResponsive';
import { HotjarProvider } from 'contexts/hotjar-context';

function App() {
  const { isMobile } = useResponsive(768);
  const config = {
    domain: process.env.REACT_APP_DOMAIN,
    clientId: process.env.REACT_APP_CLIENT_ID,
    audience: process.env.REACT_APP_AUDIENCE,
    useRefreshTokens: true,
    redirect: `${window.location.origin}/callback`,
    cacheLocation: 'localstorage',
    scope: 'openid profile email',
    logout: `${window.location.origin}`,
  };

  //valida codigo único de usuário (UTM) e se veio
  // através de uma campanha (CAMPAING) e envia essas infos para o Auth0 pelo Auth0Provider.
  const search = window.location.search;
  let utm = new URLSearchParams(search).get('utm_source');
  let campaign = new URLSearchParams(search).get('utm_campaign');
  let partner = new URLSearchParams(search).get('utm_partner');
  let appAlert = new URLSearchParams(search).get('app_alert');
  let invite = false;

  if (utm || campaign || partner) {
    invite = true;
    localStorage.setItem('utm_source_cb', utm);
    localStorage.setItem('utm_campaign_cb', campaign);
    localStorage.setItem('utm_partner_cb', partner);
  } else {
    utm = localStorage.getItem('utm_source_cb');
    campaign = localStorage.getItem('utm_campaign_cb');
    partner = localStorage.getItem('utm_partner_cb');
  }

  console.log(`v${pckg.version}`);
  i18n.changeLanguage('pt-BR');
  analytics.initialize();

  const configCatKey = useMemo(() => {
    const key = isMobile
      ? process.env.REACT_APP_CONFIGCAT_MOBILE
      : process.env.REACT_APP_CONFIGCAT_DESKTOP;

    return key ?? '';
  }, [isMobile]);

  return (
    <ErrorBoundary>
      <React.StrictMode>
        <Auth0Provider
          domain={config.domain}
          clientId={config.clientId}
          redirectUri={config.redirect}
          useRefreshTokens={config.useRefreshTokens}
          audience={config.audience}
          cacheLocation={config.cacheLocation}
          utm_source={utm}
          utm_campaign={campaign}
          utm_partner={partner}
          screen_hint={invite ? 'signup' : null}
          app_alert={appAlert ?? null}
        >
          <Suspense fallback={<Loading useSuspense={false} />}>
            <GlobalStyle />
            <ConfigCatProvider sdkKey={configCatKey}>
              <UserAuthorizationProvider>
                <RegistrationsProvider>
                  <AccountRegistryByIdentifierProvider>
                    <AccountRegistryProvider>
                      <AccountBusinessProvider>
                        <ModalManagerProvider>
                          <ModalNotificationsProvider>
                            <AgentConflicProvider>
                              <ApplicationProvider>
                                <NewMenuProvider>
                                  <PixKeysProvider>
                                    <HotjarProvider
                                      defaultStart={false}
                                      hotjarId={process.env.REACT_APP_HOTJAR_ID}
                                      hotjarVersion={
                                        process.env.REACT_APP_HOTJAR_VERSION
                                      }
                                    >
                                      <Router>
                                        <AppLoadInfoHOC>
                                          <AnalyticsProvider>
                                            <Routes />
                                          </AnalyticsProvider>
                                        </AppLoadInfoHOC>
                                      </Router>
                                    </HotjarProvider>
                                  </PixKeysProvider>
                                </NewMenuProvider>
                              </ApplicationProvider>
                            </AgentConflicProvider>
                          </ModalNotificationsProvider>
                        </ModalManagerProvider>
                      </AccountBusinessProvider>
                    </AccountRegistryProvider>
                  </AccountRegistryByIdentifierProvider>
                </RegistrationsProvider>
              </UserAuthorizationProvider>
            </ConfigCatProvider>
          </Suspense>
        </Auth0Provider>
      </React.StrictMode>
    </ErrorBoundary>
  );
}

export default App;
