import styled from 'styled-components';
import GlobalConfigs from 'styles/variables';
import * as MenuConfigs from 'styles/menuVariables';

export const Overlay = styled.div.attrs((props) => ({
  className: `alert-progress-overlay ${props.className || ''}`,
}))`
  transition: 0.5s;
  height: var(--vh);
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 401;
  opacity: 1;
  letter-spacing: -0.2px;

  &.alert-progress-overlay-enter {
    opacity: 0;
  }

  &.alert-progress-overlay-enter-done,
  &.alert-progress-overlay-enter-active {
    opacity: 1;
  }

  &.alert-progress-overlay-exit {
    opacity: 1;
  }

  &.alert-progress-overlay-exit-active {
    opacity: 0;
  }

  &.alert-progress-overlay-exit-done {
    opacity: 1;
  }
`;

export const AlertProgress = styled.div.attrs((props) => ({
  className: `alert-progress ${props.className || ''}`,
}))`
  min-height: 64px;
  min-width: 256px;
  position: absolute;
  background-color: ${GlobalConfigs.newColors.t200};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1rem;
  top: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;

  @media (${MenuConfigs.MEDIA_MAX_MOBILE}) {
    width: 100%;
    border-radius: 0px;
    right: auto;
    top: auto;
    bottom: 0px;
  }
`;
export const Content = styled.div.attrs((props) => ({
  className: `alert-progress-content ${props.className || ''}`,
}))`
  padding: 1rem;
  width: 100%;
  font-family: 'Manrope', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  strong {
    margin-top: 0.25rem;
    font-weight: 500;
    display: block;
  }
`;
export const ProgressBar = styled.div.attrs((props) => ({
  className: `alert-progress-progress-bar ${props.className || ''}`,
}))`
  position: absolute;
  align-self: flex-end;
  height: 3px;
  display: block;
  width: 100%;

  &::before {
    content: '';
    height: 3px;
    background-color: ${GlobalConfigs.newColors.p800};
    display: block;
    width: 100%;
    animation: animateProgress
      ${({ sizeProgressSeconds }) =>
        sizeProgressSeconds > 0 ? sizeProgressSeconds + 's' : '0s'}
      linear;

    @keyframes animateProgress {
      from {
        width: 0%;
      }
      to {
        width: 100%;
      }
    }
  }
`;
