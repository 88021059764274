import styled from 'styled-components';
import media from 'styles/media';
import Configs from 'styles/variables';

export const TitleTag = styled.h1.attrs((props) => ({
  maxWidth: props.prose && props.big ? '30ch' : '65ch',
  size: props.big ? 48 : 16,
  padding: props.big ? 13 : 0,
  // marginBottom: props.big ? 26 : 0,
  weight: props.important ? 700 : 300,
  marginBottom: props.marginBottom ? 5 : 16,
  color: props.white
    ? Configs.white
    : props.yellow
    ? Configs.yellow
    : props.error
    ? Configs.errorColor
    : Configs.darkGray,
}))`
  max-width: ${(props) => props.maxWidth};
  font-family: Manrope, sans-serif;
  padding: ${(props) => props.padding}px 0;
  margin-bottom: ${(props) => props.marginBottom}px;
  font-size: ${(props) => props.size}px;
  font-weight: ${(props) => props.weight};
  line-height: 1.15;
  letter-spacing: -0.5px;
  text-align: ${(props) => (props.align ? props.align : 'center')};
  margin: ${(props) => (props.margin ? props.margin : 0)};
  color: ${(props) => props.color};
  & > strong {
    color: ${Configs.yellow};
  }
  & > br {
    ${media.mobile`
      display: inline
    `}
  }
  ${({ small }) =>
    small &&
    `
      padding: 0;
      font-size: 1rem;
      font-weight: 300;
      text-align: initial;
      
      strong {
        color: #000;
        font-weight: 700;
      }
    `}
  ${media.mobile`
    font-family: "Manrope";
    font-size: 30px;
    line-height: 1.25;
    letter-spacing: -0.6px;
    padding: ${(props) => props.padding}px 46px;
    width: 100%;
    text-align: ${(props) => (props.align ? props.align : 'left')};

    ${({ wordBreak }) => wordBreak && 'word-break: break-word'};
    ${({ small }) =>
      small &&
      `
      padding: 0;
      font-size: 1rem;
      font-weight: 300;
      text-align: initial;
      
      strong {
        color: #000;
        font-weight: 700;
      }
    `}
  `}
`;
