import styled, { keyframes } from 'styled-components';
import Configs from 'styles/variables';

const loadAnimation = keyframes`
    0% {
    transform: translate3d(-30%,0,0);
  }
  100% {
    transform: translate3d(30%,0,0);
  }
`;

export const LoaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 20px 15px 15px;
  overflow: hidden;
  background-color: #fff;
  width: 100%;
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    width: 300%;
    z-index: 1;
    margin-left: -150%;
    animation: ${loadAnimation} 1s linear infinite;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 46%,
        rgba(255, 255, 255, 0.35) 50%,
        rgba(255, 255, 255, 0) 54%
      )
      50% 50%;
  }
  & > * {
    flex: 1 1 auto;
    display: flex;
  }
`;
export const LoaderRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;
  &.bottom {
    align-items: flex-end;
  }
`;
export const LoaderEl = styled.div.attrs((props) => ({
  w:
    props.size === 1
      ? 8.33333
      : props.size === 2
      ? 16.66667
      : props.size === 4
      ? 33.33333
      : props.size === 6
      ? 50
      : props.size === 8
      ? 66.66667
      : props.size === 10
      ? 83.33333
      : 100,
}))`
  min-height: 16px;
  max-height: 16px;
  background-color: ${Configs.lightGray};
  margin-bottom: 8px;
  border-right: 8px solid #fff;
  flex: 0 0 ${(props) => props.w}%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  &.bottom {
    align-items: baseline;
  }
  &.big {
    min-height: 32px;
    max-height: 32px;
  }
  &.medium {
    min-height: 24px;
    max-height: 24px;
  }
  &.empty {
    background-color: rgba(255, 255, 255, 0);
  }
`;

export const ImageEl = styled.div`
  position: relative;
  width: 100%;
  min-width: 32px;
  max-width: 100%;
  background-color: ${Configs.lightGray};
  margin-bottom: 15px;
  border-radius: 50%;
  overflow: hidden;
  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;
