import React from 'react';

const PersonProfileManagementIcon = (props) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.5 9.99977C18.5376 9.99977 21 12.4622 21 15.4998C21 18.5373 18.5376 20.9998 15.5 20.9998C12.4624 20.9998 10 18.5373 10 15.4998C10 12.4622 12.4624 9.99977 15.5 9.99977ZM12.8536 15.1462C12.6583 14.951 12.3417 14.951 12.1464 15.1462C11.9512 15.3415 11.9512 15.6581 12.1464 15.8533L14.1464 17.8533C14.3417 18.0486 14.6583 18.0486 14.8536 17.8533L18.8536 13.8533C19.0488 13.6581 19.0488 13.3415 18.8536 13.1462C18.6583 12.951 18.3417 12.951 18.1464 13.1462L14.5 16.7927L12.8536 15.1462ZM10.0223 11.9991C9.72556 12.4624 9.48603 12.9658 9.31362 13.4994L2.25242 13.4997C1.83882 13.4997 1.50354 13.835 1.50354 14.2486V14.8262C1.50354 15.3619 1.69465 15.88 2.04251 16.2874C3.29582 17.755 5.26169 18.5008 8 18.5008C8.59644 18.5008 9.15631 18.4655 9.68023 18.3949C9.92542 18.8898 10.233 19.3484 10.5917 19.7613C9.79624 19.9215 8.93136 20.0008 8 20.0008C4.85414 20.0008 2.46812 19.0957 0.90182 17.2614C0.322058 16.5825 0.00354004 15.719 0.00354004 14.8262V14.2486C0.00354004 13.0065 1.0104 11.9997 2.25242 11.9997L10.0223 11.9991ZM8 0.00439453C10.7614 0.00439453 13 2.24297 13 5.00439C13 7.76582 10.7614 10.0044 8 10.0044C5.23857 10.0044 3 7.76582 3 5.00439C3 2.24297 5.23857 0.00439453 8 0.00439453ZM8 1.50439C6.067 1.50439 4.5 3.0714 4.5 5.00439C4.5 6.93739 6.067 8.50439 8 8.50439C9.93299 8.50439 11.5 6.93739 11.5 5.00439C11.5 3.0714 9.93299 1.50439 8 1.50439Z" />
    </svg>
  );
};

export default PersonProfileManagementIcon;
