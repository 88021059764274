import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { dateFormat } from 'tools';

const DATE_FORMATS = {
  DATE_STRING: 'date_string',
};

const handleDefaultFormatFunc = (value, format, lng) => {
  /*
   * Format date string to locale
   */
  if (format === DATE_FORMATS.DATE_STRING) {
    if (typeof value === 'string') {
      return dateFormat(value, lng);
    }
  }
  return value;
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['common', 'public', 'dashboard', 'steps-registers'],
    defaultNS: 'common',
    fallbackLng: {
      'pt-br': ['pt-BR'],
      default: ['pt-BR'],
    },
    supportedLngs: ['pt-BR'],
    whitelist: ['pt-BR'],
    debug: false,
    transSupportBasicHtmlNodes: true,
    compatibilityJSON: 'v3',
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
      format: handleDefaultFormatFunc,
    },
    react: {
      //wait: true,
      useSuspense: false,
    },
  });

export default i18n;
