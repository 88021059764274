import React from 'react';
import WizardPublic from 'containers/WizardPublic';
import { Route } from 'react-router-dom';

const PublicWizard = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <WizardPublic {...props}>
          <Component {...props} />
        </WizardPublic>
      )}
    />
  );
};

export default PublicWizard;
