import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { KEYS } from './error-keys';

const usePixKeysError = () => {
  const errors = useMemo(
    () => [
      {
        code: 500,
        defaultMessage: 'register generic error',
        key: KEYS.GENERIC,
        title: null,
        message: null,
        btnLabel: null,
        redirectToDashboard: false,
      },
      {
        code: 400,
        key: KEYS.INVALID_PARAMETER,
        title: null,
        message: null,
        btnLabel: null,
        redirectToDashboard: false,
      },
      {
        code: 409,
        key: KEYS.ADDRESSING_KEY_VALUE_AND_ACCOUNT_HOLDER_DOCUMENT_ARE_DIFFERENT,
        title: null,
        message: null,
        btnLabel: null,
        redirectToDashboard: false,
      },
      {
        code: 409,
        key: KEYS.ADDRESSING_KEY_TYPE_IS_INVALID_FOR_ACCOUNT_HOLDER_TYPE,
        title: null,
        message: null,
        btnLabel: null,
        redirectToDashboard: false,
      },
      {
        code: 409,
        key: KEYS.TARGET_ACCOUNT_DOES_NOT_EXIST,
        title: null,
        message: null,
        btnLabel: null,
        redirectToDashboard: false,
      },
      {
        code: 409,
        key: KEYS.INVALID_ACCOUNT_STATUS,
        title: null,
        message: null,
        btnLabel: null,
        redirectToDashboard: false,
      },
      {
        code: 409,
        key: KEYS.INVALID_ACCOUNT_HOLDER_STATUS,
        title: null,
        message: null,
        btnLabel: null,
        redirectToDashboard: false,
      },
      {
        code: 409,
        key: KEYS.MAXIMUM_ENTRIES_COUNT_REGISTERED_FOR_ACCOUNT,
        title: null,
        message: null,
        btnLabel: null,
        redirectToDashboard: false,
      },
      {
        code: 409,
        key: KEYS.ENTRY_ALREADY_EXISTS_TO_SAME_HOLDER_AND_ANOTHER_ACCOUNT,
        title: null,
        message: null,
        btnLabel: null,
        redirectToDashboard: false,
      },
      {
        code: 409,
        key: KEYS.ENTRY_ALREADY_EXISTS_TO_ANOTHER_HOLDER,
        title: (
          <Trans
            ns="pix-keys"
            i18nKey="titles.registered_another_institution"
            defaults="Chave Pix cadastrada em outra instituição"
          />
        ),
        message: (
          <Trans
            ns="pix-keys"
            i18nKey="subtitles.registered_another_institution"
            defaults="Ainda não conseguimos fazer portabilidade, por isso pedimos que você delete manualmente a chave na outra instituição."
          />
        ),
        btnLabel: (
          <Trans
            ns="pix-keys"
            i18nKey="buttons.register_another_key"
            defaults="Cadastrar outra chave"
          />
        ),
        showData: true,
      },
      {
        code: 409,
        key: KEYS.ENTRY_ALREADY_EXISTS_TO_SAME_ACCOUNT,
        title: (
          <Trans
            ns="pix-keys"
            i18nKey="titles.registered_another_institution"
            defaults="Chave Pix cadastrada em outra instituição"
          />
        ),
        message: (
          <Trans
            ns="pix-keys"
            i18nKey="subtitles.registered_another_institution"
            defaults="Ainda não conseguimos fazer portabilidade, por isso pedimos que você exclua manualmente a chave na outra instituição."
          />
        ),
        btnLabel: (
          <Trans
            ns="pix-keys"
            i18nKey="buttons.register_another_key"
            defaults="Cadastrar outra chave"
          />
        ),
        showData: true,
      },
      {
        code: 409,
        key: KEYS.ENTRY_ALREADY_EXISTS_TO_SAME_OWNER_INTO_ANOTHER_PLAYER,
        title: (
          <Trans
            ns="pix-keys"
            i18nKey="titles.registered_another_institution"
            defaults="Chave Pix cadastrada em outra instituição"
          />
        ),
        message: (
          <Trans
            ns="pix-keys"
            i18nKey="subtitles.registered_another_institution"
            defaults="Ainda não conseguimos fazer portabilidade, por isso pedimos que você delete manualmente a chave na outra instituição."
          />
        ),
        btnLabel: (
          <Trans
            ns="pix-keys"
            i18nKey="buttons.register_another_key"
            defaults="Cadastrar outra chave"
          />
        ),
        showData: true,
      },
      {
        code: 409,
        key: KEYS.EVP_ENTRY_CANNOT_REGISTER_SHORT_INTERVAL,
        title: (
          <Trans
            ns="pix-keys"
            i18nKey="titles.wait_interval"
            defaults="Aguarde para cadastrar uma nova chave"
          />
        ),
        message: (
          <Trans
            ns="pix-keys"
            i18nKey="subtitles.wait_interval"
            defaults="Por segurança, é necesário um intervalo de aproximadamente 1 minuto para criar novas chaves."
          />
        ),
        btnLabel: (
          <Trans
            ns="pix-keys"
            i18nKey="buttons.back_to_area_pix"
            defaults="Voltar para Area Pix"
          />
        ),
        redirectToDashboard: false,
      },
      {
        code: 409,
        key: KEYS.INVALID_USER_ID,
        title: null,
        message: null,
        btnLabel: null,
        redirectToDashboard: false,
      },
      {
        code: 409,
        key: KEYS.PERMISSION_NOT_GRANTED,
        title: null,
        message: null,
        btnLabel: null,
        redirectToDashboard: false,
      },
      {
        code: 404,
        key: KEYS.NOTFOUND,
        title: null,
        message: null,
        btnLabel: null,
        redirectToDashboard: false,
      },
      {
        code: 400,
        key: KEYS.INVALID_PASSWORD,
        defaultMessage: 'invalid password',
        title: (
          <Trans
            ns="steps-transfers"
            i18nKey="messages.error.titles.invalid_password"
            defaults="Senha incorreta"
          />
        ),
        btnLabel: null,
        message: (
          <Trans
            ns="steps-transfers"
            i18nKey="messages.error.invalid_password"
            defaults="Digite a sua senha transacional de 4 dígitos. Após 3 tentativas incorretas, sua senha será bloqueada."
          />
        ),
        redirectToDashboard: false,
      },
      {
        code: 400,
        key: KEYS.EXCEED_MAX_VALIDATE_ATTEMPTS,
        defaultMessage: 'exceed max validate attempts',
        title: null,
        btnLabel: null,
        message: (
          <Trans
            ns="steps-transfers"
            i18nKey="messages.error.max_password_attempts"
            defaults="Você atingiu o limite máximo de tentativas e, por segurança, <br/> suas transações estão bloqueadas. Entre em contato conosco para desbloqueio."
          />
        ),
        redirectToDashboard: true,
      },
    ],
    []
  );

  return {
    errors,
  };
};

export default usePixKeysError;
