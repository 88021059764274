import React, { useCallback, useContext, useEffect, useState } from 'react';
import useAccountsService from 'services/accounts';
import UserAuthorizationContext from './authorization';
import useToken from 'hooks/useToken';

const AccountRegistryByIdentifierContext = React.createContext({
  registry: null,
  message: null,
  success: false,
  error: false,
  loading: false,
  loadAccountByIdentifier: (
    paramIdentifier,
    paramAutoSetIdentifier = true,
    detailed = false
  ) => {},
});

export const AccountRegistryByIdentifierProvider = ({ children }) => {
  const [identifier, setIdentifier] = useState();
  const [registry, setRegistry] = useState();
  const [autoSetIdentifier, setAutoSetIdentifier] = useState();
  const [fetchState, setFetchState] = useState('');
  const [message, setMessage] = useState();
  const [detailed, setDetailed] = useState(false);
  const token = useToken();

  const { getBankAccount } = useContext(UserAuthorizationContext);
  const { getAccountByIdentifier } = useAccountsService(token);
  const loading = fetchState === 'loading';
  const error = fetchState === 'error';
  const success = fetchState === 'success';
  const initial = fetchState === 'initial';

  const handleError = useCallback((e) => {
    console.error(e.message, '.Error trying to fetch account registry');
    if (
      e.response &&
      e.response.data &&
      e.response.data.code >= 400 &&
      e.response.data.code < 500
    ) {
      if (e.response.data.code === 404) {
        setMessage('Ops... parece que sua conta não foi registrada!');
      } else {
        setMessage('Ocorreu um erro ao buscar os dados.');
      }
    } else {
      setMessage('Oops... ocorreu um erro inesperado!');
    }
    setRegistry(null);
    setFetchState('error');
  }, []);

  const handleResponse = useCallback((response, actions) => {
    const { data } = response;

    setRegistry(data ? data : null);
    setFetchState('success');
  }, []);

  useEffect(() => {
    let mounted = true;
    const acc = getBankAccount();

    if (mounted && autoSetIdentifier && acc && acc !== identifier) {
      setIdentifier(acc);
    }
    return () => (mounted = false);
  }, [identifier, autoSetIdentifier, getBankAccount]);

  useEffect(() => {
    let mounted = true;
    const fetchAccountRegistry = async () => {
      setFetchState('loading');
      try {
        handleResponse(await getAccountByIdentifier(identifier, detailed));
      } catch (e) {
        handleError(e);
      }
    };

    if (token && mounted && initial && identifier) {
      fetchAccountRegistry();
    }
    return () => (mounted = false);
  }, [
    token,
    initial,
    handleError,
    handleResponse,
    getAccountByIdentifier,
    identifier,
    detailed,
  ]);

  const loadAccountByIdentifier = useCallback(
    (paramIdentifier, paramAutoSetIdentifier = true, detailed = false) => {
      if (paramIdentifier) {
        setIdentifier(paramIdentifier);
        setAutoSetIdentifier(paramAutoSetIdentifier);
        setDetailed(detailed);
      }
      setFetchState('initial');
    },
    []
  );

  const defaultValues = {
    registry,
    message,
    success,
    error,
    loading,
    loadAccountByIdentifier,
  };

  return (
    <AccountRegistryByIdentifierContext.Provider value={defaultValues}>
      {children}
    </AccountRegistryByIdentifierContext.Provider>
  );
};

const useAccountRegistryByIdentifierContext = () =>
  useContext(AccountRegistryByIdentifierContext);

export default useAccountRegistryByIdentifierContext;
