import useToken from 'hooks/useToken';
import React, { useState, useEffect } from 'react';
import useCategoriesService from 'services/categories';
const ProductCategoriesContext = React.createContext({ list: [] });

export const ProductCategoriesProvider = ({ children }) => {
  const [productCategories, setProductCategories] = useState(null);
  const [loading, setLoading] = useState(true);
  const [canLoad, setCanLoad] = useState(false);
  const { getCategories } = useCategoriesService();
  const token = useToken();

  useEffect(() => {
    const fetchProductCategories = async () => {
      setLoading(true);
      try {
        const productCategoriesReq = await getCategories(token);

        if (productCategoriesReq.data.categories) {
          setProductCategories(productCategoriesReq.data.categories);
        }
        setLoading(false);
      } catch (e) {
        if (e) {
          console.error(e.message, 'Try updating the API key in App.js');
        }
        setLoading(false);
      }
    };

    if (token && canLoad) fetchProductCategories();
  }, [token, canLoad, getCategories]);

  const loadProductCategories = () => {
    setCanLoad(true);
  };

  return (
    <ProductCategoriesContext.Provider
      value={{
        productCategories,
        setProductCategories,
        loadProductCategories,
        loading,
      }}
    >
      {children}
    </ProductCategoriesContext.Provider>
  );
};

export default ProductCategoriesContext;
