import React from 'react';
import * as S from '../style';

export default function MenuButtonSwitchProfile({
  leftButtonText,
  leftButtonOnClick,
  leftButtonActive,
  rightButtonText,
  rightButtonOnClick,
  rightButtonActive,
  allowLeftButton,
}) {
  return (
    <S.ButtonSwitchProfile>
      <div>
        <button
          className={leftButtonActive ? 'active' : undefined}
          onClick={rightButtonActive ? leftButtonOnClick : null}
        >
          {leftButtonText}
        </button>
        {allowLeftButton && (
          <button
            className={rightButtonActive ? 'active' : undefined}
            onClick={leftButtonActive ? rightButtonOnClick : null}
          >
            {rightButtonText}
          </button>
        )}
      </div>
    </S.ButtonSwitchProfile>
  );
}
