import React, { useContext } from 'react';
import WizardContext from 'contexts/wizard';
import { WizardButtonBack } from './styles';

const WizardBackButton = ({ className, onClick, hydeStep, ...props }) => {
  const { setCurrentStep, currentStep } = useContext(WizardContext);

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const classList = [className, 'wizard-back-button'];

  if (hydeStep) {
    if (currentStep !== hydeStep) classList.push('visible');
  } else {
    if (currentStep > 1) classList.push('visible');
  }

  return (
    <WizardButtonBack
      {...props}
      className={classList}
      onClick={onClick || prevStep}
    />
  );
};

export default WizardBackButton;
