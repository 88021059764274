import React from 'react';
import S from './styles';

const WizardBasicHeader = ({ rightButtom, actionClose, actionGoBack }) => {
  return (
    <S.Container>
      <S.ButtonBack className="visible" onClick={actionGoBack} />
      {rightButtom ? (
        rightButtom
      ) : (
        <S.ButtonClose
          className="open"
          animated={false}
          onClick={actionClose}
        />
      )}
    </S.Container>
  );
};

export default WizardBasicHeader;
