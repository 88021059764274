import React from 'react';

import { Container } from './styles';

const TwoColumnsLayout = ({ children, className, ...props }) => {
  return (
    <Container className={className} {...props}>
      {children}
    </Container>
  );
};

export default TwoColumnsLayout;
