import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import useToken from 'hooks/useToken';
import useNotifications from 'services/notifications';
import useConstants from 'services/contants';
import { useTranslation } from 'react-i18next';
import useAccountRegistry from './account-registry';
import { getData, removeData, setData } from 'services/session-store';

const ModalNotificationsContext = createContext({
  currentTemplate: {} || null,
  notifications: [] || null,
  loading: true || false,
  success: true || false,
  error: true || false,
  initial: true || false,
  canShowModal: true || false,
  errorMessage: '' || null,
  clearModalView: () => {},
  createModalView: (data) => ({}),
  loadModalNotifications: () => {},
});

export const ModalNotificationsProvider = ({ children }) => {
  const token = useToken();
  const { t } = useTranslation('modal-notifications');
  const { FETCH_STATES, STORAGE_LABELS } = useConstants();
  const { registry: account } = useAccountRegistry();
  const { getNotificationsModal, postNotificationsModal } =
    useNotifications(token);

  const [currentTemplate, setCurrentTemplate] = useState();
  const [notifications, setNotifications] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [fetchState, setFetchState] = useState(FETCH_STATES.NONE);

  const success = fetchState === FETCH_STATES.SUCCESS;
  const loading = fetchState === FETCH_STATES.LOADING;
  const error = fetchState === FETCH_STATES.ERROR;
  const initial = fetchState === FETCH_STATES.INITIAL;

  const handleError = useCallback(
    (e) => {
      const { response } = e;

      if (
        response &&
        response?.data?.code === 404 &&
        response?.data?.messages.includes('not found')
      ) {
        setFetchState(FETCH_STATES.SUCCESS);
        setData(STORAGE_LABELS.SMN, 0);
      } else {
        //console.error(e.message, '.Error trying to fetch notifications modal');
        setErrorMessage(t('modal-notifications:default'));
        setFetchState(FETCH_STATES.ERROR);
      }
      setNotifications(null);
    },
    [t, FETCH_STATES, STORAGE_LABELS]
  );

  const getNotifications = useCallback(async () => {
    try {
      setFetchState(FETCH_STATES.LOADING);
      const response = await getNotificationsModal(account.id);

      setNotifications(response?.data?.templates);
      setCurrentTemplate(
        response?.data?.templates?.length > 0 && response?.data?.templates[0]
      );
      setFetchState(FETCH_STATES.SUCCESS);
    } catch (e) {
      handleError(e);
    }
  }, [getNotificationsModal, account, handleError, FETCH_STATES]);

  const createModalView = useCallback(
    async (data) => {
      try {
        const response = await postNotificationsModal(data);

        setData(STORAGE_LABELS.SMN, 0);
        return response;
      } catch (e) {
        console.error(e.message, '. Error on create notifications modal.');
      }
    },
    [postNotificationsModal, STORAGE_LABELS]
  );

  const loadModalNotifications = () => {
    setFetchState(FETCH_STATES.INITIAL);
  };

  const clearModalView = useCallback(() => {
    removeData(STORAGE_LABELS.SMN);
  }, [STORAGE_LABELS]);

  const canShowModal = getData(STORAGE_LABELS.SMN) !== '0';

  useEffect(() => {
    let mounted = true;

    if (mounted && initial && token) {
      getNotifications();
    }
    return () => {
      mounted = false;
    };
  }, [token, initial, getNotifications, FETCH_STATES]);

  const values = {
    currentTemplate,
    notifications,
    error,
    success,
    loading,
    initial,
    canShowModal,
    errorMessage,
    createModalView,
    clearModalView,
    loadModalNotifications,
  };

  return (
    <ModalNotificationsContext.Provider value={values}>
      {children}
    </ModalNotificationsContext.Provider>
  );
};

const useModalNotificationsContext = () =>
  useContext(ModalNotificationsContext);

export default useModalNotificationsContext;
