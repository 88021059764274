import React, { useCallback, useEffect, useState } from 'react';
import useDebounce from 'hooks/useDebounce';
import useToken from 'hooks/useToken';
import useCustomersService from 'services/customers';

const SearchMenuContext = React.createContext({});

export const SearchMenuProvider = ({ children }) => {
  const [customers, setCustomers] = useState();
  const [fetchState, setFetchState] = useState('');
  const [searchText, setSearchText] = useState();
  const { getList } = useCustomersService();
  const searchValue = useDebounce(searchText, 1000);
  const token = useToken();

  const loading = fetchState === 'loading';
  const error = fetchState === 'error';
  const success = fetchState === 'success';
  const initial = fetchState === 'initial';

  const handleError = useCallback((e) => {
    console.error(e.message, '. Error trying to search customer on menu');
    setFetchState('error');
  }, []);

  const handleResponse = useCallback((response) => {
    const { data } = response;

    setCustomers(data.customers || null);
    setFetchState('success');
  }, []);

  useEffect(() => {
    let mounted = true;
    const getCustomers = async () => {
      setFetchState('loading');
      try {
        handleResponse(await getList(1, searchValue));
      } catch (error) {
        handleError(error);
      }
    };

    if (
      mounted &&
      token &&
      initial &&
      searchValue &&
      searchValue === searchText
    ) {
      if (searchValue.length >= 3) {
        getCustomers();
      } else {
        setCustomers(null);
      }
    }
    return () => (mounted = false);
  }, [
    token,
    searchValue,
    initial,
    searchText,
    getList,
    handleResponse,
    handleError,
  ]);

  const initSearch = (text) => {
    setSearchText(text);
    setCustomers(null);
    setFetchState('initial');
  };

  const defaultValues = {
    customers,
    error,
    searchText,
    loading,
    success,
    initSearch,
  };

  return (
    <SearchMenuContext.Provider value={defaultValues}>
      {children}
    </SearchMenuContext.Provider>
  );
};

export default SearchMenuContext;
