const { useCallback } = require('react');

const useErrorHandlerService = (errors) => {
  const getGenericMessage = useCallback(
    () => errors.find((error) => error.code === 500),
    [errors]
  );

  const getHttpError = useCallback(
    (e) => {
      try {
        const { response } = e;
        const { data } = response;
        const { code, key } = data;
        const result = errors.find((error) => {
          const hasKey = key && error.key && error.key !== '';

          return hasKey
            ? error.code === code && error.key === key
            : error.code === code && error.defaultMessage === key;
        });

        return result ? result : getGenericMessage();
      } catch (e) {
        console.log(e.message, '. Error trying to get error message.');
        return getGenericMessage();
      }
    },
    [getGenericMessage, errors]
  );

  const getNonHttpError = useCallback(
    (e) => {
      try {
        const { message: errorMessage, key, code } = e;
        const result = errors.find((error) => {
          const hasKey = key && error.key && error.key !== '';

          return hasKey
            ? error.code === code && error.key === key
            : error.defaultMessage === errorMessage;
        });

        return result ? result : getGenericMessage();
      } catch (e) {
        console.log(e.message, '. Error trying to get error message.');
        return getGenericMessage();
      }
    },
    [getGenericMessage, errors]
  );

  const getErrorMessage = useCallback(
    (error, values) => {
      const currError = error.response
        ? getHttpError(error)
        : getNonHttpError(error);

      return typeof currError.message === 'function'
        ? currError.message(values)
        : currError.message;
    },
    [getHttpError, getNonHttpError]
  );

  const getError = useCallback(
    (error) => (error.response ? getHttpError(error) : getNonHttpError(error)),
    [getHttpError, getNonHttpError]
  );

  return {
    getErrorMessage,
    getError,
  };
};

export default useErrorHandlerService;
