import styled from 'styled-components';
import media from 'styles/media';
import Configs from 'styles/variables';

export const TitleTag = styled.h2.attrs((props) => ({
  size: props.big ? 48 : 16,
  padding: props.big ? 13 : 0,
  marginBottom: props.big ? 26 : 0,
  weight: props.important ? 300 : 300,
  color: props.white
    ? Configs.white
    : props.yellow
    ? Configs.yellow
    : Configs.darkGray,
}))`
  font-family: 'Manrope-Light';
  padding: ${(props) => props.padding}px 0;
  margin: 0;
  margin-bottom: 26px;
  margin-top: -14px;

  ${media.tablet`
    margin-top: -26px;
  `}
  font-size: ${(props) => props.size}px;
  font-weight: ${(props) => props.weight};
  line-height: 1.5;
  letter-spacing: -0.5px;
  text-align: center;
  color: ${(props) => props.color};
  ${media.mobile`
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0.1px;
    padding: ${(props) => props.padding}px 46px;
    text-align: left;
    margin-bottom: 12px;
    margin-top:0px;
    width: 100%;
  `}
`;
