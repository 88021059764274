import { CloseBox } from 'components/CloseBox';
import WizardBackButton from 'components/WizardBackButton';
import styled from 'styled-components';
import media from 'styles/media';

const Container = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  height: 21.5px;
  margin: 45px 16px 23.5px 16px;
  z-index: 10;
`;

const ButtonBack = styled(WizardBackButton)`
  ${media.mobile`
    top: 0;
    left: 16px;
    height: 24px;
    width: 24px;
  `}
`;

const ButtonClose = styled(CloseBox)`
  background: transparent;
  z-index: 300;
  svg {
    fill: transparent;
  }
  ${media.tablet`
    top: 30px;
    right: 30px;
    border-radius: 50px;
  `}
  ${media.mobile`
  right: 16px;
  
  &.open {
      top: 0;
      width: auto;
      height: 24px;
      background-color: transparent
    }
  `}
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  Container,
  ButtonBack,
  ButtonClose,
};
