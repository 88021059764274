import styled from 'styled-components';
import { ButtonBox } from 'components/RoundedButton/styles';

export const Box = styled(ButtonBox)`
  min-width: 180px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 64px;
  > div {
    padding: 7px 0 0 0;
  }
`;
