import * as Sentry from '@sentry/react';
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  configureScopeWithState: (scope, state) => {
    // Set tag if the user is using imperial units.
    if (state.authorization.userId) {
      scope.setUser({ id: state.authorization.userId });
      scope.setTag('UserId', state.authorization.userId);
      scope.setContext('UserId', {
        value: state.authorization.userId,
      });
    }
    if (state.authorization.accountId) {
      scope.setTag('AccountId', state.authorization.accountId);
      scope.setContext('AccountID', {
        value: state.authorization.accountId,
      });
    }
  },
});

export default sentryReduxEnhancer;
