import React, { forwardRef } from 'react';

import { ButtonBox, Label } from './styles';

const RoundedButton = ({ children, className, ...props }, ref) => (
  <ButtonBox className={[className, 'btn']} {...props} ref={ref}>
    <Label className="label" noTextOverflow={props.noTextOverflow}>
      {children}
    </Label>
  </ButtonBox>
);

export default forwardRef(RoundedButton);
