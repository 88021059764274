import { moneyToFloat, onlyNumbers } from 'tools';
import { PixOptions } from './pix';

const defaultAccount = {
  bank_code: '',
  bank_name: '',
  branch: '',
  contact_id: '',
  created_at: '',
  current_identity: '',
  id: null,
  ispb: '',
  number: '',
  type: '',
  updated_at: '',
};

const defaultContact = {
  address: {},
  created_at: '',
  current_identity: '',
  favorite: false,
  id: null,
  identifier: '',
  name: '',
  updated_at: '',
  user_id: '',
};

const ContbankISPB = '13140088';

const PortabiltyPix = 'PORTABILITY';

const InitializationTypes = {
  KEY: 'Key',
  ACCOUNT: 'Manual',
  QRCODE: 'StaticQrCode',
  COPY_AND_PASTE: 'StaticQrCode',
};

const PixKeysOptions = {
  CPFCNPJ: 'cpf_cnpj',
  PHONE: 'phone',
  EMAIL: 'email',
  RANDOM: 'random_pix_key',
};

const PixTransferWhoBarOptions = {
  CONTACT: 0,
  RECENTS: 1,
  FAVORITES: 3,
};

const isBusinessAccount = (registry) => registry.account_type === 'BUSINESS';

const createRecipientFromPixKey = (data) => {
  console.log(data);
  return {
    document_number:
      data && data.holder && data.holder.document && data.holder.document.value,
    document_type: data && data.holder.document && data.holder.document.type,
    name: data.holder.name || data.holder.social_name,
  };
};

const createRecipientFromContact = (
  contact = defaultContact,
  account = defaultAccount
) => {
  return {
    account: {
      branch: `${onlyNumbers(account.branch)}`,
      number: `${onlyNumbers(account.number)}`,
    },
    bank: {
      ispb: account.ispb,
      name: account.bank_name,
      code: account.bank_code,
    },
    document_number: contact.identifier,
    name: contact.name,
  };
};

const getSenderName = (registry) =>
  isBusinessAccount(registry)
    ? registry.business_name || registry.trading_name
    : registry.register_name;

const createSenderFromAccountRegistry = (registry) => ({
  account: {
    branch: `${onlyNumbers(registry.information.branch)}`,
    number: `${onlyNumbers(registry.information.number)}`,
  },
  bank: {
    ispb: ContbankISPB,
  },
  name: getSenderName(registry),
});

const createRecipientOnPixTransferSendObject = (
  pixType,
  recipient,
  register
) => {
  return pixType === PixOptions.ACCOUNT ||
    (pixType === PixOptions.KEY && register && !register.endToEnd)
    ? recipient
    : null;
};

const createInitializationType = (pixType, register) => {
  const isKeyWhitoutEndToEnd =
    pixType === PixOptions.KEY && register && !register.endToEnd;

  return isKeyWhitoutEndToEnd
    ? InitializationTypes['ACCOUNT']
    : InitializationTypes[pixType];
};

const createPixTransferSendObject = (
  register,
  recipient,
  registry,
  pixType = PixOptions.ACCOUNT
) => ({
  amount: moneyToFloat(register.amount),
  description: register.message,
  sender: createSenderFromAccountRegistry(registry),
  recipient: createRecipientOnPixTransferSendObject(
    pixType,
    recipient,
    register
  ),
  initialization_type: createInitializationType(pixType, register),
  end_to_end_id: register.endToEnd || null,
});

export {
  PixKeysOptions,
  InitializationTypes,
  PixTransferWhoBarOptions,
  ContbankISPB,
  PortabiltyPix,
  createRecipientFromPixKey,
  createRecipientFromContact,
  createPixTransferSendObject,
};
