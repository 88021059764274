import { useEffect, useState } from 'react';
import useCan from 'hooks/useCan';
import useBankMenu from 'hooks/useBankMenu';
import useBusinessAgentMenu from 'hooks/useBusinessAgentMenu';
import { MENU_TYPE } from 'contexts/new-menu';

const useNewMenu = () => {
  const [menuType, setMenuType] = useState();
  const [canRender, setCanRender] = useState(false);
  const [hasTransactionToken, setHasTransactionToken] = useState(false);
  const [hasBank, setHasBank] = useState(false);
  const [menuContent, setMenuContent] = useState([]);
  const { loading } = useCan();
  const { menuOptions: bankOptions } = useBankMenu(
    hasTransactionToken,
    hasBank
  );
  const { menuOptions: agentOptions } = useBusinessAgentMenu();

  useEffect(() => {
    let mounted = true;

    if (mounted && !loading && canRender) {
      setMenuContent(menuType === MENU_TYPE.BANK ? bankOptions : agentOptions);
    }
    return () => (mounted = false);
  }, [loading, canRender, menuType, bankOptions, agentOptions]);

  const getMenus = (hasTransactionToken, hasBankAccount, menuType) => {
    setHasTransactionToken(hasTransactionToken);
    setHasBank(hasBankAccount);
    setMenuType(menuType);
    setCanRender(true);
  };

  return {
    getMenus,
    menuContent,
  };
};

export default useNewMenu;
