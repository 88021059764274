import React, { useState } from 'react';
import {
  MENU_UNLOADED_CLASS,
  NAV_MENU_OPEN_CLASS,
  NAV_MENU_CLOSE_CLASS,
  NAV_SWITCHER_OPEN_CLASS,
  NAV_SWITCHER_CLOSE_CLASS,
} from 'components/NewMenu/constants';

export const MenuVisibilityContext = React.createContext({
  setVisible: () => {},
  setInvisible: () => {},
  isVisible: true || false,
  canShow: () => true || false,
  setNearToFooter: () => {},
  setAwayToFooter: () => {},
  onFooter: () => true || false,
  menuState: '' || NAV_MENU_OPEN_CLASS || NAV_MENU_CLOSE_CLASS,
  setMenuState: (newState = '') => {},
  menuIsUnloaded: true || false,
  menuIsOpen: true || false,
  menuIsClose: true || false,
  handleCloseMenu: () => {},
  switcherIsOpen: true || false,
  switcherIsClose: true || false,
  handleCloseSwitcher: () => {},
});

export const MenuVisibilityProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isNearToFooter, setIsNearToFooter] = useState(false);
  const [menuState, setMenuState] = useState('');
  const menuIsUnloaded = menuState === MENU_UNLOADED_CLASS;
  const menuIsOpen = menuState === NAV_MENU_OPEN_CLASS;
  const menuIsClose = menuState === NAV_MENU_CLOSE_CLASS;
  const switcherIsOpen = menuState === NAV_SWITCHER_OPEN_CLASS;
  const switcherIsClose = menuState === NAV_SWITCHER_CLOSE_CLASS;

  const handleCloseMenu = () => {
    setMenuState(
      !menuIsOpen || menuIsUnloaded ? NAV_MENU_OPEN_CLASS : NAV_MENU_CLOSE_CLASS
    );
  };

  const handleCloseSwitcher = () => {
    setMenuState(
      !switcherIsOpen || menuIsUnloaded
        ? NAV_SWITCHER_OPEN_CLASS
        : NAV_SWITCHER_CLOSE_CLASS
    );
  };

  const setVisible = () => {
    setIsVisible(true);
  };

  const setInvisible = () => {
    setIsVisible(false);
  };

  const setNearToFooter = () => {
    setIsNearToFooter(true);
  };

  const setAwayToFooter = () => {
    setIsNearToFooter(false);
  };

  const canShow = () => isVisible;

  const onFooter = () => isNearToFooter;

  const sharedValues = {
    setVisible,
    setInvisible,
    isVisible,
    canShow,
    setNearToFooter,
    setAwayToFooter,
    onFooter,
    menuState,
    setMenuState,
    menuIsUnloaded,
    menuIsOpen,
    menuIsClose,
    handleCloseMenu,
    switcherIsOpen,
    switcherIsClose,
    handleCloseSwitcher,
  };

  return (
    <MenuVisibilityContext.Provider value={sharedValues}>
      {children}
    </MenuVisibilityContext.Provider>
  );
};

export default MenuVisibilityContext;
