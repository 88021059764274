import React from 'react';
import { PATHS } from 'Routes/constants';
import PublicWizard from 'Routes/components/PublicWizard';

const EditProfile = React.lazy(() => import('pages/Profile/Edit'));

export const ProfileRoutes = () => {
  return [
    <PublicWizard
      component={EditProfile}
      exact
      path={PATHS.EDIT_PROFILE}
      key={PATHS.EDIT_PROFILE}
    />,
  ];
};
