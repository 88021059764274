import styled from 'styled-components';
import media from 'styles/media';
import Title from 'components/Title';
import WizardBasicContainer from 'components/WizardBasicContainer';
import RoundedButton from 'components/RoundedButton';

export const ColumnBig = styled.div.attrs((props) => ({
  dir: props.horizontal ? 'row' : 'column',
  align: props.horizontal ? 'flex-start' : 'center',
  flow: props.horizontal ? 'unset' : 'column',
}))`
  display: flex;
  flex-direction: ${(props) => props.dir};
  align-items: flex-start !important;
  justify-content: ${(props) => props.align};
  overflow: auto;
  flex-wrap: wrap;
  max-height: 100vh;
  overflow: auto;
  padding: 48px 48px 48px 0;
  align-content: flex-start;

  &:nth-child(1) {
    min-width: 50%;
    justify-content: flex-end;
  }
  ${media.mobile`
    flex-flow: ${(props) => props.flow};
    padding: 0 32px 16px 32px;
    justify-content: flex-start;
    max-width: 100%;
  `}
`;

export const TitleLine = styled(Title).attrs((props) => ({
  bottom: props.big ? 0 : 26,
  font: props.big ? 48 : 20,
}))`
  width: 90%;
  max-width: 90%;
  text-align: left;
  padding-left: 190px;
  margin-bottom: ${(props) => props.bottom}px;
  font-size: ${(props) => props.font}px;
  ${media.mobile`
    padding-left: 0px;
    margin-bottom: 20px;
    font-family: "Manrope";
    font-size: 30px;
    font-weight: 900;
    line-height: 1.25;
  `}
`;

export const Container = styled(WizardBasicContainer)`
  ${media.mobile`
    padding-bottom: 0;
    padding-top: 40px;
  `}
`;

export const AccountList = styled.div`
  display: flex;
  max-height: 80vh;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  width: 90%;
`;

export const AuthCard = styled(RoundedButton)`
  width: auto;
  min-height: 60px;
  ${media.mobile`
    width: 90%;
  `}
  padding-left: 12px;
  span {
    ${media.mobile`
      text-align: left;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  }
`;

export const Dark = styled.span`
  font-weight: bold;
  color: #03071f;
`;
export const SmallInfo = styled.div`
  font-size: 16px;
`;

export const CloseButton = styled(RoundedButton)`
  margin-top: 32px;
`;
