import React from 'react';

import { Block } from './styles';
import ShowIcon from 'assets/images/ShowIcon';

const ShowButton = ({ className, children, ...props }) => {
  return (
    <Block title="Mostrar" className={className} {...props}>
      <ShowIcon />
    </Block>
  );
};

export default ShowButton;
