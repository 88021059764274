import styled from 'styled-components';
import Configs from 'styles/variables';

const BOX_WIDTH = 67;
const BACKGROUND_LOGO = Configs.header.backgroundLogo;
const SPEED_BASE = 0.25;

export const Box = styled.div`
  background: ${BACKGROUND_LOGO};
  border: none;
  height: ${Configs.header.height}px;
  width: ${BOX_WIDTH}px;
  z-index: 4;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  fill: ${Configs.yellow};
  transition: all ${SPEED_BASE}s ease;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    transform: translateY(-50%);
    height: 25px;
    opacity: 0.2;
    background: ${Configs.header.dividerColor};
    transition: opacity 0.05s ease;
  }
  img {
    width: 24px;
    height: 20px;
    object-fit: contain;
  }
  &.open {
    background-color: #1a1b21;
    &:after {
      opacity: 0;
    }
  }
`;
