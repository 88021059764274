import styled from 'styled-components';

export const ButtonBox = styled.div`
  margin: 0;
  padding: 16px 24px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  white-space: normal;
  position: relative;
  overflow: hidden;
  align-items: center;
  margin-bottom: 24px;
`;
