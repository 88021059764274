import { useContext, useEffect } from 'react';
import UserAuthorizationContext from 'contexts/authorization';
import RegistrationsContext from 'contexts/registrations';
import { useApplicationContext } from 'contexts/application';
import useToken from 'hooks/useToken';
import useAccountRegistry from 'contexts/account-registry';
import useAccountRegistryByIdentifierContext from 'contexts/account-registry-by-identifier';

const AppLoadInfoHOC = ({ children }) => {
  const token = useToken();

  const {
    currentAccountId,
    loading: loadingAuthorization,
    success: successAuthorization,
    error: errorAuthorization,
    loadUserAuthorization: loadAuthorization,
  } = useContext(UserAuthorizationContext);

  const {
    registrations,
    loading: loadingReg,
    success: successReg,
    error: errorReg,
    loadRegistrations: loadReg,
  } = useContext(RegistrationsContext);

  const {
    loading: loadingAccIdentifier,
    success: successAccIdentifier,
    error: errorAccIdentifier,
    loadAccountByIdentifier,
  } = useAccountRegistryByIdentifierContext();

  const {
    loading: loadingAccRegistry,
    success: successAccRegistry,
    error: errorAccRegistry,
    loadAccountRegistry,
  } = useAccountRegistry();

  const { hasAccount } = useApplicationContext();

  /*
   * Load Authorization data when has any type off account is Approved
   */
  useEffect(() => {
    let mounted = true;

    if (
      mounted &&
      token &&
      !loadingAuthorization &&
      !successAuthorization &&
      !errorAuthorization
    ) {
      loadAuthorization();
    }

    return () => (mounted = false);
  }, [
    loadingAuthorization,
    successAuthorization,
    errorAuthorization,
    loadAuthorization,
    token,
    hasAccount,
  ]);

  /*
   *  Load Registration data
   */
  useEffect(() => {
    let mounted = true;

    if (mounted && !loadingReg && !successReg && !errorReg && token) {
      loadReg();
    }

    return () => (mounted = false);
  }, [loadingReg, successReg, errorReg, loadReg, token]);

  /*
   * Load Account when user has account
   */
  useEffect(() => {
    let mounted = true;

    if (
      mounted &&
      token &&
      currentAccountId &&
      !loadingAccRegistry &&
      !successAccRegistry &&
      !errorAccRegistry
    ) {
      loadAccountRegistry();
    }
    return () => (mounted = false);
  }, [
    token,
    currentAccountId,
    loadingAccRegistry,
    successAccRegistry,
    errorAccRegistry,
    loadAccountRegistry,
  ]);

  /*
   * Load Account when has account
   */
  useEffect(() => {
    let mounted = true;

    if (
      mounted &&
      token &&
      (errorAuthorization || (successAuthorization && !currentAccountId)) &&
      registrations &&
      !loadingAccIdentifier &&
      !successAccIdentifier &&
      !errorAccIdentifier
    ) {
      loadAccountByIdentifier(registrations.identifier, false, true);
    }
    return () => (mounted = false);
  }, [
    token,
    registrations,
    currentAccountId,
    errorAuthorization,
    successAuthorization,
    loadingAccIdentifier,
    successAccIdentifier,
    errorAccIdentifier,
    loadAccountByIdentifier,
  ]);

  // /*
  //  * Load Account PF info when has registration
  //  */
  // useEffect(() => {
  //   let mounted = true;

  //   if (
  //     mounted &&
  //     token &&
  //     !loadingAccPFIdentifier &&
  //     !successAccPFIdentifier &&
  //     !errorAccPFIdentifier &&
  //     successReg &&
  //     registrations
  //   ) {
  //     loadAccPFIdentifier(registrations.identifier, false);
  //   }

  //   return () => (mounted = false);
  // }, [
  //   loadingAccPFIdentifier,
  //   successAccPFIdentifier,
  //   errorAccPFIdentifier,
  //   loadAccPFIdentifier,
  //   successReg,
  //   registrations,
  //   token,
  // ]);

  // /*
  //  * Load accounts PJ when was registration
  //  */
  // useEffect(() => {
  //   let mounted = true;

  //   if (
  //     mounted &&
  //     token &&
  //     !loadingAccsPJ &&
  //     !successAccsPJ &&
  //     !errorAccsPJ &&
  //     successReg
  //   ) {
  //     loadAccsPJ();
  //   }

  //   return () => (mounted = false);
  // }, [
  //   loadingAccsPJ,
  //   successAccsPJ,
  //   errorAccsPJ,
  //   loadAccsPJ,
  //   successReg,
  //   token,
  // ]);

  // /*
  //  * Load Authorization data when has any type off account is Approved
  //  */
  // useEffect(() => {
  //   let mounted = true;

  //   if (
  //     mounted &&
  //     token &&
  //     !loadingAuth &&
  //     !successAuth &&
  //     !errorAuth &&
  //     hasAccount
  //   ) {
  //     loadAuth();
  //   }

  //   return () => (mounted = false);
  // }, [loadingAuth, successAuth, errorAuth, loadAuth, token, hasAccount]);

  // /*
  //  * Load Account PF info when has authorization
  //  * this is last call, because this context should be loaded when user
  //  * was selected account to use
  //  */
  // useEffect(() => {
  //   let mounted = true;

  //   if (
  //     mounted &&
  //     token &&
  //     !loadingAccPF &&
  //     !successAccPF &&
  //     !errorAccPF &&
  //     hasAccount &&
  //     successReg &&
  //     successAuth &&
  //     registrations
  //   ) {
  //     loadAccPF();
  //   }

  //   return () => (mounted = false);
  // }, [
  //   loadingAccPF,
  //   successAccPF,
  //   errorAccPF,
  //   hasAccount,
  //   successAuth,
  //   loadAccPF,
  //   successReg,
  //   registrations,
  //   token,
  // ]);
  return <>{children}</>;
};

export default AppLoadInfoHOC;
