import { REGISTER_STEPS } from './register';
import { PF_STEPS } from './pf';
import { PJ_SMALL_STEPS } from './pj-small';
import {
  ROOT,
  CORPORATION_PF_STEPS,
  CORPORATION_PJ_STEPS,
  CORPORATION_DOCS_STEPS,
} from './pj-corporation';

const ONBOARDING_STEPS = {
  REGISTER: REGISTER_STEPS,
  PF: PF_STEPS,
  PJ_SMALL: PJ_SMALL_STEPS,
  PJ_CORPORATION: [
    ...ROOT,
    ...CORPORATION_DOCS_STEPS,
    ...CORPORATION_PJ_STEPS,
    ...CORPORATION_PF_STEPS,
  ],
};

export default ONBOARDING_STEPS;
