import { CloseBox } from 'components/CloseBox';
import ProgressBar from 'components/ProgressBar';
import styled from 'styled-components';
import LogoBox from 'components/LogoBox';
import media from 'styles/media';

export const MainContainer = styled.div`
  position: fixed;
  z-index: 1100;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  .main-menu {
    .main-bar {
      ${media.mobile`
        padding-top: 16px;
        z-index:1229;
      `}
    }
  }
`;

export const MainBox = styled.div`
  flex: 1;
`;

export const Close = styled(CloseBox)``;

export const ProgressBarComp = styled(ProgressBar)`
  ${media.tablet`
    position: fixed;
    top: 0;
    left: 0;
  `};
`;

export const LogoComp = styled(LogoBox)`
  ${media.mobile`
    display: none;
  `};
`;
