import React from 'react';
import DataHolder from 'components/DataHolder';

function SingleLineLoader({ className }) {
  let loaderPattern = [{ w: 10 }];

  return <DataHolder className={className} pattern={loaderPattern} />;
}

export default SingleLineLoader;
