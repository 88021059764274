import Configs from './variables';

export const CONTAINER_BACKGROUND = Configs.newColors.s900;
export const CONTAINER_TEXT_COLOR = Configs.newColors.n0;
export const CONTAINER_WIDTH = '400px';
export const HEADER_CLOSED_WIDTH = '150px';
export const PADDING_X = '1.125rem';
export const HEADER_HEIGHT = '60px';
export const HEADER_HEIGHT_MOBILE = '80px';
export const MENU_SWITCHER_HEIGHT_MOBILE = '60vh';
export const MEDIA_SIZE_SM = '640px';
export const MEDIA_SIZE_MD = '768px';
export const MEDIA_SIZE_LG = '1024px';
export const MEDIA_SIZE_XL = '1280px';
export const MEDIA_SIZE_2XL = '1536px';
export const MEDIA_MAX_MOBILE = `max-width: ${MEDIA_SIZE_SM}`;
export const MEDIA_MIN_TABLET = `min-width: ${MEDIA_SIZE_MD}`;
export const Z_INDEX_MENU = 300;
export const MEDIA_MIN_DESKTOP = `min-width: ${MEDIA_SIZE_LG}`;
export const APP_VERSION_HEIGHT = '3rem';
