import React, { useContext, useRef } from 'react';
import Configs from 'styles/variables';
import DrawerIcon from 'assets/images/DrawerIcon';
import * as S from '../style';
import { MenuVisibilityContext } from 'contexts/menuVisibility';
import {
  NAV_SWITCHER_CLOSE_CLASS,
  NAV_SWITCHER_OPEN_CLASS,
} from '../constants';
import MenuNavSwitcherProfile from './MenuNavSwitcherProfile';

export default function MenuNavSwitcher() {
  const { switcherIsOpen, switcherIsClose } = useContext(MenuVisibilityContext);
  const navRef = useRef(null);

  if (switcherIsOpen && navRef?.current) {
    navRef.current.scrollTop = 0;
  }

  return (
    <S.MenuNavSwitcher
      ref={navRef}
      className={`${switcherIsOpen ? NAV_SWITCHER_OPEN_CLASS : ''} ${
        switcherIsClose ? NAV_SWITCHER_CLOSE_CLASS : ''
      }`}
    >
      <S.Drawer>
        <DrawerIcon fill={Configs.newColors.s700} />
      </S.Drawer>
      <div>
        <MenuNavSwitcherProfile />
      </div>
    </S.MenuNavSwitcher>
  );
}
