import styled from 'styled-components';
import RoundedButton from 'components/RoundedButton';
import Configs from 'styles/variables';
import media from 'styles/media';

export const Button = styled(RoundedButton).attrs((props) => ({
  size: props.big ? 24 : 19,
  align: props.center ? 'center' : 'space-between',
  height: props.big ? 88 : 64,
  width: props.big ? 280 : 230,
  arrowColor: props.black ? Configs.white : Configs.black,
  arrowColorHover: props.black ? Configs.black : Configs.white,
}))`
  height: ${(props) => props.height}px;
  border-radius: ${(props) => props.height}px;
  padding: 0 35px;
  min-width: ${(props) => props.width}px;
  display: flex;
  svg {
    fill: ${(props) => props.arrowColor};
  }
  & > div {
    font-size: ${(props) => props.size}px;
    letter-spacing: -0.38px;
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => props.align};
    align-content: center;
    flex: 1;
  }
  &:hover {
    svg {
      fill: ${(props) => props.arrowColorHover};
    }
  }
  &:disabled {
    background-color: ${Configs.disabledButtonBg};
    color: ${Configs.white};
    cursor: not-allowed;
    svg {
      fill: ${Configs.white};
    }
  }
  ${media.tablet`
    background-color: ${(props) =>
      props.darkYellow ? Configs.darkYellow : Configs.yellow};
    color: ${Configs.black};
    font-family: "Manrope";
    border: none;
    svg {
      fill: ${Configs.black};
    }
    border-radius: 0;
  `}
`;

export const Arrow = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-left: 16px;
`;
