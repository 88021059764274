import React, { useCallback, useContext, useEffect, useState } from 'react';
import useCan from 'hooks/useCan';
import RegistrationsContext from './registrations';
import useMenu from 'hooks/useMenu';
import UserAuthorizationContext from './authorization';

const MenuContext = React.createContext(false);

export const MenuProvider = ({ children }) => {
  const [menuItens, setMenuItens] = useState(false);
  const [userHasToken, setUserHasToken] = useState(false);
  const [lastId, setLastId] = useState(false);
  const { permissions, loading } = useCan();
  const { hasTransactionToken, loading: loadingReg } =
    useContext(RegistrationsContext);
  const { getCurrentID, getBankAccount } = useContext(UserAuthorizationContext);
  const { getMenus, menuContent } = useMenu();
  const currId = getCurrentID();

  useEffect(() => {
    let mounted = true;

    if (mounted && menuItens && currId && currId !== lastId) {
      setLastId(currId);
      setMenuItens(false);
    }
    return () => (mounted = false);
  }, [currId, lastId, menuItens]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (menuContent) setMenuItens(menuContent);
    }
    return () => (mounted = false);
  }, [menuContent, menuItens]);

  const updateMenuItens = useCallback(
    (hasToken) => {
      getMenus(hasToken, getBankAccount());
    },
    [getBankAccount, getMenus]
  );

  useEffect(() => {
    let mounted = true;
    const hasPermissions = permissions && permissions.length > 0;

    if (mounted && !loading && !menuItens && hasPermissions && !loadingReg) {
      updateMenuItens(userHasToken);
    }
    return () => (mounted = false);
  }, [
    permissions,
    menuItens,
    loading,
    userHasToken,
    loadingReg,
    updateMenuItens,
  ]);

  useEffect(() => {
    let mounted = true;

    if (
      mounted &&
      menuItens &&
      !loadingReg &&
      userHasToken !== hasTransactionToken
    ) {
      setUserHasToken(hasTransactionToken);
      updateMenuItens(hasTransactionToken);
    }
    return () => (mounted = false);
  }, [
    hasTransactionToken,
    menuItens,
    userHasToken,
    updateMenuItens,
    loadingReg,
  ]);

  return (
    <MenuContext.Provider value={{ menuItens }}>
      {children}
    </MenuContext.Provider>
  );
};

export default MenuContext;
