import {
  ONBOARDING_STEPS,
  ONBOARDING_ACCOUNT_TYPES,
} from 'pages/Onboarding/shared/onboarding-constants';

const STEPS = ONBOARDING_STEPS;

export const PJ_SMALL_STEPS = [
  {
    id: STEPS.LOADING,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.PHONE,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.PHONE_SMS,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.DATE_OF_BIRTH,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.MOTHER_NAME,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.OCUPATION,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.INCOME,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.ADDRESS,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.CONFIRM,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.LOADING_WAIT_USER_UPDATE,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.ERROR_USER_UPDATE,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.SUCCESS_PJ_SAVED_USER,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.VERIFY_PJ_IDENTIFIER,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.LOADING_WAIT_BUSINESS_VERIFY,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.ERROR_INSTABILITY_BUSINESS_VERIFY,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.ERROR_INVALID_COMPANIES_STATUS,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.ERROR_LEGAL_NATURE_NOT_ALLOWED,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.ERROR_ONBOARDING_NOT_ALLOWED,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.ERROR_COMPANIES_NOT_FOUND,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.BUSINESS_NAME,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.TRADING_NAME,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.BUSINESS_EMAIL,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.BUSINESS_PHONE,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.BUSINESS_CURRENCY,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.BUSINESS_ADDRESS,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.CONFIRM_BUSINESS,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
  {
    id: STEPS.LOADING_WAIT_BUSINESS_UPDATE,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.LOADING_WAIT_STRUCTURE_BUSINESS,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.ERROR_BUSINESS_UPDATE,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.SUCCESS_SAVED_BUSINESS,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.SELFIE_TIPS,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.AUTHORIZATION_CAMERA,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.ERROR_SELFIE,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.DOCUMENT_TYPE,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.ERROR_DOCUMENTS,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.DOCUMENT_FRONT,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.DOCUMENT_BACK,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.SUCCESS_SENDED_DOCUMENTS,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.LOADING_WAIT_ACCOUNT_CREATION,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.ERROR_INSTABILITY,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.ANALYSING_ACCOUNT,
    skip: false,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.NAME,
    skip: true,
    visible: false,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.EMAIL,
    skip: true,
    visible: false,
    type: ONBOARDING_ACCOUNT_TYPES.PF,
  },
  {
    id: STEPS.WAITING_ANALYSIS_ACCOUNT,
    skip: true,
    visible: true,
    type: ONBOARDING_ACCOUNT_TYPES.PJ,
  },
];
