import ArrowIcon from 'assets/images/ArrowIcon';
import React from 'react';

import { Button, Arrow } from './styles';

const RoundedButtonArrow = ({ children, className, ...props }) => (
  <Button className={[className, 'btn']} {...props}>
    {children}
    {props.center ? null : (
      <Arrow>
        <ArrowIcon />
      </Arrow>
    )}
  </Button>
);

export default RoundedButtonArrow;
