import { useContext } from 'react';
import UserAuthorizationContext from 'contexts/authorization';
import api from './api';

const useProductsService = () => {
  const { getCurrentID } = useContext(UserAuthorizationContext);

  const patchProduct = async (price, token) => {
    const url = `/products/${price.id}`;

    return await api.patch(url, price, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });
  };

  const postProduct = async (price, token) =>
    await api.post('/products', price, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });

  const getProductById = async (id, token) => {
    const url = `/products/${id}`;

    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });
  };

  const getProducts = async (token, search, page = 1, perPage = 10) => {
    const url = `/products?page=${page}&per_page=${perPage}${
      search ? `&name=${search}` : ''
    }`;

    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Current-Identity': `${getCurrentID()}`,
      },
    });
  };

  const saveProduct = async (price, token) =>
    price.id ? patchProduct(price, token) : postProduct(price, token);

  return {
    patchProduct,
    postProduct,
    getProducts,
    getProductById,
    saveProduct,
  };
};

export default useProductsService;
