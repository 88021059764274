import React from 'react';

const ModalManagerContext = React.createContext({
  registerModalId: (modalId) => {},
  removeModalId: (modalId) => {},
  registerOpenedModalId: (modalId) => {},
  removeOpenedModalId: (modalId) => {},
  registeredIds: [],
  openedIds: [],
});

export const ModalManagerProvider = ({ children }) => {
  const [ids, setIds] = React.useState([]);
  const [openedIds, setOpenedIds] = React.useState([]);

  const registerModalId = React.useCallback((modalId) => {
    setIds((prevIds) => [...prevIds, modalId]);
  }, []);

  const removeModalId = React.useCallback((modalId) => {
    setIds((prevIds) => prevIds.filter((id) => id !== modalId));
  }, []);

  const registerOpenedModalId = React.useCallback(
    (modalId) => {
      const isOpened = openedIds.find((id) => id === modalId);

      if (!isOpened) {
        setOpenedIds((prevIds) => [...prevIds, modalId]);
      }
    },
    [openedIds]
  );

  const removeOpenedModalId = React.useCallback((modalId) => {
    setOpenedIds((prevIds) => prevIds.filter((id) => id !== modalId));
  }, []);

  const propValues = {
    registerModalId,
    removeModalId,
    registerOpenedModalId,
    removeOpenedModalId,
    registeredIds: ids,
    openedIds,
  };

  return (
    <ModalManagerContext.Provider value={propValues}>
      {children}
    </ModalManagerContext.Provider>
  );
};

const useModalManagerContext = () => React.useContext(ModalManagerContext);

export default useModalManagerContext;
