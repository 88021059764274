import Loading from 'pages/Loading';
import React, { useEffect } from 'react';

export default function Logout() {
  useEffect(() => {
    async function fetchData() {
      // const AUTH0_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

      // delete_cookie('auth0.is.authenticated');
      // delete_cookie('_legacy_auth0.is.authenticated');
      // window.location.href = `https://contbank.us.auth0.com/v2/logout?client_id=${AUTH0_CLIENT_ID}&returnTo=${window.location.origin}`;
      let url = window.location.origin;

      window.location.href = url;
    }
    fetchData();
  }, []);

  return <Loading />;
}

// function delete_cookie(name, path, domain) {
//   if (get_cookie(name)) {
//     document.cookie =
//       name +
//       '=' +
//       (path ? ';path=' + path : '') +
//       (domain ? ';domain=' + domain : '') +
//       ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
//   }
// }

// function get_cookie(name) {
//   return document.cookie.split(';').some((c) => {
//     return c.trim().startsWith(name + '=');
//   });
// }
