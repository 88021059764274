import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Container, Title, Text } from './styles';

function NotFound() {
  const { t } = useTranslation('not-found');

  return (
    <Container>
      <Title>404</Title>
      <Text>
        {t('not-found:messages.desc_1')} <br />
        <Link to="/">{t('not-found:labels.click_here')}</Link>
        {t('not-found:messages.desc_2')}
      </Text>
    </Container>
  );
}

export default NotFound;
