import React from 'react';

const DrawerIcon = (props) => {
  return (
    <svg
      width="34"
      height="6"
      viewBox="0 0 34 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.424805" width="34" height="5.36991" rx="2.68495" />
    </svg>
  );
};

export default DrawerIcon;
