import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { maskCpfOrCnpj, onlyNumbers, toPhoneNumberFormat } from 'tools';
import { validateCNPJ } from 'tools/cnpj-utils';
import { PixRegisterKeysTypes } from './pix';
import { bankName } from 'config/constants';
import { ContbankISPB, PortabiltyPix } from './pix-transfers';

const usePixKeysService = () => {
  const { t } = useTranslation('pix-keys');
  const isCnpj = (value) => validateCNPJ(onlyNumbers(value));

  const pixKeysTexts = useCallback(
    () => ({
      [PixRegisterKeysTypes.CPF]: t('pix-keys:labels.cpf'),
      [PixRegisterKeysTypes.CNPJ]: t('pix-keys:labels.cnpj'),
      [PixRegisterKeysTypes.EMAIL]: t('pix-keys:labels.email'),
      [PixRegisterKeysTypes.PHONE]: t('pix-keys:labels.phone'),
      [PixRegisterKeysTypes.RANDOM]: t('pix-keys:labels.random_key'),
    }),
    [t]
  );

  const pixKeysValueFormatters = useCallback(
    (key) => ({
      [PixRegisterKeysTypes.CPF]: maskCpfOrCnpj(key),
      [PixRegisterKeysTypes.CNPJ]: maskCpfOrCnpj(key),
      [PixRegisterKeysTypes.EMAIL]: key,
      [PixRegisterKeysTypes.PHONE]: toPhoneNumberFormat(key),
      [PixRegisterKeysTypes.RANDOM]: key,
    }),
    []
  );
  const generateAccountData = (accountInfo) => ({
    number: accountInfo.number,
    branch: accountInfo.branch,
  });

  const generatePortabilityData = (accountInfo, register) => ({
    type: PortabiltyPix,
    addressing_key: {
      type: register.key_type,
      value: register.key,
    },
    claimer: {
      ...generateAccountData(accountInfo),
      bank: {
        ispb: ContbankISPB,
        name: bankName,
      },
    },
  });

  const generateRandomKeyData = (accountInfo, register) => ({
    addressing_key: {
      type: PixRegisterKeysTypes.RANDOM,
    },
    account: generateAccountData(accountInfo),
  });

  const generateEmailKeyData = (accountInfo, register) => ({
    addressing_key: {
      type: PixRegisterKeysTypes.EMAIL,
      value: register.key,
    },
    account: generateAccountData(accountInfo),
  });

  const generatePhoneKeyData = (accountInfo, register) => ({
    addressing_key: {
      type: PixRegisterKeysTypes.PHONE,
      value: register.key,
    },
    account: generateAccountData(accountInfo),
  });

  const generateCpfCnpjKeyData = (accountInfo, register) => ({
    addressing_key: {
      type: isCnpj(register.key)
        ? PixRegisterKeysTypes.CNPJ
        : PixRegisterKeysTypes.CPF,
      value: register.key,
    },
    account: generateAccountData(accountInfo),
  });

  const generators = {
    [PixRegisterKeysTypes.CPF]: generateCpfCnpjKeyData,
    [PixRegisterKeysTypes.CNPJ]: generateCpfCnpjKeyData,
    [PixRegisterKeysTypes.EMAIL]: generateEmailKeyData,
    [PixRegisterKeysTypes.PHONE]: generatePhoneKeyData,
    [PixRegisterKeysTypes.RANDOM]: generateRandomKeyData,
  };

  const generateKeyData = (accountInfo, register) => {
    const key = register.key_type;

    return generators[key](accountInfo, register);
  };

  return {
    generateKeyData,
    pixKeysTexts,
    pixKeysValueFormatters,
    generatePortabilityData,
  };
};

export default usePixKeysService;
