import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 40px;
  font-weight: normal;
  padding-top: 0;
  margin: 8px 0 32px 0;
  line-height: 1;
  letter-spacing: -0.8px;
`;
