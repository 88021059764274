import queryString from 'query-string';

export const isMobile = (width) => {
  const WIDTH = width ?? 768;

  if (window && window.location && window.location.search) {
    const querySearch = queryString.parse(window.location.search);

    if (querySearch['mobile']) {
      return true;
    }
  }

  return window.innerWidth < WIDTH || navigator.userAgent.indexOf('Mobi') > -1;
};

export const hasWebcam = async () => {
  if (
    !(
      navigator &&
      navigator.mediaDevices &&
      navigator.mediaDevices.enumerateDevices
    )
  ) {
    return false;
  }

  const devices = await navigator.mediaDevices.enumerateDevices();

  return devices.some((device) => device.kind === 'videoinput');
};
