import Menu from 'components/Menu';
import ProgressBar from 'components/ProgressBar';
import styled from 'styled-components';
import media from 'styles/media';

export const MainContainer = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;

  ${media.mobile`
    flex-direction: column-reverse;
  `}
`;

export const MainBox = styled.div`
  flex: 1;
`;

export const ProgressBarComp = styled(ProgressBar)`
  ${media.tablet`
    position: fixed; 
    top: 0;
  `}
`;

export const MenuBox = styled(Menu)`
  .menu-open-icon {
    ${media.tablet`
      bottom: 80px;
    `}
  }
  .onfooter {
    ${media.tablet`
      bottom: 20px;
    `}
  }
`;
