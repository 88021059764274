import axios from 'axios';

export const addr = process.env.REACT_APP_API;

const api = axios.create({
  baseURL: addr,
  timeout: 100000,
});

export const getTimedApi = (timeout) =>
  axios.create({
    baseURL: addr,
    timeout: timeout,
  });

export default api;
