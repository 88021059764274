import React, { forwardRef, useEffect, useState } from 'react';

import { Field } from '../styles';

let RandomPixKeyInput = ({ className, inputId, onChange, ...props }, input) => {
  const [count, setCount] = useState(0);
  const [dirt, setDirt] = useState(false);

  useEffect(() => {
    let mounted = true;
    const el = input.current;

    if (
      mounted &&
      count === 0 &&
      !dirt &&
      el &&
      props.defaultValue &&
      props.defaultValue !== 'undefined'
    ) {
      el.value = props.defaultValue;
      setCount(count + 1);
    }
    return () => (mounted = false);
  }, [input, props.defaultValue, count, dirt]);

  const handleOnChange = (e) => {
    if (e.target?.value) {
      e.target.value = e.target.value.replace(/[^a-zA-Z0-9-]/g, '');
    }

    if (onChange) {
      onChange(e);
    }

    setDirt(true);
  };

  return (
    <Field
      {...props}
      className={className}
      id={inputId}
      type="text"
      ref={input}
      maxLength={36}
      defaultValue={props.defaultValue}
      onChange={handleOnChange}
    />
  );
};

export default forwardRef(RandomPixKeyInput);
