import React, { useEffect, useRef } from 'react';
import BarcodeInput from './masked/barcodeInput';
import CellphoneInput from './masked/cellphoneInput';
import CNPJInput from './masked/cnpjInput';
import CPFCNPJInput from './masked/cpfcnpjInput';
import CPFInput from './masked/cpfInput';
import CEPInput from './masked/cepInput';
import PasswordInput from './masked/passwordInput';
import TokenInput from './masked/tokenInput';
import CodeUnlockCardInput from './masked/codeUnlockCardInput';
import MoneyInput from './masked/moneyInput';
import DateInput from './masked/dateInput';
import EmailInput from './masked/emailInput';
import AccountNumberInput from './masked/accountnumberinput';
import PhoneInput from './masked/phoneInput';
import RandomPixKeyInput from './masked/randomPixKeyInput';
import { isMobile } from 'react-device-detect';

import { Field, FieldGroup, FieldLabel, FieldError } from './styles';

const InputText = ({ className, mask, detectChanges, ...props }) => {
  const elRef = useRef(null);

  useEffect(() => {
    let mounted = true;
    const keyPressHandler = (e) => {
      if (props.keyPressAction) {
        props.keyPressAction(e);
      }
    };
    const el = elRef.current;

    if (mounted && el) {
      if (props.shouldFocus && !isMobile) el.focus();
      if (props.keyPressAction)
        el.addEventListener('keypress', keyPressHandler);
    }
    return () => {
      mounted = false;
      if (props.keyPressAction && el)
        el.removeEventListener('keypress', keyPressHandler);
    };
  }, [elRef, props]);

  useEffect(() => {
    let mounted = true;

    if (
      mounted &&
      detectChanges &&
      elRef &&
      (props.defaultValue !== null || props.defaultValue !== undefined)
    ) {
      const el = elRef.current;

      el.value = props.defaultValue;
    }
    return () => (mounted = false);
  }, [detectChanges, props, elRef]);

  const inputId = props.id || `field_${Math.ceil(Math.random() * 999)}`;

  return (
    <FieldGroup align={props.align}>
      {mask ? (
        mask === 'cnpj' ? (
          <CNPJInput
            {...props}
            ref={elRef}
            className={[className, props.error && 'has-error']}
            id={inputId}
            inputMode="numeric"
          />
        ) : mask === 'cep' ? (
          <CEPInput
            {...props}
            ref={elRef}
            className={[className, props.error && 'has-error']}
            id={inputId}
            inputMode="numeric"
          />
        ) : mask === 'cpf' ? (
          <CPFInput
            {...props}
            ref={elRef}
            className={[className, props.error && 'has-error']}
            id={inputId}
            inputMode="numeric"
          />
        ) : mask === 'cpf_cnpj' ? (
          <CPFCNPJInput
            {...props}
            ref={elRef}
            className={[className, props.error && 'has-error']}
            id={inputId}
            inputMode="numeric"
          />
        ) : mask === 'account_number' ? (
          <AccountNumberInput
            {...props}
            ref={elRef}
            className={[className, props.error && 'has-error']}
            id={inputId}
            inputMode="numeric"
          />
        ) : mask === 'code_unlock_card' ? (
          <CodeUnlockCardInput
            {...props}
            ref={elRef}
            className={[className, props.error && 'has-error']}
            id={inputId}
          />
        ) : mask === 'token' ? (
          <TokenInput
            {...props}
            ref={elRef}
            className={[className, props.error && 'has-error']}
            id={inputId}
          />
        ) : mask === 'money' ? (
          <MoneyInput
            {...props}
            ref={elRef}
            className={[className, props.error && 'has-error']}
            id={inputId}
            inputMode="decimal"
          />
        ) : mask === 'cellphone' ? (
          <CellphoneInput
            {...props}
            ref={elRef}
            className={[className, props.error && 'has-error']}
            id={inputId}
            inputMode="tel"
          />
        ) : mask === 'phone' ? (
          <PhoneInput
            {...props}
            ref={elRef}
            className={[className, props.error && 'has-error']}
            id={inputId}
            inputMode="tel"
          />
        ) : mask === 'password' ? (
          <PasswordInput
            {...props}
            ref={elRef}
            className={[className, props.error && 'has-error']}
            id={inputId}
            inputMode={props.numbers ? 'numeric' : null}
          />
        ) : mask === 'date' ? (
          <DateInput
            {...props}
            ref={elRef}
            className={[className, props.error && 'has-error']}
            id={inputId}
            inputMode="numeric"
          />
        ) : mask === 'barcode' ? (
          <BarcodeInput
            {...props}
            ref={elRef}
            className={[className, props.error && 'has-error']}
            id={inputId}
          />
        ) : mask === 'email' ? (
          <EmailInput
            {...props}
            ref={elRef}
            className={[className, props.error && 'has-error']}
            id={inputId}
            inputMode="email"
          />
        ) : mask === 'random_pix_key' ? (
          <RandomPixKeyInput
            {...props}
            ref={elRef}
            className={[className, props.error && 'has-error']}
            id={inputId}
          />
        ) : (
          <Field
            {...props}
            ref={elRef}
            className={[className, props.error && 'has-error']}
            id={inputId}
          />
        )
      ) : (
        <Field
          {...props}
          ref={elRef}
          className={[className, props.error && 'has-error']}
          id={inputId}
        />
      )}
      {props.label && <FieldLabel htmlFor={inputId}>{props.label}</FieldLabel>}
      {props.error && (
        <FieldError noTransform={props.noTransformError}>
          {props.error}
        </FieldError>
      )}
    </FieldGroup>
  );
};

export default InputText;
