import styled from 'styled-components';
import GlobalConfigs from 'styles/variables';
import * as MenuConfigs from 'styles/menuVariables';
import {
  NAV_SWITCHER_OPEN_CLASS,
  NAV_MENU_OPEN_CLASS,
  NAV_MENU_CLOSE_CLASS,
  NAV_SWITCHER_CLOSE_CLASS,
} from './constants';
import media from 'styles/media';

export const Container = styled.div.attrs((props) => ({
  className: `main-menu-container  ${props.menuState} ${props.className || ''}`,
}))`
  color: ${MenuConfigs.CONTAINER_TEXT_COLOR};
  min-height: ${MenuConfigs.HEADER_HEIGHT};
  width: 100%;
  position: fixed;
  z-index: ${MenuConfigs.Z_INDEX_MENU};
  overflow: hidden;
  top: 0;
  font-family: 'Manrope', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  letter-spacing: -0.2px;
  padding-top: 0;

  h4 {
    font-weight: 500;
  }

  h6 {
    font-weight: 400;
  }

  &.${NAV_MENU_OPEN_CLASS}, &.${NAV_SWITCHER_OPEN_CLASS} {
    min-height: 100%;
  }

  @media (${MenuConfigs.MEDIA_MIN_TABLET}) {
    width: ${MenuConfigs.CONTAINER_WIDTH};
  }

  @media (${MenuConfigs.MEDIA_MAX_MOBILE}) {
    min-height: 0;

    &.${NAV_SWITCHER_OPEN_CLASS} {
      padding-top: calc(100vh - ${MenuConfigs.MENU_SWITCHER_HEIGHT_MOBILE});
    }

    &.${NAV_MENU_CLOSE_CLASS}, &.${NAV_SWITCHER_CLOSE_CLASS} {
      top: 0;
      padding-top: 0;
      transition: all 0.4s ease 0.4s;
    }
  }
`;

export const MenuOverlay = styled.div.attrs((props) => ({
  className: `main-menu-overlay ${props.className || ''}`,
}))`
  transition: 0.5s;
  height: 0;
  visibility: hidden;
  z-index: 1;

  @media (${MenuConfigs.MEDIA_MAX_MOBILE}) {
    .${NAV_SWITCHER_OPEN_CLASS} & {
      height: 100vh;
      width: 100vw;
      background-color: rgba(0, 0, 0, 0.2);
      top: 0;
      left: 0;
      position: absolute;
      overflow: hidden;
      visibility: visible;
      opacity: 1;
    }

    .${NAV_SWITCHER_CLOSE_CLASS} & {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export const Wrapper = styled.div.attrs(({ className, ...props }) => ({
  className: `main-menu-wrapper`,
  ...props,
}))`
  z-index: 98;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  height: 0px;
  /* width: 100%; */
  opacity: 1;
  flex-direction: column;
  position: relative;
  background: ${MenuConfigs.CONTAINER_BACKGROUND};
  top: 0;
  transform: translate3d(0, 0, 0);
  color: ${GlobalConfigs.newColors.n100};
  padding: 0 ${MenuConfigs.PADDING_X};

  @media (${MenuConfigs.MEDIA_MAX_MOBILE}) {
    opacity: 0;

    &.${NAV_MENU_CLOSE_CLASS}, &.${NAV_SWITCHER_CLOSE_CLASS} {
      transition: all 0.4s ease-in, opacity 0.4s ease-in -0.2s;
    }

    &.${NAV_MENU_OPEN_CLASS}, &.${NAV_SWITCHER_OPEN_CLASS} {
      top: 0;
      /* height: 100vh; */
      height: calc(1vh * 100);
      width: 100vw;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 98;
      background: transparent;
      opacity: 1;
      transition: all 0.4s ease 0.2s;
      padding-bottom: ${MenuConfigs.APP_VERSION_HEIGHT};
    }
  }

  @media (${MenuConfigs.MEDIA_MIN_TABLET}) {
    @keyframes menuOpen {
      0% {
        transform: translate3d(0, calc(-100vh + 60px), 0);
        opacity: 1;
        height: 0px;
      }
      100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        height: calc(100vh - 60px);
        top: 0;
      }
    }

    @keyframes menuClose {
      0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        height: calc(100vh - 60px);
        top: 0;
      }

      100% {
        transform: translate3d(0, calc(-100vh + 60px), 0);
        opacity: 1;
        height: 0px;
      }
    }

    &.${NAV_MENU_CLOSE_CLASS}, &.${NAV_SWITCHER_CLOSE_CLASS} {
      animation: menuClose 0.4s ease-in;
      height: 0px;
    }

    &.${NAV_MENU_OPEN_CLASS}, &.${NAV_SWITCHER_OPEN_CLASS} {
      animation: menuOpen 0.4s ease;
      opacity: 1;
      height: calc(100vh - 60px);
      transform: translate3d(0, 0, 0);
      overflow-y: auto;
      overflow-x: hidden;
      top: 0;
      padding-bottom: ${MenuConfigs.APP_VERSION_HEIGHT};
    }
  }

  &::-webkit-scrollbar-track {
    all: unset;
  }

  &::-webkit-scrollbar {
    all: unset;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    all: unset;
    background-color: #555;
    border-radius: 8px;
  }
`;

export const MenuNavPrimary = styled(Wrapper)`
  .${NAV_MENU_OPEN_CLASS} & {
    transform: inherit;
  }

  @media (${MenuConfigs.MEDIA_MAX_MOBILE}) {
    .${NAV_MENU_OPEN_CLASS} & {
      padding-top: ${MenuConfigs.HEADER_HEIGHT_MOBILE};
      transform: inherit;
      overflow-y: auto;
      overflow-x: hidden;
      height: var(--vh);
    }
  }
`;
export const MenuNavSwitcher = styled(Wrapper)`
  position: relative !important;
  @media (${MenuConfigs.MEDIA_MAX_MOBILE}) {
    top: 0;
    opacity: 0;
    transform: translateY(${MenuConfigs.MENU_SWITCHER_HEIGHT_MOBILE});
    height: 0px;
    width: 100vw;
    border-radius: 0;
    visibility: visible;
    /* padding-top: 2rem; */

    > div:nth-child(2) {
      margin-top: 2rem;
    }

    .${NAV_SWITCHER_OPEN_CLASS} & {
      top: 0;
      visibility: visible;
      background: ${MenuConfigs.CONTAINER_BACKGROUND};
      height: ${MenuConfigs.MENU_SWITCHER_HEIGHT_MOBILE};
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 98;
      opacity: 1;
      transform: translateY(0);
      transition: all 0.3s ease;
      transition-property: transform;
      border-radius: 10px 10px 0 0;
      transform: inherit;
    }

    .${NAV_SWITCHER_CLOSE_CLASS} & {
      transform: translateY(80vh);
      height: 0px;
      transition: all 0.4s ease-in, height 0.4s ease 0.4s,
        opacity 0.2s ease 0.4s;
    }
  }
`;

export const Header = styled.div.attrs((props) => ({
  className: `main-menu-header ${props.className || ''}`,
}))`
  margin-left: auto;
  margin-right: auto;
  top: 0;
  width: ${MenuConfigs.CONTAINER_WIDTH};
  height: ${MenuConfigs.HEADER_HEIGHT};
  box-sizing: border-box;
  z-index: 99;
  transform: translate3d(0, 0, 0);
  align-items: center;
  display: flex;
  position: relative;

  @media (${MenuConfigs.MEDIA_MAX_MOBILE}) {
    display: none;
  }
`;

export const HeaderNavMenuPrimary = styled.div.attrs((props) => ({
  className: `main-menu-header-nav-menu-primary ${props.className || ''}`,
}))`
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
  width: ${MenuConfigs.CONTAINER_WIDTH};
  background: ${MenuConfigs.CONTAINER_BACKGROUND};
  height: ${MenuConfigs.HEADER_HEIGHT};
  padding: 0 ${MenuConfigs.PADDING_X};
  z-index: 100;

  @media (${MenuConfigs.MEDIA_MAX_MOBILE}) {
    width: 100vw !important;
  }

  @keyframes menuHeaderOpen {
    0% {
      width: ${MenuConfigs.HEADER_CLOSED_WIDTH};
    }
    100% {
      width: ${MenuConfigs.CONTAINER_WIDTH};
    }
  }

  @keyframes menuHeaderClose {
    0% {
      width: ${MenuConfigs.CONTAINER_WIDTH};
    }

    100% {
      width: ${MenuConfigs.HEADER_CLOSED_WIDTH};
    }
  }

  .${NAV_MENU_OPEN_CLASS} & {
    animation: menuHeaderOpen 0.4s ease;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    @media (${MenuConfigs.MEDIA_MIN_TABLET}) {
      width: ${MenuConfigs.CONTAINER_WIDTH};
    }
  }

  .${NAV_MENU_CLOSE_CLASS} & {
    animation: menuHeaderClose 0.4s ease;
    width: ${MenuConfigs.HEADER_CLOSED_WIDTH};
  }

  @media (${MenuConfigs.MEDIA_MIN_TABLET}) {
    width: ${MenuConfigs.HEADER_CLOSED_WIDTH};
  }
`;

export const NavMenuHeaderLogo = styled.div.attrs((props) => ({
  className: `main-menu-nav-menu-header-logo ${props.className || ''}`,
}))`
  align-items: center;
  display: flex;

  @media (${MenuConfigs.MEDIA_MAX_MOBILE}) {
    display: none;
  }

  a {
    flex-shrink: 0;
    padding: 0.5rem;
    display: flex;
    margin-left: -0.5rem;
    height: ${MenuConfigs.HEADER_HEIGHT};
    align-items: center;
  }
  svg {
    width: auto;
    max-width: 100%;
    height: 22px;
    display: block;
    vertical-align: middle;
    shape-rendering: geometricPrecision;
  }
`;

export const HeaderBackButtonMobile = styled.div.attrs((props) => ({
  className: `main-menu-header-back-button-mobile ${props.className || ''}`,
}))`
  padding: 0;
  width: calc(100% - (${MenuConfigs.PADDING_X} * 2));
  position: fixed;
  height: 0;
  top: 0;
  background-color: ${MenuConfigs.CONTAINER_BACKGROUND};
  opacity: 0;

  .${NAV_MENU_OPEN_CLASS} & {
    opacity: 1;
    transition: all 0.4s ease 0.6s;
    height: ${MenuConfigs.HEADER_HEIGHT_MOBILE};
  }

  @media (${MenuConfigs.MEDIA_MIN_TABLET}) {
    display: none;
  }

  button {
    cursor: pointer;
    text-transform: none;
    font-family: inherit;
    line-height: inherit;
    background-color: transparent;
    border: none;
    color: ${GlobalConfigs.newColors.n0};
    display: blo;
    -webkit-tap-highlight-color: transparent;
    align-items: center;
    height: ${MenuConfigs.HEADER_HEIGHT_MOBILE};
  }

  svg {
    width: auto;
    max-width: 100%;
    height: 22px;
    display: block;
    vertical-align: middle;
    shape-rendering: geometricPrecision;
    fill: ${GlobalConfigs.newColors.n0};
  }
`;

export const HeaderMenuButton = styled.div.attrs((props) => ({
  className: `main-menu-header-button ${props.className || ''}`,
}))`
  display: flex;
  flex: 1;
  justify-content: end;

  @media (${MenuConfigs.MEDIA_MAX_MOBILE}) {
    display: none;
    button {
      -webkit-tap-highlight-color: transparent;
    }
  }

  button {
    cursor: pointer;
    text-transform: none;
    font-family: inherit;
    line-height: inherit;
    margin: 0;
    background-color: transparent;
    border: none;
    color: ${GlobalConfigs.newColors.n0};
    padding: 0;
    align-items: center;
    display: flex;
    height: ${MenuConfigs.HEADER_HEIGHT};
    -webkit-tap-highlight-color: transparent;
  }

  span {
    font-size: 0.875rem;
    transition: 1s;
    font-weight: 500;
  }

  span.${NAV_MENU_OPEN_CLASS} {
    opacity: 0;
    display: none;
  }

  @keyframes openText {
    0% {
      opacity: 0;
      display: block;
    }
    99% {
      opacity: 1;
    }
    100% {
      display: none;
    }
  }

  .${NAV_MENU_OPEN_CLASS} & {
    span.${NAV_MENU_OPEN_CLASS}, svg {
      animation: openText 0.4s ease-in;
      opacity: 1;
      display: block;
    }

    span.${NAV_MENU_CLOSE_CLASS} {
      opacity: 0;
      display: none;
    }
  }

  .${NAV_MENU_CLOSE_CLASS} & {
    span.${NAV_MENU_OPEN_CLASS} {
      opacity: 0;
      display: none;
    }
    span.${NAV_MENU_CLOSE_CLASS}, svg {
      animation: openText 0.4s ease-in;
      opacity: 1;
      display: block;
    }
  }

  svg {
    width: auto;
    max-width: 100%;
    height: 1.5rem;
    display: block;
    vertical-align: middle;
    shape-rendering: geometricPrecision;
    margin-left: 0.5rem;
  }
`;
export const NavMenuList = styled.ul.attrs((props) => ({
  className: `main-menu-nav-menu-list ${props.className || ''}`,
}))`
  padding: 1rem 0;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid ${GlobalConfigs.newColors.s800};
  &:last-child {
    border: none;
  }
`;

export const NavMenuListItem = styled.li.attrs((props) => ({
  className: `main-menu-nav-menu-list-item ${props.className || ''}`,
}))`
  h4 {
    font-size: 1rem;
  }
  h6 {
    font-size: 0.875rem;
  }
  &.active {
    a,
    button {
      color: ${GlobalConfigs.newColors.n0};
      span {
        background-color: ${GlobalConfigs.newColors.p600};
        svg {
          fill: ${GlobalConfigs.newColors.n900};
        }
      }
    }
  }

  a,
  button {
    border-radius: 0.375rem;
    justify-content: start;
    align-items: center;
    display: flex;
    width: 100%;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.375rem;
    text-decoration: inherit;
    background-color: transparent;
    border: none;
    color: ${GlobalConfigs.newColors.n200};
    transition: 0.5s;
    margin: 0.5rem 0;
    cursor: pointer;
    flex-flow: row;
    align-items: center;
    letter-spacing: 0px;

    span.description,
    .label,
    .description {
      display: flex;
      justify-content: start;
      text-align: left;
    }

    &:hover {
      color: ${GlobalConfigs.newColors.n0};
      span {
        background-color: ${GlobalConfigs.newColors.s700};
        svg {
          fill: ${GlobalConfigs.newColors.n0};
        }
      }
    }
  }
`;

export const AccountInfo = styled.div.attrs((props) => ({
  className: `main-menu-account-info ${props.className || ''}`,
}))`
  h4 {
    font-size: 1rem;
    color: ${(props) =>
      !props.color ? GlobalConfigs.newColors.n100 : props.color};
  }

  h6 {
    font-size: 0.875rem;
    color: ${(props) =>
      !props.color ? GlobalConfigs.newColors.n100 : props.color};
  }

  &.active {
    > div,
    a,
    button {
      color: ${(props) =>
        !props.color ? GlobalConfigs.newColors.n100 : props.color};
      span {
        background-color: ${GlobalConfigs.newColors.p600};
        svg {
          fill: ${GlobalConfigs.newColors.n900};
        }
      }
    }
  }

  > div,
  a,
  button {
    text-decoration: inherit;
    background-color: transparent;
    border: none;
    justify-content: start;
    align-items: center;
    display: flex;
    width: 100%;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin: 0.5rem 0;
    transition: 0.5s;
    flex-flow: row;
    align-items: center;
    letter-spacing: 0px;
    color: ${(props) =>
      !props.color ? GlobalConfigs.newColors.n0 : props.color};

    span.description,
    .label,
    .description {
      display: flex;
      justify-content: start;
      text-align: left;
    }
  }

  a,
  button {
    cursor: pointer;
    &:hover {
      color: ${GlobalConfigs.newColors.n0};
      span {
        background-color: ${GlobalConfigs.newColors.s700};
        svg {
          fill: ${GlobalConfigs.newColors.n0};
        }
      }
    }
  }
`;

export const NavMenuItemIcon = styled.span.attrs((props) => ({
  className: `main-menu-nav-menu-item-icon ${props.className || ''} size-${
    props.size || 'normal'
  }`,
}))`
  background-color: ${GlobalConfigs.newColors.s800};
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0.325rem;
  border-radius: 100%;
  height: 3rem;
  width: 3rem;
  flex-shrink: 0;
  margin-right: 1rem;
  transition: 0.5s;
  svg {
    shape-rendering: geometricPrecision;
  }

  &.size-small {
    height: 2.625rem;
    width: 2.625rem;
  }
`;

export const HeaderNavMenuSwitcher = styled.div.attrs((props) => ({
  className: `main-menu-header-nav-menu-switcher ${props.className || ''}`,
}))`
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
  width: calc(
    ${MenuConfigs.CONTAINER_WIDTH} - ${MenuConfigs.HEADER_CLOSED_WIDTH}
  );
  background: ${GlobalConfigs.newColors.p600};
  height: ${MenuConfigs.HEADER_HEIGHT};
  padding: 0 ${MenuConfigs.PADDING_X};
  z-index: 99;
  right: 0;
  transform: translate3d(0, 0, 0);

  @media (${MenuConfigs.MEDIA_MAX_MOBILE}) {
    display: none;
  }

  @keyframes menuHeaderSwitcherOpen {
    0% {
      width: calc(
        ${MenuConfigs.CONTAINER_WIDTH} - ${MenuConfigs.HEADER_CLOSED_WIDTH}
      );
    }
    1% {
      z-index: 101;
    }
    100% {
      z-index: 101;
      width: calc(${MenuConfigs.CONTAINER_WIDTH});
    }
  }

  @keyframes menuHeaderSwitcherClose {
    0% {
      z-index: 101;
      width: calc(${MenuConfigs.CONTAINER_WIDTH});
    }
    99% {
      z-index: 101;
      width: calc(
        ${MenuConfigs.CONTAINER_WIDTH} - ${MenuConfigs.HEADER_CLOSED_WIDTH}
      );
    }
    100% {
      z-index: 99;
    }
  }

  .${NAV_SWITCHER_OPEN_CLASS} & {
    animation: menuHeaderSwitcherOpen 0.4s ease;
    width: calc(${MenuConfigs.CONTAINER_WIDTH});
    z-index: 101;
  }

  .${NAV_SWITCHER_CLOSE_CLASS} & {
    animation: menuHeaderSwitcherClose 0.4s ease;
    width: calc(
      ${MenuConfigs.CONTAINER_WIDTH} - ${MenuConfigs.HEADER_CLOSED_WIDTH}
    );
    z-index: 99;
  }
`;

export const FloatButton = styled.div.attrs((props) => ({
  className: `main-menu-float-button ${props.className || ''}`,
}))`
  height: 56px;
  width: 56px;
  background-color: ${MenuConfigs.CONTAINER_BACKGROUND};
  border-radius: 100%;
  display: flex;
  overflow: hidden;
  position: fixed;
  bottom: 80px;
  right: 32px;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  z-index: 95;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.5);

  @media (${MenuConfigs.MEDIA_MIN_TABLET}) {
    display: none;
  }

  h5 {
    color: white;
    font-size: 12px;
    top: 0.125rem;
    font-weight: 300;
    position: relative;
  }

  .${NAV_MENU_OPEN_CLASS} & {
    &::before {
      opacity: 1;
      height: 3000px;
      width: 3000px;
      transition: opacity 0.1s ease, height 0.75s ease, width 0.75s ease;
    }
  }

  .${NAV_MENU_CLOSE_CLASS} & {
    &::before {
      opacity: 0;
      height: 56px;
      width: 56px;
      transition: opacity 0.5s ease 0.4s, height 0.5s ease, width 0.5s ease;
    }
  }

  &::before {
    content: '';
    height: 56px;
    width: 56px;
    background-color: ${MenuConfigs.CONTAINER_BACKGROUND};
    border-radius: 100%;
    position: fixed;
    z-index: 90;
    transform: translate3d(calc(100vwh - 64px), calc(100vh - 64px), 0);
    opacity: 0;
  }

  &.onfooter {
    ${media.tablet`
      bottom: 32px;
    `}
  }
`;

export const HamburguerIcon = styled.div.attrs((props) => ({
  className: `main-menu-hamburguer-icon ${props.className || ''}`,
}))`
  display: flex;
  flex-shrink: 0;
  svg {
    shape-rendering: geometricPrecision;
    width: 20px;
    fill: ${GlobalConfigs.newColors.n0};
  }
`;

export const CardInfoAccount = styled.div.attrs((props) => ({
  className: `main-menu-card-info-account ${props.className || ''}`,
}))`
  border-radius: 0.5rem;
  margin: 1.5rem 0 2rem;
  padding: 1rem;
  background-color: ${GlobalConfigs.newColors.s800};
  display: flex;
  flex-flow: column;
  color: ${GlobalConfigs.newColors.n100};
  span {
    font-size: 0.875rem;
    display: block;
    font-weight: 400;
    margin-bottom: 0.375rem;
    color: ${GlobalConfigs.newColors.n100};
  }
  h4 {
    font-size: 0.875rem;
    font-weight: bold;
  }
  & > :not([hidden]) ~ :not([hidden]) {
    margin-top: 1.5rem;
  }

  button {
    cursor: pointer;
    text-transform: none;
    font-family: inherit;
    line-height: inherit;
    background-color: transparent;
    border: none;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    color: ${GlobalConfigs.newColors.n100};
  }
`;

export const CardInfoAccountLine = styled.div.attrs((props) => ({
  className: `main-menu-card-info-account-line ${props.className || ''}`,
}))`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
`;

export const ButtonSwitchProfile = styled.div.attrs((props) => ({
  className: `main-menu-button-switch-profile ${props.className || ''}`,
}))`
  div {
    display: inline-flex;
    justify-content: start;
    border-radius: 1.5rem;
    background-color: ${GlobalConfigs.newColors.s800};
  }

  button {
    cursor: pointer;
    text-transform: none;
    font-family: inherit;
    line-height: inherit;
    background-color: transparent;
    border: none;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    padding: 0.75rem 1rem;
    color: ${GlobalConfigs.newColors.n50};
    font-weight: 500;
    letter-spacing: -0.3px;

    &.active {
      display: flex;
      align-items: center;
      color: ${GlobalConfigs.newColors.n900};
      background-color: ${GlobalConfigs.newColors.n50};
      border-radius: 1.5rem;
    }
  }
`;
export const Button = styled.div.attrs((props) => ({
  className: `main-menu-button ${props.className || ''}`,
}))`
  button {
    cursor: pointer;
    text-transform: none;
    font-family: inherit;
    line-height: inherit;
    border: none;
    -webkit-tap-highlight-color: transparent;
    padding: 0.75rem 1.5rem;
    color: ${GlobalConfigs.newColors.n50};
    display: inline-flex;
    justify-content: start;
    border-radius: 1.5rem;
    background-color: ${GlobalConfigs.newColors.s800};
    margin: 0;
    cursor: pointer;
    transition: 0.5s;

    &.active {
      color: ${GlobalConfigs.newColors.n900};
      background-color: ${GlobalConfigs.newColors.n50};
    }

    &:hover {
      color: ${GlobalConfigs.newColors.n0};
      background-color: ${GlobalConfigs.newColors.s700};
    }
  }
`;

export const MennuTitle = styled.h3.attrs((props) => ({
  className: `main-menu-title ${props.className || ''}`,
}))`
  font-size: 1.125rem;
  margin: 2rem 0 0rem;
  font-weight: 400;
`;
export const MenuAppVersion = styled.div.attrs((props) => ({
  className: `main-menu-app-version ${props.className || ''}`,
}))`
  position: fixed;
  bottom: 0;
  left: 1rem;
  height: ${MenuConfigs.APP_VERSION_HEIGHT};
  width: calc(${MenuConfigs.CONTAINER_WIDTH} - 2rem);
  text-align: center;
  background-color: ${MenuConfigs.CONTAINER_BACKGROUND};
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (${MenuConfigs.MEDIA_MAX_MOBILE}) {
    width: calc(100vw - 2rem);
  }
`;
export const Drawer = styled.div.attrs((props) => ({
  className: `main-menu-drawer ${props.className || ''}`,
}))`
  display: none;
  @media (${MenuConfigs.MEDIA_MAX_MOBILE}) {
    width: calc(100vw - 2rem);
    position: fixed;
    left: 1rem;
    height: ${MenuConfigs.APP_VERSION_HEIGHT};
    text-align: center;
    background-color: ${MenuConfigs.CONTAINER_BACKGROUND};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;
