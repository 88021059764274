import React, { useCallback, useContext, useEffect, useState } from 'react';
import useConstants from 'services/contants';
import useBankPixService from 'services/bank-pix';
import usePixKeysService from 'services/pix-keys';
import useToken from 'hooks/useToken';
import useAccountRegistry from './account-registry';
import usePixKeysError from 'services/errors/pix-keys-error';
import useErrorHandlerService from 'services/errors/error-handler';

export const PixKeysContext = React.createContext({
  keys: null,
  loading: false,
  success: false,
  error: false,
  none: true,
  errorInfo: {
    code: 0,
    showData: null,
    title: '',
    message: '',
    btnLabel: '',
  },
  loadKeys: () => {},
  reloadKeys: () => {},
  alreadyAddedKey: (type, value) => true || false,
});

export const PixKeysProvider = ({ children }) => {
  const token = useToken();
  const { registry } = useAccountRegistry();
  const { FETCH_STATES } = useConstants();
  const { errors } = usePixKeysError();
  const { getError } = useErrorHandlerService(errors);
  const { getKeys } = useBankPixService(token);
  const { pixKeysTexts, pixKeysValueFormatters } = usePixKeysService();

  const [fetchState, setFetchState] = useState(FETCH_STATES.NONE);
  const [keys, setKeys] = useState(null);
  const [errorInfo, setErrorInfo] = useState(null);

  const initial = fetchState === FETCH_STATES.INITIAL;
  const loading = fetchState === FETCH_STATES.LOADING;
  const success = fetchState === FETCH_STATES.SUCCESS;
  const error = fetchState === FETCH_STATES.ERROR;
  const none = fetchState === FETCH_STATES.NONE;
  const handleError = useCallback(
    (error) => {
      setFetchState(FETCH_STATES.ERROR);
      const result = getError(error);

      setErrorInfo(() => ({ ...result }));
    },
    [FETCH_STATES, getError]
  );

  const alreadyAddedKey = useCallback(
    (type, value) => {
      if (keys && keys.length > 0) {
        return keys.find((k) => k.type === type && k.value === value);
      }
      return false;
    },
    [keys]
  );

  const textByType = useCallback(
    (item) => {
      const texts = pixKeysTexts();

      return texts[item?.type];
    },
    [pixKeysTexts]
  );

  const keyFormatted = useCallback(
    (item) => {
      const texts = pixKeysValueFormatters(item.value);

      return texts[item?.type];
    },
    [pixKeysValueFormatters]
  );

  const formatKeys = useCallback(
    (keys) =>
      keys.map((key) => {
        return {
          text: textByType(key),
          formattedValue: keyFormatted(key),
          ...key,
        };
      }),
    [keyFormatted, textByType]
  );

  const fetchPixKeys = useCallback(
    async (accountNumber) => {
      try {
        setFetchState(FETCH_STATES.LOADING);
        const response = await getKeys(accountNumber);

        const { data } = response;

        if (data.length > 0) {
          const formattedKeys = formatKeys(data);

          setKeys(formattedKeys);
          setFetchState(FETCH_STATES.SUCCESS);
        }
      } catch (error) {
        handleError(error);
      }
    },
    [getKeys, formatKeys, handleError, FETCH_STATES]
  );

  const loadKeys = () => {
    setFetchState(FETCH_STATES.INITIAL);
  };

  const reloadKeys = () => {
    setKeys(null);
    setFetchState(FETCH_STATES.NONE);
  };

  useEffect(() => {
    let mounted = true;

    if (mounted && token && registry && initial) {
      fetchPixKeys(registry?.information?.number);
    }

    return () => {
      mounted = false;
    };
  }, [fetchPixKeys, token, registry, initial]);

  const defaultValue = {
    keys,
    loading,
    error,
    success,
    none,
    errorInfo,
    loadKeys,
    reloadKeys,
    alreadyAddedKey,
  };

  return (
    <PixKeysContext.Provider value={defaultValue}>
      {children}
    </PixKeysContext.Provider>
  );
};

const usePixKeysContext = () => useContext(PixKeysContext);

export default usePixKeysContext;
