import React from 'react';
import DataHolder from 'components/DataHolder';

import { Box } from './roundButton.style';

function RoundButtonLoader({ className, ...props }) {
  let loaderPattern = [{ w: 12 }];

  return (
    <Box className={className} {...props}>
      <DataHolder pattern={loaderPattern} />
    </Box>
  );
}

export default RoundButtonLoader;
