import React, { useCallback, useContext, useEffect, useState } from 'react';
import TwoColumnsLayout from 'components/TwoColumnsLayout';
import useAuthorization from 'hooks/useAuthorization';
import RegistrationsContext from 'contexts/registrations';
import { bankNumber } from 'config/constants';

import {
  ColumnBig,
  Container,
  TitleLine,
  AccountList,
  AuthCard,
  CloseButton,
  SmallInfo,
  Dark,
} from './styles';
import RadioButton from 'components/RadioButton';
import useToken from 'hooks/useToken';
import SingleLineLoader from 'components/Loaders/singleLineLoader';
import { PATHS } from 'Routes/constants';
import { useHistory } from 'react-router-dom';
import useAccountsService from 'services/accounts';

const DEFAULT_ERROR = { error: 'not_found' };
const PAGE_STATES = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const SwitchBankAccounts = () => {
  const token = useToken();
  const history = useHistory();
  const { userAuthorization, getBankAccount, setBankAccount } =
    useAuthorization();
  const { loadPassword } = useContext(RegistrationsContext);
  const { getAccountRegistry } = useAccountsService(token);
  const [pageState, setPageState] = useState(PAGE_STATES.INITIAL);
  const [accountInfo, setAccountInfo] = useState(null);

  const loading = pageState === PAGE_STATES.LOADING;
  const initial = pageState === PAGE_STATES.INITIAL;

  const handleResponseAccountRegistry = useCallback(
    (response) => {
      if (response?.data?.information) {
        const { data } = response;
        const { information } = data;

        setAccountInfo(information);
        loadPassword();
        setPageState(PAGE_STATES.SUCCESS);
      } else {
        setPageState(PAGE_STATES.ERROR);
        setAccountInfo(DEFAULT_ERROR);
      }
    },
    [loadPassword]
  );

  /*
   * Load account information
   */
  useEffect(() => {
    let mounted = true;

    const fetchAccountRegistry = async () => {
      try {
        setPageState(PAGE_STATES.LOADING);
        handleResponseAccountRegistry(await getAccountRegistry());
      } catch (e) {
        setPageState(PAGE_STATES.ERROR);
        setAccountInfo(DEFAULT_ERROR);
      }
    };

    if (mounted && initial && token) {
      fetchAccountRegistry();
    }

    return () => (mounted = false);
  }, [getAccountRegistry, handleResponseAccountRegistry, initial, token]);

  const setAccount = (value) => {
    setAccountInfo(false);
    setBankAccount(value);
    setPageState(PAGE_STATES.INITIAL);
  };

  const GoToDashboard = () => history.push(PATHS.BANK.DASHBOARD);

  return (
    userAuthorization && (
      <Container>
        <TwoColumnsLayout>
          <ColumnBig>
            <TitleLine big>
              Qual conta deseja utilizar?
              {loading ? (
                <SingleLineLoader />
              ) : (
                accountInfo &&
                accountInfo.branch && (
                  <SmallInfo>
                    <br />
                    <Dark>Conta ativa: </Dark>
                    <br />
                    Banco: <Dark>{bankNumber}</Dark> / Agência:{' '}
                    <Dark> {accountInfo.branch}</Dark> / Conta:{' '}
                    <Dark> {accountInfo.number}</Dark>
                  </SmallInfo>
                )
              )}
              <CloseButton medium yellow onClick={GoToDashboard}>
                Ver em Meu Contbank
              </CloseButton>
            </TitleLine>
          </ColumnBig>
          <ColumnBig>
            {userAuthorization?.banks && (
              <AccountList>
                {userAuthorization.banks.map(
                  (bank) =>
                    bank.current_identity && (
                      <AuthCard
                        key={bank.current_identity}
                        white
                        noTextOverflow
                        title={bank.name}
                      >
                        <RadioButton
                          id={bank.current_identity}
                          name="bank_id"
                          text={bank.name}
                          value={bank.current_identity}
                          checked={getBankAccount() === bank.current_identity}
                          className=""
                          onChange={(ev) => setAccount(ev.target.value)}
                          noTextOverflow
                        />
                      </AuthCard>
                    )
                )}
              </AccountList>
            )}
          </ColumnBig>
        </TwoColumnsLayout>
      </Container>
    )
  );
};

export default SwitchBankAccounts;
