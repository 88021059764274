import { camelize } from './index';

export const separateSearchWordFromPhrase = (text = '', searchText) => {
  const searchRegex = new RegExp(camelize(searchText));
  const splittedPhrase = camelize(text)
    .replace(searchRegex, (match) => {
      return `_${match}_`;
    })
    .split('_');

  return splittedPhrase;
};
