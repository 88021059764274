import React from 'react';

import { Block } from './styles';
import HydeIcon from 'assets/images/HydeIcon';

const HydeButton = ({ className, children, ...props }) => {
  return (
    <Block title="Esconder" className={className} {...props}>
      <HydeIcon />
    </Block>
  );
};

export default HydeButton;
