import styled from 'styled-components';
import Configs from 'styles/variables';
import media from 'styles/media';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background: ${Configs.yellow};
  display: flex;
  flex-direction: column;
`;

export const LogoContainer = styled.div`
  height: 50vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  svg {
    position: relative;
    left: -6px;
    stroke: ${Configs.newColors.n900};
    fill: ${Configs.newColors.n900};
    width: auto;
    height: auto;

    ${media.mobile`
     left: -10px;
    `}
  }
`;

export const Wrapper = styled.div`
  height: 50vh;
  width: 100vw;
  padding-top: 9px;
  display: flex;
  justify-content: center;
  padding: 0px;
`;

export const TextContainer = styled.div`
  text-align: center;
  align-items: flex-start;
  color: ${Configs.newColors.n900};
  font-family: ${Configs.fontFamily};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${media.mobile`
    padding-top: 11px;
    font-size: 16px;
  `}
`;
