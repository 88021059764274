import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import ReactPortal from 'components/ReactPortal';
import * as S from './style';

export default function AlertProgress({
  children,
  isOpen,
  sizeProgressSeconds = 2,
  callback,
}) {
  const nodeRef = useRef(null);

  if (isOpen) {
    setTimeout(() => {
      if (callback) {
        callback();
      }
    }, (sizeProgressSeconds + 0.2) * 1000);
  }

  return (
    <ReactPortal
      wrapperId="react-portal-alert-progress-container"
      deleteProgramatically={false}
    >
      <CSSTransition
        in={isOpen}
        timeout={{ entry: 0, exit: 300 }}
        unmountOnExit
        classNames="alert-progress-overlay"
        nodeRef={nodeRef}
      >
        <S.Overlay ref={nodeRef}>
          <S.AlertProgress>
            <S.Content>{children}</S.Content>
            <S.ProgressBar sizeProgressSeconds={sizeProgressSeconds} />
          </S.AlertProgress>
        </S.Overlay>
      </CSSTransition>
    </ReactPortal>
  );
}
