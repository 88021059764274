import { useLayoutEffect } from 'react';
/*
 * View height of mobile browser are calculate using brower view height
 * without consider browser content like address bar or buttons to go back.
 * 1% of the view vh was browser content;
 */
const useWevViewPortMobile = () => {
  /*
   * This method update css --vh-base root variable;
   */
  const updateCssVhVariable = () => {
    let vh = window.innerHeight * 0.01;

    const root = document.querySelector(':root');

    root.style.setProperty('--vh-base', `${vh}px`);
  };

  useLayoutEffect(() => {
    let mounted = true;

    if (mounted) {
      updateCssVhVariable();
    }

    return () => {
      mounted = false;
    };
  }, []);
};

export default useWevViewPortMobile;
