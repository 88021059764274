import React from 'react';
import { Link } from 'react-router-dom';
import * as S from '../style';

export default function MenuListItem({
  icon,
  to,
  label,
  description,
  className,
  onClick,
  sizeIcon,
  target,
}) {
  return (
    <S.NavMenuListItem className={className}>
      {to && (
        <Link to={to || ''} target={target}>
          {icon && (
            <S.NavMenuItemIcon className={'icon'} size={sizeIcon || 'small'}>
              {icon}
            </S.NavMenuItemIcon>
          )}{' '}
          <div>
            <h4 className="label">{label}</h4>
            {description && <h6 className="description">{description}</h6>}
          </div>
        </Link>
      )}

      {!to && (
        <button onClick={onClick}>
          {icon && (
            <S.NavMenuItemIcon className={'icon'} size={sizeIcon || 'small'}>
              {icon}
            </S.NavMenuItemIcon>
          )}{' '}
          <div>
            <h4 className="label">{label}</h4>
            {description && <h6 className="description">{description}</h6>}
          </div>
        </button>
      )}
    </S.NavMenuListItem>
  );
}
