import UserAuthorizationContext from 'contexts/authorization';
import useToken from 'hooks/useToken';
import { useContext } from 'react';
import api from './api';
const endpoint = '/boletos';
const newEndpoint = 'frontend-bff/billings';

const useBankSlipsService = (token) => {
  const serviceToken = useToken();
  const { getBankAccount } = useContext(UserAuthorizationContext);

  const postBankSlip = async (data) => {
    return await api.post(endpoint, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const getBankSlipById = async (id) => {
    const url = `${endpoint}/${id}`;

    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const getBankSlips = async (text, dsc = false, page = 1, perPage = 10) => {
    let url = `/boletos?page=${page}&per_page=${perPage}`;

    url = text ? `${url}&text=${text}` : url;
    url = dsc ? `${url}&desc=true` : `${url}&desc=false`;
    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const getBankSlipByBarCode = async (code) => {
    const url = `${endpoint}/barcode/${code}`;

    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const downloadBankSlip = async (id) => {
    const url = `${newEndpoint}/${id}/download`;

    return await api.get(url, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
      responseType: 'blob',
    });
  };

  const validateBankSlip = async (code, token) => {
    const url = `${endpoint}/payments/validate`;

    return await api.post(url, code, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  const payBankSlip = async (data, code, token) => {
    const url = `${endpoint}/payments/confirm`;

    return await api.post(url, data, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
        'X-Transaction-Token': `${code}`,
      },
    });
  };

  const getBankSlipPaidById = async (id) => {
    return await api.get(`${endpoint}/payments/${id}`, {
      headers: {
        Authorization: `Bearer ${token || serviceToken}`,
        'X-Current-Identity': `${getBankAccount()}`,
      },
    });
  };

  return {
    postBankSlip,
    getBankSlips,
    getBankSlipById,
    getBankSlipByBarCode,
    downloadBankSlip,
    validateBankSlip,
    payBankSlip,
    getBankSlipPaidById,
  };
};

export const fetchValidateBankSlip = async (
  code,
  token,
  getBankAccount,
  serviceToken
) => {
  const url = `${endpoint}/payments/validate`;

  return await api.post(url, code, {
    headers: {
      Authorization: `Bearer ${token || serviceToken}`,
      'X-Current-Identity': `${getBankAccount()}`,
    },
  });
};

export default useBankSlipsService;
