import React from 'react';
import NavigationIcon from 'assets/images/NavigationIcon';
import * as S from '../style';

export default function MenuFloatButton(props) {
  return (
    <S.FloatButton {...props}>
      <S.HamburguerIcon>
        <NavigationIcon />
      </S.HamburguerIcon>
      <h5>Menu</h5>
    </S.FloatButton>
  );
}
