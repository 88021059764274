import React, { useEffect, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PATHS } from 'Routes/constants';
import { BankSlipsProvider } from 'contexts/bank-slips';
import { ContactProvider } from 'contexts/contact';
import { AccountRegistryByIdentifierProvider } from 'contexts/account-registry-by-identifier';
import { updateQueryString } from 'services/history';
import WizardContext from 'contexts/wizard';

const BankSlipsList = React.lazy(() => import('./List'));
const BankSlipsForm = React.lazy(() => import('./Add'));
const BankSlipDetails = React.lazy(() => import('./Details'));

const BankSlips = () => {
  const { currentStep } = useContext(WizardContext);

  useEffect(() => {
    if (currentStep > 1) {
      updateQueryString('step', currentStep);
    }
  }, [currentStep]);

  return (
    <Switch>
      <Route path={PATHS.BANKSLIP.SEND} exact>
        <ContactProvider>
          <AccountRegistryByIdentifierProvider>
            <BankSlipsForm />
          </AccountRegistryByIdentifierProvider>
        </ContactProvider>
      </Route>
      <Route path={PATHS.BANKSLIP.SENDTO} exact>
        <ContactProvider>
          <AccountRegistryByIdentifierProvider>
            <BankSlipsForm />
          </AccountRegistryByIdentifierProvider>
        </ContactProvider>
      </Route>
      <Route path={PATHS.BANKSLIP.LIST} exact>
        <BankSlipsProvider>
          <BankSlipsList />
        </BankSlipsProvider>
      </Route>
      <Route path={PATHS.BANKSLIP.DETAILS} exact>
        <BankSlipDetails />
      </Route>
    </Switch>
  );
};

export default BankSlips;
