import React, { forwardRef, useEffect, useState } from 'react';
import { Field } from '../styles';

let DateInput = ({ className, inputId, ...props }, input) => {
  let minDate = props.minDate || undefined;
  let maxDate = props.maxDate || undefined;

  let [count, setCount] = useState(0);
  const [dirt, setDirt] = useState(false);

  useEffect(() => {
    let mounted = true;
    const el = input.current;

    if (
      mounted &&
      count === 0 &&
      !dirt &&
      el &&
      props.defaultValue &&
      props.defaultValue !== 'undefined'
    ) {
      el.value = props.defaultValue.value || props.defaultValue;
      setCount(count + 1);
    }
    return () => (mounted = false);
  }, [input, props.defaultValue, count, dirt]);

  const handleOnChange = (value) => {
    if (props.onChange) {
      props.onChange(value);
    }

    setDirt(true);
  };

  return (
    <Field
      {...props}
      className={className}
      id={inputId}
      ref={input}
      defaultValue={props.defaultValue}
      onChange={handleOnChange}
      type="date"
      min={minDate}
      max={maxDate}
    />
  );
};

export default forwardRef(DateInput);
