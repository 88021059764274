import React from 'react';
import Logo from 'assets/images/ContbankLogo';

import { Box } from './styles';

const LogoBox = ({ location, className, ...props }) => {
  return (
    <Box className={className} {...props}>
      <Logo />
    </Box>
  );
};

export default LogoBox;
