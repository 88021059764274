// @ts-nocheck
import styled, { css } from 'styled-components';
import Configs from 'styles/variables';

const outlinedCss = css`
  border: 1px solid black;
  background: ${Configs.newColors.n0};
  color: ${Configs.black};
`;

const outlinedHoverCss = css`
  border: 1px solid black;
  background: ${Configs.newColors.n100};
  color: ${Configs.black};
`;

const yellowHoverCss = css`
  border: 1px solid ${Configs.newColors.p800};
  background: ${Configs.newColors.p800};
  color: ${Configs.black};
`;

const dangerHoverCss = css`
  border: 1px solid ${Configs.newColors.a400};
  background: ${Configs.newColors.a100};
  color: ${Configs.newColors.a400};
`;

const dangerCss = css`
  border: 1px solid ${Configs.newColors.a400};
  background: ${Configs.newColors.a400};
  color: ${Configs.newColors.n0};
`;

const yellowCss = css`
  border: 1px solid ${Configs.yellow};
  background: ${Configs.yellow};
  color: ${Configs.black};
`;

const whiteCss = css`
  background: ${Configs.white};
  color: ${Configs.black};
  border: 1px solid ${Configs.black};
`;

const blackCss = css`
  border: 1px solid ${Configs.white};
  background: ${Configs.black};
  color: ${Configs.white};
`;

const blackCssBorder = css`
  border: solid 1px ${Configs.white};
  background: ${Configs.black};
  color: ${Configs.white};
`;

const defaultCss = css`
  border: 1px solid ${Configs.buttons.background};
  background: ${Configs.buttons.background};
  color: ${Configs.buttons.color};
`;

const defaultHoverCss = css`
  border: 1px solid ${Configs.buttons.hover.background};
  background: ${Configs.buttons.hover.background};
  color: ${Configs.buttons.hover.color};
`;

const getButtonCss = ({ black, white, yellow, danger, tertiary, outlined }) => {
  if (black) return blackCss;
  if (white) return whiteCss;
  if (yellow) return yellowCss;
  if (outlined) return outlinedCss;
  if (danger) return dangerCss;
  return defaultCss;
};

const getButtonHoverCss = ({
  black,
  white,
  yellow,
  danger,
  tertiary,
  outlined,
  newType,
}) => {
  if (black) return yellow ? yellowCss : whiteCss;
  if (yellow) {
    if (white) return whiteCss;
    return newType ? yellowHoverCss : blackCssBorder;
  }
  if (white) return yellow ? yellowCss : blackCss;
  if (outlined) return outlinedHoverCss;
  if (danger) return dangerHoverCss;
  return defaultHoverCss;
};

export const ButtonBox = styled.button.attrs((props) => ({
  background: props.black
    ? Configs.black
    : props.white
    ? Configs.white
    : props.yellow
    ? Configs.yellow
    : props.darkYellow
    ? Configs.darkYellow
    : Configs.buttons.background,
  backgroundHover: props.black
    ? Configs.white
    : props.white
    ? Configs.black
    : props.yellow || props.darkYellow
    ? Configs.black
    : Configs.buttons.hover.background,
  color: props.black ? Configs.white : Configs.buttons.color,
  colorHover: props.black ? Configs.buttons.color : Configs.white,
  paddingX: props.big ? 35 : Configs.buttons.paddingX,
  paddingY: props.big ? 35 : props.medium ? 24 : 0,
  size: 24,
}))`
  border: none;
  display: flex;
  height: ${Configs.buttons.height}px;
  justify-content: center;
  align-items: center;
  margin-bottom: 26px;
  border-radius: ${(props) => props.paddingX}px;
  font-size: ${(props) => props.size}px;
  padding: ${(props) => props.paddingY}px ${(props) => props.paddingX}px;
  transition: all 0.25s ease;
  box-shadow: ${(props) => (props.big ? '0 0 10px 0 rgba(0, 0, 0, 0.09)' : '')};
  cursor: pointer;
  position: relative;
  ${(props) => getButtonCss(props)}

  :hover {
    ${(props) => getButtonHoverCss(props)}
  }

  &.active {
    background: ${Configs.buttons.active.background};
    color: ${Configs.buttons.active.color};
  }
  &.inactive,
  &[disabled] {
    background: ${Configs.lightGray};
    color: ${Configs.mediumGray};
    cursor: not-allowed;
    border-color: ${Configs.mediumGray};
    & svg {
      fill: ${Configs.mediumGray};
    }
    & > .label {
      cursor: not-allowed;
    }
  }
  &.hasIcon {
    svg {
      margin-left: 8px;
      margin-right: -8px;
      display: inline;
    }
  }
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 2.29;
  letter-spacing: -0.28px;
  display: block;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  ${({ noTextOverflow }) =>
    noTextOverflow &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `};
`;
