import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import App from './containers/App';
import store from 'store';
import './translate/i18n';
import { registerServiceWorker } from './serviceWorker';
import 'normalize.css';

registerServiceWorker();

// Disabled Sentry on localhost
if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: 'https://2b12eecda2104770aad993c23e4af492@o1108600.ingest.sentry.io/6136365',
    integrations: [new Sentry.BrowserTracing()],
    normalizeDepth: 10,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate:
      window.location.hostname === 'app.contbank.com' ? 0.5 : 1.0,
    environment:
      process.env.REACT_APP_ENV === 'PROD' ? 'production' : 'sandbox',
  });
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
