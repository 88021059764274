export const validateCPF = (cpf) => {
  cpf = `${cpf}`.replace(/[^\d]+/g, '');

  let Sum = 0;
  let Rest;

  if (cpf.length !== 11) return false;
  if (new Set(cpf.split('')).size === 1) return false;

  for (let i = 1; i <= 9; i++) {
    Sum = Sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  Rest = (Sum * 10) % 11;
  if (Rest === 10 || Rest === 11) Rest = 0;
  if (Rest !== parseInt(cpf.substring(9, 10))) return false;

  Sum = 0;
  for (let i = 1; i <= 10; i++) {
    Sum = Sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  Rest = (Sum * 10) % 11;
  if (Rest === 10 || Rest === 11) Rest = 0;
  if (Rest !== parseInt(cpf.substring(10, 11))) return false;

  return true;
};

export const maskCPF = (cpf) =>
  (cpf = `${cpf}`
    .replace(/[^\d]+/g, '')
    .replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'));

export const maskHiddenCPF = (cpf) =>
  (cpf = `${cpf}`.replace(
    /^((\d|\*){3})((\d|\*){3})((\d|\*){3})((\d|\*){2})/,
    '$1.$3.$5-$7'
  ));

export const maskCPFWithHiddenFields = (cpf) =>
  (cpf = `${cpf}`
    .replace(/[^\d]+/g, '')
    .replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '***.$2.$3-**'));
